import * as React from "react";
import * as cn from "classnames";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { RouteComponentProps } from "react-router-dom";
import { push } from "connected-react-router";

import { WithInnerHeight, RippleEffect, LoadingOverlay } from "@components/common";
import { introImgs } from "@assets";
import { AppState } from "@state/store";
import { LoginRoute, IntroRoute } from "@common/routes";
import { intoWatchedAction } from "@state/user";
import { setIntroWatched } from "@common/helpers";

import "./Intro.scss";
import { useState } from "react";

interface IntroProps {
  step: number;
  isLoggedIn: boolean;

  onGoToStep(step: number): void;
  onGoToLogin(): void;
  onIntroWatchded(): void;
}
const titles = ["Välkommen", "Att-göra-lista", "Utveckling"];

export const Intro = connect(
  (state: AppState, ownProps: RouteComponentProps<{ step: string }>) => ({
    isLoggedIn: !!state.user.data,
    step: +ownProps.match.params.step
  }),
  (dispatch: Dispatch) => ({
    onGoToStep(step: number) {
      dispatch(push(IntroRoute.format({ step })));
    },
    onIntroWatchded() {
      dispatch(intoWatchedAction({}));
    },
    onGoToLogin() {
      dispatch(push(LoginRoute.format({})));
    }
  })
)(({ step, isLoggedIn, onGoToLogin, onGoToStep, onIntroWatchded }: IntroProps) => {
  const [showLoading, setShowLoading] = useState(false);
  const index = step - 1;

  const onNext = () => {
    if (index === 2) {
      setIntroWatched();
      setShowLoading(true);
      isLoggedIn ? onIntroWatchded() : onGoToLogin();
    } else {
      onGoToStep(step + 1);
    }
  };

  return (
    <WithInnerHeight>
      <LoadingOverlay isShown={showLoading} />
      <div className="Intro">
        <div className="Intro__top">
          <img src={introImgs[index]} alt="" />
        </div>
        <div className="Intro__bottom">
          <h3 className="Intro__title">{titles[index]}</h3>
          <div className={cn("Intro__textWrapper", `Intro__textWrapper--step${step}`)}>
            <p className="Intro__text">
              {isLoggedIn ? (
                <>Pensa är appen som enkelt sköter din pension.</>
              ) : (
                <>Utan kostnad får du tillgång till en tjänst från Pensionskraft som enkelt sköter din pension.</>
              )}
            </p>
            <p className="Intro__text">I din att-göra-lista ser du vad du ska göra för att höja ditt pensionsbetyg.</p>
            <p className="Intro__text">
              Du kan följa hur din pension utvecklas. Varje månad får du ett meddelande som visar hur dina fonder har
              gått
            </p>
          </div>
          <div className="Intro__nav">
            {index !== 0 && (
              <RippleEffect
                className="Intro__navAction Intro__navAction--prev"
                rounded
                onClick={() => onGoToStep(step - 1)}
              >
                Föregående
              </RippleEffect>
            )}
            <div className="Intro__navBullets">
              <div className={cn("Intro__navBullet", { "Intro__navBullet--active": index === 0 })} />
              <div className={cn("Intro__navBullet", { "Intro__navBullet--active": index === 1 })} />
              <div className={cn("Intro__navBullet", { "Intro__navBullet--active": index === 2 })} />
            </div>
            <RippleEffect
              className={cn("Intro__navAction", { "Intro__navAction--primary": index === 2 })}
              rounded
              onClick={onNext}
            >
              {index === 2 ? "Sätt igång!" : "Nästa"}
            </RippleEffect>
          </div>
        </div>
      </div>
    </WithInnerHeight>
  );
});
