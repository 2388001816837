import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { push } from "connected-react-router";

import { Layout } from "@components/common";
import { AppState } from "@state/store";
import { HomeRoute, NotificationDetailsRoute } from "@common/routes";
import { InAppMessage, MessageCodes } from "@common/models";
import { visibleMessagesSelector } from "@state/notifications";
import { icons } from "@assets";

import { NotificationItem } from "./item";

import "./NotificationList.scss";

interface NotificationsListComponentProps {
  messages: InAppMessage[];

  onGoBack(): any;
  onNotificationClick(code: MessageCodes): any;
}

export const NotificationList = connect(
  (state: AppState) => ({
    messages: visibleMessagesSelector(state)
  }),
  (dispatch: Dispatch) => {
    return {
      onGoBack() {
        dispatch(push(HomeRoute.format({})));
      },
      onNotificationClick(code: MessageCodes) {
        dispatch(push(NotificationDetailsRoute.format({ code })));
      }
    };
  }
)(({ messages, onGoBack, onNotificationClick }: NotificationsListComponentProps) => {
  return (
    <Layout title="Meddelanden" onGoBack={onGoBack}>
      <div className="NotificationList">
        {messages.length ? (
          messages.map(msg => (
            <NotificationItem
              key={msg.code}
              title={msg.title}
              description={msg.summary}
              sentDate={msg.date}
              isRead={!!msg.viewed || !!msg.completed}
              onClick={() => onNotificationClick(msg.code)}
            />
          ))
        ) : (
          <div className="NotificationList__empty">
            <img className="NotificationList__emptyImage" src={icons.noMessages} alt="" />
            <p className="NotificationList__emptyTitle">Du har inga nya meddelanden.</p>
            <p className="NotificationList__emptyDescription">Du får en notis när det finns nya meddelanden.</p>
          </div>
        )}
      </div>
    </Layout>
  );
});
