import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";

import {
  LoginLayout,
  TextField,
  TextFieldType,
  Button,
  BlockerScreen,
  BlockerScreenType,
  Checkbox
} from "@components/common";
import { icons } from "@assets";
import { FormField, validateForm, FormBase, isFormValid } from "@common/form";
import { AppState } from "@state/store";
import { logInWithBankIDAction } from "@state/auth";
import { isMobile, getPersonNumber, setPersonNumber, clearPersonNumber } from "@common/helpers";

import "./Login.scss";

interface LoginComponentProps {
  isLoginInProgress: boolean;

  onLogin(personNumber: string): any;
}

interface LoginForm extends FormBase {
  personNumber: FormField<string>;
}

interface LoginComponentState {
  form: LoginForm;
  rememberPersonNumber: boolean;
}

const constraints = {
  personNumber: {
    presence: {
      message: "Glöm inte fylla i din personnummer!",
      allowEmpty: false
    },
    format: {
      message: "Kontrollera din Personnummer!",
      pattern: /^\d{6,8}[-|(\s)]{0,1}\d{4}$/
    }
  }
};

class LoginComponent extends React.Component<LoginComponentProps, LoginComponentState> {
  readonly state: LoginComponentState = {
    form: {
      personNumber: { value: getPersonNumber() || "" }
    },
    rememberPersonNumber: !!getPersonNumber()
  };

  controlChanged(controlName: keyof LoginForm, controlValue: string) {
    const form = { ...this.state.form };
    form[controlName].value = controlValue;
    this.setState(prev => ({
      ...prev,
      form
    }));
  }

  onSumbit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.onLoginClick();
  };

  onToggleRememberPersonNumber = () => {
    this.setState(
      prev => ({
        ...prev,
        rememberPersonNumber: !prev.rememberPersonNumber
      }),
      () => {
        if (!this.state.rememberPersonNumber) {
          clearPersonNumber();
        }
      }
    );
  };

  onLoginClick = () => {
    const form = validateForm(this.state.form, constraints);
    if (isFormValid(form)) {
      if (this.state.rememberPersonNumber) {
        setPersonNumber(form.personNumber.value);
      }
      this.props.onLogin(form.personNumber.value);
    } else {
      this.setState(prev => ({
        ...prev,
        form
      }));
    }
  };

  render() {
    const { isLoginInProgress } = this.props;
    const { form, rememberPersonNumber } = this.state;

    return isLoginInProgress ? (
      <BlockerScreen type={BlockerScreenType.Progress} title={`${isMobile() ? "Startar" : "Starta"} din BankID-app`} />
    ) : (
      <LoginLayout className="Login">
        <img className="Login__logo" src={icons.logoPensionskraft} alt="" />
        <p className="Login__text">Logga in med mobilt BankID</p>
        <form className="Login__form" noValidate autoComplete="off" onSubmit={this.onSumbit}>
          <div className="Login__formField">
            <TextField
              type={TextFieldType.Text}
              label="Personnummer"
              value={form.personNumber.value}
              onChange={val => this.controlChanged("personNumber", val.trim())}
            />
            <div className="Settings__line" />
            {form.personNumber.errors && form.personNumber.errors.length && (
              <p className="Login__formFieldError">{form.personNumber.errors[0]}</p>
            )}
            <Checkbox
              value={rememberPersonNumber}
              label="Spara mitt personnummer"
              onClick={this.onToggleRememberPersonNumber}
            />
          </div>

          <Button
            text="Logga in"
            logo={icons.logoBankid}
            onClick={this.onLoginClick}
            disabled={!form.personNumber.value}
          />
        </form>
      </LoginLayout>
    );
  }
}

export const Login = connect(
  (state: AppState) => ({
    isLoginInProgress: state.auth.isBankdIDLoginInProgress
  }),
  (dispatch: Dispatch) => ({
    onLogin(personNumber: string) {
      dispatch(logInWithBankIDAction({ personNumber }));
    }
  })
)(LoginComponent);
