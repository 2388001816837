import * as React from "react";
import ReactSelect from "react-select";
import AsyncSelect from "react-select/async";
import { ValueType, GroupedOptionsType, OptionsType, ActionMeta } from "react-select";
import { InfoPopup } from "@components/common/infoPopup";
import * as classNames from "classnames";

import "./Select.scss";

export interface OptionType {
  label: string;
  value: string;
}

type OptionsProp = GroupedOptionsType<OptionType> | OptionsType<OptionType>;

const findOptionFromValue = (options?: OptionsProp, value?: string) => {
  let optionFromValue: OptionType | null = null;

  if (options) {
    for (let i = 0; i < options.length && !optionFromValue; i++) {
      if (options[i]["options"]) {
        const nestedOptions: OptionsType<OptionType> = options[i]["options"];
        const optionKey = Object.keys(nestedOptions).find((key) => nestedOptions[key].value === value);
        if (optionKey) {
          optionFromValue = nestedOptions[optionKey];
        }
      } else if (options[i].value === value) {
        optionFromValue = options[i] as OptionType;
      }
    }
  }

  return optionFromValue;
};

export interface SelectProps {
  readonly label?: string;
  readonly className?: string;
  readonly value?: string;
  readonly options?: OptionsProp;
  readonly disabled?: boolean;
  readonly placeholder?: string;
  readonly id?: string;
  readonly isSearchable?: boolean;
  readonly noOptionsMessage?: (obj: { inputValue: string }) => string | null;
  menuPlacement?: "auto" | "top" | "bottom";
  readonly info?: React.ReactNode;
  onChange?: (value: ValueType<OptionType>, action: ActionMeta) => void;
  onBlur?: React.FocusEventHandler;
}

export const Select: React.SFC<SelectProps> = (props) => {
  const optionFromValue = findOptionFromValue(props.options, props.value);

  return (
    <div className="Select" id={props.id}>
      {props.label && (
        <p className="Select__label">
          {props.label}
          {props.info && <InfoPopup popupContent={props.info} />}
        </p>
      )}
      <ReactSelect
        className={classNames("react-select-container", props.className)}
        classNamePrefix="react-select"
        isDisabled={props.disabled}
        value={optionFromValue}
        options={props.options}
        placeholder={props.placeholder}
        onChange={props.onChange}
        isSearchable={props.isSearchable}
        noOptionsMessage={props.noOptionsMessage || (() => "Inga optioner...")}
        onBlur={props.onBlur}
        menuPlacement={props.menuPlacement}
      />
    </div>
  );
};

export interface SelectAsync {
  label?: string;
  className?: string;
  value: OptionType | null;
  disabled?: boolean;
  placeholder?: string;
  id?: string;

  loadOptions: (inputValue: string, callback: (options: OptionsType<OptionType>) => void) => Promise<any> | void;
  noOptionsMessage?: (obj: { inputValue: string }) => string | null;
  loadingMessage?: (obj: { inputValue: string }) => string | null;

  onChange(v: OptionType): any;
}

export const SelectAsync = (props: SelectAsync) => {
  return (
    <div className="Select" id={props.id}>
      {props.label && <p className="Select__label">{props.label}</p>}
      <AsyncSelect
        className={classNames("react-select-container", props.className)}
        classNamePrefix="react-select"
        isDisabled={props.disabled}
        value={props.value}
        loadOptions={props.loadOptions}
        loadingMessage={props.loadingMessage}
        noOptionsMessage={props.noOptionsMessage}
        onChange={props.onChange}
        placeholder={props.placeholder}
        menuPlacement="auto"
      />
    </div>
  );
};
