import { defineAction } from "../../lib/rd-action-creators/index";

export const signDocumentAction = defineAction<{ signature: string; isReview?: boolean }>("CHANGE FUNDS SIGN DOCUMENT");
export const sendChangeFundFormAction = defineAction<{ email: string }>("CHANGE FUNDS SEND FORM");

export const showFormPreviewAction = defineAction<{ signature: string; pdfViewerDocUrl: string; itemId: string }>(
  "CHANGE FUNDS SHOW FORM PREVIEW"
);

export const toggleFormPreviewAction = defineAction<{}>("CHANGE FUNDS TOGGLE FORM PREVIEW");

export const submitPPMChangeFundsAction = defineAction("SUBMIT PPM CHANGE FUNDS");
