import * as React from "react";

import { MeterType } from "@common/types";
import { icons } from "@assets";
import { getMeterTitle } from "@common/helpers";

import "./MeterItem.scss";

interface MeterItemProps {
  type: MeterType;
  score: number;
  increase?: number;
}

const typeToContent = {
  [MeterType.Funds]: {
    icon: icons.funds,
    description: "Här ser du det genomsnittliga fondbetyget för dina fonder."
  },
  [MeterType.Risk]: {
    icon: icons.risk,
    description:
      "Visar om du har rätt risk just för dig. Ju närmare rätt risk desto högre betyg. Vilken risknivå du har kan du se i menyn och under inställningar."
  },
  [MeterType.Savings]: {
    icon: icons.save,
    description: "Visar om du har tillräckligt mycket sparat pensionskapital för din ålder."
  }
};

export const MeterItem = ({ type, score, increase }: MeterItemProps) => {
  return (
    <div className="MeterItem">
      <div className="MeterItem__header">
        <img className="MeterItem__icon" src={typeToContent[type].icon} alt="" />
        <h3 className="MeterItem__title">{getMeterTitle(type)}</h3>
      </div>
      <p className="MeterItem__description">{typeToContent[type].description}</p>
      {(!!increase || increase === 0) && (
        <p className="MeterItem__description">
          Ditt {getMeterTitle(type).toLowerCase()}betyg {increase > 0 ? "går upp" : "ändras"} med{" "}
          <strong>{increase.toLocaleString("sv", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</strong> till{" "}
          <strong>{score.toLocaleString("sv", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</strong>
        </p>
      )}
      <MeterItemProgress progress={score} />
      <h3 className="MeterItem__score">
        {score.toLocaleString("sv", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
      </h3>
    </div>
  );
};

interface MeterItemProgressProps {
  progress: number;
}

const MeterItemProgress = ({ progress }: MeterItemProgressProps) => {
  return (
    <div className="MeterItemProgress">
      {(Array.apply(null, Array(5)) as number[]).map((_, index) => {
        const diff = progress - index;
        const width = diff > 1 ? "100%" : `${diff * 100}%`;
        return (
          <div key={index} className="MeterItemProgress__track">
            <div className="MeterItemProgress__fill" style={{ width }} />
          </div>
        );
      })}
    </div>
  );
};
