import * as React from "react";

import { Layout, LoadingOverlay, Button, RippleEffect } from "@components/common";
import { AxaInsuranceOptions } from "@common/models";

import "./LifeInsuranceBenefits.scss";

interface LifeInsuranceBenefitsProps {
  uncoveredCapital: number;
  alternatives: AxaInsuranceOptions | undefined;
  isSkipping: boolean;
  isUpsell: boolean;

  onNext(selectedAmount: number): any;
  onPrev(): any;
  onSkip(): any;
}

export const LifeInsuranceBenefits = ({
  alternatives,
  isSkipping,
  isUpsell,
  uncoveredCapital,
  onPrev,
  onNext,
  onSkip,
}: LifeInsuranceBenefitsProps) => {
  return (
    <Layout title="Fördelarna för dig" contentClassName="LifeInsuranceBenefits" onGoBack={onPrev}>
      <div className="LifeInsuranceBenefits__card">
        <LifeInsuranceBenefitsDescription uncoveredCapital={uncoveredCapital} />
      </div>
      <div className="LifeInsuranceBenefits__alternatives">
        {alternatives &&
          alternatives.coverAmounts.map((amount) => (
            <Button
              key={`life_insurance_${amount}`}
              text={`${amount.toLocaleString("sv")} kr (${alternatives.grossMonthlyPremium[amount].toLocaleString(
                "sv"
              )} kr/mån)`}
              onClick={() => onNext(amount)}
            />
          ))}
      </div>
      <RippleEffect className="LifeInsuranceBenefits__skip" rounded onClick={onSkip}>
        {isUpsell ? "Nej tack, jag vill inte ändra nivån" : "Nej tack, jag vill inte ha denna försäkring"}
      </RippleEffect>
      <LoadingOverlay isShown={!alternatives || isSkipping} />
    </Layout>
  );
};

interface LifeInsuranceBenefitsDescriptionProps {
  uncoveredCapital: number;
}

export const LifeInsuranceBenefitsDescription = ({ uncoveredCapital }: LifeInsuranceBenefitsDescriptionProps) => (
  <p>
    En livförsäkring är extra värdefull om du har familj eller om ni har olika inkomster i hushållet. Din familj kan få
    ekonomisk ersättning för den närmaste tiden och har råd att behålla boendet. Inga snabba ekonomiska beslut behöver
    tas – dina anhöriga kan i lugn och ro fundera på hur de vill lägga upp sin ekonomi i framtiden.
    <br />
    <br />
    {uncoveredCapital && (
      <>
        Av din pension är det <strong>{uncoveredCapital.toLocaleString("sv")} kr</strong> som inte har något
        efterlevandeskydd. Det betyder att din familj inte får del av det om det skulle hända dig något.
        <br />
        <br />
      </>
    )}
    Genom att teckna en livförsäkring kan din familj få en ekonomisk trygghet. Här kan du läsa mer:
    <br />
    <a href="https://www.pensionskraft.se/livforsakring/" target="_blank">
      www.pensionskraft.se/livforsakring
    </a>
  </p>
);
