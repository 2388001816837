import * as React from "react";
import { useState, useMemo } from "react";
import { Formik, FormikProps, Field } from "formik";
import { ValidationError } from "yup";

import {
  Button,
  FormikMultiSelect,
  FormikSelect,
  Checkbox,
  Collapse,
  TextField,
  TextFieldType,
  otherValueMoreThan,
  MultiSelectValue,
  InfoPopup,
} from "@components/common";

import {
  KycFormInsuranceAmount,
  KycFormCustomerInfo,
  PayoutInfo,
  PayoutType,
  BeneficiaryInfo,
  BeneficiaryInfoPrivateProperty,
} from "@common/models";

import {
  KycFormInsuranceAmountSchema,
  insurancePurposeOptions,
  defaultMultiSelectValue,
  noYesOptions,
  sourceOfFundsOptions,
  KycFormCustomerInfoSchema,
  paymentOptions,
  getPayoutInfoSchema,
} from "./form";

import "./ChangeSavingsQuestions.scss";

interface KycFormInsuranceAmountProps {
  form: KycFormInsuranceAmount | undefined;

  onFormSubmit(form: KycFormInsuranceAmount): any;
}

const kycFormInsuranceAmountInitialValues: Partial<KycFormInsuranceAmount> = {
  insurancePurpose: defaultMultiSelectValue,
  initialPremiumAmount: defaultMultiSelectValue,
  yearlyPremiumAmount: defaultMultiSelectValue,
};

const valueToString = (v: boolean | undefined) => (v === undefined ? v : v ? "true" : "false");
const isWrongAmountEntered = (val: MultiSelectValue) => !otherValueMoreThan(val, 5000000);
// Vilken är den planerade insättningen i samband med tecknandet av försäkringen?
export const KycFormInsuranceAmountComponent = ({ form, onFormSubmit }: KycFormInsuranceAmountProps) => {
  return (
    <Formik
      initialValues={form || kycFormInsuranceAmountInitialValues}
      validationSchema={KycFormInsuranceAmountSchema}
      onSubmit={onFormSubmit}
    >
      {(formikProps: FormikProps<KycFormInsuranceAmount>) => {
        return (
          <>
            <div className="ChangeSavingsQuestions">
              <div className="ChangeSavingsQuestions__card">
                <form onSubmit={formikProps.handleSubmit} noValidate autoComplete="off">
                  <h5>Frågor om ditt sparande:</h5>
                  <Field
                    component={FormikMultiSelect}
                    name="insurancePurpose"
                    label="Syftet med försäkringen"
                    options={insurancePurposeOptions}
                    hideError={true}
                    single
                    otherOption={{ label: "Annat", value: "Other" }}
                    id="kyc-insurance-amount-insurance-purpose"
                  />
                  <div className="Settings__line" />
                  <Field
                    component={FormikMultiSelect}
                    name="initialPremiumAmount"
                    label="Vilken är den planerade insättningen i samband med tecknandet av försäkringen?"
                    options={paymentOptions}
                    hideError={true}
                    single
                    otherOption={{ label: "Över 5 000 000 kr", value: "AmountAbove5M" }}
                    otherOptionDescription="Ange det uppskattade beloppet"
                    otherType={TextFieldType.Number}
                    id="kyc-insurance-amount-initial-premium-amount"
                    isWrongValue={isWrongAmountEntered}
                    wrongValueText="Ska vara mer än 5 miljoner"
                  />
                  <div className="Settings__line" />
                  <Field
                    component={FormikMultiSelect}
                    name="yearlyPremiumAmount"
                    label="Vilken är den planerade insättningen per år?"
                    options={paymentOptions}
                    hideError={true}
                    single
                    otherOption={{ label: "Över 5 000 000 kr", value: "AmountAbove5M" }}
                    otherType={TextFieldType.Number}
                    otherOptionDescription="Ange det uppskattade beloppet"
                    id="kyc-insurance-amount-yearly-premium-amount"
                    isWrongValue={isWrongAmountEntered}
                    wrongValueText="Ska vara mer än 5 miljoner"
                  />
                </form>
              </div>
            </div>
            <Button
              text="Nästa"
              onClick={formikProps.handleSubmit}
              disabled={!(formikProps.dirty ? formikProps.isValid : !!form)}
            />
          </>
        );
      }}
    </Formik>
  );
};

interface KycFormCustomerInfoProps {
  form: KycFormCustomerInfo | undefined;

  onFormSubmit(form: KycFormCustomerInfo): any;
}

const kycFormCustomerInfoInitialValues: Partial<KycFormCustomerInfo> = {
  sourceOfFunds: defaultMultiSelectValue,
  isPep: undefined,
  taxableInOtherCountry: undefined,
  hasOtherCitizenship: false,
};

export const KycFormCustomerInfoComponent = ({ form, onFormSubmit }: KycFormCustomerInfoProps) => {
  return (
    <Formik
      initialValues={form || kycFormCustomerInfoInitialValues}
      validationSchema={KycFormCustomerInfoSchema}
      onSubmit={onFormSubmit}
    >
      {(formikProps: FormikProps<KycFormCustomerInfo>) => {
        return (
          <>
            <div className="ChangeSavingsQuestions">
              <div className="ChangeSavingsQuestions__card">
                <form onSubmit={formikProps.handleSubmit} noValidate autoComplete="off">
                  <h5>Några frågor du behöver besvara:</h5>
                  <Field
                    component={FormikSelect}
                    name="hasOtherCitizenship"
                    label="Har du något annat medborgskap än svenskt?"
                    options={noYesOptions}
                    placeholder="Välj"
                    hideError={true}
                    id="kyc-customer-info-has-other-citizenship"
                    stringToValue={(v: string) => v === "true"}
                    valueToString={valueToString}
                    isSearchable={false}
                    isWrongValue={(val: boolean) => val}
                    wrongValueText="Tyvärr kan vi inte erbjuda detta till personer med dubbelt medborgarskap."
                  />
                  <div className="Settings__line" />
                  <Field
                    component={FormikSelect}
                    name="isPep"
                    label="År du en person i politiskt utsatt ställning? – Har, eller har du tidigare haft en hög politisk post, en hög statlig befattning eller funktion i ledningen i en internationell organisation, eller en nära familjemedlem eller medarbetare med ovanstående befattning?"
                    placeholder="Välj"
                    options={noYesOptions}
                    stringToValue={(v: string) => v === "true"}
                    valueToString={valueToString}
                    isSearchable={false}
                    id="kyc-customer-info-is-pep"
                    isWrongValue={(val: boolean) => val}
                    wrongValueText="Tyvärr kan vi inte erbjuda detta sparande till personer i politiskt utsatt ställning."
                  />
                  <div className="Settings__line" />
                  <Field
                    component={FormikSelect}
                    name="taxableInOtherCountry"
                    label="Är du skyldig att betala skatt i annat land än Sverige?"
                    placeholder="Välj"
                    options={noYesOptions}
                    stringToValue={(v: string) => v === "true"}
                    valueToString={valueToString}
                    isSearchable={false}
                    id="kyc-customer-info-taxableinothercountry"
                    isWrongValue={(val: boolean) => val}
                    wrongValueText="Tyvärr kan vi bara erbjuda detta sparande till skattskyldiga i Sverige."
                  />
                  <div className="Settings__line" />
                  <Field
                    component={FormikMultiSelect}
                    name="sourceOfFunds"
                    label="Varifrån kommer de inbetalningar som görs på försäkringen? Det är möjligt att välja flera alternativ"
                    options={sourceOfFundsOptions}
                    hideError={true}
                    otherOption={{ label: "Annat", value: "Other" }}
                    id="kyc-customer-info-source-of-funds"
                  />
                </form>
              </div>
            </div>
            <Button
              text="Nästa"
              onClick={formikProps.handleSubmit}
              disabled={!(formikProps.dirty ? formikProps.isValid : !!form)}
            />
          </>
        );
      }}
    </Formik>
  );
};

interface PayoutInfoProps {
  form: PayoutInfo | undefined;
  userAge: number;

  onFormSubmit(form: PayoutInfo): any;
}

const payoutTypeToLabel = {
  [PayoutType.OneTime]: "Engångsutbetalning",
  [PayoutType.Recurring]: "Löpande utbetalning",
};

export const PayoutInfoComponent = ({ form, userAge, onFormSubmit }: PayoutInfoProps) => {
  const PayoutInfoSchema = useMemo(() => getPayoutInfoSchema(userAge), [userAge]);

  const [type, setType] = useState(form?.type || PayoutType.Recurring);
  const [recurringStartAge, setRecurringStartAge] = useState(form?.payoutStartAge || 65);
  const [oneTimeStartAge, setOneTimeStartAge] = useState(form?.payoutStartAge || 65);
  const [period, setPeriod] = useState(form?.payoutPeriod || 5);

  const getForm = (): PayoutInfo =>
    type === PayoutType.Recurring
      ? {
          type,
          payoutStartAge: recurringStartAge,
          payoutPeriod: period,
        }
      : {
          type,
          payoutStartAge: oneTimeStartAge,
        };

  let errors;
  try {
    PayoutInfoSchema.validateSync(getForm());
  } catch (err) {
    errors = (err as ValidationError).errors;
  }

  return (
    <>
      <div className="ChangeSavingsQuestions">
        <div className="ChangeSavingsQuestions__card PayoutInfo">
          <div className="ChangeSavingsQuestions__heading">
            <h5>Utbetalning:</h5>
            <InfoPopup
              popupContent={
                <>
                  Här väljer du hur du vill att dina pengar ska betalas ut när det är dags. Med löpande utbetalning får
                  du pengarna utbetalade varje månad jämnt över det antal år du valt.
                  <br />
                  <br />
                  Vid engångsutbetalning får du alla pengar utbetalda som en klumpsumma vid den ålder du själv väljer.
                  <br />
                  <br />
                  Välj det alternativ som du tycker passar dig bäst. Vill du senare ändra ditt val så går det såklart
                  bra.
                </>
              }
            />
          </div>

          <PayoutSection
            type={PayoutType.Recurring}
            isSelected={type === PayoutType.Recurring}
            userAge={userAge}
            startAge={recurringStartAge}
            period={period}
            onSelect={() => setType(PayoutType.Recurring)}
            onStartAgeChange={setRecurringStartAge}
            onPeriodChange={setPeriod}
          />
          {type === PayoutType.Recurring && errors && <p className="PayoutInfo__error">{errors[0]}</p>}

          <PayoutSection
            type={PayoutType.OneTime}
            isSelected={type === PayoutType.OneTime}
            userAge={userAge}
            startAge={oneTimeStartAge}
            onSelect={() => setType(PayoutType.OneTime)}
            onStartAgeChange={setOneTimeStartAge}
          />
          {type === PayoutType.OneTime && errors && <p className="PayoutInfo__error">{errors[0]}</p>}
          <p className="PayoutInfo__text">Du kan ändra detta senare om du vill.</p>
        </div>
      </div>
      <Button text="Nästa" onClick={() => onFormSubmit(getForm())} disabled={!!errors} />
    </>
  );
};

interface PayoutSectionProps {
  type: PayoutType;

  isSelected: boolean;
  userAge: number;
  startAge: number;
  period?: number;

  onSelect(): any;
  onStartAgeChange(val: number): any;
  onPeriodChange?(val: number): any;
}

const PayoutSection = ({
  type,
  isSelected,
  startAge,
  period,
  onStartAgeChange,
  onSelect,
  onPeriodChange,
}: PayoutSectionProps) => {
  return (
    <div className="PayoutInfo__section">
      <Checkbox label={payoutTypeToLabel[type]} value={isSelected} onClick={onSelect} />

      <div className="PayoutInfo__inputs">
        <div className="PayoutInfo__field">
          <p className="PayoutInfo__label">{type === PayoutType.OneTime ? "Ålder" : "Start (ålder)"}</p>
          <TextField
            type={TextFieldType.Number}
            value={startAge ? startAge.toString() : ""}
            onChange={(val) => onStartAgeChange(+val)}
            disabled={!isSelected}
          />
        </div>

        {type !== PayoutType.OneTime && (
          <div className="PayoutInfo__field">
            <p className="PayoutInfo__label">Antal år</p>
            <TextField
              type={TextFieldType.Number}
              value={period ? period.toString() : ""}
              onChange={(val) => onPeriodChange!(+val)}
              disabled={!isSelected}
            />
          </div>
        )}
      </div>
    </div>
  );
};

interface BeneficiaryInfoComponentProps {
  form: BeneficiaryInfo | undefined;
  onFormSubmit(form: BeneficiaryInfo): any;
}

const beneficiaryInfoInitialValue: BeneficiaryInfo = {
  beneficiaryCode: "F10",
  disposition: true,
  privateProperty: BeneficiaryInfoPrivateProperty.False,
};

export const BeneficiaryInfoComponent = ({ form, onFormSubmit }: BeneficiaryInfoComponentProps) => {
  const [beneficiaryCode, setBeneficiaryCode] = useState((form || beneficiaryInfoInitialValue).beneficiaryCode);
  const [disposition, setDisposition] = useState((form || beneficiaryInfoInitialValue).disposition);
  const [privateProperty, setPrivateProperty] = useState((form || beneficiaryInfoInitialValue).privateProperty);

  return (
    <>
      <div className="ChangeSavingsQuestions">
        <div className="ChangeSavingsQuestions__card BeneficiaryInfo">
          <div className="ChangeSavingsQuestions__heading">
            <h5>Förmånstagarförordnande:</h5>
            <InfoPopup
              popupContent={
                <>
                  Här väljer du vem som ska ärva dina pengar i händelse du skulle avlida. De olika alternativen visar
                  också i vilken ordning de ska ärva. Vid alternativet arvingar följs den enligt lag fastslagna
                  arvsordningen.
                </>
              }
            />
          </div>

          <div className="BeneficiaryInfo__beneficiaryCode">
            <Checkbox
              value={beneficiaryCode === "F10"}
              label={
                <>
                  I första hand: mak(a/e)/sambo. <br /> I andra hand: barn. <br />I tredje hand: arvingar.
                </>
              }
              onClick={() => setBeneficiaryCode("F10")}
            />

            <Collapse title="fler alternativ" inline>
              <Checkbox
                value={beneficiaryCode === "F11"}
                label={
                  <>
                    I första hand: barn. <br /> I andra hand: mak(a/e)/sambo. <br />I tredje hand: arvingar.
                  </>
                }
                onClick={() => setBeneficiaryCode("F11")}
              />

              <Checkbox
                value={beneficiaryCode === "F12"}
                label={
                  <>
                    I första hand: barnbarn.
                    <br />I andra hand: arvingar.
                  </>
                }
                onClick={() => setBeneficiaryCode("F12")}
              />

              <Checkbox
                value={beneficiaryCode === "F13"}
                label={
                  <>
                    I första hand: barn. <br />I andra hand: arvingar.
                  </>
                }
                onClick={() => setBeneficiaryCode("F13")}
              />

              <Checkbox value={beneficiaryCode === "F21"} label="Arvingar." onClick={() => setBeneficiaryCode("F21")} />
            </Collapse>
          </div>
          <div className="Settings__line" />
          <Checkbox
            value={!disposition}
            label={<>Ej förfoganderätt (förmånstagaren ska inte få förfoganderätt över försäkringen)</>}
            info={
              <>
                Här kan du välja om den/de du sätter som arvingar ska fritt få förfoga över pengarna, eller om
                utbetalningen måste följa det du bestämde i föregående steg.
              </>
            }
            onClick={() => setDisposition(!disposition)}
          />
          <div className="Settings__line" />
          <div className="BeneficiaryInfo__disposition">
            <div className="ChangeSavingsQuestions__heading">
              <h5>Enskild egendom:</h5>
              <InfoPopup
                popupContent={
                  <>
                    Här väljer du om de som ärvt pengarna äger det som enskild egendom eller som gemensam egendom. Det
                    har betydelse vid en eventuell bodelning, då det bestämmer om pengarna ska delas (vid gemensam
                    egendom) eller inte behöver delas med den andra hälften i en bodelning (enskild egendom).
                  </>
                }
              />
            </div>
            <Checkbox
              value={BeneficiaryInfoPrivateProperty.False === privateProperty}
              label="Belopp, försäkring och avkastning ska inte bli förmånstagarens enskilda egendom"
              onClick={() => setPrivateProperty(BeneficiaryInfoPrivateProperty.False)}
            />
            <Collapse title="fler alternativ" inline>
              <Checkbox
                value={BeneficiaryInfoPrivateProperty.TrueWithout === privateProperty}
                label="Belopp, försäkring och avkastning ska bli förmånstagarens enskilda egendom"
                onClick={() => setPrivateProperty(BeneficiaryInfoPrivateProperty.TrueWithout)}
              />
              <Checkbox
                value={BeneficiaryInfoPrivateProperty.TrueWith === privateProperty}
                label="Belopp, försäkring och avkastning ska bli förmånstagarens enskilda egendom men med rätt att förordna annorlunda"
                onClick={() => setPrivateProperty(BeneficiaryInfoPrivateProperty.TrueWith)}
              />
            </Collapse>
          </div>
        </div>
      </div>
      <Button
        text="Nästa"
        onClick={() => onFormSubmit({ ...form, beneficiaryCode, disposition, privateProperty })}
        disabled={!beneficiaryCode}
      />
    </>
  );
};
