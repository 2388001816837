import { Action } from "redux";
import isEmpty from "lodash-es/isEmpty";

import { getCurrentApplicationRequest, putCancelInsuranceApplicationRequest } from "@common/requests";
import { InsuranceApplicationResponse } from "@common/models";

import { ChangeSavingsState, defaultChangeSavingsState } from "./state";
import { changeMonthlyDepositAction, setInsuranceAplicationsStep, setSavingsInsuranceNumber } from "./actions";

export function changeSavingsReducer(
  state: ChangeSavingsState = defaultChangeSavingsState,
  action: Action
): ChangeSavingsState {
  if (changeMonthlyDepositAction.is(action)) {
    return { ...state, selectedDeposit: action.deposit };
  }

  if (setInsuranceAplicationsStep.is(action)) {
    return { ...state, insuranceApplicationStep: action.step };
  }

  if (getCurrentApplicationRequest.actions.isOk(action)) {
    if (isEmpty(action.result)) {
      return state;
    }

    return {
      ...state,
      insuranceApplicationResponse: action.result as InsuranceApplicationResponse,
    };
  }

  if (putCancelInsuranceApplicationRequest.actions.isOk(action)) {
    return {
      ...state,
      insuranceApplicationResponse: undefined,
      insuranceApplicationStep: undefined,
    };
  }

  if (setSavingsInsuranceNumber.is(action)) {
    return {
      ...state,
      insuranceApplicationResponse: state.insuranceApplicationResponse && {
        ...state.insuranceApplicationResponse,
        insuranceNumber: action.insuranceNumber,
      },
    };
  }

  return state;
}
