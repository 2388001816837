import * as React from "react";

interface ChevronProps {
  className?: string;
  color?: string;
  direction: "left" | "right" | "up" | "down";
  width?: string;
  height?: string;
}

const rotateMap = {
  right: 0,
  down: 90,
  left: 180,
  up: 270
};

export const ChevronBold = ({ width, height, direction, className, color }: ChevronProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={className}
      style={{
        transform: `rotate(${rotateMap[direction]}deg)`
      }}
      viewBox="0 0 8 12"
    >
      <g fill="none" fillRule="evenodd">
        <path d="M-8-6h24v24H-8z" />
        <path fill={color || "white"} d="M.59 10.59L5.17 6 .59 1.41 2 0l6 6-6 6z" />
      </g>
    </svg>
  );
};
