import { InsuranceApplicationBody } from "@common/models";

import { defineAction } from "../../lib/rd-action-creators/index";

import { InsuranceApplicationStep } from "./state";

export const submitInsuranceApplicationAction = defineAction<InsuranceApplicationBody>("SUBMIT INSURANCE APPLICATION");
export const changeMonthlyDepositAction = defineAction<{ deposit: number | undefined }>("CHANGE MONTHLY DEPOSIT");
export const loadDanicaDocumentsAction = defineAction("LOAD DANICA DOCUMENTS");
export const pollSavingsSendJobAction = defineAction<{ jobId: string }>("POLL SAVINGS SEND JOB APPLICATION");
export const pollSavingsSignJobAction = defineAction<{ jobId: string }>("POLL SAVINGS SIGN JOB APPLICATION");
export const signInsuranceApplicationAction = defineAction("SIGN INSURANCE APPLICATION");
export const setInsuranceAplicationsStep = defineAction<{ step: InsuranceApplicationStep }>(
  "SET INSURANCE APPLICATION STEP"
);
export const cancelInsuranceAplication = defineAction<{ goToSupport?: boolean }>("CANCEL INSURANCE APPLICATION ");
export const setSavingsInsuranceNumber = defineAction<{ insuranceNumber: string | null }>(
  "INSURANCE APPLICATION SET INSURANCE NUMBER "
);
