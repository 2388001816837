import { takeEvery, put, select, call } from "redux-saga/effects";
import { push } from "connected-react-router";

import { postRequestFCMTokenRequest, putMessageReadRequest, putMessageActionCompletedRequest } from "@common/requests";
import { MessageClickActions, InAppMessage } from "@common/models";
import { NotificationsRoute, HomeRoute, ShowMoreRoute, SummaryRoute } from "@common/routes";
import { ShowMoreType } from "@common/types";

import { homeSlideChangeAction } from "@state/home";
import { AppState, getStore } from "@state/store";
import { responseErrorAction } from "@state/error";
import { getUserInfoSaga } from "@state/user";

import { getMessaging, isWorkerReadyForMessages, init, isSupported } from "../../web/firebase";

import {
  registerTokenAction,
  notificationsErrorAction,
  notificationMessageReadAction,
  notificationMessageButtonClickAction,
  notificationMessageCompleteAction,
  pushReceivedAction,
  askForPermissionsAction
} from "./actions";
import { visibleMessagesSelector } from "./selectors";

const fcmTokenStorageKey = "fcmToken";

function* registerTokenSaga() {
  if (!isSupported) {
    return;
  }

  const userId = yield select<AppState>(state => state.user.userId!);

  try {
    // wait for SW to register
    const isReady = yield call(async () => await isWorkerReadyForMessages);
    if (!isReady) {
      return;
    }

    const messaging = getMessaging();
    if (!messaging) {
      return;
    }

    yield call([messaging, messaging.requestPermission]);
    let token = yield call([messaging, messaging.getToken]);
    const prevToken = localStorage.getItem(fcmTokenStorageKey);

    // get subscription
    const registration = yield call(() => navigator.serviceWorker.ready);
    const subsc = yield call([registration.pushManager, registration.pushManager.getSubscription]);

    if (prevToken && prevToken === token) {
      if (subsc) {
        return;
      } else {
        yield call([messaging, messaging.deleteToken], token);
        token = yield call([messaging, messaging.getToken]);
      }
    }
    yield put(postRequestFCMTokenRequest.actions.running({ userId }));

    const resp: typeof postRequestFCMTokenRequest.types.response = yield call(
      postRequestFCMTokenRequest,
      { userId },
      { registration_id: token }
    );
    if (resp.ok) {
      yield put(postRequestFCMTokenRequest.actions.ok({ userId }, resp));
      localStorage.setItem(fcmTokenStorageKey, token);
    } else {
      // add here some
      yield put(postRequestFCMTokenRequest.actions.error({ userId }, resp));
      yield put(responseErrorAction({ response: resp }));
    }
  } catch (error) {
    yield put(notificationsErrorAction({ error }));
  }
}

function* askForPermissionsSaga() {
  if ("Notification" in window && (Notification as any).permission !== "granted") {
    const permission = yield call(Notification.requestPermission);
    if (permission === "granted") {
      yield call(init, getStore());
      yield put(registerTokenAction({}));
    }
  }
}

function* messageReadSaga({ code }: typeof notificationMessageReadAction.typeInterface) {
  const userId = yield select<AppState>(state => state.user.data && state.user.data._id);

  yield put(putMessageReadRequest.actions.running({ userId, messageCode: code }));

  const response: typeof putMessageReadRequest.types.response = yield call(
    putMessageReadRequest,
    { userId, messageCode: code },
    {}
  );

  if (response.ok) {
    yield put(putMessageReadRequest.actions.ok({ userId, messageCode: code }, response));
  } else {
    yield put(putMessageReadRequest.actions.error({ userId, messageCode: code }, response));
  }
}

function* messageButtonClickSaga({ action, code }: typeof notificationMessageButtonClickAction.typeInterface) {
  const message: InAppMessage = (yield select<AppState>(visibleMessagesSelector)).find(
    (msg: InAppMessage) => msg.code === code
  );
  switch (action) {
    case MessageClickActions.ShowMainPage:
    case MessageClickActions.ShowFundPerformance:
    case MessageClickActions.ShowScrapingSummary:
      if (!message.completed) {
        yield put(notificationMessageCompleteAction({ code }));
      }
      if (action === MessageClickActions.ShowScrapingSummary) {
        yield put(push(SummaryRoute.format({})));
      } else if (action === MessageClickActions.ShowFundPerformance) {
        yield put(push(ShowMoreRoute.format({ type: ShowMoreType.Funds })));
      } else {
        yield put(homeSlideChangeAction({ newSlideNumber: 0 }));
        yield put(push(HomeRoute.format({})));
      }

      return;

    case MessageClickActions.ShowEndOfYearSummary:
    default:
      yield put(push(NotificationsRoute.format({})));
  }
}

function* messageActionCompletedSaga({ code }: typeof notificationMessageCompleteAction.typeInterface) {
  const userId = yield select<AppState>(state => state.user.data && state.user.data._id);

  yield put(putMessageActionCompletedRequest.actions.running({ userId, messageCode: code }));

  const response: typeof putMessageActionCompletedRequest.types.response = yield call(
    putMessageActionCompletedRequest,
    { userId, messageCode: code },
    {}
  );

  if (response.ok) {
    yield put(putMessageActionCompletedRequest.actions.ok({ userId, messageCode: code }, response));
  } else {
    yield put(putMessageActionCompletedRequest.actions.error({ userId, messageCode: code }, response));
    yield put(responseErrorAction({ response }));
  }
}

function* pushReceivedSaga({}: typeof pushReceivedAction.typeInterface) {
  const userId = yield select<AppState>(state => state.user.data && state.user.data._id);

  yield call(getUserInfoSaga, { id: userId });
}

export function* notificationsSaga() {
  yield takeEvery(registerTokenAction.type, registerTokenSaga);
  yield takeEvery(askForPermissionsAction.type, askForPermissionsSaga);
  yield takeEvery(notificationMessageReadAction.type, messageReadSaga);
  yield takeEvery(notificationMessageButtonClickAction.type, messageButtonClickSaga);
  yield takeEvery(notificationMessageCompleteAction.type, messageActionCompletedSaga);
  yield takeEvery(pushReceivedAction.type, pushReceivedSaga);
}
