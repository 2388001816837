import {
  MinpensionData,
  AllmanPensionData,
  TjanstePensionData,
  PrivatPensionData,
  PensionPlanData,
} from "@common/models";

import { defineAction } from "../../lib/rd-action-creators";

export const getMinpensionDataAction = defineAction("GET MINPENSION DATA");
export const getPensionInstitutesAction = defineAction("MINPENSION DATA GET PENSION INSTITUTES");

export const updateAllmanPensionDataAction = defineAction<{ data: AllmanPensionData }>(
  "MINPENSION DATA UPDATE ALLMANPENSION DATA"
);
export const updateTjanstePensionDataAction = defineAction<{ data: TjanstePensionData }>(
  "MINPENSION DATA UPDATE TJANSTEPENSION DATA"
);
export const updatePrivatPensionDataAction = defineAction<{ data: PrivatPensionData }>(
  "MINPENSION DATA UPDATE PRIVATPENSION DATA"
);
export const updateTotalPensionAction = defineAction<{ total: number }>("MINPENSION DATA UPDATE TOTAL PENSION");
export const updateNewPensionPlanAction = defineAction<{ data: Partial<PensionPlanData> }>(
  "MINPENSION DATA UPDATE NEW PENSION PLAN"
);

export const dataCollectionCompletedAction = defineAction("MINPENSION DATA COLLECTION COMPLETED");
export const pollProcessMinpensionDataJobAction = defineAction<{ jobId: string }>("MINPENSION DATA POLL PROCESS JOB");

export const saveMinpensionDataAction = defineAction<MinpensionData>("SAVE MINPENSION DATA");
