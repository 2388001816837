import * as React from "react";

interface ArrowProps {
  direction: "asc" | "none" | "desc";
  size: string;
  color?: string;
}

const directionToColor = {
  asc: "#2ecc71",
  desc: "#e74c3c",
  none: "#D5D5D5"
};

export const CircledArrow = ({ direction, size, color }: ArrowProps) => {
  return (
    <svg
      style={
        direction === "asc"
          ? {}
          : {
              transform: direction === "desc" ? "rotate(90deg)" : "rotate(45deg)"
            }
      }
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 22 22"
    >
      <path
        fill={color || directionToColor[direction]}
        fillRule="nonzero"
        d="M3.303 3.303c-4.246 4.245-4.246 11.149 0 15.394 4.245 4.246 11.149 4.246 15.394 0 4.246-4.245 4.246-11.149 0-15.394-4.245-4.246-11.149-4.246-15.394 0zm12.461 10.484h-2.398V10.32L7.435 16.25 5.75 14.565l5.93-5.93H8.214v-2.4l7.535.017.016 7.535z"
      />
    </svg>
  );
};
