import { takeEvery, put, call, select, all } from "redux-saga/effects";
import get from "lodash-es/get";
import { push, replace } from "connected-react-router";

import {
  getAxaGroupProductsRequest,
  postCreateInsuraceRequest,
  getActiveInsurancesRequest,
  postUpsellInsuraceRequest,
  postSkipInsuranceOfferRequest
} from "@common/requests";
import { AxaInsuranceRequest, UserWithInfo, InsuranceType } from "@common/models";
import {
  StandaloneInsuranceSuccessRoute,
  StandaloneOfferSkippedRoute,
  StandaloneLifeInsuranceRoute,
  StandaloneSafetyInsuranceRoute,
  StandaloneLifeInsuranceUpsellRoute,
  StandaloneSafetyInsuranceUpsellRoute,
  StandaloneOfferCompletedRoute,
  StandaloneOfferRoutePrefix
} from "@common/routes";

import { AppState } from "@state/store";
import { responseErrorAction } from "@state/error";
import { finalizeTodoItemSaga } from "@state/todoItem";
import { trackHeapEvent, HeapEventNames } from "@state/analytics";

import {
  getAxaProductsDescriptionAction,
  createInsuranceAction,
  upsellInsuranceAction,
  skipStandaloneOfferAction,
  getActiveInsurancesAction
} from "./actions";
import { StandaloneInsuranceOffers } from "@common/types";
import { offerCompletedSelector, offerSkippedSelector } from "./selectors";

export function* getAxaProductsDescriptionSaga() {
  const personNumber = yield select<AppState>(state => get(state, "user.data.person_number", ""));
  const params = { "person-number": personNumber };
  yield put(getAxaGroupProductsRequest.actions.running(params));
  const response: typeof getAxaGroupProductsRequest.types.response = yield call(getAxaGroupProductsRequest, params);

  if (response.ok) {
    yield put(getAxaGroupProductsRequest.actions.ok(params, response));
  } else {
    yield put(getAxaGroupProductsRequest.actions.error(params, response));
    yield put(responseErrorAction({ response }));
  }

  return response;
}

export function* getActiveInsurancesSaga() {
  const user: UserWithInfo = yield select<AppState>(state => state.user.data);
  const id = user._id!;

  yield put(getActiveInsurancesRequest.actions.running({ id }));
  const response: typeof getActiveInsurancesRequest.types.response = yield call(getActiveInsurancesRequest, { id });

  if (response.ok) {
    yield put(getActiveInsurancesRequest.actions.ok({ id }, response));
  } else {
    yield put(getActiveInsurancesRequest.actions.error({ id }, response));
    yield put(responseErrorAction({ response, hideBtn: true }));
  }

  return response;
}

function* fetchInsuranceInfo() {
  const [resp, productsResp]: [
    typeof getActiveInsurancesRequest.types.response,
    typeof getAxaGroupProductsRequest.types.response
  ] = yield all([call(getActiveInsurancesSaga), call(getAxaProductsDescriptionSaga)]);

  return resp.ok && productsResp.ok;
}

function* createInsuranceSaga({
  productCode,
  coverAmount,
  price,
  form,
  isStandaloneFlow
}: typeof createInsuranceAction.typeInterface) {
  const user: UserWithInfo = yield select<AppState>(state => state.user.data);
  const body: AxaInsuranceRequest = {
    user: user._id!,
    product_code: productCode,
    cover_amount: coverAmount,
    gross_monthly_premium: price,
    autogiro: {
      bank_name: form.bankName,
      sort_code: form.sortCode,
      account_number: form.accountNumber
    }
  };
  yield put(postCreateInsuraceRequest.actions.running({}));
  const response: typeof postCreateInsuraceRequest.types.response = yield call(postCreateInsuraceRequest, {}, body);
  if (response.ok) {
    // if insurance only user - track event with heap and show summary screen
    if (isStandaloneFlow) {
      trackHeapEvent(HeapEventNames.StandaloneInsurancePurchase, { productCode });
      const type: InsuranceType = yield select<AppState>(
        state => state.insurance.axaProducts.data!.find(insurance => insurance.id === productCode)!.type
      );
      yield call(fetchInsuranceInfo);
      yield put(
        push(
          StandaloneInsuranceSuccessRoute.format({
            type:
              type === InsuranceType.Life
                ? StandaloneInsuranceOffers.LifeInsurance
                : StandaloneInsuranceOffers.SafetyInsurance
          })
        )
      );
    } else {
      yield call(finalizeTodoItemSaga);
    }
    yield put(postCreateInsuraceRequest.actions.ok({}, response));
  } else {
    yield put(postCreateInsuraceRequest.actions.error({}, response));
  }
}

function* upsellInsuranceSaga({
  productCode,
  coverAmount,
  price,
  isStandaloneFlow
}: typeof upsellInsuranceAction.typeInterface) {
  const user: UserWithInfo = yield select<AppState>(state => state.user.data);
  const body: AxaInsuranceRequest = {
    user: user._id!,
    product_code: productCode,
    cover_amount: coverAmount,
    gross_monthly_premium: price,
    autogiro: {}
  };
  yield put(postUpsellInsuraceRequest.actions.running({}));
  const response: typeof postUpsellInsuraceRequest.types.response = yield call(postUpsellInsuraceRequest, {}, body);
  if (response.ok) {
    // if insurance only user - track event with heap and show summary screen
    if (isStandaloneFlow) {
      trackHeapEvent(HeapEventNames.StandaloneInsuranceUpsell, { productCode });
      const type = yield select<AppState>(
        state => state.insurance.axaProducts.data!.find(insurance => insurance.id === productCode)!.type
      );
      yield call(fetchInsuranceInfo);
      yield put(
        push(
          StandaloneInsuranceSuccessRoute.format({
            type:
              type === InsuranceType.Life
                ? StandaloneInsuranceOffers.LifeInsuranceUpsell
                : StandaloneInsuranceOffers.SafetyInsuranceUpsell
          })
        )
      );
    } else {
      yield call(finalizeTodoItemSaga);
    }
    yield put(postUpsellInsuraceRequest.actions.ok({}, response));
  } else {
    yield put(postUpsellInsuraceRequest.actions.error({}, response));
  }
}

function* skipInsuranceOfferSaga({ offer }: typeof skipStandaloneOfferAction.typeInterface) {
  const user: UserWithInfo = yield select<AppState>(state => state.user.data);
  const params = { id: user._id!, offerCode: offer };
  yield put(postSkipInsuranceOfferRequest.actions.running(params));
  const response: typeof postSkipInsuranceOfferRequest.types.response = yield call(
    postSkipInsuranceOfferRequest,
    params,
    {}
  );
  if (response.ok) {
    trackHeapEvent(HeapEventNames.StandaloneInsuranceOfferSkipped, { offerCode: offer });

    yield put(push(StandaloneOfferSkippedRoute.format({ type: offer })));

    yield put(postSkipInsuranceOfferRequest.actions.ok(params, response));
  } else {
    yield put(postSkipInsuranceOfferRequest.actions.error(params, response));
  }
}

const getOfferFromRoute = (route: string): StandaloneInsuranceOffers | undefined => {
  // NOTE: these routes should also capture derived 'utility' routes (skipped/completed/success) so no exact comparison needed
  if (StandaloneLifeInsuranceUpsellRoute.match(route).isMatched) {
    return StandaloneInsuranceOffers.LifeInsuranceUpsell;
  } else if (StandaloneSafetyInsuranceUpsellRoute.match(route).isMatched) {
    return StandaloneInsuranceOffers.SafetyInsuranceUpsell;
  } else if (StandaloneLifeInsuranceRoute.match(route).isMatched) {
    return StandaloneInsuranceOffers.LifeInsurance;
  } else if (StandaloneSafetyInsuranceRoute.match(route).isMatched) {
    return StandaloneInsuranceOffers.SafetyInsurance;
  } else {
    return undefined;
  }
};

export function* handleInsuranceOnlyUserSaga(redirectTo: string, user: UserWithInfo) {
  let destinationRoute = redirectTo;
  const ok = yield call(fetchInsuranceInfo);
  if (!ok) {
    return;
  }

  const offer = getOfferFromRoute(redirectTo);

  if (!offer) {
    yield put(push("/not-found"));
    return;
  }

  const isOfferCompleted = yield select<AppState>(state => offerCompletedSelector(state, offer));
  const isOfferSkipped = yield select<AppState>(state => offerSkippedSelector(state, offer));

  // check if offer was completed/skipped and redirect to corresponding utility route
  if (isOfferCompleted) {
    destinationRoute = StandaloneOfferCompletedRoute.format({ type: offer });
  } else if (isOfferSkipped) {
    destinationRoute = StandaloneOfferSkippedRoute.format({ type: offer });
  } else {
    // just show offer page otherwise
    destinationRoute = `/${StandaloneOfferRoutePrefix}/${offer}`;
  }

  yield put(replace(destinationRoute));
}

export function* insuranceSaga() {
  yield takeEvery(getAxaProductsDescriptionAction.type, getAxaProductsDescriptionSaga);
  yield takeEvery(getActiveInsurancesAction.type, getActiveInsurancesSaga);
  yield takeEvery(createInsuranceAction.type, createInsuranceSaga);
  yield takeEvery(upsellInsuranceAction.type, upsellInsuranceSaga);
  yield takeEvery(skipStandaloneOfferAction.type, skipInsuranceOfferSaga);
}
