export const getConfidenceInterval = (capital: number, yearsUntil: number) => {
  // const from Tobias
  const STD = 0.12;

  const EXPECTED_VALUE_IN_POWER_OF_2 = Math.pow(capital, 2);
  const MY = Math.log(
    EXPECTED_VALUE_IN_POWER_OF_2 / Math.sqrt(EXPECTED_VALUE_IN_POWER_OF_2 + Math.pow(STD, 2) * yearsUntil)
  );

  const Z = 1.96;
  const VARIANCE = Math.sqrt(Math.pow(STD, 2) * yearsUntil);

  const UPPER_INTERVAL = Math.pow(Math.E, MY + Z * VARIANCE);
  const LOWER_INTERVAL = Math.pow(Math.E, MY - Z * VARIANCE);

  return {
    min: LOWER_INTERVAL,
    max: UPPER_INTERVAL
  };
};
