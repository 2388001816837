import * as React from "react";
import { useSelector, useDispatch } from "react-redux";

// import { minpensionDataImages } from "@assets";
import { AllmanPensionData, FundInList } from "@common/models";
import { allmanPensionDataSelector, updateAllmanPensionDataAction } from "@state/minpensionData";
import { TextField, TextFieldType } from "@components/common";

import { FundSelect } from "../fundSelect";

import { InstructionsLayout } from "./InstructionsLayout";

interface AllmanPensionProps {
  onNext(): any;
  onBack(): any;
}

const PPM_SHELL_ID = "53d774ac2bf411a236912c34";

export const AllmanPension = ({ onNext, onBack }: AllmanPensionProps) => {
  const allmanPensionData: AllmanPensionData | undefined = useSelector(allmanPensionDataSelector);
  const premiePensionFunds = allmanPensionData?.premiePension || [];
  const inkomstPension = allmanPensionData?.inkomstPension || 0;
  const dispatch = useDispatch();

  const onAddFund = (newFund: FundInList) => {
    dispatch(
      updateAllmanPensionDataAction({
        data: {
          ...(allmanPensionData || {}),
          premiePension: [...premiePensionFunds, newFund],
        },
      })
    );
  };
  const onRemoveFund = (fundId: string) => {
    dispatch(
      updateAllmanPensionDataAction({
        data: {
          ...(allmanPensionData || {}),
          premiePension: premiePensionFunds.filter((f) => f.fund_in_shell_id !== fundId),
        },
      })
    );
  };
  const onInkomstPensionChange = (newVal: number) => {
    dispatch(
      updateAllmanPensionDataAction({
        data: {
          ...(allmanPensionData || {}),
          inkomstPension: newVal,
          premiePension: premiePensionFunds,
        },
      })
    );
  };
  const onFundValuationChange = (newVal: number, fundId: string) => {
    dispatch(
      updateAllmanPensionDataAction({
        data: {
          ...(allmanPensionData || {}),
          premiePension: premiePensionFunds.map((f) =>
            f.fund_in_shell_id === fundId ? { ...f, valuation: newVal } : f
          ),
        },
      })
    );
  };

  return (
    <InstructionsLayout
      contentClassName="LoginInstructions"
      nextBtnText="Nästa, jag är klar med Allmän pension nu"
      onNext={onNext}
      onBack={onBack}
    >
      <h5 className="MinpensionDataInstructions__title">Information om din allmänna pension</h5>
      <div className="MinpensionDataInstructions__text" style={{ marginBottom: "1.5rem" }}>
        Ange nu de belopp du ser under din Allmänna pension.
      </div>
      <div className="MinpensionDataInstructions__line" />
      <div className="InkomstPension">
        <h5 className="InkomstPension__title">Inkomstpension</h5>
        <div className="InkomstPension__row">
          <p>Kapital:</p>
          <TextField
            type={TextFieldType.Number}
            value={inkomstPension.toString()}
            onChange={(v) => onInkomstPensionChange(+v)}
            suffix="kr"
          />
        </div>
      </div>
      <div className="MinpensionDataInstructions__line" />
      <FundSelect
        shellId={PPM_SHELL_ID}
        fundsList={premiePensionFunds}
        fundListHeader="Premiepension"
        onAddFund={onAddFund}
        onRemoveFund={onRemoveFund}
        onFundValuationChange={onFundValuationChange}
      />
      <div className="MinpensionDataInstructions__line" />
    </InstructionsLayout>
  );
};
