import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Redirect } from "react-router-dom";
import { goBack } from "connected-react-router";

import { Layout, LoadingOverlay } from "@components/common";
import { isPPMFund } from "@common/helpers";
import { AppState } from "@state/store";
import { selectedTodoItemSelector } from "@state/todoItem";
import { ChangeFundTodoItem } from "@common/models";
import { HomeRoute } from "@common/routes";

import { ChangeFundsPPM } from "./ppm";
import { ChangeFundsPrint } from "./print";
// import { ChangeFundsSign } from "./sign";

import "./ChangeFunds.scss";

interface ChangeFundsProps {
  item: ChangeFundTodoItem | undefined;
  isFetching: boolean;
  isPreviewOpen: boolean;

  onCancel(): any;
}

const getComponentInfo = (pensionInstitute: string) => {
  if (isPPMFund(pensionInstitute)) {
    return {
      className: "ChangeFundsPPM",
      child: <ChangeFundsPPM />
    };
    // } else if (canSignDigitally(pensionInstitute)) {
    //   return {
    //     className: "ChangeFundsSign",
    //     child: <ChangeFundsSign />
    //   };
    // ONLY ALLOW SEND FORM TO USERS FOR NOW
  } else {
    return {
      className: "ChangeFundsPrint",
      child: <ChangeFundsPrint />
    };
  }
};

export const ChangeFunds = connect(
  (state: AppState) => ({
    item: selectedTodoItemSelector(state),
    isPreviewOpen: state.changeFunds.preview.isOpen,
    isFetching: state.changeFunds.isFetching
  }),
  (dispatch: Dispatch) => ({
    onCancel() {
      dispatch(goBack());
    }
  })
)(({ item, isFetching, isPreviewOpen, onCancel }: ChangeFundsProps) => {
  if (!item) {
    return <Redirect to={HomeRoute.format({})} />;
  }
  const { className, child } = getComponentInfo(item.payload.pensionInstitute);
  return (
    <Layout
      title={item.payload.pensionInstitute}
      onGoBack={onCancel}
      contentClassName={className}
      isOverlayed={isPreviewOpen}
    >
      {child}
      <LoadingOverlay isShown={isFetching} />
    </Layout>
  );
});
