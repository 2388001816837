import { takeEvery, put, select, call } from "redux-saga/effects";
import { isEqual } from "lodash-es";
import { push } from "connected-react-router";

import { applyMainSettingsForm, UserWithInfo, applyPensionProfileForm, TodoItemModel } from "@common/models";
import { postUserDataRequest } from "@common/requests";
import { TodoItemType } from "@common/types";
import { HomeRoute } from "@common/routes";

import { AppState } from "@state/store";
import { responseErrorAction } from "@state/error";
import {
  markItemAsCompleted,
  selectedTodoItemSelector,
  recentCompletedItemSelector,
  skipItemAction
} from "@state/todoItem";

import {
  updateUserMainSettingsAction,
  updateUserPensionProfileAction,
  changeRequestedRiskLevelAction,
  updatePensionProfileAndRiskLevelAction,
  finishPensionProfileUpdateAction,
  pensionProfileUpdateFinished
} from "./actions";
import { trackHeapEvent, HeapEventNames } from "@state/analytics";

function* updateUserSaga(newUser: UserWithInfo, completeWithRedirect: boolean = false) {
  const user: UserWithInfo = yield select<AppState>(state => state.user.data);

  if (!isEqual(user, newUser)) {
    const userId = newUser._id!;
    yield put(postUserDataRequest.actions.running({ userId }));

    const response: typeof postUserDataRequest.types.response = yield call(postUserDataRequest, { userId }, newUser);

    if (response.ok) {
      yield put(postUserDataRequest.actions.ok({ userId }, response));
    } else {
      yield put(postUserDataRequest.actions.error({ userId }, response));
      yield put(responseErrorAction({ response }));
    }

    const selectedTodoItem: TodoItemModel | undefined = yield select<AppState>(selectedTodoItemSelector);
    if (response.ok && selectedTodoItem && selectedTodoItem.type === TodoItemType.UpdatePensionProfile) {
      trackHeapEvent(HeapEventNames.TodoItemCompleted, { itemType: TodoItemType.UpdatePensionProfile });
      yield put(markItemAsCompleted({ withRedirect: completeWithRedirect }));
    }
  }
}

function* changeRiskLevelSaga({ riskLevel }: typeof changeRequestedRiskLevelAction.typeInterface) {
  const user: UserWithInfo = yield select<AppState>(state => state.user.data);
  yield call(updateUserSaga, {
    ...user,
    risk_profile: { ...(user.risk_profile || {}), requested_risk_level: riskLevel }
  });
}

function* updateMainSettingsSaga({ form }: typeof updateUserMainSettingsAction.typeInterface) {
  const user: UserWithInfo = yield select<AppState>(state => state.user.data);
  yield call(updateUserSaga, applyMainSettingsForm(user, form));
}

function* updatePensionProfileSaga({ form }: typeof updateUserPensionProfileAction.typeInterface) {
  const user: UserWithInfo = yield select<AppState>(state => state.user.data);
  yield call(updateUserSaga, applyPensionProfileForm(user, form));
}

function* updatePensionProfileAndRiskLevelSaga({
  form,
  riskLevel
}: typeof updatePensionProfileAndRiskLevelAction.typeInterface) {
  const user: UserWithInfo = yield select<AppState>(state => state.user.data);
  yield call(
    updateUserSaga,
    applyPensionProfileForm(
      {
        ...user,
        risk_profile: { ...(user.risk_profile || {}), requested_risk_level: riskLevel }
      },
      form
    )
  );
}

function* finishPensionProfileUpdateSaga({ riskLevel }: typeof changeRequestedRiskLevelAction.typeInterface) {
  const user: UserWithInfo = yield select<AppState>(state => state.user.data);
  yield call(
    updateUserSaga,
    {
      ...user,
      risk_profile: { ...(user.risk_profile || {}), requested_risk_level: riskLevel }
    },
    true
  );

  const isInitialUpdate = yield select((state: AppState) => state.user.isInitialPensionProfileUpdate);

  if (!isInitialUpdate) {
    const selectedTodoItem: TodoItemModel | undefined = yield select<AppState>(selectedTodoItemSelector);
    const completedTodoItem: TodoItemModel | undefined = yield select<AppState>(recentCompletedItemSelector);
    if (selectedTodoItem && selectedTodoItem.type === TodoItemType.UpdatePensionProfile && !completedTodoItem) {
      yield put(skipItemAction({}));
    } else {
      yield put(push(HomeRoute.format({})));
    }
  }
  yield put(pensionProfileUpdateFinished({}));
}

export function* settingsSaga() {
  yield takeEvery(updateUserMainSettingsAction.type, updateMainSettingsSaga);
  yield takeEvery(updateUserPensionProfileAction.type, updatePensionProfileSaga);
  yield takeEvery(changeRequestedRiskLevelAction.type, changeRiskLevelSaga);
  yield takeEvery(updatePensionProfileAndRiskLevelAction.type, updatePensionProfileAndRiskLevelSaga);
  yield takeEvery(finishPensionProfileUpdateAction.type, finishPensionProfileUpdateSaga);
}
