import * as React from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { push, replace, goBack } from "connected-react-router";
import { Redirect } from "react-router-dom";

import { Layout, TodoItemIncrease, Button, LoadingOverlay, PensaAutogiro } from "@components/common";
import { AppState } from "@state/store";
import {
  SavingsTodoItem,
  SavingsRecomendationModel,
  KycFormCustomerInfo,
  KycFormInsuranceAmount,
  PayoutInfo,
  BeneficiaryInfo,
  InsuranceApplicationBody,
} from "@common/models";
import { selectedTodoItemSelector } from "@state/todoItem";
import { HomeRoute, InsuranceApplicationRoute } from "@common/routes";
import {
  changeMonthlyDepositAction,
  sliderConfigSelector,
  savingsRecommendationsSelector,
  loadDanicaDocumentsAction,
  submitInsuranceApplicationAction,
} from "@state/changeSavings";

import { ChangeSavingsSlider } from "./slider";
import {
  KycFormInsuranceAmountComponent,
  KycFormCustomerInfoComponent,
  PayoutInfoComponent,
  BeneficiaryInfoComponent,
} from "./questions";

import "./ChangeSavings.scss";

enum ChangeSavingsStep {
  Slider,
  KycCustomerInfo,
  KycInsuranceAmount,
  PayoutInfo,
  BeneficiaryInfo,
  BankDetails,
}

interface ChangeSavingsProps {
  selectedItem: SavingsTodoItem | undefined;
  recommendation: SavingsRecomendationModel | undefined;
  selectedDeposit: number | undefined;
  sliderConfig: { min: number; max: number; step: number } | undefined;
  yearsUntilRetirement: number | undefined;
  retirementAge: number | undefined;
  userAge: number | undefined;
  hasInsuranceApplication: boolean;

  postInsuranceApplicationInProgress: boolean;

  onGoHome(replaceRoute?: boolean): any;
  onDepositChange(deposit: number): any;
  onSubmitInsuranceApplication(data: InsuranceApplicationBody): any;
}

const getPrevStep = (step: ChangeSavingsStep) => {
  switch (step) {
    case ChangeSavingsStep.KycCustomerInfo:
      return ChangeSavingsStep.Slider;
    case ChangeSavingsStep.KycInsuranceAmount:
      return ChangeSavingsStep.KycCustomerInfo;
    case ChangeSavingsStep.PayoutInfo:
      return ChangeSavingsStep.KycInsuranceAmount;
    case ChangeSavingsStep.BeneficiaryInfo:
      return ChangeSavingsStep.PayoutInfo;
    case ChangeSavingsStep.BankDetails:
      return ChangeSavingsStep.BeneficiaryInfo;
    default:
      return ChangeSavingsStep.Slider;
  }
};

const ChangeSavingsAtom = ({
  hasInsuranceApplication,
  userAge,
  selectedDeposit,
  selectedItem,
  postInsuranceApplicationInProgress,
  recommendation,
  sliderConfig,
  retirementAge,
  yearsUntilRetirement,
  onGoHome,
  onDepositChange,
  onSubmitInsuranceApplication,
}: ChangeSavingsProps) => {
  const [step, setStep] = useState<ChangeSavingsStep>(ChangeSavingsStep.Slider);
  const [kycCustomerInfo, setKycCustomerInfo] = useState<KycFormCustomerInfo>();
  const [kycInsuranceAmount, setKycInsuranceAmount] = useState<KycFormInsuranceAmount>();
  const [payoutInfo, setPayoutInfo] = useState<PayoutInfo>();
  const [beneficiaryInfo, setBeneficiaryInfo] = useState<BeneficiaryInfo>();

  if (hasInsuranceApplication) {
    return <Redirect to={InsuranceApplicationRoute.format({})} />;
  }

  if (!selectedItem || !sliderConfig || !userAge) {
    return <LoadingOverlay isShown={true} />;
  }

  const monthlyPaymentAmount = selectedDeposit || recommendation!.suggested_monthly_amount;

  return (
    <Layout
      title="Spara"
      onGoBack={step === ChangeSavingsStep.Slider ? onGoHome : () => setStep(getPrevStep(step))}
      contentClassName="ChangeSavings"
    >
      {step === ChangeSavingsStep.Slider && (
        <>
          <div>
            <ChangeSavingsSlider
              selectedDeposit={monthlyPaymentAmount}
              minDeposit={sliderConfig.min}
              maxDeposit={sliderConfig.max}
              capitalPer100kr={recommendation!.capital_gained_for_each_100kr}
              suggestedDeposit={recommendation!.suggested_monthly_amount}
              yearsUntilRetirement={yearsUntilRetirement}
              retirementAge={retirementAge}
              onDepositChange={onDepositChange}
            />
            <div className="ChangeSavings__increase">
              <TodoItemIncrease withLink />
            </div>
          </div>
          <Button text="Nästa" primary onClick={() => setStep(ChangeSavingsStep.KycCustomerInfo)} />
        </>
      )}
      {step === ChangeSavingsStep.KycCustomerInfo && (
        <KycFormCustomerInfoComponent
          form={kycCustomerInfo}
          onFormSubmit={(form) => {
            setKycCustomerInfo(form);
            setStep(ChangeSavingsStep.KycInsuranceAmount);
          }}
        />
      )}
      {step === ChangeSavingsStep.KycInsuranceAmount && (
        <KycFormInsuranceAmountComponent
          form={kycInsuranceAmount}
          onFormSubmit={(form) => {
            setKycInsuranceAmount(form);
            setStep(ChangeSavingsStep.PayoutInfo);
          }}
        />
      )}
      {step === ChangeSavingsStep.PayoutInfo && (
        <PayoutInfoComponent
          form={payoutInfo}
          userAge={userAge}
          onFormSubmit={(form) => {
            setPayoutInfo(form);
            setStep(ChangeSavingsStep.BeneficiaryInfo);
          }}
        />
      )}
      {step === ChangeSavingsStep.BeneficiaryInfo && (
        <BeneficiaryInfoComponent
          form={beneficiaryInfo}
          onFormSubmit={(form) => {
            setBeneficiaryInfo(form);
            setStep(ChangeSavingsStep.BankDetails);
          }}
        />
      )}

      {step === ChangeSavingsStep.BankDetails && (
        <PensaAutogiro
          title="Bankuppgifter"
          subscriptionInfo={
            <>
              Det kommer dras <strong>{monthlyPaymentAmount.toLocaleString("sv")} kr</strong> per månad via autogiro
              till Futur Pension.
            </>
          }
          termsInfo={<></>}
          onSubmit={(bankInfo) =>
            onSubmitInsuranceApplication({
              monthlyPaymentAmount,
              kycCustomerInfo: kycCustomerInfo!,
              kycInsuranceAmount: kycInsuranceAmount!,
              payoutInfo: payoutInfo!,
              beneficiaryInfo: beneficiaryInfo!,
              bankInfo,
            })
          }
          skipBankName
        />
      )}
      <LoadingOverlay isShown={postInsuranceApplicationInProgress} />
    </Layout>
  );
};

export const ChangeSavings = connect(
  (state: AppState) => ({
    userAge: state.user.data?.calculated_current_age.years,
    selectedItem: selectedTodoItemSelector(state) as SavingsTodoItem | undefined,
    recommendation: savingsRecommendationsSelector(state),
    selectedDeposit: state.changeSavings.selectedDeposit,
    sliderConfig: sliderConfigSelector(state),
    yearsUntilRetirement: state.scraping.result && state.scraping.result.years_until_retirement,
    retirementAge: state.scraping.result && state.scraping.result.retirement_age,
    hasInsuranceApplication: !!state.changeSavings.insuranceApplicationResponse?.applicationId,
    postInsuranceApplicationInProgress: state.requests.postInsuranceApplication.loading,
  }),
  (dispatch: Dispatch) => ({
    onGoHome(replaceRoute?: boolean) {
      dispatch(replaceRoute ? replace(HomeRoute.format({})) : push(HomeRoute.format({})));
    },
    onDepositChange(deposit: number) {
      dispatch(changeMonthlyDepositAction({ deposit }));
    },
    onLoadDocuments() {
      dispatch(loadDanicaDocumentsAction({}));
    },
    onGoBack() {
      dispatch(goBack());
    },
    onSubmitInsuranceApplication(data: InsuranceApplicationBody) {
      dispatch(submitInsuranceApplicationAction(data));
    },
  })
)(ChangeSavingsAtom);
