import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { push, goBack } from "connected-react-router";

import { Layout, ButtonLink, Button } from "@components/common";
import { HomeRoute, SupportFAQRoute, SupportTermsRoute } from "@common/routes";
import { AppState } from "@state/store";
import { icons } from "@assets";

import "./Support.scss";

interface SupportProps {
  hasPrevLocation: boolean;

  onGoBack(): any;
  onGoHome(): any;
  onGoToFAQ(): any;
  onGoToTerms(): any;
}

export const Support = connect(
  (state: AppState) => ({
    hasPrevLocation: !!state.router.prevLocation,
  }),
  (dispatch: Dispatch) => ({
    onGoHome() {
      dispatch(push(HomeRoute.format({})));
    },
    onGoBack() {
      dispatch(goBack());
    },
    onGoToFAQ() {
      dispatch(push(SupportFAQRoute.format({})));
    },
    onGoToTerms() {
      dispatch(push(SupportTermsRoute.format({})));
    },
  })
)(({ hasPrevLocation, onGoHome, onGoBack, onGoToFAQ, onGoToTerms }: SupportProps) => {
  return (
    <Layout title="Support" onGoBack={hasPrevLocation ? onGoBack : onGoHome} contentClassName="Support">
      <SupportContacts text="Vill du komma i kontakt med oss?" />
      <ButtonLink text="Vanliga Frågor" inverse onClick={onGoToFAQ} />
      <ButtonLink text="Avtalsvillkor" inverse onClick={onGoToTerms} />
    </Layout>
  );
});

interface SupportContactsProps {
  text: string;
}

export const SupportContacts = ({ text }: SupportContactsProps) => (
  <div className="Support__contacts">
    <p className="Support__text">{text}</p>
    <div className="Support__wrapper">
      <div className="Support__item">
        <img className="Support__itemLogo" src={icons.email} alt="" />
        <p className="Support__itemText">kundtjanst@pensionskraft.se</p>
        <Button text="Maila oss" primary onClick={() => window.open("mailto:kundtjanst@pensionskraft.se")} />
      </div>
      <div className="Support__item">
        <img className="Support__itemLogo" src={icons.phone} alt="" />
        <p className="Support__itemText">Vardagar 09:00 - 12:00</p>
        <p className="Support__itemPhone">063 – 501 16 20</p>
        <Button text="Ring oss" primary onClick={() => (window.location.href = "tel:+46635011620")} />
      </div>
    </div>
  </div>
);
