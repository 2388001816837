import { Action } from "redux";

import { NotificationsState, defaultNotificationsState } from "./state";
import { notificationsErrorAction } from "./actions";

export function notificationsReducer(
    state: NotificationsState = defaultNotificationsState,
    action: Action
): NotificationsState {
    if (notificationsErrorAction.is(action)) {
        return { ...state, error: action.error };
    }
    return state;
}
