export enum MinpensionDataSteps {
  Login = "login",
  CollectionStatus = "insamlingsstatus",
  TotalPension = "totalpension",
  AllmanPensionInfo = "allmanpension-info",
  AllmanPension = "allmanpension",
  TjanstePensionInfo = "tjanstepension-info",
  TjanstePension = "tjanstepension",
  PrivatPensionInfo = "privatpension-info",
  PrivatPension = "privatpension",
}

export interface FundInList {
  fund_in_shell_id: string;
  name: string;
  valuation: number;
}

export interface AllmanPensionData {
  inkomstPension?: number;
  premiePension: FundInList[];
}

export interface PensionPlanData {
  insurance_type: string;
  insurance_shell: string;
  insurance_number: string;
  pension_institute: string;
  management_type: string;

  capital?: number;
  annuity?: number;
  funds?: FundInList[];
}

export interface PensionWithPensionPlans {
  pensionPlans: PensionPlanData[];
}
export interface TjanstePensionData extends PensionWithPensionPlans {}
export interface PrivatPensionData extends PensionWithPensionPlans {}

export interface MinpensionData {
  totalPension?: number;
  allmanPension?: AllmanPensionData;
  tjanstePension?: TjanstePensionData;
  privatPension?: PrivatPensionData;
}

export interface MinpensionDataResponse {
  user: string;
  data: MinpensionData;
  created_date: string;
  updated_date: string;
}

export const managementTypeOptions = [
  {
    label: "Traditionell",
    value: "traditional",
  },
  {
    label: "Fond",
    value: "fund",
  },
  {
    label: "Blandad",
    value: "mixed",
  },
  {
    label: "Förmånsbestämd",
    value: "defined-benefit",
  },
  {
    label: "Depå",
    value: "depot",
  },
];

export const managementTypeKeyLabelMap = managementTypeOptions.reduce((acc, curr) => {
  acc[curr.value] = curr.label;
  return acc;
}, {});

const getFundsTotal = (funds: FundInList[]): number => funds.reduce((total, fund) => total + (fund.valuation || 0), 0);
const getPlansTotal = (plans: PensionPlanData[]): number =>
  plans.reduce((total, plan) => total + (plan.capital || 0) + getFundsTotal(plan.funds || []), 0);

export const getTotalPension = (data: MinpensionData): number => {
  let total = 0;
  total += data.allmanPension?.inkomstPension || 0;
  total += getFundsTotal(data.allmanPension?.premiePension || []);
  total += getPlansTotal(data.tjanstePension?.pensionPlans || []);
  total += getPlansTotal(data.privatPension?.pensionPlans || []);
  return total;
};
