import { createSelector } from "reselect";

import { scrapingResultSelector } from "@state/scraping/selectors";

const minpensionPrognosSelector = createSelector([scrapingResultSelector], data =>
  data ? data.prognosis.minpension : null
);
const approximationPrognosSelector = createSelector([scrapingResultSelector], data =>
  data ? data.prognosis.approximation : null
);

export const pensionPrognosvalueSelector = createSelector(
  [minpensionPrognosSelector, approximationPrognosSelector],
  (minpension, approximation) => {
    const minpensionValue = minpension && minpension.averaged.sek_per_month;
    const approximationValue = approximation && approximation.sek_per_month;
    const value = minpensionValue || approximationValue;
    return value ? Math.round(value) : value;
  }
);
