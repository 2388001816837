export interface ChangeFundsState {
  isFetching: boolean;
  preview: ChangeFundsPreviewState;
}

export interface ChangeFundsPreviewState {
  isOpen: boolean;
  itemId?: string;
  pdfViewerDocUrl?: string;
  signature?: string;
}

export const defaultChangeFundsState: ChangeFundsState = {
  isFetching: false,
  preview: {
    isOpen: false
  }
};
