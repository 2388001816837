import * as React from "react";

import { Layout, LoadingOverlay, Button, RippleEffect } from "@components/common";
import { AxaInsuranceOptions } from "@common/models";

import "./SafetyInsuranceBenefits.scss";

interface SafetyInsuranceBenefitsProps {
  alternatives: AxaInsuranceOptions | undefined;
  isSkipping: boolean;
  isUpsell: boolean;

  onNext(selectedAmount: number): any;
  onPrev(): any;
  onSkip(): any;
}

export const SafetyInsuranceBenefits = ({
  isSkipping,
  isUpsell,
  alternatives,
  onPrev,
  onNext,
  onSkip,
}: SafetyInsuranceBenefitsProps) => {
  return (
    <Layout title="Fördelarna för dig" contentClassName="SafetyInsuranceBenefits" onGoBack={onPrev}>
      <div className="SafetyInsuranceBenefits__card">
        <SafetyInsuranceBenefitsDescription />
      </div>
      <div className="SafetyInsuranceBenefits__alternatives">
        {alternatives &&
          alternatives.coverAmounts.map((amount) => (
            <Button
              key={`life_insurance_${amount}`}
              text={`Ja, försäkra ${amount.toLocaleString("sv")} kr/mån (${alternatives.grossMonthlyPremium[
                amount
              ].toLocaleString("sv")} kr/mån)`}
              onClick={() => onNext(amount)}
            />
          ))}
      </div>
      <RippleEffect className="SafetyInsuranceBenefits__skip" rounded onClick={onSkip}>
        {isUpsell ? "Nej tack, jag vill inte ändra nivån" : "Nej tack, jag vill inte ha denna försäkring"}
      </RippleEffect>
      <LoadingOverlay isShown={!alternatives || isSkipping} />
    </Layout>
  );
};

export const SafetyInsuranceBenefitsDescription = () => (
  <p>
    Hur skulle du klara dig om du tappade 40% av din lön? Det är verkligheten för väldigt många som idag blir
    arbetslösa, sjuka eller skadade. <br />
    <br />
    En traditionell inkomstförsäkring via tex ditt fackförbund täcker ofta upp till 80% av lönen i ungefär sju månader,
    efter en karens på 30 dagar. Pensionskraft Trygghetsförsäkring gör det möjligt att få 100% av din lön. Då kan du
    vara helt säker på att du och din familj kan behålla samma levnadsstandard upp till 12 månader. Här kan du läsa mer:
    <br />
    <a href="https://www.pensionskraft.se/trygghetsforsakring#villkor" target="_blank">
      www.pensionskraft.se/trygghetsforsakring
    </a>
  </p>
);
