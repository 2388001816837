export default function () {
    const splashScreen = document.getElementById("splashScreen");
    splashScreen!.style.opacity = "0";

    setTimeout(
        () => {
            splashScreen!.style.display = "none";
        },
        400,
    );
}
