import * as React from "react";
import { useState } from "react";

import { Layout, Checkbox, Button } from "@components/common";

import "./SafetyInsuranceQuestions.scss";

interface SafetyInsuranceQuestionsProps {
  initAllChecked: boolean;

  onPrev(): any;
  onNext(): any;
}

const questions = [
  "har fyllt 18, men inte 64 år;",
  "är folkbokförd i Sverige;",
  "för närvarande har en tillsvidareanställning sedan minst 6 månader hos samma arbetsgivare och arbetar minst 17 timmar per vecka;",
  "inte känner till uppsägning eller varsel om uppsägning av min anställning."
];

const setAllQuestionsTo = (val: boolean) =>
  questions.reduce((prev, curr) => {
    prev[curr] = val;
    return prev;
  }, {});

export const SafetyInsuranceQuestions = ({ initAllChecked, onPrev, onNext }: SafetyInsuranceQuestionsProps) => {
  const [checkedQuestions, setCheckedQuestions] = useState(initAllChecked ? setAllQuestionsTo(true) : {});
  const isAllChecked = !questions.some(text => !checkedQuestions[text]);

  return (
    <Layout title="Kvalificeringsfrågor" onGoBack={onPrev} contentClassName="SafetyInsuranceQuestions">
      <div>
        <div className="SafetyInsuranceQuestions__card">
          <p>Jag försäkrar att jag:</p>
          {questions.map((text, index) => (
            <Checkbox
              key={`life_insurance_healthcheck_question_${index}`}
              value={checkedQuestions[text]}
              label={text}
              onClick={() => setCheckedQuestions({ ...checkedQuestions, [text]: !checkedQuestions[text] })}
              alignTop
            />
          ))}
          <p>
            Försäkringen lämnar inte ersättning för: sjukdomar och skador som du har i dag eller som är direkt
            relaterade till sjukdom eller skada som du har idag, som konstaterats eller behandlats av en vårdgivare
            under de senaste 12 månaderna eller där du redan har avtalat tid för att träffa vårdgivare.
          </p>
          <p>
            Försäkringen kan inte tecknas av dig som är egenföretagare eller av dig som arbetar och är delägare i ett så
            kallat fåmansbolag.
          </p>
        </div>
      </div>

      <div className="SafetyInsuranceQuestions__action">
        <Button text="Nästa" disabled={!isAllChecked} onClick={onNext} />
      </div>
    </Layout>
  );
};
