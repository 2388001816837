import * as React from "react";
import * as cn from "classnames";

import { icons } from "@assets";

import "./InstructionsLayout.scss";
import { RippleEffect, Arrow } from "@components/common";

interface LoginInstructionsProps {
  children: React.ReactNode;
  contentClassName?: string;

  primaryNext?: boolean;
  nextBtnText?: string;

  onNext?(): void;
  onBack?(): void;
}
export const InstructionsLayout = ({
  children,
  contentClassName,
  nextBtnText,
  onNext,
  onBack,
}: LoginInstructionsProps) => (
  <div className="InstructionsLayout">
    {onBack && (
      <RippleEffect className="InstructionsLayout__back" circled onClick={onBack}>
        <Arrow size="1.5rem" color="#12121c" />
      </RippleEffect>
    )}
    <div className="InstructionsLayout__header">
      <img className="InstructionsLayout__logo" src={icons.logoPensionskraft} alt="Logo Pensionskraft" />
    </div>

    <div className={cn("InstructionsLayout__content", contentClassName)}>{children}</div>
    <div className="InstructionsLayout__actions">
      {onNext && nextBtnText && (
        <RippleEffect
          rounded
          className={cn("InstructionsLayout__nextBtn", { "InstructionsLayout__nextBtn--centered": !onBack })}
          onClick={onNext}
        >
          {nextBtnText}
        </RippleEffect>
      )}
    </div>
  </div>
);
