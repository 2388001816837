import * as React from "react";

import { Layout, Button, LoadingOverlay } from "@components/common";

import "./SafetyInsuranceSummary.scss";

interface SafetyInsuranceSummaryProps {
  coverage: number;
  price: number;
  isSaving: boolean;

  onPrev(): any;
  onNext(): any;
}

export const SafetyInsuranceSummary = ({ isSaving, coverage, price, onPrev, onNext }: SafetyInsuranceSummaryProps) => {
  return (
    <Layout title="Din Trygghetsforsakring" contentClassName="SafetyInsuranceSummary" onGoBack={onPrev}>
      <div className="SafetyInsuranceSummary__card">
        <h3 className="SafetyInsuranceSummary__title">Teckna försäkringen</h3>
        <p className="SafetyInsuranceSummary__text">
          Du har valt att försäkra ett belopp om <strong>{coverage.toLocaleString("sv")} kr.</strong>
          <br />
          Din månadskostnad för försäkringen blir <strong>{price.toLocaleString("sv")} kr.</strong>
          <br />
          <br />
          Försäkringen innehåller vissa undantag som du kan läsa om i förköpsinformationen. Försäkringsvillkor och
          förköpsinformation finns på{" "}
          <a href="https://www.pensionskraft.se/trygghetsforsakring#villkor" target="_blank">
            www.pensionskraft.se/trygghetsforsakring
          </a>
          .
        </p>

        <h3 className="SafetyInsuranceSummary__title">Genom att signera bekräftar jag att jag:</h3>
        <p className="SafetyInsuranceSummary__text">
          -har tagit del av och förstått förköpsinformationen;
          <br />
          -har fyllt 18, men inte 64 år;
          <br />
          -är folkbokförd i Sverige;
          <br />
          -för närvarande har en tillsvidareanställning sedan minst 6 månader hos samma arbetsgivare och arbetar minst
          17 timmar per vecka;
          <br />
          -inte känner till uppsägning eller varsel om uppsägning av min anställning.
          <br />
          <br />
          För din information lämnar den här försäkringen inte ersättning för: sjukdomar och skador som du har i dag
          eller som är direkt relaterade till sjukdom eller skada som du har idag, som konstaterats eller behandlats av
          en vårdgivare under de senaste 12 månaderna eller där du redan har avtalat tid för att träffa vårdgivare.
          <br />
          <br />
          Det är viktigt att du uppfyller ovan annars kan försäkringsersättning utebli.
        </p>

        <h3 className="SafetyInsuranceSummary__title">Viktiga undantag</h3>
        <p className="SafetyInsuranceSummary__text">
          Sjukskrivning och Ofrivillig arbetslöshet:
          <br />
          -försäkringen kan inte tecknas av dig som är egenföretagare eller av dig som arbetar och är delägare i ett så
          kallat fåmansbolag;
          <br />
          -försäkringen täcker inte arbetslöshet eller varsel som du kände till eller borde ha känt till vid tecknandet
          av försäkringen eller arbetslöshet som du underrättas om inom 180 dagar från försäkringens tecknande;
          <br />
          -försäkringen gäller inte en sjukdom, sjukdomstillstånd eller skada som du har känt till inom de 12 månaderna
          närmast före försäkringens tecknande;
          <br />
          -det är 30 dagars karens. Det innebär att ersättning kan beviljas först från dag 31 av sjukskrivning eller
          arbetslöshet;
          <br />
          -försäkringen omfattar inte heller psykiskt relaterade sjukdomstillstånd;
          <br />
          -ingen ersättning beviljas om sjukskrivningen infaller under de första 30 dagarna av försäkringstiden och inte
          för arbetslöshet som du underrättats om inom de första 180 dagarna av försäkringstiden;
          <br />
          -försäkringsskyddet upphör om du har gått i någon form av pension, livränta eller uppbär motsvarande
          ersättning.
        </p>

        <h3 className="SafetyInsuranceSummary__title">Ångerrätt</h3>
        <p className="SafetyInsuranceSummary__text">
          Du kan kontakta Pensionskraft inom 30 dagar efter att du fått hem försäkringsbeskedet, om du vill ångra
          tecknandet av försäkringen. Du kan därefter säga upp försäkringen när du vill. Ytterligare information om
          ångerrätt och uppsägning finner du i förköpsinformationen.
        </p>
      </div>

      <Button text="Teckna försäkringen" primary onClick={onNext} />

      <LoadingOverlay isShown={isSaving} />
    </Layout>
  );
};
