import { UserWithInfo } from "./user-info";

export interface MainSettingsForm {
  firstName: string;
  lastName: string;
  personNumber: string;
  email: string;
  phone: string;
}

export const userToMainSettingsForm = (user?: UserWithInfo): MainSettingsForm => {
  return {
    firstName: user ? user.name.first : "",
    lastName: user ? user.name.last : "",
    personNumber: user ? user.person_number : "",
    email: user ? user.email : "",
    phone: user ? user.phone.mobile : ""
  };
};

export const applyMainSettingsForm = (src: UserWithInfo, form: MainSettingsForm): UserWithInfo => {
  return {
    ...src,
    name: {
      first: form.firstName,
      first_lowercase: form.firstName.toLocaleLowerCase(),
      last: form.lastName,
      last_lowercase: form.lastName.toLowerCase()
    },
    email: form.email.toLowerCase(),
    phone: {
      ...src.phone,
      mobile: form.phone
    }
  };
};

export interface PensionProfileForm {
  ownsHouse?: boolean;
  propertyValuetion?: string;
  mortage?: string;
  otherLoans?: string;
  savings?: string;
  employmentSector?: string;
  collectiveAgreement?: string;
  grossMonthlyIncome?: number;
  retirementAge?: number;
  maritalStatus?: string;
  dependantChildren?: number;
  riskWillingness?: boolean;
  hasFundExperience?: string;
  hasFundKnowledge?: string;
}

export const userToPensionProfileForm = (user: UserWithInfo): PensionProfileForm => {
  const riskProfile = user ? user.risk_profile : null;

  return {
    ownsHouse: riskProfile ? riskProfile.owns_own_home : undefined,
    propertyValuetion: riskProfile ? riskProfile.property_valuation_mkr : undefined,
    mortage: riskProfile ? riskProfile.mortgage_mkr : undefined,
    otherLoans: riskProfile ? riskProfile.other_loans_kkr : undefined,
    savings: riskProfile ? riskProfile.savings_kkr : undefined,
    employmentSector: riskProfile ? riskProfile.employment_sector : undefined,
    collectiveAgreement: riskProfile ? riskProfile.collective_agreement : undefined,
    grossMonthlyIncome: riskProfile ? riskProfile.gross_monthly_income : undefined,
    retirementAge: riskProfile ? riskProfile.retirement_age || 65 : undefined,
    maritalStatus: riskProfile ? riskProfile.marital_status : undefined,
    dependantChildren: riskProfile ? riskProfile.dependant_children : undefined,
    riskWillingness: riskProfile ? riskProfile.risk_willingness : undefined,
    hasFundExperience: riskProfile ? riskProfile.has_fund_experience : undefined,
    hasFundKnowledge: riskProfile ? riskProfile.has_fund_knowledge : undefined
  };
};

export const applyPensionProfileForm = (src: UserWithInfo, form: PensionProfileForm): UserWithInfo => {
  return {
    ...src,
    risk_profile: {
      ...src.risk_profile,
      owns_own_home: form.ownsHouse,
      property_valuation_mkr: form.propertyValuetion,
      mortgage_mkr: form.mortage,
      other_loans_kkr: form.otherLoans,
      savings_kkr: form.savings,
      employment_sector: form.employmentSector,
      collective_agreement: form.collectiveAgreement,
      gross_monthly_income: form.grossMonthlyIncome,
      retirement_age: form.retirementAge,
      marital_status: form.maritalStatus,
      dependant_children: form.dependantChildren,
      risk_willingness: form.riskWillingness,
      has_fund_experience: form.hasFundExperience,
      has_fund_knowledge: form.hasFundKnowledge
    }
  };
};
