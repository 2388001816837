import { createSelector } from "reselect";
import get from "lodash-es/get";
import isEmpty from "lodash-es/isEmpty";

import { AppState } from "@state/store";

import { isEmptyObject } from "@common/helpers";
import { scrapingResultSelector } from "@state/scraping/selectors";
import { userSelector } from "@state/user/selectors";

export const currentPrognosisSelector = createSelector([scrapingResultSelector], data => {
  return data && !isEmpty(data)
    ? data.prognosis.minpension
      ? data.prognosis.minpension.averaged.sek_per_month
      : data.prognosis.approximation.sek_per_month
    : undefined;
});

export const selectedDepositAmountSelector = (state: AppState) => {
  return state.changeSavings.selectedDeposit;
};

export const savingsRecommendationsSelector = createSelector(
  [scrapingResultSelector],
  data =>
    data &&
    (isEmptyObject(get(data, "summary.recommendation.savings", {})) ? undefined : data.summary.recommendation.savings)
);

export const sliderConfigSelector = createSelector([savingsRecommendationsSelector], rec => {
  const values: number[] =
    rec && rec.deltas_for_alternatives ? Object.keys(rec.deltas_for_alternatives).map(val => +val) : [];
  return {
    min: values[0] || 0,
    max: values.length >= 2 ? values[values.length - 1] : values[0] || 0,
    step: values.length >= 2 ? values[1] - values[0] : 0
  };
});

const insuranceApplicationSelector = (state: AppState) => state.changeSavings.insuranceApplicationResponse;

export const insuranceApplicationReceiptUrlSelector = createSelector(
  [userSelector, insuranceApplicationSelector],
  (user, application) => {
    if (!user || !application) {
      return undefined;
    }

    return `${location.origin}/web/viewer.html?file=${encodeURI(
      `${location.origin}/api/users/${user._id}/danica/insurance-application/${application.applicationId}/receipt`
    )}`;
  }
);
