import * as React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import { HomeRoute } from "@common/routes";
import { AppState } from "@state/store";
import { pensionPrognosvalueSelector } from "@state/home";
import { monthlyIncomeSelector, collectiveAgreementSelector, pensionPrognosisGradeSelector } from "@state/showMore";
import { MinpensionGrades } from "@common/models";
import { isRetiredUserSelector } from "@state/user";

import { PensionPrognosisScore } from "./score";

import "./ShowMorePrognosis.scss";

interface ShowMorePrognosisProps {
  isDataFethced: boolean;
  isRetired: boolean;
  pensionPrognosis: number | null;
  monthlyIncome: number | null;
  collectiveAgreement: string;
  prognosisGrade: MinpensionGrades | undefined;
}

export const ShowMorePrognosisAtom = ({
  isDataFethced,
  isRetired,
  pensionPrognosis,
  monthlyIncome,
  prognosisGrade,
  collectiveAgreement
}: ShowMorePrognosisProps) => {
  return isDataFethced && (!(pensionPrognosis && prognosisGrade) || isRetired) ? (
    <Redirect to={HomeRoute.format({})} />
  ) : (
    <div className="ShowMorePrognosis">
      <div className="ShowMorePrognosis__info">
        <h4 className="ShowMorePrognosis__infoTitle">Pensionsprognos</h4>
        <p className="ShowMorePrognosis__infoText">
          Här ser du din pensionsprognos och hur bra det är mot din nuvarande månadslön. Din prognos har beräknats på en
          månadslön på <strong>{monthlyIncome ? `${(monthlyIncome * 1000).toLocaleString("sv")} kr` : "N/A"}</strong>{" "}
          och tjänsteavtal: <strong>{collectiveAgreement}</strong>
        </p>
      </div>
      <div className="ShowMorePrognosis__prognosis">
        <h3 className="ShowMorePrognosis__prognosisHeader">Prognosresultat</h3>
        <div className="ShowMorePrognosis__prognosisContent">
          <h2 className="ShowMorePrognosis__prognosisValue">
            {pensionPrognosis && pensionPrognosis.toLocaleString("sv")} kr
          </h2>
          <p className="ShowMorePrognosis__prognosisPeriod">per månad</p>
        </div>
        <PensionPrognosisScore grade={prognosisGrade!} />
      </div>
    </div>
  );
};

export const ShowMorePrognosis = connect((state: AppState) => ({
  isDataFethced: !!state.scraping.result,
  isRetired: isRetiredUserSelector(state),
  pensionPrognosis: pensionPrognosvalueSelector(state),
  monthlyIncome: monthlyIncomeSelector(state),
  collectiveAgreement: collectiveAgreementSelector(state),
  prognosisGrade: pensionPrognosisGradeSelector(state)
}))(ShowMorePrognosisAtom);
