import * as React from "react";
import * as cn from "classnames";

import { icons } from "@assets";
import { getFundName, getArrowDirection, getFormattedPercentsValue, getSign } from "@common/helpers";
import { CircledArrow } from "@components/common";

import "./FundsItem.scss";

interface FundsItemProps {
  title?: string;
  isTotal?: boolean;
  valuation: number;
  meanReturn?: number | null;
  monthChange?: number | null;
  ytdReturn?: number | null;
  ytdChange?: number | null;
  monthName: string;
  year: string;
}

export const FundsItem = ({
  title,
  isTotal,
  valuation,
  meanReturn,
  monthChange,
  ytdChange,
  ytdReturn,
  monthName,
  year
}: FundsItemProps) => {
  return (
    <div className="FundsItem">
      <div className="FundsItem__left">
        {isTotal ? (
          <div className="FundsItem__total">
            <img src={icons.funds} alt="" />
            <p>TOTAL</p>
          </div>
        ) : (
          <h4 className="FundsItem__title">{getFundName(title || "Ingen info")}</h4>
        )}
        <p className={cn("FundsItem__valuation", { "FundsItem__valuation--total": !title })}>
          {valuation.toLocaleString("sv")} kr
        </p>
      </div>
      <div className="FundsItem__right">
        <FundsItemColumn date={monthName} percents={meanReturn} valuation={monthChange} />
        <FundsItemColumn date={year} percents={ytdReturn} valuation={ytdChange} />
      </div>
    </div>
  );
};

interface FundsItemColumnProps {
  date: string;
  percents?: number | null;
  valuation?: number | null;
}

export const FundsItemColumn = ({ date, percents, valuation }: FundsItemColumnProps) => {
  const className = valuation ? getArrowDirection(valuation) : "";
  return (
    <div className="FundsItemColumn">
      <div className="FundsItemColumn__top">
        <p className="FundsItemColumn__date">{date}*</p>
        {percents || percents === 0 ? (
          <div className="FundsItemColumn__meanReturn">
            <CircledArrow direction={getArrowDirection(percents)} size="2.2rem" />
            <p className="FundsItemColumn__meanReturnValue">{getFormattedPercentsValue(percents)}</p>
          </div>
        ) : (
          <div className="FundsItemColumn__noInfo">Ingen Info</div>
        )}
      </div>

      {(!!valuation || valuation === 0) && (
        <p className={cn("FundsItemColumn__valuation", `FundsItemColumn__valuation--${className}`)}>
          {`${getSign(valuation)}${valuation.toLocaleString("sv")} kr`}
        </p>
      )}
    </div>
  );
};
