import * as React from "react";
import { icons } from "@assets";

import "./InfoPopup.scss";
import Tooltip from "rc-tooltip";

interface InfoPopupProps {
  readonly popupContent: React.ReactNode;
}

export const InfoPopup: React.SFC<InfoPopupProps> = props => {
  return (
    <Tooltip placement="bottom" overlayClassName="InfoPopup__tooltip" trigger={["click"]} overlay={props.popupContent}>
      {props.children || <img className="InfoPopup__icon" src={icons.info} alt="" />}
    </Tooltip>
  );
};
