import * as React from "react";
import MaskedInput from "react-text-mask";
import * as cn from "classnames";

import "./TextField.scss";

export interface TextFieldProps {
  name?: string;
  label?: string;
  value?: string;
  type: TextFieldType;
  disabled?: boolean;
  placeholder?: string;
  id?: string;
  suffix?: string;

  onChange?(value: string): any;
  onBlur?(e: React.FocusEvent<HTMLInputElement>): any;
}

interface TextFieldState {
  focused: boolean;
}

export enum TextFieldType {
  Text = "Text",
  Number = "Number",
  NumberAndHyphen = "NumberAndHyphen",
  Email = "Email",
  Phone = "Phone",
  Personnumber = "Personnumber",
  Salary = "Salary",
}

let inputCount = 0;
const numberRegExp = /^[0-9]*$/;
const numberAndHyphenRegExp = /^[0-9\-]*$/;
const phoneMask = [/\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, " ", /\d/, /\d/, " ", /\d/, /\d/];
const personNumberMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/];

const getSuffixPosition = (value: string | number) => {
  const length = value.toString().length;
  return length < 4 ? 1.2 + 0.9 * (length - 1) : 1.2 + 0.8 * (length - 1);
};
export class TextField extends React.Component<TextFieldProps, TextFieldState> {
  textFieldId = `trygga-text-field-${inputCount++}`;

  constructor(props: TextFieldProps) {
    super(props);
    this.state = {
      focused: false,
    };
  }

  filterValue(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    const prev = this.props.value || "";
    if (!value) {
      return "";
    }

    switch (this.props.type) {
      // accept only digits or hyphen
      case TextFieldType.Number:
        return numberRegExp.test(value) ? value : prev;
      case TextFieldType.NumberAndHyphen:
        return numberAndHyphenRegExp.test(value) ? value : prev;
      default:
        return value;
    }
  }

  removeMask(valueWithMask: string) {
    switch (this.props.type) {
      case TextFieldType.Phone:
        return valueWithMask.split(" ").join("");
      default:
        return valueWithMask;
    }
  }

  handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { onChange } = this.props;
    const filteredValue = this.filterValue(e);
    const valueWithoutMask = this.removeMask(filteredValue);
    onChange && onChange(valueWithoutMask);
  };

  get mask() {
    switch (this.props.type) {
      case TextFieldType.Phone:
        return phoneMask;
      case TextFieldType.Personnumber:
        return personNumberMask;
      default:
        return false;
    }
  }

  get inputType() {
    switch (this.props.type) {
      case TextFieldType.Email:
        return "email";
      case TextFieldType.Number:
        return "number";
      default:
        return "text";
    }
  }

  render() {
    const { type, disabled, label, value, suffix, onBlur } = this.props;
    return (
      <div className={cn("TextField", { disabled })} id={this.props.id}>
        {label && (
          <label htmlFor={this.textFieldId} className={cn("TextField__label")}>
            {label}
          </label>
        )}
        {[TextFieldType.Text, TextFieldType.Email, TextFieldType.Number, TextFieldType.NumberAndHyphen].indexOf(type) >
        -1 ? (
          <input
            name={this.props.name}
            type={this.inputType}
            value={value || ""}
            onChange={this.handleInputChange}
            onFocus={() => this.setState({ focused: true })}
            onBlur={(e) => {
              this.setState({ focused: false });
              if (typeof onBlur === "function") {
                onBlur(e);
              }
            }}
            className="TextField__input"
            id={this.textFieldId}
            disabled={disabled}
            placeholder={this.props.placeholder}
            autoComplete="off"
          />
        ) : (
          <MaskedInput
            name={this.props.name}
            mask={this.mask}
            value={value || ""}
            onChange={this.handleInputChange}
            onFocus={() => this.setState({ focused: true })}
            onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
              this.setState({ focused: false });
              if (typeof onBlur === "function") {
                onBlur(e);
              }
            }}
            className="TextField__input"
            id={this.textFieldId}
            disabled={disabled}
            placeholder={this.props.placeholder}
          />
        )}
        {suffix && value && (
          <span className="TextField__suffix" style={{ left: `${getSuffixPosition(value)}rem` }}>
            {suffix}
          </span>
        )}
      </div>
    );
  }
}
