import { createSelector } from "reselect";
import get from "lodash-es/get";

import { pensionPlansSelector } from "@state/showMore/selectors";
import { isFreeUserSelector, isPPMConfirmedSelector, userSelector } from "@state/user/selectors";
import {
  selectedDepositAmountSelector,
  savingsRecommendationsSelector,
  sliderConfigSelector,
} from "@state/changeSavings/selectors";
import { fundRecommendationsSelector } from "@state/changeFunds/selectors";
import { AppState } from "@state/store";
import { scrapingResultSelector } from "@state/scraping/selectors";

import { TodoItemType, MeterType } from "@common/types";
import {
  TodoItemModel,
  ChangeFundTodoItem,
  BasicScoringModel,
  InsuranceRecommendationModel,
  UpdatePensionProfileTodoItem,
  UpdatePensionAnalysisTodoItem,
  InsuranceUpsellModel,
  SavingsTodoItem,
} from "@common/models";
import { roundNumberTo, isPPMFund } from "@common/helpers";

export const overallScoringSelector = createSelector([scrapingResultSelector], (data) => {
  const scoring: BasicScoringModel = {
    risk: get(data, "summary.overall.scoring.risk.currently", 0),
    quality: get(data, "summary.overall.scoring.quality.currently", 0),
    savings: get(data, "summary.overall.scoring.savings.currently", 0),
    total: get(data, "summary.overall.scoring.total.currently", 0),
  };

  return scoring;
});

export const changeFundTodoItemSelector = createSelector(
  [fundRecommendationsSelector, isPPMConfirmedSelector, isFreeUserSelector],
  (recommendations, isPPMConfirmed, isFreeUser) =>
    isFreeUser
      ? []
      : recommendations
          .filter((rec) => isPPMConfirmed || !isPPMFund(rec.pension_institute))
          .map((rec) => {
            // const instituteName = isPPMFund(rec.pension_institute) ? "PPM" : rec.pension_institute;

            return {
              id: `${TodoItemType.ChangeFunds}__${rec.pension_institute}__${!!rec.is_rebalancing}`,
              type: TodoItemType.ChangeFunds,
              title: rec.is_rebalancing
                ? `Rebalansera fonder hos ${rec.pension_institute}`
                : `Byt fonder hos ${rec.pension_institute}`,
              description: rec.is_rebalancing
                ? `Justerar fördelningen av dina fonder.`
                : `Byt ${rec.insurance_numbers!.length} pensionssparande hos ${rec.pension_institute}`,
              payload: {
                pensionInstitute: rec.pension_institute,
                isRebalancing: rec.is_rebalancing,
              },
              increase: {
                risk: roundNumberTo(rec.improvements.risk || 0),
                savings: roundNumberTo(rec.improvements.savings || 0),
                quality: roundNumberTo(rec.improvements.quality || 0),
                total: roundNumberTo(rec.improvements.total || 0),
              },
              finished: !!rec.submitted_date,
              submitted_date: rec.submitted_date,
            } as ChangeFundTodoItem;
          })
);

export const changeSavingsTodoItemSelector = createSelector(
  [
    savingsRecommendationsSelector,
    selectedDepositAmountSelector,
    sliderConfigSelector,
    isFreeUserSelector,
    userSelector,
  ],
  (rec, selectedDepositAmount, sliderConfig, isFreeUser, user) => {
    if (user?._id !== "5383428ea87d9b5a195346b4") {
      return [];
    }

    if (isFreeUser) {
      return [];
    }
    if (!rec || rec.suggested_monthly_amount === 0 || !sliderConfig.step) {
      return [];
    }

    const selectedDeposit = selectedDepositAmount || rec.requested_monthly_amount || rec.suggested_monthly_amount;
    const selectedDeltas = rec.deltas_for_alternatives![
      Math.round(selectedDeposit / sliderConfig.step) * sliderConfig.step
    ];

    return [
      {
        id: TodoItemType.ChangeSavings,
        type: TodoItemType.ChangeSavings,
        title: "Börja spara",
        description: `Få ${(
          Math.round((selectedDeposit * rec.capital_gained_for_each_100kr) / 100000) * 1000
        ).toLocaleString("sv")} kr mer i pension`,
        finished: !!rec.submitted_date,
        submitted_date: rec.submitted_date,
        increase: {
          risk: 0,
          savings: selectedDeltas && selectedDeltas.savings_score ? roundNumberTo(selectedDeltas.savings_score) : 0,
          quality: 0,
          total: selectedDeltas && selectedDeltas.total_score ? roundNumberTo(selectedDeltas.total_score) : 0,
        },
      } as SavingsTodoItem,
    ];
  }
);

export const insurancesRecommendationSelector = createSelector(
  [scrapingResultSelector],
  (data) => get(data, "summary.recommendation.group_insurances") as InsuranceRecommendationModel
);

export const insuranceTodoItemSelector = createSelector(
  [insurancesRecommendationSelector, userSelector],
  (recocmmendation, user) => {
    if (!recocmmendation || !user) {
      return [];
    }
    const insurances: TodoItemModel[] = [];

    // life insurance
    if (get(recocmmendation, "life_insurance.needs_cover") && user.calculated_current_age.decimal_years < 63.9) {
      insurances.push({
        id: TodoItemType.LifeInsurance,
        type: TodoItemType.LifeInsurance,
        title: "Skaffa en livförsäkring",
        description: "Du har pensionskapital som är oförsäkrat",
        finished: !!(
          get(recocmmendation, "life_insurance.submitted_date") || get(recocmmendation, "life_insurance.skipped_date")
        ),
        submitted_date: get(recocmmendation, "life_insurance.submitted_date"),
      });
    }

    // life insurance upsell
    const lifeInsuranceUpsell: InsuranceUpsellModel = get(recocmmendation, "life_insurance.upsell");
    if (lifeInsuranceUpsell.upsell_possible) {
      insurances.push({
        id: TodoItemType.LifeInsuranceUpsell,
        type: TodoItemType.LifeInsuranceUpsell,
        title: "Din Livförsäkring",
        description: "Se över ditt skydd",
        finished: !!(lifeInsuranceUpsell.submitted_date || lifeInsuranceUpsell.skipped_date),
        submitted_date: lifeInsuranceUpsell.submitted_date,
      });
    }

    // safety insurance
    if (user.calculated_current_age.decimal_years < 63.9) {
      insurances.push({
        id: TodoItemType.SafetyInsurance,
        type: TodoItemType.SafetyInsurance,
        title: "Skaffa en trygghetsförsäkring",
        description: "Skydd vid arbetslöshet, sjukdom och skada",
        finished: !!(
          get(recocmmendation, "safety_insurance.submitted_date") ||
          get(recocmmendation, "safety_insurance.skipped_date")
        ),
        submitted_date: get(recocmmendation, "safety_insurance.submitted_date"),
      });
    }

    // safety insurance upsell
    const safetyInsuranceUpsell: InsuranceUpsellModel = get(recocmmendation, "safety_insurance.upsell");
    if (safetyInsuranceUpsell.upsell_possible) {
      insurances.push({
        id: TodoItemType.SafetyInsuranceUpsell,
        type: TodoItemType.SafetyInsuranceUpsell,
        title: "Din Trygghetsförsäkring",
        description: "Se över ditt skydd",
        finished: !!(safetyInsuranceUpsell.submitted_date || safetyInsuranceUpsell.skipped_date),
        submitted_date: safetyInsuranceUpsell.submitted_date,
      });
    }

    return insurances;
  }
);

export const updatePensionProfileTodoItemSelector = createSelector([userSelector], (user) => {
  return user
    ? [
        {
          id: TodoItemType.UpdatePensionProfile,
          type: TodoItemType.UpdatePensionProfile,
          title: "Se över din Pensionsprofil",
          description: "Kontrollera att uppgifterna stämmer",
          finished: !user.is_risk_profile_update_required,
        } as UpdatePensionProfileTodoItem,
      ]
    : [];
});

export const updatePensionAnalysisTodoItemSelector = createSelector([userSelector], (user) => {
  // TMP:MINPENSION
  return user && user.is_tryggakollen_analysis_expired
    ? [
        {
          id: TodoItemType.UpdatePensionAnalysis,
          type: TodoItemType.UpdatePensionAnalysis,
          title: "Gör ny Pensionskoll",
          description: "Du behöver uppdatera din Pensionskoll",
        } as UpdatePensionAnalysisTodoItem,
      ]
    : [];
});

export const todoItemListSelector = createSelector(
  [
    changeFundTodoItemSelector,
    changeSavingsTodoItemSelector,
    insuranceTodoItemSelector,
    updatePensionProfileTodoItemSelector,
    updatePensionAnalysisTodoItemSelector,
  ],
  (
    changeFundItems: TodoItemModel[],
    changeSavingsItem: TodoItemModel[],
    insuranceItems: TodoItemModel[],
    updatePensionProfileItem: TodoItemModel[],
    updatePensionAnalysisItem: TodoItemModel[]
  ) => {
    return [
      ...updatePensionProfileItem,
      ...changeFundItems,
      ...changeSavingsItem,
      ...insuranceItems,
      ...updatePensionAnalysisItem,
    ];
  }
);

export const recentCompletedItemIdSelector = (state: AppState) => state.todoItem.completedItemId;

export const selectedTodoItemIdSelector = (state: AppState) => state.todoItem.selectedItemId;

export const recentCompletedItemSelector = createSelector(
  [recentCompletedItemIdSelector, todoItemListSelector],
  (id, items) => {
    return (id && items.find((item) => item.id === id)) as TodoItemModel | undefined;
  }
);

export const selectedTodoItemSelector = createSelector(
  [selectedTodoItemIdSelector, todoItemListSelector],
  (id, items) => {
    return (id && items.find((item) => item.id === id)) as TodoItemModel | undefined;
  }
);

const freeUserTodoItems: TodoItemModel[] = [
  {
    id: `${TodoItemType.FreeUserMock}__${MeterType.Funds}`,
    type: TodoItemType.FreeUserMock,
    title: "Byt till bättre fonder",
    description: "Premiumkunder får fondförslag.",
    finished: false,
    detailsType: MeterType.Funds,
  },
  {
    id: `${TodoItemType.FreeUserMock}__${MeterType.Savings}`,
    type: TodoItemType.FreeUserMock,
    title: "Har du tillräckligt sparat?",
    description: "Premiumkunder får reda på det.",
    finished: false,
    detailsType: MeterType.Savings,
  },
];

export const availableTodoItemListSelector = createSelector(
  [todoItemListSelector, recentCompletedItemSelector],
  (todoItems: TodoItemModel[], recentCompletedItem: TodoItemModel | undefined) => {
    const notCompletedItems = todoItems.filter(
      (todoItem) => !todoItem.finished || (recentCompletedItem && recentCompletedItem.id === todoItem.id)
    );
    const updatePensionAnalysisItem = notCompletedItems.find(
      (item) => item.type === TodoItemType.UpdatePensionAnalysis
    );
    const updateProfileItem = notCompletedItems.filter((item) => item.type === TodoItemType.UpdatePensionProfile);

    return updatePensionAnalysisItem ? [updatePensionAnalysisItem, ...updateProfileItem] : notCompletedItems;
  }
);

export const homeScreenTodoItemListSelector = createSelector(
  [availableTodoItemListSelector, isFreeUserSelector],
  (items, isFreeUser) => (isFreeUser ? [...items, ...freeUserTodoItems] : items)
);

// print only todo items that change score
export const printableTodoItemListSelector = createSelector(
  [todoItemListSelector, recentCompletedItemSelector],
  (todoItems: TodoItemModel[], recentCompletedItem: TodoItemModel | undefined) => {
    const notCompletedItems = todoItems.filter(
      (todoItem) => !todoItem.finished || (recentCompletedItem && recentCompletedItem.id === todoItem.id)
    );

    const printableTodoItemTypes = [
      TodoItemType.ChangeFunds,
      TodoItemType.ChangeSavings,
      TodoItemType.LifeInsurance,
      TodoItemType.SafetyInsurance,
      TodoItemType.LifeInsuranceUpsell,
      TodoItemType.SafetyInsuranceUpsell,
    ];
    return notCompletedItems.filter((item) => printableTodoItemTypes.find((type) => type === item.type));
  }
);

export const savingsTodoItemSelector = createSelector([availableTodoItemListSelector], (todoItems: TodoItemModel[]) =>
  todoItems.find((item) => item.type === TodoItemType.ChangeSavings)
);

const pensionDataDateSelector = createSelector([scrapingResultSelector], (data) => data && data.pension_data_date);

const submittedTodoItemsSelector = createSelector(
  [todoItemListSelector, pensionDataDateSelector],
  (todoItems, pensionDataDate) => {
    return pensionDataDate
      ? todoItems.filter(
          (item) => item.submitted_date && new Date(item.submitted_date).getTime() > new Date(pensionDataDate).getTime()
        )
      : [];
  }
);

const currentRevisionTodoItemsSelector = createSelector(
  [todoItemListSelector, pensionDataDateSelector],
  (todoItems, pensionDataDate) => {
    return pensionDataDate
      ? todoItems.filter(
          (item) =>
            !item.submitted_date || new Date(item.submitted_date).getTime() > new Date(pensionDataDate).getTime()
        )
      : [];
  }
);

const socresWihItemsSelectorCreator = (itemsSelector: (state: AppState) => TodoItemModel[]) =>
  createSelector([overallScoringSelector, itemsSelector], (overallScoring, todoItems) => {
    const initVal: BasicScoringModel = {
      total: 0,
      risk: 0,
      savings: 0,
      quality: 0,
    };
    const anticipatedDelta = todoItems.reduce(
      (prev, curr) => ({
        risk: prev.risk + ((curr.increase && curr.increase.risk) || 0),
        quality: prev.quality + ((curr.increase && curr.increase.quality) || 0),
        savings: prev.savings + ((curr.increase && curr.increase.savings) || 0),
        total: prev.total + ((curr.increase && curr.increase.total) || 0),
      }),
      initVal
    );
    return (overallScoring
      ? {
          risk: roundNumberTo(overallScoring.risk + anticipatedDelta.risk),
          savings: roundNumberTo(overallScoring.savings + anticipatedDelta.savings),
          quality: roundNumberTo(overallScoring.quality + anticipatedDelta.quality),
          total: roundNumberTo(overallScoring.total + anticipatedDelta.total),
        }
      : {
          risk: 0,
          savings: 0,
          quality: 0,
          total: 0,
        }) as BasicScoringModel;
  });

export const currentScoresSelector = socresWihItemsSelectorCreator(submittedTodoItemsSelector);

export const anticipatedScoresSelector = socresWihItemsSelectorCreator(currentRevisionTodoItemsSelector);

export const currentItemRecommededFundsSelector = createSelector(
  [pensionPlansSelector, fundRecommendationsSelector, selectedTodoItemSelector],
  (pensionPlans, fundRecommendations, item) => {
    if (!item || item.type !== TodoItemType.ChangeFunds) {
      return [];
    }

    const { isRebalancing, pensionInstitute } = item.payload;

    const recommendation = fundRecommendations.find((rec) => rec.pension_institute === pensionInstitute);
    if (!recommendation) {
      return [];
    }

    const pensionPlanWithRecommendedFunds = pensionPlans.find(
      (pp) =>
        pp.pension_institute === pensionInstitute &&
        !!(recommendation.insurance_numbers || []).find((insNumber) =>
          isPPMFund(pensionInstitute) ? insNumber === pp.insurance_name : insNumber === pp.insurance_number
        ) &&
        !!pp.is_rebalancing === !!isRebalancing
    );

    return (pensionPlanWithRecommendedFunds && pensionPlanWithRecommendedFunds.recommended_funds) || [];
  }
);
