import { defineAction } from "../../lib/rd-action-creators/index";

import { ChartPointData } from "./state";

export const homeSlideChangeAction = defineAction<{ newSlideNumber: number }>("HOME SLIDE CHANGE");
export const toggleFreeUserModalAction = defineAction("HOME TOGGLE FREE USER MODAL ACTION");
export const moneyChartPointSelectAction = defineAction<{ selectedPoint: ChartPointData }>(
  "HOME MONEY CHART POINT SELECTED"
);
export const toggleSidebarMenuAction = defineAction("HOME TOGGLE SIDEBAR MENU");
