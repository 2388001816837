import * as React from "react";
import * as cn from "classnames";

import { formatTimeInterval } from "@common/helpers";
import { RippleEffect } from "@components/common";

import "./NotificationItem.scss";

interface NotificationItemProps {
  title: string;
  sentDate: string;
  description: string;
  isRead: boolean;

  onClick(): any;
}

export const NotificationItem = ({ title, description, sentDate, isRead, onClick }: NotificationItemProps) => {
  return (
    <RippleEffect className={cn("NotificationItem", { "NotificationItem--new": !isRead })} onClick={onClick}>
      <div className="NotificationItem__header">
        <div className="NotificationItem__title">
          {!isRead && <div className="NotificationItem__titleDot" />}
          <p className="NotificationItem__titleText">{title}</p>
        </div>
        <p className="NotificationItem__date">{formatTimeInterval(new Date(sentDate), new Date())}</p>
      </div>
      <p className="NotificationItem__description">{description}</p>
    </RippleEffect>
  );
};
