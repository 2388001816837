import * as React from "react";

import { BlockerScreen, BlockerScreenType } from "@components/common";

import "./MinpensionDataProcessing.scss";

interface MinpensionDataProcessingProps {}

export const MinpensionDataProcessing = ({}: MinpensionDataProcessingProps) => {
  return <BlockerScreen type={BlockerScreenType.Progress} title="Ett ögonblick bara" />;
};
