import * as React from "react";
import { useState } from "react";

import { FormField, validateForm } from "@common/form";

import { PensaModal } from "../modal";
import { TextField, TextFieldType } from "../controls";

import "./EmailModal.scss";

interface PensaEmailModalProps {
  isOpened: boolean;
  header: string;

  initialEmail?: string;
  onSubmit(email: string): any;
  onClose(): any;
}

interface EmailForm {
  email: FormField<string>;
}

const constraints = {
  email: {
    presence: {
      message: "Glöm inte fylla i din email!",
      allowEmpty: false
    },
    email: {
      message: "Kontrollera din email-adress!"
    }
  }
};

export const PensaEmailModal = ({ isOpened, header, initialEmail, onSubmit, onClose }: PensaEmailModalProps) => {
  const [form, setForm] = useState<EmailForm>({
    email: { value: initialEmail || "" }
  });

  //   useEffect(() => {
  //     setEmail({
  //         email: { value: initialEmail }
  //       });
  //   }, [initialEmail]);

  const controlChanged = (controlName: string, controlValue: string) => {
    const newForm = { ...form };
    form[controlName].value = controlValue;
    setForm({
      ...validateForm(newForm, constraints)
    });
  };

  return (
    <PensaModal
      isOpened={isOpened}
      header={header}
      contentClassName="PensaEmailModal__wrapper"
      content={
        <form
          className="PensaEmailModal__form"
          noValidate
          onSubmit={e => {
            e.preventDefault();
            onSubmit(form.email.value);
          }}
          autoComplete="off"
        >
          <TextField
            type={TextFieldType.Email}
            label="E-post"
            value={form.email.value}
            onChange={val => controlChanged("email", val)}
          />
          <div className="Settings__line" />
          {form.email.errors && form.email.errors.length && (
            <p className="PensaEmailModal__error">{form.email.errors[0]}</p>
          )}
        </form>
      }
      submitText="Spara"
      submitDisabled={!form.email.value || !!(form.email.errors && form.email.errors.length)}
      onSubmit={() => onSubmit(form.email.value)}
      onClose={onClose}
    />
  );
};
