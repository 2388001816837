import { takeEvery, put, call, select } from "redux-saga/effects";
import { LocationChangeAction, push } from "connected-react-router";
import { LocationDescriptor } from "lib/rd-url-utils";
import { delay } from "redux-saga";

import {
  getMinpensionDataRequest,
  postMinpensionDataRequest,
  getPensionInstitutesRequest,
  postProcessMinpensionDataRequest,
  getJobRequest,
} from "@common/requests";
import { MinpensionData, MinpensionDataSteps } from "@common/models";
import { MinpensionDataRoute, MinpensionDataProcessingRoute } from "@common/routes";

import { responseErrorAction, showErrorAction } from "@state/error";
import { AppState } from "@state/store";
import { isLocationChangeAction } from "@state/customRouter";
import { scrapingFinishedSaga, getJobSaga } from "@state/scraping/saga";

import {
  getMinpensionDataAction,
  getPensionInstitutesAction,
  saveMinpensionDataAction,
  dataCollectionCompletedAction,
  pollProcessMinpensionDataJobAction,
} from "./actions";

export function* getMinpensionDataSaga() {
  yield put(getMinpensionDataRequest.actions.running({}));
  const response: typeof getMinpensionDataRequest.types.response = yield call(getMinpensionDataRequest, {});

  if (response.ok) {
    yield put(getMinpensionDataRequest.actions.ok({}, response));
  } else {
    yield put(getMinpensionDataRequest.actions.error({}, response));
    yield put(responseErrorAction({ response, hideBtn: true }));
  }

  return response;
}

export function* postMinpensionDataSaga(data: MinpensionData) {
  yield put(postMinpensionDataRequest.actions.running({}));
  const response: typeof postMinpensionDataRequest.types.response = yield call(postMinpensionDataRequest, {}, data);

  if (response.ok) {
    yield put(postMinpensionDataRequest.actions.ok({}, response));
  } else {
    yield put(postMinpensionDataRequest.actions.error({}, response));
    yield put(responseErrorAction({ response, hideBtn: true }));
  }

  return response;
}

export function* saveMinpensionDataSaga() {
  const minpensionData: MinpensionData = yield select<AppState>((state) => state.minpensionData.data || {});
  const wasChanged: boolean = yield select<AppState>((state) => state.minpensionData.wasChanged);

  if (wasChanged) {
    const response = yield call(postMinpensionDataSaga, minpensionData);
    return { ok: response.ok };
  }

  return { ok: true };
}

export function* getPensionInstitutesSaga() {
  yield put(getPensionInstitutesRequest.actions.running({}));
  const response: typeof getPensionInstitutesRequest.types.response = yield call(getPensionInstitutesRequest, {});

  if (response.ok) {
    yield put(getPensionInstitutesRequest.actions.ok({}, response));
  } else {
    yield put(getPensionInstitutesRequest.actions.error({}, response));
    yield put(responseErrorAction({ response, hideBtn: true }));
  }

  return response;
}

export function* processMinpensionDataSaga() {
  // first save most recent data
  const { ok } = yield call(saveMinpensionDataSaga);
  if (!ok) {
    return;
  }

  // send request to start processing and get back jobId
  yield put(postProcessMinpensionDataRequest.actions.running({}));
  const response: typeof postProcessMinpensionDataRequest.types.response = yield call(
    postProcessMinpensionDataRequest,
    {},
    {}
  );
  if (response.ok) {
    yield put(postProcessMinpensionDataRequest.actions.ok({}, response));
    // change route to wating screen
    yield put(push(MinpensionDataProcessingRoute.format({})));
    yield put(pollProcessMinpensionDataJobAction({ jobId: response.result.jobId }));
  } else {
    yield put(postProcessMinpensionDataRequest.actions.error({}, response));
    yield put(responseErrorAction({ response, hideBtn: true }));
  }
}

function* pollProcessMinpensionDataJobSaga({ jobId }: typeof pollProcessMinpensionDataJobAction.typeInterface) {
  const resp: typeof getJobRequest.types.response = yield call(getJobSaga, jobId);
  if (!resp.ok) {
    return;
  }

  const job = resp.result;

  if (job.failed) {
    yield put(showErrorAction({ hideBtn: true }));
    return;
  }

  if (job.finished_at) {
    const userId = yield select<AppState>((state) => state.user.userId);
    yield call(scrapingFinishedSaga, userId);
    return;
  }

  switch (job.current_step.name) {
    case "create-pension-revision-started":
    case "create-pension-revision-completed":
    case "generate-recommendations-started":
    case "generate-recommendations-completed":
    default:
  }

  yield delay(job.poll_interval * 1000);
  yield put(pollProcessMinpensionDataJobAction({ jobId }));
}

const isEditDataRoute = (location: LocationDescriptor) => {
  const match = MinpensionDataRoute.match(location);
  if (!match.isMatched) {
    return false;
  }
  const step = match.params.step;
  return [
    MinpensionDataSteps.TotalPension,
    MinpensionDataSteps.AllmanPension,
    MinpensionDataSteps.TjanstePension,
    MinpensionDataSteps.PrivatPension,
  ].includes(step);
};

export function* minpensionDataSaga() {
  yield takeEvery(getMinpensionDataAction.type, getMinpensionDataSaga);
  yield takeEvery(saveMinpensionDataAction.type, saveMinpensionDataSaga);
  yield takeEvery(getPensionInstitutesAction.type, getPensionInstitutesSaga);
  yield takeEvery(dataCollectionCompletedAction.type, processMinpensionDataSaga);
  yield takeEvery(pollProcessMinpensionDataJobAction.type, pollProcessMinpensionDataJobSaga);

  yield takeEvery(isLocationChangeAction, function* (action: LocationChangeAction) {
    const needUpdate = yield select<AppState>((state) => {
      const stillMinpensionDataRoute = (state.router.location && MinpensionDataRoute.match(state.router.location))
        ?.isMatched;
      const editedDataOnPrevRoute = state.router.prevLocation && isEditDataRoute(state.router.prevLocation);
      return stillMinpensionDataRoute && editedDataOnPrevRoute;
    });
    if (needUpdate) {
      yield call(saveMinpensionDataSaga);
    }
  });
}
