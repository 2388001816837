import * as React from "react";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { RouteComponentProps } from "react-router-dom";

import { BlockerScreen, BlockerScreenType } from "@components/common";
import { StandaloneInsuranceOffers } from "@common/types";
import { StandaloneLifeInsuranceUpsellRoute, StandaloneSafetyInsuranceUpsellRoute } from "@common/routes";

import { AppState } from "@state/store";
import { hasOfferOptionsSelector } from "@state/insurance";

interface StandaloneOfferCompletedProps {
  offerCode: StandaloneInsuranceOffers;
  hasOfferOptions: boolean;
  onGoBack(): any;
  onGoToOffer(offerCode: StandaloneInsuranceOffers): any;
}

export const StandaloneOfferCompleted = connect(
  (state: AppState, ownProps: RouteComponentProps<{ type: StandaloneInsuranceOffers }>) => ({
    offerCode: ownProps.match.params.type,
    hasOfferOptions: hasOfferOptionsSelector(state, ownProps.match.params.type)
  }),
  (dispatch: Dispatch) => ({
    onGoToOffer(offerCode: StandaloneInsuranceOffers) {
      switch (offerCode) {
        case StandaloneInsuranceOffers.LifeInsurance:
        case StandaloneInsuranceOffers.LifeInsuranceUpsell:
          dispatch(push(StandaloneLifeInsuranceUpsellRoute.format({})));
          break;

        case StandaloneInsuranceOffers.SafetyInsurance:
        case StandaloneInsuranceOffers.SafetyInsuranceUpsell:
          dispatch(push(StandaloneSafetyInsuranceUpsellRoute.format({})));
          break;
        default:
          break;
      }
    }
  })
)(({ hasOfferOptions, offerCode, onGoToOffer }: StandaloneOfferCompletedProps) => {
  return (
    <BlockerScreen
      type={BlockerScreenType.Error}
      title="Redan klart!"
      content={
        hasOfferOptions ? (
          <>Du har redan köpt denna försäkring. Vill du höja din försäkring så klickar du på knappen nedan.</>
        ) : (
          <>Du har redan ändrat din premie och kan inte höja den mer.</>
        )
      }
      btnText={hasOfferOptions ? "Jag vill försäkra ett större belopp" : ""}
      onClick={() => onGoToOffer(offerCode)}
    />
  );
});
