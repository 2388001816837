import * as React from "react";

import { InfoPopup } from "@components/common/infoPopup";

import { Checkbox } from "../checkbox";
import { TextField, TextFieldType } from "../textField";

import "./MultiSelect.scss";

export interface MultiSelectValue {
  selected: string[];
  other?: string;
}

export function otherValueMoreThan(value: MultiSelectValue, min: number): boolean {
  if (value.other === undefined || value.other === "") {
    return true;
  }
  return +value.other > min;
}

export interface MultiSelectProps {
  options: { label: string; value: string }[];
  otherOption?: { label: string; value: string };
  otherOptionDescription?: string;

  otherType?: TextFieldType;
  label: React.ReactNode;
  info: React.ReactNode;

  value: MultiSelectValue;
  single: boolean;

  onChange(newValue: MultiSelectValue): any;
}

// {single} prop should not be change after component initial render
export const MultiSelect = ({
  options,
  otherOption,
  otherType,
  otherOptionDescription,
  label,
  info,
  value,
  single,
  onChange
}: MultiSelectProps) => {
  return (
    <div className="MultiSelect">
      {label && (
        <p className="MultiSelect__label">
          {label}
          {info && <InfoPopup popupContent={info} />}
        </p>
      )}
      {options.map(option => {
        const isSelected = value.selected.includes(option.value);
        const onClick = () => {
          if (isSelected) {
            onChange({ ...value, selected: single ? [] : value.selected.filter(val => val !== option.value) });
          } else {
            onChange({
              selected: single ? [option.value] : [...value.selected, option.value],
              other: single ? undefined : value.other
            });
          }
        };
        return <Checkbox key={option.value} value={isSelected} label={option.label} onClick={onClick} />;
      })}
      {otherOption && (
        <>
          <Checkbox
            value={value.other !== undefined}
            label={otherOption.label}
            onClick={() => {
              if (value.other !== undefined) {
                onChange({
                  other: undefined,
                  selected: single ? [] : value.selected.filter(val => val !== otherOption.value)
                });
              } else {
                onChange({
                  selected: single ? [otherOption.value] : [...value.selected, otherOption.value],
                  other: ""
                });
              }
            }}
          />
          {value.other !== undefined && (
            <TextField
              label={otherOptionDescription}
              type={otherType || TextFieldType.Text}
              value={value.other}
              onChange={newVal => onChange({ ...value, other: newVal })}
            />
          )}
        </>
      )}
    </div>
  );
};
