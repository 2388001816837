import * as React from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { Dispatch } from "redux";

import { ChartPointData, moneyChartPointSelectAction } from "@state/home";
import { AppState } from "@state/store";
import { yourMoneyChartDataSelector, totalCapitalSelector, pensionRevisionDateSelector } from "@state/showMore";
import { isRetiredUserSelector } from "@state/user";

import { ShowMoreRoute } from "@common/routes";
import { ShowMoreType } from "@common/types";

import { MoneySlideForRetired } from "./MoneySlideForRetired";
import { MoneySlideChartData, MoneySlide } from "./MoneySlide";

interface GenericMoneySlideProps {
  yourMoneyData: MoneySlideChartData[];
  selectedPoint?: ChartPointData;
  selectedPointSize?: string;
  totalCapital: number | undefined;
  pensionRevisionDate: string | null;
  isRetired: boolean;

  onShowMore?(): any;
  onPointSelect(point: ChartPointData): any;
}

export const GenericMoneySlide = connect(
  (state: AppState) => ({
    yourMoneyData: yourMoneyChartDataSelector(state),
    selectedPoint: state.home.selectedPoint,
    totalCapital: totalCapitalSelector(state),
    pensionRevisionDate: pensionRevisionDateSelector(state),
    isRetired: isRetiredUserSelector(state)
  }),
  (dispatch: Dispatch) => ({
    onShowMore() {
      dispatch(push(ShowMoreRoute.format({ type: ShowMoreType.Money })));
    },
    onPointSelect(selectedPoint: ChartPointData) {
      dispatch(moneyChartPointSelectAction({ selectedPoint }));
    }
  })
)(
  ({
    yourMoneyData,
    selectedPoint,
    totalCapital,
    pensionRevisionDate,
    isRetired,
    onShowMore,
    onPointSelect
  }: GenericMoneySlideProps) => {
    return isRetired ? (
      <MoneySlideForRetired totalCapital={totalCapital} pensionRevisionDate={pensionRevisionDate} />
    ) : (
      <MoneySlide
        data={yourMoneyData}
        selectedPoint={selectedPoint}
        onPointSelect={onPointSelect}
        onShowMore={onShowMore}
      />
    );
  }
);
