import * as React from "react";

import { BlockerScreen, BlockerScreenType } from "@components/common";

export const Error404Page = () => (
  <BlockerScreen
    type={BlockerScreenType.Error}
    content="Sidan som du letar efter finns inte."
    btnText="Gå hem"
    onClick={() => (window.location.href = "/")}
  />
);
