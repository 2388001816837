import { Action } from "redux";

import { ErrorState, defaultErrorState } from "./state";
import { showErrorAction } from "./actions";

export function errorReducer(state: ErrorState = defaultErrorState, action: Action): ErrorState {
    if (showErrorAction.is(action)) {
        return {
            ...state,
            title: action.title,
            hideBtn: action.hideBtn,
            message: action.message
        };
    }

    return state;
}
