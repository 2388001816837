import * as React from "react";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { RouteComponentProps } from "react-router-dom";

import { BlockerScreen, BlockerScreenType } from "@components/common";
import { StandaloneInsuranceOffers } from "@common/types";

import { AppState } from "@state/store";
import {
  StandaloneLifeInsuranceRoute,
  StandaloneSafetyInsuranceRoute,
  StandaloneLifeInsuranceUpsellRoute,
  StandaloneSafetyInsuranceUpsellRoute
} from "@common/routes";

interface StandaloneOfferSkippedProps {
  offerCode: StandaloneInsuranceOffers;

  onGoToOffer(offerCode: StandaloneInsuranceOffers): any;
}

export const StandaloneOfferSkipped = connect(
  (state: AppState, ownProps: RouteComponentProps<{ type: StandaloneInsuranceOffers }>) => ({
    offerCode: ownProps.match.params.type
  }),
  (dispatch: Dispatch) => ({
    onGoToOffer(offerCode: StandaloneInsuranceOffers) {
      switch (offerCode) {
        case StandaloneInsuranceOffers.LifeInsurance:
          dispatch(push(StandaloneLifeInsuranceRoute.format({})));
          break;
        case StandaloneInsuranceOffers.SafetyInsurance:
          dispatch(push(StandaloneSafetyInsuranceRoute.format({})));
          break;
        case StandaloneInsuranceOffers.LifeInsuranceUpsell:
          dispatch(push(StandaloneLifeInsuranceUpsellRoute.format({})));
          break;
        case StandaloneInsuranceOffers.SafetyInsuranceUpsell:
          dispatch(push(StandaloneSafetyInsuranceUpsellRoute.format({})));
          break;
        default:
          break;
      }
    }
  })
)(({ offerCode, onGoToOffer }: StandaloneOfferSkippedProps) => {
  return (
    <BlockerScreen
      type={BlockerScreenType.NotInterested}
      title="Nej tack"
      content={<>Du har avböjt erbjudandet. Om du ångrar dig kan du klicka på knappen nedan eller kontakta oss.</>}
      btnText="Jag vill se erbjudandet igen"
      onClick={() => onGoToOffer(offerCode)}
    />
  );
});
