import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import throttle from "lodash-es/throttle";

// import { minpensionDataImages } from "@assets";
import {
  pensionInstituteOptionsSelector,
  updatePrivatPensionDataAction,
  privatPensionPlansSelector,
  privatPensionGroupedPlansSelector,
} from "@state/minpensionData";
import { PensionPlanData } from "@common/models";

import { InsuranceShellSelect, PensionPlanEdit } from "../pensionPlans";

import { InstructionsLayout } from "./InstructionsLayout";

interface PrivatPensionProps {
  onNext(): any;
  onBack(): any;
}

export const PrivatPension = ({ onNext, onBack }: PrivatPensionProps) => {
  const pensionInstituteOptions = useSelector(pensionInstituteOptionsSelector);

  const pensionPlans = useSelector(privatPensionPlansSelector);
  const groupedPP = useSelector(privatPensionGroupedPlansSelector);

  const dispatch = useDispatch();

  const addPensionPlan = (ppData: PensionPlanData) => {
    dispatch(
      updatePrivatPensionDataAction({
        data: {
          pensionPlans: [...pensionPlans, ppData],
        },
      })
    );
  };
  const updatePensionPlan = throttle((newData: PensionPlanData) => {
    const newArray: PensionPlanData[] = [];
    for (const plan of pensionPlans) {
      if (plan.insurance_number === newData.insurance_number && plan.insurance_shell === newData.insurance_shell) {
        newArray.push(newData);
      } else {
        newArray.push(plan);
      }
    }
    dispatch(
      updatePrivatPensionDataAction({
        data: {
          pensionPlans: newArray,
        },
      })
    );
  }, 500);
  const deletePensionPlan = (plan: PensionPlanData) => {
    dispatch(
      updatePrivatPensionDataAction({
        data: {
          pensionPlans: pensionPlans.filter(
            (pp) => plan.insurance_number !== pp.insurance_number || plan.insurance_shell !== pp.insurance_shell
          ),
        },
      })
    );
  };

  return (
    <InstructionsLayout
      contentClassName="LoginInstructions"
      nextBtnText="Jag är klar, ta mig till resultatet"
      onNext={onNext}
      onBack={onBack}
    >
      <h5 className="MinpensionDataInstructions__title">Information om din Privata pension</h5>
      <div className="MinpensionDataInstructions__text" style={{ marginBottom: "1.5rem" }}>
        Nu väljer du Privat pension istället.
        <br />
        Bocka i rutan "Se alla försäkringar i detalj"
        {/* <img
          className="MinpensionDataInstructions__pensionTypeImage"
          src={minpensionDataImages.privatPensionCheckbox}
          alt="Privat Pension Checkbox Image"
        /> */}
        Ange sedan informationen om din Privata pension nedan.
        <div className="MinpensionDataInstructions__pensionPlans">
          <div className="MinpensionDataInstructions__line" />
          {groupedPP.map(({ pension_institute, plans }) => (
            <div key={pension_institute}>
              <h3 className="MinpensionDataInstructions__title">{pension_institute}</h3>
              <div className="MinpensionDataInstructions__line" />
              {plans.map((plan) => (
                <div key={`${plan.insurance_number}_${plan.insurance_shell}`}>
                  <PensionPlanEdit plan={plan} onUpdate={updatePensionPlan} onDelete={() => deletePensionPlan(plan)} />
                  <div className="MinpensionDataInstructions__line" />
                </div>
              ))}
            </div>
          ))}
          <InsuranceShellSelect pensionInstitutes={pensionInstituteOptions} onAddPensionPlan={addPensionPlan} />
          <div className="MinpensionDataInstructions__line" />
        </div>
      </div>
    </InstructionsLayout>
  );
};
