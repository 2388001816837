import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { push } from "connected-react-router";

import { ShowMoreType } from "@common/types";
import { AppState } from "@state/store";
import { Layout, LayoutColor, Button } from "@components/common";
import { HomeRoute, ShowMoreRoute, MinpensionDataRoute } from "@common/routes";
import { MinpensionDataSteps } from "@common/models";

import { ShowMoreMeter } from "./meter";
import { ShowMoreFunds } from "./funds";
import { ShowMoreMoney } from "./money";
import { ShowMorePrognosis } from "./prognosis";

import "./ShowMore.scss";

interface ShowMoreOwnProps extends RouteComponentProps<{ type: ShowMoreType }> {}

interface ShowMoreProps {
  type: ShowMoreType;

  onStartScraping(type: ShowMoreType.Funds | ShowMoreType.Money): any;
  onGoBack(): any;
}

const typeToHeaderText = {
  [ShowMoreType.Meter]: "Mer om ditt betyg",
  [ShowMoreType.Funds]: "Dina Fonder",
  [ShowMoreType.Money]: "Dina Pengar",
  [ShowMoreType.Prognosis]: "Din Pensionsprognos",
};

const typeToColor = {
  [ShowMoreType.Meter]: "pink",
  [ShowMoreType.Funds]: "blue",
  [ShowMoreType.Money]: "green",
  [ShowMoreType.Prognosis]: "purple",
};

export const ShowMore = connect(
  (state: AppState, ownProps: ShowMoreOwnProps) => ({
    type: ownProps.match.params.type,
  }),
  (dispatch: Dispatch) => ({
    onGoBack() {
      dispatch(push(HomeRoute.format({})));
    },
    onStartScraping(type: ShowMoreType.Funds | ShowMoreType.Money) {
      dispatch(
        push(
          MinpensionDataRoute.format(
            { step: MinpensionDataSteps.Login },
            { backRedirect: ShowMoreRoute.format({ type }) }
          )
        )
      );
    },
  })
)(({ type, onGoBack, onStartScraping }: ShowMoreProps) => {
  return (
    <Layout title={typeToHeaderText[type]} color={typeToColor[type] as LayoutColor} onGoBack={onGoBack}>
      {type === ShowMoreType.Meter && <ShowMoreMeter />}
      {type === ShowMoreType.Funds && <ShowMoreFunds />}
      {type === ShowMoreType.Money && <ShowMoreMoney />}
      {type === ShowMoreType.Prognosis && <ShowMorePrognosis />}
      {/*button to make new tryggakollen*/}
      {(type === ShowMoreType.Funds || type === ShowMoreType.Money) && (
        <Button className="ShowMore__newAnalysis" text="Uppdatera nu" onClick={() => onStartScraping(type)} />
      )}
    </Layout>
  );
});
