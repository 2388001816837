import { createPath } from "../lib/rd-url-utils";
import { MessageCodes, MinpensionDataSteps } from "./models";
import { MeterType, ShowMoreType, StandaloneInsuranceOffers } from "./types";

export const HomeRoute = createPath("/");
export const LoginRoute = createPath("/login");
export const IntroRoute = createPath<{ step: number }>("/intro/:step");

export const FreeTodoItemRoute = createPath<{ type: MeterType }>("/free-todo-item/:type");
export const TodoItemBenefitsRoute = createPath("/todo-item-benefits");
export const TodoItemBenefitsDetailsRoute = createPath("/todo-item-benefits/details");
export const ChangeFundsRoute = createPath("/change-funds");

export const ChangeSavingsRoute = createPath("/start-savings");
export const InsuranceApplicationRoute = createPath("/insurance-application");
export const UpdateProfileBenefitsRoute = createPath("/update-profile-benefits");
export const LifeInsuranceRoute = createPath("/life-insurance");
export const SafetyInsuranceRoute = createPath("/safety-insurance");
export const LifeInsuranceUpsellRoute = createPath("/life-insurance-upsell");
export const SafetyInsuranceUpsellRoute = createPath("/safety-insurance-upsell");

export const StandaloneOfferRoutePrefix = "insurance-only";
// next 4 routes actually are unwinded route createPath<{ type: StandaloneInsuranceOffers }>("/insurance-only/:type/")
// so before change them keep in mind that :type path segment is used in components
export const StandaloneSafetyInsuranceRoute = createPath(
  `/${StandaloneOfferRoutePrefix}/${StandaloneInsuranceOffers.SafetyInsurance}`
);
export const StandaloneLifeInsuranceRoute = createPath(
  `/${StandaloneOfferRoutePrefix}/${StandaloneInsuranceOffers.LifeInsurance}`
);
export const StandaloneSafetyInsuranceUpsellRoute = createPath(
  `/${StandaloneOfferRoutePrefix}/${StandaloneInsuranceOffers.SafetyInsuranceUpsell}`
);
export const StandaloneLifeInsuranceUpsellRoute = createPath(
  `/${StandaloneOfferRoutePrefix}/${StandaloneInsuranceOffers.LifeInsuranceUpsell}`
);
export const StandaloneOfferSkippedRoute = createPath<{ type: StandaloneInsuranceOffers }>(
  `/${StandaloneOfferRoutePrefix}/:type/skipped`
);
export const StandaloneInsuranceSuccessRoute = createPath<{ type: StandaloneInsuranceOffers }>(
  `/${StandaloneOfferRoutePrefix}/:type/success`
);
export const StandaloneOfferCompletedRoute = createPath<{ type: StandaloneInsuranceOffers }>(
  `/${StandaloneOfferRoutePrefix}/:type/completed`
);

export const TodoItemSuccessRoute = createPath("/todo-item-success");

// export const ScrapingRoute = createPath<{}, { backRedirect?: string }>("/minpension-login");
export const MinpensionDataRoute = createPath<{ step: MinpensionDataSteps }, { backRedirect?: string }>(
  "/minpension-data/:step"
);
export const MinpensionDataHelpRoute = createPath<{ step: MinpensionDataSteps }, { backRedirect?: string }>(
  "/minpension-data/:step/help"
);
export const MinpensionDataProcessingRoute = createPath("/minpension-data-processing");

export const PPMConfirmationRoute = createPath("/ppm-confirmation");
export const SummaryRoute = createPath("/summary");

export const ShowMoreRoute = createPath<{ type: ShowMoreType }>("/show-more/:type");

export const ErrorWithMessageRoute = createPath<{}, { message: string }>("/error");
export const SupportRoute = createPath("/support");
export const SupportFAQRoute = createPath("/support/faq");
export const SupportTermsRoute = createPath("/support/terms");

export const SettingsRoute = createPath("/settings");
export const SettingsRiskLevelRoute = createPath("/settings/risk-level");
export const SettingsPensionProfileRoute = createPath("/settings/pensionprofile");

export const NotificationsRoute = createPath("/notifications");
export const NotificationDetailsRoute = createPath<{ code: MessageCodes }>("/notifications/:code");

export const LevelUpRoute = createPath("/level-up");

export const PensionProfileUpdateRoute = createPath<{ section?: string }, { initialUrl?: string }>(
  "/pensionprofile-update/:section?"
);

export const isPremiumRoute = (url: string) => {
  if (
    TodoItemBenefitsRoute.match(url).isMatched ||
    ChangeFundsRoute.match(url).isMatched ||
    ChangeSavingsRoute.match(url).isMatched
  ) {
    return true;
  }
  return false;
};

export const isInsuranceOnlyRoute = (url: string) => {
  if (
    StandaloneSafetyInsuranceRoute.match(url).isMatched ||
    StandaloneLifeInsuranceRoute.match(url).isMatched ||
    StandaloneSafetyInsuranceUpsellRoute.match(url).isMatched ||
    StandaloneLifeInsuranceUpsellRoute.match(url).isMatched ||
    StandaloneInsuranceSuccessRoute.match(url).isMatched ||
    StandaloneOfferSkippedRoute.match(url).isMatched ||
    StandaloneOfferCompletedRoute.match(url).isMatched
  ) {
    return true;
  }
  return false;
};
