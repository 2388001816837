import * as React from "react";

import { MeterStar } from "./meterStar";

import "./StarsBar.scss";

interface StarsBarProps {
  progress: number; // from 0 to 5
  fillColor: string;
  backgroundColor: string;
}

export const StarsBar = ({ progress, ...rest }: StarsBarProps) => {
  return (
    <div className="StarsBar">
      {(Array.apply(null, Array(5)) as number[]).map((_, index) => {
        const diff = progress - index;
        return <MeterStar key={`meter_star_${index}`} progress={diff >= 1 ? 1 : diff >= 0 ? diff : 0} {...rest} />;
      })}
    </div>
  );
};
