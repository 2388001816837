import { Action } from "redux";

import { postUserDataRequest } from "@common/requests";

import { SettingsState, defaultSettingsState } from "./state";

export function settingsReducer(state: SettingsState = defaultSettingsState, action: Action): SettingsState {
  if (postUserDataRequest.actions.isRunning(action)) {
    return {
      ...state,
      isFetching: true
    };
  }

  if (postUserDataRequest.actions.isOk(action)) {
    return {
      ...state,
      isFetching: false
    };
  }

  if (postUserDataRequest.actions.isError(action)) {
    return {
      ...state,
      isFetching: false,
      error: action.errorType
    };
  }

  return state;
}
