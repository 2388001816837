import * as React from "react";

import { MinpensionGrades } from "@common/models";

import "./PensionPrognosisScore.scss";

interface PensionPrognosisScoreProps {
  grade: MinpensionGrades;
}

const transformScore = (score: MinpensionGrades): number => {
  switch (score) {
    case MinpensionGrades.Poor:
      return 0;
    case MinpensionGrades.Low:
      return 1;
    case MinpensionGrades.Ok:
      return 2;
    default:
      return 0;
  }
};

const labels = ["Lågt", "Ok", "Bra"];

export const PensionPrognosisScore = ({ grade }: PensionPrognosisScoreProps) => {
  const transformedScore = transformScore(grade);
  return (
    <div className="PensionPrognosisScore">
      {(Array.apply(null, Array(3)) as number[]).map((_, index) => {
        const width = transformedScore >= index ? "100%" : 0;
        return (
          <div key={index} className="PensionPrognosisScore__track">
            <div className="PensionPrognosisScore__fill" style={{ width }} />
            {transformedScore === index && (
              <div className="PensionPrognosisScore__popup">
                <div className="PensionPrognosisScore__popupArrow" />
                <div className="PensionPrognosisScore__popupContent">{labels[transformedScore]}</div>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};
