import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";

import { AppState } from "@state/store";
import {
  FundPerformanceSummary,
  fundPerformanceSummarySelector,
  pensionRevisionDateSelector,
  IdentifiedFundData,
  UnidentifiedFundData,
  showMoreFundsSelector
} from "@state/showMore";
import { InAppMessage, MessageCodes } from "@common/models";

import { FundsItem } from "./item";

import "./ShowMoreFunds.scss";
import { fundPerformanceMessageSelector, notificationMessageCompleteAction } from "@state/notifications";

interface ShowMoreFundsProps {
  summary: FundPerformanceSummary;
  data: ShowMoreFundsData;
  pensionRevisionDate: string | null;
  fundPerformanceMsg: InAppMessage | undefined;

  onSetMessageToCompleted(): any;
}

interface ShowMoreFundsData {
  funds: IdentifiedFundData[];
  unidentifiedFunds: UnidentifiedFundData[];
}

class ShowMoreFundsComponent extends React.Component<ShowMoreFundsProps, {}> {
  componentDidMount() {
    const { fundPerformanceMsg, onSetMessageToCompleted } = this.props;
    if (fundPerformanceMsg && !fundPerformanceMsg.completed) {
      onSetMessageToCompleted();
    }
  }

  componentDidUpdate(prev: ShowMoreFundsProps) {
    const { fundPerformanceMsg, onSetMessageToCompleted } = this.props;
    if (
      fundPerformanceMsg &&
      !fundPerformanceMsg.completed &&
      (!prev.fundPerformanceMsg || prev.fundPerformanceMsg.completed)
    ) {
      onSetMessageToCompleted();
    }
  }

  render() {
    const {
      summary,
      data: { funds, unidentifiedFunds },
      pensionRevisionDate
    } = this.props;
    return (
      <div className="ShowMoreFunds">
        <FundsItem
          valuation={summary.valuation}
          meanReturn={summary.avgMeanReturn}
          monthChange={summary.valuationChange}
          ytdChange={summary.ytdValuationChange}
          ytdReturn={summary.ytdAvgMeanReturn}
          monthName={summary.monthName}
          year={summary.year}
          isTotal
        />

        <div className="ShowMoreFunds__info">
          <h3 className="ShowMoreFunds__infoTitle">Så har dina fonder gått</h3>
          <p className="ShowMoreFunds__infoDescription"> Det är spännande att följa sina pensionspengar.</p>
        </div>

        {funds.map((fund, index) => (
          <FundsItem
            key={`identified_fund_${index}`}
            title={fund.name}
            meanReturn={fund.meanReturn}
            monthChange={fund.monthlyChange}
            ytdReturn={fund.ytdMeanReturn}
            ytdChange={fund.ytdChange}
            monthName={summary.monthName}
            year={summary.year}
            valuation={fund.totalValuation}
          />
        ))}
        {unidentifiedFunds.map((fund, index) => (
          <FundsItem
            key={`unidentified_fund_${index}`}
            title={fund.name}
            monthName={summary.monthName}
            year={summary.year}
            valuation={fund.totalValuation}
          />
        ))}
        <p className="ShowMoreFunds__revisionDate">
          *Information om ditt kapital hämtades {pensionRevisionDate || "N/A"}. Siffrorna för förändringar per månad är
          uppskattade utifrån det och kan avvika från det faktiska.
        </p>
      </div>
    );
  }
}

export const ShowMoreFunds = connect(
  (state: AppState) => ({
    summary: fundPerformanceSummarySelector(state),
    data: showMoreFundsSelector(state),
    pensionRevisionDate: pensionRevisionDateSelector(state),
    fundPerformanceMsg: fundPerformanceMessageSelector(state)
  }),
  (dispatch: Dispatch) => ({
    onSetMessageToCompleted() {
      dispatch(notificationMessageCompleteAction({ code: MessageCodes.FundPerformance }));
    }
  })
)(ShowMoreFundsComponent);
