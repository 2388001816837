import { Action } from "redux";

import { skipTodoItemRequest } from "@common/requests";

import { TodoItemState, defaultTodoItemState } from "./state";
import { setTodoItemAction, closeTodoItemAction, markItemAsCompleted, clearCompletedItemAction } from "./actions";

export function todoItemReducer(state: TodoItemState = defaultTodoItemState, action: Action): TodoItemState {
  if (setTodoItemAction.is(action)) {
    return {
      ...state,
      selectedItemId: action.item.id
    };
  }

  if (closeTodoItemAction.is(action)) {
    return {
      ...state,
      selectedItemId: undefined
    };
  }

  if (markItemAsCompleted.is(action)) {
    return {
      ...state,
      selectedItemId: undefined,
      completedItemId: state.selectedItemId
    };
  }

  if (clearCompletedItemAction.is(action)) {
    return {
      ...state,
      completedItemId: undefined
    };
  }

  if (skipTodoItemRequest.actions.isRunning(action)) {
    return { ...state, isSkippingInProgress: true };
  }

  if (skipTodoItemRequest.actions.isOk(action) || skipTodoItemRequest.actions.isError(action)) {
    return { ...state, isSkippingInProgress: false };
  }

  return state;
}
