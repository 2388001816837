import { AutogiroForm } from "@common/models";
import { StandaloneInsuranceOffers } from "@common/types";

import { defineAction } from "../../lib/rd-action-creators";

export const insuranceUserClickedYesAction = defineAction("INSURANCE USER CLICKED YES");
export const insuranceUserClickedNoAction = defineAction("INSURANCE USER CLICKED NO");
export const getAxaProductsDescriptionAction = defineAction("INSURANCE GET AXA PRODUCT DESCRIPTION");
export const getActiveInsurancesAction = defineAction("INSURANCE GET ACTIVE INSURANCES");
export const createInsuranceAction = defineAction<{
  productCode: string;
  coverAmount: number;
  price: number;
  form: AutogiroForm;
  isStandaloneFlow: boolean;
}>("CREATE INSURANCE");
export const upsellInsuranceAction = defineAction<{
  productCode: string;
  coverAmount: number;
  price: number;
  isStandaloneFlow: boolean;
}>("UPSELL INSURANCE");
export const skipStandaloneOfferAction = defineAction<{
  offer: StandaloneInsuranceOffers;
}>("SKIP STANDALONE INSURANCE OFFER");
