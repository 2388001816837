import * as React from "react";
import { FieldProps } from "formik";
import { Checkbox, CheckboxProps } from "../checkbox";
import { TextFieldProps, TextField } from "../textField";
import { SelectProps, Select, OptionType } from "../select";
import { MultiSelectProps, MultiSelect } from "../multiselect";

import "./adapters.scss";

interface FormatterProps {
  hideError?: boolean;
  wrongValueText?: string;

  isWrongValue?(value?: any): boolean;
  valueToString?(value: any): string;
  stringToValue?(value: string): any;
}

type PropsWithFieldAndFormatters<P> = P & FieldProps & FormatterProps;

export const FormikTextField: React.SFC<PropsWithFieldAndFormatters<TextFieldProps>> = ({
  field,
  form,
  valueToString,
  stringToValue,
  hideError,
  ...textFieldProps
}) => {
  return (
    <>
      <TextField
        {...textFieldProps}
        name={field.name}
        value={valueToString ? valueToString(field.value) : field.value}
        onChange={(v: string) => form.setFieldValue(field.name, stringToValue ? stringToValue(v) : v)}
        onBlur={field.onBlur}
      />
      {!hideError && form.errors[field.name] && form.touched[field.name] && (
        <p className="FormikField__error">{form.errors[field.name]}</p>
      )}
    </>
  );
};

export const FormikSelect: React.SFC<PropsWithFieldAndFormatters<SelectProps>> = ({
  field,
  form,
  valueToString,
  stringToValue,
  hideError,
  isWrongValue,
  wrongValueText,
  ...selectProps
}) => {
  return (
    <>
      <Select
        {...selectProps}
        value={valueToString ? valueToString(field.value) : field.value}
        onChange={(v: OptionType) => form.setFieldValue(field.name, stringToValue ? stringToValue(v.value) : v.value)}
        onBlur={field.onBlur}
      />
      {!hideError && form.errors[field.name] && form.touched[field.name] && (
        <p className="FormikField__error">{form.errors[field.name]}</p>
      )}
      {isWrongValue && isWrongValue(field.value) && wrongValueText && (
        <p className="FormikField__error">{wrongValueText}</p>
      )}
    </>
  );
};

export const FormikMultiSelect: React.SFC<PropsWithFieldAndFormatters<MultiSelectProps>> = ({
  field,
  form,
  hideError,
  isWrongValue,
  wrongValueText,
  ...multiSelectProps
}) => {
  return (
    <>
      <MultiSelect {...multiSelectProps} value={field.value} onChange={v => form.setFieldValue(field.name, v)} />
      {!hideError && form.errors[field.name] && form.touched[field.name] && (
        <p className="FormikField__error">{form.errors[field.name]}</p>
      )}
      {isWrongValue && isWrongValue(field.value) && wrongValueText && (
        <p className="FormikField__error">{wrongValueText}</p>
      )}
    </>
  );
};

export const FormikCheckbox: React.SFC<PropsWithFieldAndFormatters<CheckboxProps>> = ({
  field,
  form,
  valueToString,
  stringToValue,
  hideError,
  ...checkboxProps
}) => {
  return (
    <>
      <Checkbox {...checkboxProps} value={!!field.value} onClick={() => form.setFieldValue(field.name, !field.value)} />
      {!hideError && form.errors[field.name] && form.touched[field.name] && (
        <p className="FormikField__error">{form.errors[field.name]}</p>
      )}
    </>
  );
};
