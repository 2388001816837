import * as React from "react";
import * as cn from "classnames";

import { ChevronBold } from "../ChevronBold";

import "./Collapse.scss";
import { RippleEffect } from "../rippleEffect";

interface CollapseProps {
  title: string;
  children: React.ReactNode;
  inline?: boolean;
}

interface CollapseState {
  isOpened: boolean;
  contentHeight: number;
}

export class Collapse extends React.Component<CollapseProps, CollapseState> {
  content: HTMLDivElement | null;

  readonly state = {
    isOpened: false,
    contentHeight: 0
  };

  toggleOpen = () => {
    this.setState(prev => ({
      isOpened: !prev.isOpened,
      contentHeight: this.content && !prev.isOpened ? this.content.clientHeight : 0
    }));
  };

  render() {
    const { title, children, inline } = this.props;

    const { isOpened, contentHeight } = this.state;
    return (
      <div
        className={cn("Collapse", { "Collapse--opened": isOpened, "Collapse--inline": inline })}
        onClick={inline ? undefined : this.toggleOpen}
      >
        {inline ? (
          <RippleEffect className="Collapse__panel" actionFirst onClick={this.toggleOpen} rounded>
            <p className="Collapse__header">{title}</p>
            <ChevronBold
              width="0.8rem"
              height="1.2rem"
              className="Collapse__chevron"
              direction={isOpened ? "up" : "down"}
              color="#12121c"
            />
          </RippleEffect>
        ) : (
          <div className="Collapse__panel">
            <p className="Collapse__header">{title}</p>
            <ChevronBold
              width="0.8rem"
              height="1.2rem"
              className="Collapse__chevron"
              direction={isOpened ? "up" : "down"}
              color="#12121c"
            />
          </div>
        )}

        <div className="Collapse__contentWrapper" style={{ height: contentHeight }}>
          <div className="Collapse__content" ref={ref => (this.content = ref)}>
            {children}
          </div>
        </div>
      </div>
    );
  }
}
