import * as React from "react";
import * as cn from "classnames";

import { RippleEffect } from "../rippleEffect";

import "./Button.scss";

interface ButtonProps {
  text: string;
  logo?: string;
  disabled?: boolean;
  fakeDisabled?: boolean;
  primary?: boolean;
  inverse?: boolean;
  actionFirst?: boolean;
  className?: string;

  onClick?(): any;
}

export const Button = ({
  text,
  logo,
  disabled,
  fakeDisabled,
  primary,
  inverse,
  actionFirst,
  onClick,
  className
}: ButtonProps) => {
  return (
    <RippleEffect
      className={cn("Button", className, {
        "Button--withLogo": !!logo,
        "Button--disabled": disabled || fakeDisabled,
        "Button--primary": primary,
        "Button--inverse": inverse
      })}
      disabled={disabled}
      inverse={!(primary || inverse)}
      onClick={onClick}
      actionFirst={actionFirst}
    >
      <p className="Button__text">{text}</p>
      {!!logo && <img className="Button__logo" src={logo} />}
    </RippleEffect>
  );
};
