import * as React from "react";
import Tooltip from "rc-tooltip";
import Slider, { Handle } from "rc-slider";
import * as cn from "classnames";

import { getConfidenceInterval } from "@common/calculations/confidence-interval";
import { PensaModal, RippleEffect } from "@components/common";
import { icons } from "@assets";
import { formatCapital } from "@common/helpers";

import "rc-slider/assets/index.css";
import "rc-tooltip/assets/bootstrap.css";

import "./ChangeSavingsSlider.scss";

const getTooltipContainer = () => document.querySelector(".ChangeSavingsSlider")!;

const getPlacement = (min: number, max: number, value: number) => {
  const threshhold = (max - min) * 0.1;
  const isLeftPlaced = threshhold > value - min;
  const isRightPlaced = threshhold > max - value;
  if (isLeftPlaced) {
    return "topLeft";
  }
  if (isRightPlaced) {
    return "topRight";
  }
  return "top";
};

const getAlignFromPlacement = (placement: string) => {
  switch (placement) {
    case "topLeft":
      return {
        offset: [-5, -2]
      };
    case "topRight":
      return {
        offset: [5, -2]
      };
    default:
      return {
        offset: [0, -2]
      };
  }
};

const handle = (props: any) => {
  const { value, dragging, min, max, index, ...restProps } = props;

  const placement = getPlacement(min, max, value);
  return (
    <Tooltip
      overlay={`${value.toLocaleString("sv")}kr`}
      getTooltipContainer={getTooltipContainer}
      visible
      arrowContent={<div className="rc-tooltip-arrow-inner" />}
      placement={placement}
      align={getAlignFromPlacement(placement)}
      key={index}
    >
      <Handle value={value} min={min} max={max} {...restProps} />
    </Tooltip>
  );
};

interface ChangeSavingsSliderProps {
  minDeposit: number;
  maxDeposit: number;
  selectedDeposit: number;
  suggestedDeposit: number;
  capitalPer100kr: number;
  yearsUntilRetirement: number | undefined;
  retirementAge: number | undefined;

  onDepositChange(val: number): any;
}

interface ChangeSavingsSliderState {
  isModalOpen: boolean;
}

export class ChangeSavingsSlider extends React.Component<ChangeSavingsSliderProps, ChangeSavingsSliderState> {
  constructor(props: ChangeSavingsSliderProps) {
    super(props);
    this.state = {
      isModalOpen: false
    };
  }

  toggleModal = () => {
    this.setState(prev => ({ isModalOpen: !prev.isModalOpen }));
  };

  render() {
    const {
      minDeposit,
      maxDeposit,
      selectedDeposit,
      suggestedDeposit,
      capitalPer100kr,
      retirementAge,
      yearsUntilRetirement,
      onDepositChange
    } = this.props;
    const marks = {};
    marks[suggestedDeposit] = "Rekommenderat!";
    const expectedCapital = selectedDeposit * capitalPer100kr;
    const confidenceInterval = getConfidenceInterval(expectedCapital, yearsUntilRetirement || 0);

    return (
      <section
        className={cn("ChangeSavingsSlider", {
          "ChangeSavingsSlider--recommended": selectedDeposit === suggestedDeposit
        })}
      >
        <p className="ChangeSavingsSlider__title">Hur mycket vill du spara i månaden?</p>
        <div className="ChangeSavingsSlider__slider">
          <Slider
            min={minDeposit}
            max={maxDeposit}
            step={100}
            defaultValue={selectedDeposit}
            handle={handle}
            marks={marks}
            onChange={onDepositChange}
          />
        </div>
        <div className="ChangeSavingsSlider__pensionPrognos">
          <RippleEffect className="ChangeSavingsSlider__pensionPrognosInfo" onClick={this.toggleModal} circled>
            <img src={icons.help} alt="" />
          </RippleEffect>
          <div className="ChangeSavingsSlider__pensionPrognosValue">Få {formatCapital(expectedCapital)} kr</div>
          <p className="ChangeSavingsSlider__pensionPrognosText">mer i pension!</p>
        </div>
        <PensaModal
          header=""
          isOpened={this.state.isModalOpen}
          content={
            <React.Fragment>
              Med 95% sannolikhet kommer värdet vara någonstans mellan{" "}
              <strong>{formatCapital(confidenceInterval.min)} kr</strong> och{" "}
              <strong>{formatCapital(confidenceInterval.max)} kr</strong> när du är {retirementAge}, med ett förväntat
              värde på <strong>{formatCapital(expectedCapital)} kr</strong>. Beräknad genomsnittlig avkastning är 6% per
              år.
            </React.Fragment>
          }
          onClose={this.toggleModal}
        />
      </section>
    );
  }
}
