import * as React from "react";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { push } from "connected-react-router";

import { Layout, InfoItem, Button, LoadingOverlay, RippleEffect, PensaModal } from "@components/common";
import { AppState } from "@state/store";
import { SettingsRoute } from "@common/routes";
import { RiskLevels } from "@common/types";
import { requestedRiskLevelSelector, yearsUntilRetirementSelector } from "@state/user";
import { changeRequestedRiskLevelAction } from "@state/settings";

import { RiskLevelBadge } from "./riskLevelBadge";

import "./SettingsRiskLevel.scss";
import { riskLevelTranslations, riskLevelToKiidRisk } from "@common/helpers";

interface SettingsRiskLevelProps {
  isFetching: boolean;
  riskWillingness: boolean;
  requestedRiskLevel: RiskLevels;
  recommendedRiskLevel: RiskLevels;
  yearsUntilRetirement: number;

  onGoBack(): any;
  onChangeRiskLevel(riskLevel: RiskLevels): any;
}

export const SettingsRiskLevel = connect(
  (state: AppState) => ({
    isFetching: state.settings.isFetching,
    requestedRiskLevel: requestedRiskLevelSelector(state),
    recommendedRiskLevel: state.user.data && state.user.data.recommended_5step_risk_level,
    yearsUntilRetirement: yearsUntilRetirementSelector(state),
    riskWillingness: !!state.user.data?.risk_profile.risk_willingness,
  }),
  (dispatch: Dispatch) => ({
    onGoBack() {
      dispatch(push(SettingsRoute.format({})));
    },
    onChangeRiskLevel(riskLevel: RiskLevels) {
      dispatch(changeRequestedRiskLevelAction({ riskLevel }));
    },
  })
)(
  ({
    isFetching,
    riskWillingness,
    requestedRiskLevel,
    yearsUntilRetirement,
    recommendedRiskLevel,
    onGoBack,
    onChangeRiskLevel,
  }: SettingsRiskLevelProps) => {
    const [riskLevel, setRiskLevel] = useState(requestedRiskLevel);
    const [showRiskLevelExplanation, setShowRiskLevelExplanation] = useState(false);

    useEffect(() => {
      if (requestedRiskLevel && !riskLevel) {
        setRiskLevel(requestedRiskLevel);
      }
    }, [requestedRiskLevel]);

    return (
      <React.Fragment>
        <Layout title="Din Risknivå" onGoBack={onGoBack} contentClassName="SettingsRiskLevel">
          <InfoItem
            text={
              <>
                Din risknivå används för att välja vilka fonder och investeringar som passar dig.{" "}
                <RippleEffect
                  className="SettingsRiskLevel__inlineBtn"
                  rounded
                  onClick={() => setShowRiskLevelExplanation(!showRiskLevelExplanation)}
                >
                  <span>Läs mer.</span>
                </RippleEffect>
              </>
            }
          />
          <div className="SettingsRiskLevel__info">
            <h4 className="SettingsRiskLevel__header">Risknivåer</h4>
            <div className="SettingsRiskLevel__levels">
              <RiskLevelBadge
                level={RiskLevels.High}
                isSelected={riskLevel === RiskLevels.High}
                isRecommended={recommendedRiskLevel === RiskLevels.High}
                onClick={() => setRiskLevel(RiskLevels.High)}
              />
              <RiskLevelBadge
                level={RiskLevels.Medium}
                isSelected={riskLevel === RiskLevels.Medium}
                isRecommended={recommendedRiskLevel === RiskLevels.Medium}
                onClick={() => setRiskLevel(RiskLevels.Medium)}
              />
              <RiskLevelBadge
                level={RiskLevels.Low}
                isSelected={riskLevel === RiskLevels.Low}
                isRecommended={recommendedRiskLevel === RiskLevels.Low}
                onClick={() => setRiskLevel(RiskLevels.Low)}
              />
            </div>
            <p className="SettingsRiskLevel__text">Vill du ändra din risknivå?</p>
            <Button
              text="Spara"
              disabled={riskLevel === requestedRiskLevel}
              primary
              onClick={() => onChangeRiskLevel(riskLevel)}
            />
          </div>
        </Layout>
        <LoadingOverlay isShown={isFetching} />
        <PensaModal
          header="Din risknivå"
          content={
            <RiskLevelExplanation
              riskLevel={recommendedRiskLevel}
              yearsUntilRetirement={yearsUntilRetirement}
              riskWillingness={riskWillingness}
            />
          }
          isOpened={showRiskLevelExplanation}
          onClose={() => setShowRiskLevelExplanation(false)}
          closeOnOutsideClick
        />
      </React.Fragment>
    );
  }
);

interface RiskLevelExplanationProps {
  riskLevel: RiskLevels;
  yearsUntilRetirement: number;
  riskWillingness: boolean;
}

export const RiskLevelExplanation = ({
  riskLevel,
  yearsUntilRetirement,
  riskWillingness,
}: RiskLevelExplanationProps) => {
  let recommendationExplanation;

  if (yearsUntilRetirement <= 2) {
    recommendationExplanation = (
      <>
        Din rekommenderade risk har du fått baserat på att du redan har gått i pension eller har mindre än 2 år kvar
        till din pension. Då anser vi att du ska ha en <strong>Låg</strong> risk i dina investeringar.
      </>
    );
  } else if (yearsUntilRetirement <= 5) {
    recommendationExplanation = riskWillingness ? (
      <>
        Din rekommenderade risk har du fått baserat på att du har mindre än 5 år kvar till din pensionsålder samtidigt
        som du angett att du är riskvillig. Då anser vi att du ska ha en <strong>Mellan</strong>-risk i dina
        investeringar.
      </>
    ) : (
      <>
        Din rekommenderade risk har du fått baserat på att du har mindre än 5 år kvar till din pensionsålder samtidigt
        som du angett att du inte är så riskvillig. Då anser vi att du ska ha en <strong>Låg</strong> risk i dina
        investeringar.
      </>
    );
  } else if (yearsUntilRetirement <= 15) {
    recommendationExplanation = riskWillingness ? (
      <>
        Din rekommenderade risk har du fått baserat på att du har mindre än 15 år kvar till din pensionsålder samtidigt
        som du angett att du är riskvillig. Då anser vi att du ska ha en <strong>Hög</strong> risk i dina investeringar.
      </>
    ) : (
      <>
        Din rekommenderade risk har du fått baserat på att du har mindre än 15 år kvar till din pensionsålder samtidigt
        som du angett att du inte är så riskvillig. Då anser vi att du ska ha en <strong>Mellan</strong>-risk i dina
        investeringar.
      </>
    );
  } else {
    recommendationExplanation = (
      <>
        Din rekommenderade risk har du fått baserat på att du har mer än 15 år kvar till din beräknade pensionsålder. Då
        anser vi att du ska ha en <strong>Hög</strong> risk i dina investeringar för att öka chansen till högre
        avkastning.
      </>
    );
  }

  return (
    <div>
      Du har fått <strong>{riskLevelTranslations[riskLevel] || riskLevel}</strong> som rekommenderad risknivå för dina
      pensionsinvesteringar. Det motsvarar <strong>{riskLevelToKiidRisk[riskLevel] || riskLevel}</strong> på den
      sjugradiga europeiska KIID-riskskalan.
      <br />
      <br />
      {recommendationExplanation}
      <br />
      <br />
      Den angivna risknivån är den totala risknivån vi siktar på att du ska ha. För att uppnå den kan enskilda fonder vi
      rekommenderar ha en högre eller lägre risk än din angivna. Det är för att din genomsnittliga risk ska bli så nära
      som möjligt din rekommenderade risknivå.
    </div>
  );
};
