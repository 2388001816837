import * as React from "react";

import { Layout, LoadingOverlay, Button, RippleEffect } from "@components/common";
import { AxaInsuranceOptions } from "@common/models";

import { SafetyInsuranceBenefitsDescription } from "./SafetyInsuranceBenefits";

import "./SafetyInsuranceBenefits.scss";

interface StandaloneSafetyInsuranceBenefitsProps {
  alternatives: AxaInsuranceOptions | undefined;
  isUpsell: boolean;
  isSkipping: boolean;

  onNext(selectedAmount: number): any;
  onSkip(): any;
}

export const StandaloneSafetyInsuranceBenefits = ({
  alternatives,
  isUpsell,
  isSkipping,
  onNext,
  onSkip,
}: StandaloneSafetyInsuranceBenefitsProps) => {
  return (
    <Layout title="Fördelarna för dig" contentClassName="SafetyInsuranceBenefits">
      <div className="SafetyInsuranceBenefits__card">
        <SafetyInsuranceBenefitsDescription />
      </div>
      <div className="SafetyInsuranceBenefits__alternatives">
        {alternatives &&
          alternatives.coverAmounts.map((amount) => (
            <Button
              key={`life_insurance_${amount}`}
              text={`Ja, försäkra ${amount.toLocaleString("sv")} kr/mån (${alternatives.grossMonthlyPremium[
                amount
              ].toLocaleString("sv")} kr/mån)`}
              onClick={() => onNext(amount)}
            />
          ))}
      </div>

      <RippleEffect className="SafetyInsuranceBenefits__skip" rounded onClick={onSkip}>
        {isUpsell ? "Nej tack, jag vill inte ändra nivån" : "Nej tack, jag vill inte ha denna försäkring"}
      </RippleEffect>

      <LoadingOverlay isShown={!alternatives || isSkipping} />
    </Layout>
  );
};
