import { createStore, combineReducers, compose, applyMiddleware, Store } from "redux";
import { routerMiddleware } from "connected-react-router";
import createSagaMiddleware from "redux-saga";
import { all } from "redux-saga/effects";
import { History } from "history";

import { customRouterSaga, customRouterReducer, CustomRouterState } from "./customRouter";
import { authReducer, AuthState, authSaga } from "./auth";
import { userReducer, userSaga, UserState } from "./user";
import { errorSaga, ErrorState, errorReducer } from "./error";
import { scrapingReducer, scrapingSaga, ScrapingState } from "./scraping";
import { homeReducer, HomeState } from "./home";
import { changeFundsReducer, changeFundsSaga, ChangeFundsState } from "./changeFunds";
import { todoItemReducer, todoItemSaga, TodoItemState } from "./todoItem";
import { changeSavingsReducer, changeSavingsSaga, ChangeSavingsState } from "./changeSavings";
import { faqReducer, FAQState } from "./faq";
import { SettingsState, settingsReducer, settingsSaga } from "./settings";
import { NotificationsState, notificationsReducer, notificationsSaga } from "./notifications";
import { insuranceSaga, InsuranceState, insuranceReducer } from "./insurance";
import { ppmConfirmationReducer, ppmConfirmationSaga, PPMConfirmationState } from "./ppmConfirmation";
import { analyticsLoggerMiddleware } from "./analytics";
import { RequestsState, requestsReducer } from "./requests";
import { MinpensionDataState, minpensionDataReducer, minpensionDataSaga } from "./minpensionData";

const composeEnhancers = window["__REDUX_DEVTOOLS_EXTENSION_COMPOSE__"] || compose;
const sagaMiddleware = createSagaMiddleware();

export interface AppState {
  router: CustomRouterState;
  auth: AuthState;
  user: UserState;
  scraping: ScrapingState;
  home: HomeState;
  changeFunds: ChangeFundsState;
  changeSavings: ChangeSavingsState;
  todoItem: TodoItemState;
  faq: FAQState;
  settings: SettingsState;
  notifications: NotificationsState;
  insurance: InsuranceState;
  ppmConfirmation: PPMConfirmationState;
  requests: RequestsState;
  minpensionData: MinpensionDataState;
  error: ErrorState;
}

let _store: Store<AppState>;

export const createStoreWithHistory = (history: History) => {
  _store = createStore(
    combineReducers<AppState>({
      router: customRouterReducer(history),
      auth: authReducer,
      user: userReducer,
      scraping: scrapingReducer,
      home: homeReducer,
      changeFunds: changeFundsReducer,
      changeSavings: changeSavingsReducer,
      todoItem: todoItemReducer,
      faq: faqReducer,
      settings: settingsReducer,
      notifications: notificationsReducer,
      insurance: insuranceReducer,
      ppmConfirmation: ppmConfirmationReducer,
      requests: requestsReducer,
      minpensionData: minpensionDataReducer,
      error: errorReducer,
    }),
    composeEnhancers(applyMiddleware(sagaMiddleware, routerMiddleware(history), analyticsLoggerMiddleware))
  );

  sagaMiddleware.run(function* () {
    yield all([
      customRouterSaga(),
      userSaga(),
      errorSaga(),
      scrapingSaga(),
      changeFundsSaga(),
      todoItemSaga(),
      changeSavingsSaga(),
      settingsSaga(),
      notificationsSaga(),
      insuranceSaga(),
      ppmConfirmationSaga(),
      minpensionDataSaga(),
      authSaga(),
    ]);
  });

  return _store;
};

export function getStore() {
  return _store;
}
