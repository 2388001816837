import { Action } from "redux";

import { getTryggakollenDataRequest } from "@common/requests";
import { TodoItemType } from "@common/types";

import { defaultScrapingState, ScrapingState, ScrapingStatus } from "./state";
import {
  updateScrapingJob,
  setScrapingStatus,
  startScraping,
  setInsuranceSkippedAction,
  setInsuranceUpsellSkippedAction
} from "./actions";

export function scrapingReducer(state: ScrapingState = defaultScrapingState, action: Action): ScrapingState {
  if (updateScrapingJob.is(action)) {
    const status: ScrapingStatus = !action.job.finished_at ? "in-progress" : action.job.failed ? "error" : "finished";
    return {
      ...state,
      status,
      job: action.job
    };
  }

  if (setScrapingStatus.is(action)) {
    return {
      ...state,
      status: action.status
    };
  }

  if (getTryggakollenDataRequest.actions.isOk(action)) {
    return {
      ...state,
      result: action.result
    };
  }

  if (setInsuranceSkippedAction.is(action)) {
    const data = state.result!;
    const path = action.itemType === TodoItemType.LifeInsurance ? "life_insurance" : "safety_insurance";
    return {
      ...state,
      result: data
        ? {
            ...data!,
            summary: {
              ...data.summary,
              recommendation: {
                ...data.summary.recommendation,
                group_insurances: {
                  ...data.summary.recommendation.group_insurances,
                  [path]: {
                    ...data.summary.recommendation.group_insurances[path],
                    skipped_date: new Date().toISOString()
                  }
                }
              }
            }
          }
        : state.result
    };
  }

  if (setInsuranceUpsellSkippedAction.is(action)) {
    const data = state.result!;
    const path = action.itemType === TodoItemType.LifeInsuranceUpsell ? "life_insurance" : "safety_insurance";
    return {
      ...state,
      result: data
        ? {
            ...data!,
            summary: {
              ...data.summary,
              recommendation: {
                ...data.summary.recommendation,
                group_insurances: {
                  ...data.summary.recommendation.group_insurances,
                  [path]: {
                    ...data.summary.recommendation.group_insurances[path],
                    upsell: {
                      ...data.summary.recommendation.group_insurances[path]?.upsell,
                      skipped_date: new Date().toISOString()
                    }
                  }
                }
              }
            }
          }
        : state.result
    };
  }

  if (startScraping.is(action)) {
    return {
      ...state,
      status: "in-progress",
      job: undefined
    };
  }

  return state;
}
