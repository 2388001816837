export interface AuthState {
  isFetching: boolean;
  isBankdIDLoginInProgress: boolean;
  error?: any;
}

export const defaultAuthState: AuthState = {
  isFetching: false,
  isBankdIDLoginInProgress: false,
  error: undefined
};
