import { FAQModel } from "@common/models";

export interface FAQState {
  isFetching: boolean;
  data?: FAQModel[];
  error?: any;
}

export const defaultFAQState: FAQState = {
  isFetching: false,
  data: [
    {
      id: "one",
      title: "Hur går min pension?",
      description:
        "Du följer enkelt hur din pension utvecklas på sidan Dina Pengar. Där visas en graf över hur värdet på hela din pension har utvecklats. Klickar du på Visa mer så ser du också vem som förvaltar de olika delarna av din pension.",
    },
    {
      id: "two",
      title: "Hur går det för mina fonder?",
      description:
        "Vill du se hur dina fonder har utvecklats kan du se det på sidan Dina Fonder. Där ser du hur dina fonder gått sedan årsskiftet. Klickar du på Se dina fonder så ser du även hur det gått för varje enskild fond du har.",
    },
    {
      id: "three",
      title: "Vilka delar av pensionen får jag hjälp med?",
      description:
        "Appen går igenom alla delar av din pension oavsett om det är inkomst-, tjänste- eller privat pension. De största försäkringsbolagen och deras olika typer av försäkringar täcks av appen. Har du någon ovanligare typ av pensionsförsäkring kan det vara så att just den inte tas med.",
    },
    {
      id: "four",
      title: "Har jag gjort allt som krävs för att tjänsten ska fungera?",
      description: "Det ser du enkelt i appen. Har du information om din pension i appen så fungerar det som det ska.",
    },
    {
      id: "five",
      title: "Hur mycket kommer jag få i pension?",
      description:
        "Det görs en beräkning av din pension och den ser du på sidan Pensionsprognos. Där ser du också om din pension är bra eller inte. Om du inte ser Pensionsprognos beror det på att vi inte har alla uppgifter från dig som krävs för att beräkna den.",
    },
    {
      id: "six",
      title: "Hur genomförs fondbytena?",
      description:
        "Du får ett mail med förifyllda fondbytesblanketter. Dessa skriver du bara under och skickar direkt till försäkringsbolaget du vill byta hos.",
    },
    {
      id: "seven",
      title: "Hur avslutar jag tjänsten?",
      description:
        "Vad tråkigt om du vill lämna tjänsten. Men om du har bestämt dig för det så kontaktar du kundtjänst för att avsluta.",
    },
    {
      id: "eight",
      title: "Så fungerar Pensionsbetyget",
      description:
        "Ditt Pensionsbetyg baserar sig på: <br/> 1. Hur mycket sparat du har jämfört med andra i samma ålder. <br/> 2. Om risken i dina pensionsinvesteringar överensstämmer med den risk du valt att ha. Vilken risk du har kan du se i inställningar. <br/> 3. Vilket betyg dina fonder har som du äger.",
    },
  ],
};
