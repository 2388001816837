import * as React from "react";
import { useState } from "react";

import { Layout, Checkbox, Button } from "@components/common";

import "./LifeInsuranceHealthCheck.scss";

interface LifeInsuranceHealthCheckProps {
  initAllChecked: boolean;

  onPrev(): any;
  onNext(): any;
}

const questions = [
  "är folkbokförd i Sverige;",
  "har fyllt 18 men inte 64 år (skyddet gäller tills fyllda 65 år);",
  "är fullt frisk och fullt arbetsför, har inte kännedom om allvarlig sjukdom, sjukdomstillstånd, skada eller kommande sjukskrivning;",
  "under de senaste 12 månaderna inte haft någon kontakt med hälso- och sjukvården eller fått medicinering för en kronisk eller återkommande sjukdom;",
  "inte har någon sjukhusvistelse inplanerad och/eller har inte ett läkar-/specialistbesök inbokat för odiagnostiserat tillstånd eller symptom samt;",
  "har aldrig fått behandling för: stroke/TIA någon form av cancer, leukemi eller tumör, någon form av transplantation, hjärt-/kärlsjukdom, immunbristsjukdom, nedsatt njurfunktion eller diabetes."
];

const setAllQuestionsTo = (val: boolean) =>
  questions.reduce((prev, curr) => {
    prev[curr] = val;
    return prev;
  }, {});

export const LifeInsuranceHealthCheck = ({ initAllChecked, onPrev, onNext }: LifeInsuranceHealthCheckProps) => {
  const [checkedQuestions, setCheckedQuestions] = useState(initAllChecked ? setAllQuestionsTo(true) : {});
  const isAllChecked = !questions.some(text => !checkedQuestions[text]);

  return (
    <Layout title="Hälsodeklaration" onGoBack={onPrev} contentClassName="LifeInsuranceHealthCheck">
      <div className="LifeInsuranceHealthCheck__card">
        <p>I denna hälsodeklaration försäkrar jag att jag:</p>
        {questions.map((text, index) => (
          <Checkbox
            key={`life_insurance_healthcheck_question_${index}`}
            value={checkedQuestions[text]}
            label={text}
            onClick={() => setCheckedQuestions({ ...checkedQuestions, [text]: !checkedQuestions[text] })}
          />
        ))}
      </div>
      <div className="LifeInsuranceHealthCheck__action">
        <p>
          Ovan nämnda förutsättningar gäller inte för graviditet, epilepsi, mindre olycksfall och/eller skador som
          hindrade dig från att arbeta under kortare tid än 2 veckor.
          <br /> Om du inte uppfyller samtliga förutsättningar ovan kan försäkringsersättning helt utebli och
          försäkringen kan sägas upp av försäkringsgivaren.
        </p>
        <Button text="Nästa" disabled={!isAllChecked} onClick={onNext} />
      </div>
    </Layout>
  );
};
