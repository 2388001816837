// import { Action } from "redux";

import { logInRequest, getlogInStatusRequest } from "@common/requests";

import { AuthState, defaultAuthState } from "./state";
import { logInWithBankIDAction, bankIDLoginCompletedAction } from "./actions";

export function authReducer(state: AuthState = defaultAuthState, action: any): AuthState {
  if (logInRequest.actions.isRunning(action)) {
    return {
      ...state,
      isFetching: true
    };
  }

  if (logInRequest.actions.isOk(action)) {
    return {
      ...state,
      isFetching: false,
      error: undefined
    };
  }

  if (logInRequest.actions.isError(action)) {
    return {
      ...state,
      isFetching: false,
      error: action.errorType
    };
  }

  if (logInWithBankIDAction.is(action)) {
    return {
      ...state,
      isBankdIDLoginInProgress: true
    };
  }

  if (bankIDLoginCompletedAction.is(action)) {
    return {
      ...state,
      isBankdIDLoginInProgress: false
    };
  }

  if (getlogInStatusRequest.actions.isRunning(action)) {
    return {
      ...state,
      isBankdIDLoginInProgress: true
    };
  }

  if (getlogInStatusRequest.actions.isOk(action)) {
    if (action.result.pending) {
      return {
        ...state,
        isBankdIDLoginInProgress: action.result.pending
      };
    } else {
      return {
        ...state,
        isBankdIDLoginInProgress: action.result.pending,
        error: action.result.success ? undefined : action.result.message
      };
    }
  }

  if (getlogInStatusRequest.actions.isError(action)) {
    return {
      ...state,
      isBankdIDLoginInProgress: false,
      error: action.errorType
    };
  }

  return state;
}
