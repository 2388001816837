import * as React from "react";
import { connect } from "react-redux";

import { AppState } from "@state/store";
import { YourMoneyTableData } from "@common/models";
import { yourMoneyTableDataSelector, pensionRevisionDateSelector } from "@state/showMore";

import { YourMoneyTotalItem, YourMoneyItem } from "./item";

import "./ShowMoreMoney.scss";

interface ShowMoreMoneyProps {
  data: YourMoneyTableData[];
  pensionRevisionDate: string | null;
}

export const ShowMoreMoneyAtom = ({ data, pensionRevisionDate }: ShowMoreMoneyProps) => {
  return (
    <div className="ShowMoreMoney">
      <YourMoneyTotalItem data={data} />
      <div className="ShowMoreMoney__info">
        <h3 className="ShowMoreMoney__infoTitle">Ditt pensionsinnehav</h3>
        <p className="ShowMoreMoney__infoDescription">
          Vad kul att du vill veta mer om din pension! <br /> Här kan du se var dina pengar ligger och vilka fonder och
          investeringar du har.
        </p>
      </div>
      {data.map(item => (
        <YourMoneyItem key={`YourMoneyItem_${item.kind}`} data={item} />
      ))}
      <p className="ShowMoreMoney__revisionDate">
        Information om ditt kapital hämtades {pensionRevisionDate || "N/A"}.
      </p>
    </div>
  );
};

export const ShowMoreMoney = connect((state: AppState) => ({
  data: yourMoneyTableDataSelector(state),
  pensionRevisionDate: pensionRevisionDateSelector(state)
}))(ShowMoreMoneyAtom);
