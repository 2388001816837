export interface HomeState {
  currentSlide: number;
  isModalOpen: boolean;
  selectedPoint?: ChartPointData;
  isSidebarOpen: boolean;
}

export interface ChartPointData {
  index: number;
  cx: number;
  cy: number;
  payload: {
    date: number;
    value: number;
  };
}

export const defaultHomeState: HomeState = {
  currentSlide: 0,
  isModalOpen: false,
  isSidebarOpen: false
};
