import * as React from "react";
import Slider from "react-slick";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { push } from "connected-react-router";

import { AppState } from "@state/store";

import { currentScoresSelector } from "@state/todoItem";
import { homeSlideChangeAction, pensionPrognosvalueSelector } from "@state/home";
import { fundPerformanceSummarySelector, FundPerformanceSummary, totalCapitalSelector } from "@state/showMore";
import { isRetiredUserSelector } from "@state/user";
import { BasicScoringModel } from "@common/models";
import { ShowMoreRoute } from "@common/routes";
import { ShowMoreType } from "@common/types";
import { PensaModal, Chevron, RippleEffect } from "@components/common";
import { isMobile } from "@common/helpers";

import { MeterSlide } from "./meterSlide";
import { FundsSlide } from "./fundsSlide";
import { GenericMoneySlide } from "./moneySlide";
import { PensionPrognosisSlide } from "./pensionPrognosisSlide";

import "./Carousel.scss";

interface CarouselComponentProps {
  scoring: BasicScoringModel;
  fundsSummary: FundPerformanceSummary;
  pensionPrognosis: number | null;

  slide: number;
  isRetired: boolean;

  onSlide(index: number): any;
  onShowMoreFunds(): any;
  onShowMoreMeter(): any;
  onShowMorePensionPrognosis(): any;
}

interface CarouselComponentState {
  isPensionPrognosisInfoShown: boolean;
}

const titles = ["Ditt Pensionsbetyg", "Dina Fonder", "Dina Pengar", "Din Pensionsprognos"];

const NextArrow = ({ onClick }: any) => (
  <RippleEffect className="Carousel__slider__arrow Carousel__slider__arrowNext" circled actionFirst onClick={onClick}>
    <Chevron direction="right" />
  </RippleEffect>
);
const PrevArrow = ({ onClick }: any) => (
  <RippleEffect className="Carousel__slider__arrow Carousel__slider__arrowPrev" circled actionFirst onClick={onClick}>
    <Chevron direction="left" />
  </RippleEffect>
);
class CarouselComponent extends React.Component<CarouselComponentProps, CarouselComponentState> {
  slider: Slider | null;
  firstClientX: number;
  firstClientY: number;
  clientX: number;
  clientY: number;

  readonly state = {
    isPensionPrognosisInfoShown: false
  };

  touchStart(e: TouchEvent) {
    this.firstClientX = e.touches[0].clientX;
    this.firstClientY = e.touches[0].clientY;
  }

  preventTouch(e: TouchEvent) {
    const minValue = 5; // threshold

    this.clientX = e.touches[0].clientX - this.firstClientX;
    this.clientY = e.touches[0].clientY - this.firstClientY;

    // Vertical scrolling does not work when you start swiping horizontally.
    if (Math.abs(this.clientX) > minValue) {
      e.preventDefault();
      e.returnValue = false;
      return false;
    }
    return;
  }

  componentDidMount() {
    window.addEventListener("touchstart", this.touchStart);
    window.addEventListener("touchmove", this.preventTouch, { passive: false });
  }

  componentWillUnmount() {
    window.removeEventListener("touchstart", this.touchStart);
    window.removeEventListener("touchmove", this.preventTouch);
  }

  componentDidUpdate({ slide }: CarouselComponentProps) {
    if (this.props.slide !== slide && this.slider) {
      this.slider.slickGoTo(this.props.slide);
    }
  }

  toggleInfoModal = () => {
    this.setState(prev => ({
      ...prev,
      isPensionPrognosisInfoShown: !prev.isPensionPrognosisInfoShown
    }));
  };

  get title(): string {
    const { slide } = this.props;
    return titles[slide];
  }

  render() {
    const {
      isRetired,
      slide,
      scoring,
      fundsSummary,
      pensionPrognosis,
      onSlide,
      onShowMoreFunds,
      onShowMoreMeter,
      onShowMorePensionPrognosis
    } = this.props;

    const isDesktop = !isMobile();

    return (
      <section className="Carousel">
        <h3 className="Carousel__title">{this.title}</h3>
        <Slider
          ref={ref => (this.slider = ref)}
          className="Carousel__slider"
          arrows={isDesktop}
          slidesToShow={1}
          initialSlide={slide}
          infinite
          centerMode={!isDesktop}
          variableWidth={!isDesktop}
          focusOnSelect
          centerPadding="0"
          afterChange={onSlide}
          nextArrow={<NextArrow />}
          prevArrow={<PrevArrow />}
        >
          <div className="Carousel__slideWrapper">
            <div className="Carousel__slide">
              <MeterSlide score={scoring.total} onShowMore={onShowMoreMeter} />
            </div>
          </div>
          <div className="Carousel__slideWrapper">
            <div className="Carousel__slide">
              <FundsSlide summary={fundsSummary} onShowMore={onShowMoreFunds} />
            </div>
          </div>
          <div className="Carousel__slideWrapper">
            <div className="Carousel__slide">
              <GenericMoneySlide />
            </div>
          </div>
          {!!pensionPrognosis && !isRetired && (
            <div className="Carousel__slideWrapper">
              <div className="Carousel__slide">
                <PensionPrognosisSlide
                  prognos={pensionPrognosis}
                  onShowInfo={this.toggleInfoModal}
                  onShowMore={onShowMorePensionPrognosis}
                />
              </div>
            </div>
          )}
        </Slider>
        <PensaModal
          isOpened={this.state.isPensionPrognosisInfoShown}
          header="Din pensionsprognos"
          content="Här visas vad du beräknas få i pension. Det är beräknat utifrån den info du gett tidigare om din lön och anställning. Har du ingen pensionsprognos beräknad för dig beror det på att vi saknar dom uppgifterna. Kontakta oss då."
          onClose={this.toggleInfoModal}
        />
      </section>
    );
  }
}

export const Carousel = connect(
  (state: AppState) => ({
    scoring: currentScoresSelector(state),
    fundsSummary: fundPerformanceSummarySelector(state),
    slide: state.home.currentSlide,
    pensionPrognosis: pensionPrognosvalueSelector(state),
    totalCapital: totalCapitalSelector(state),
    isRetired: isRetiredUserSelector(state)
  }),
  (dispatch: Dispatch) => ({
    onSlide(index: number) {
      dispatch(homeSlideChangeAction({ newSlideNumber: index }));
    },
    onShowMoreFunds() {
      dispatch(push(ShowMoreRoute.format({ type: ShowMoreType.Funds })));
    },
    onShowMoreMeter() {
      dispatch(push(ShowMoreRoute.format({ type: ShowMoreType.Meter })));
    },
    onShowMorePensionPrognosis() {
      dispatch(push(ShowMoreRoute.format({ type: ShowMoreType.Prognosis })));
    }
  })
)(CarouselComponent);
