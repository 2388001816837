import * as React from "react";
import * as cn from "classnames";

import { Arrow } from "../Arrow";
import { RippleEffect } from "../rippleEffect";
import { Cross } from "../Cross";
import { WithInnerHeight } from "../withInnerHeight";

import "./Layout.scss";

export type LayoutColor = "pink" | "blue" | "green" | "purple";
interface LayoutProps {
  children: React.ReactNode;
  title?: string;
  subtitle?: string;
  color?: LayoutColor;
  className?: string;
  contentClassName?: string;
  withCross?: boolean;
  isOverlayed?: boolean;

  onGoBack?(): any;
}

export const Layout = ({
  children,
  title,
  subtitle,
  color,
  className,
  contentClassName,
  isOverlayed,
  withCross,
  onGoBack
}: LayoutProps) => {
  return (
    <WithInnerHeight limitHeight={isOverlayed} limitHeightTo={0}>
      <div className={cn("Layout", color ? `Layout--${color}` : "", className, { "Layout--overlayed": isOverlayed })}>
        <header className={cn("Layout__header", { "Layout__header--padded": !onGoBack })}>
          {onGoBack && (
            <RippleEffect className="Layout__headerBack" circled onClick={onGoBack}>
              {withCross ? (
                <Cross size="1.4rem" color={!!color ? "#fafafa" : "#12121c"} />
              ) : (
                <Arrow size="1.5rem" color={!!color ? "#fafafa" : "#12121c"} />
              )}
            </RippleEffect>
          )}
          <div className="Layout__title">
            <p className="Layout__titleMain">{title}</p>
            <p className="Layout__titleSecondary">{subtitle}</p>
          </div>
        </header>
        <section className={cn("Layout__content", contentClassName)}>{children}</section>
      </div>
    </WithInnerHeight>
  );
};
