import * as React from "react";

import { RippleEffect, Chevron } from "@components/common";

import "./ShowMoreLink.scss";

interface ShowMoreLinkProps {
  text: string;
  color?: string;
  onClick(): any;
}

export const ShowMoreLink = ({ text, color, onClick }: ShowMoreLinkProps) => {
  return (
    <RippleEffect className="ShowMoreLink" rounded onClick={onClick}>
      <span className="ShowMoreLink__text" style={{ color }}>
        {text}
      </span>
      <Chevron direction="right" color={color || "#fafafa"} size="1rem" />
    </RippleEffect>
  );
};
