import { validate } from "validate.js";

export interface FormField<T> {
  value: T;
  errors?: string[];
}

export interface FormBase {
  [field: string]: FormField<any>;
}

export const isFormValid = (form: FormBase): boolean => {
  for (const field in form) {
    if (form.hasOwnProperty(field) && form[field].errors && form[field].errors!.length) {
      return false;
    }
  }
  return true;
};

export const getFormValues = (form: FormBase) => {
  const ret = {};
  for (const field in form) {
    if (form.hasOwnProperty(field)) {
      ret[field] = form[field].value;
    }
  }
  return ret;
};

export const validateForm = <T extends FormBase>(form: T, constraints: any): T => {
  const errors = validate(getFormValues(form), constraints, { fullMessages: false });
  for (const field in form) {
    if (form.hasOwnProperty(field)) {
      form[field].errors = errors && errors[field];
    }
  }
  return form;
};

export const getErrorString = (form: any): string => {
  const errors: string[] = [];
  for (const field in form) {
    if (form.hasOwnProperty(field) && form[field].errors) {
      errors.push(form[field].errors[0]);
    }
  }
  return errors.join("; ");
};
