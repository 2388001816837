import { Action } from "redux";

import { getMinpensionDataRequest, postMinpensionDataRequest, getPensionInstitutesRequest } from "@common/requests";

import { MinpensionDataState, defaultMinpensionDataState } from "./state";
import {
  updateAllmanPensionDataAction,
  updateTjanstePensionDataAction,
  updatePrivatPensionDataAction,
  updateTotalPensionAction,
  updateNewPensionPlanAction,
} from "./actions";

export function minpensionDataReducer(
  state: MinpensionDataState = defaultMinpensionDataState,
  action: Action
): MinpensionDataState {
  if (getMinpensionDataRequest.actions.isOk(action) || postMinpensionDataRequest.actions.isOk(action)) {
    return {
      ...state,
      data: action.result?.data,
      wasChanged: false,
    };
  }

  if (getPensionInstitutesRequest.actions.isOk(action)) {
    return {
      ...state,
      pensionInstitutes: action.result,
    };
  }

  if (updateAllmanPensionDataAction.is(action)) {
    return {
      ...state,
      wasChanged: true,
      data: { ...state.data, allmanPension: action.data },
    };
  }
  if (updateTjanstePensionDataAction.is(action)) {
    return {
      ...state,
      wasChanged: true,
      data: { ...state.data, tjanstePension: action.data },
    };
  }
  if (updatePrivatPensionDataAction.is(action)) {
    return {
      ...state,
      wasChanged: true,
      data: { ...state.data, privatPension: action.data },
    };
  }
  if (updateTotalPensionAction.is(action)) {
    return {
      ...state,
      wasChanged: true,
      data: { ...state.data, totalPension: action.total },
    };
  }
  if (updateNewPensionPlanAction.is(action)) {
    return {
      ...state,
      newPensionPlan: action.data,
    };
  }

  return state;
}
