interface SingleRequestState {
  loading: boolean;
  error?: any;
}

export interface RequestsState {
  postInsuranceApplication: SingleRequestState;
  postSignInsuranceApplication: SingleRequestState;
  putCancelInsuranceApplication: SingleRequestState;
  getActiveInsurances: SingleRequestState;
  postSkipInsuranceOffer: SingleRequestState;
  getCurrentInsuranceApplication: SingleRequestState;
  getMinpensionData: SingleRequestState;
  postMinpensionData: SingleRequestState;
  getPensionInstitutes: SingleRequestState;
  postProcessMinpensionData: SingleRequestState;
}

export const defaultRequestsState: RequestsState = {
  postInsuranceApplication: { loading: false },
  postSignInsuranceApplication: { loading: false },
  putCancelInsuranceApplication: { loading: false },
  getActiveInsurances: { loading: false },
  postSkipInsuranceOffer: { loading: false },
  getCurrentInsuranceApplication: { loading: false },
  getMinpensionData: { loading: false },
  postMinpensionData: { loading: false },
  getPensionInstitutes: { loading: false },
  postProcessMinpensionData: { loading: false },
};
