import * as React from "react";

import { getSign, getFormattedPercentsValue, getArrowDirection } from "@common/helpers";
import { CircledArrow, ShowMoreLink } from "@components/common";

import { FundPerformanceSummary } from "@state/showMore";

import "./FundsSlide.scss";

interface FundsSlideProps {
  summary: FundPerformanceSummary;

  onShowMore(): any;
}

export const FundsSlide = ({ summary, onShowMore }: FundsSlideProps) => {
  return (
    <div className="FundsSlide">
      <div className="FundsSlide__top">
        <h3 className="FundsSlide__changeKr">{`${getSign(
          summary.valuationChange
        )} ${summary.valuationChange.toLocaleString("sv")} kr`}</h3>
        <div className="FundsSlide__changePercent">
          <CircledArrow direction={getArrowDirection(summary.avgMeanReturn)} size="2.2rem" color="white" />
          <p>{getFormattedPercentsValue(summary.avgMeanReturn)}</p>
        </div>
      </div>
      <div className="FundsSlide__bot">
        {summary.updateDate ? (
          <div className="FundsSlide__date">
            <p className="FundsSlide__month">{summary.monthName}</p>
            <p className="FundsSlide__year">{summary.year}</p>
          </div>
        ) : (
          <p className="FundsSlide__month">Ingen info</p>
        )}
        <ShowMoreLink text="Se dina fonder" onClick={onShowMore} />
      </div>
    </div>
  );
};
