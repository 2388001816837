import * as React from "react";

import { PensionPlanData, FundInList, managementTypeKeyLabelMap } from "@common/models";
import { RippleEffect, Cross, TextFieldType, TextField } from "@components/common";

import { FundSelect } from "../fundSelect";

import "./PensionPlanEdit.scss";

export interface PlanEditorProps {
  plan: PensionPlanData;
  onUpdate(newData: PensionPlanData): any;
}

export const PlanEditor = ({ plan, onUpdate }: PlanEditorProps) => {
  const fundsList = plan.funds || [];

  const addFund = (fund: FundInList) => {
    onUpdate({
      ...plan,
      funds: [...fundsList, fund],
    });
  };
  const changeFundValuation = (newVal: number, fundId: string) => {
    onUpdate({
      ...plan,
      funds: fundsList.map((f) => (f.fund_in_shell_id === fundId ? { ...f, valuation: newVal } : f)),
    });
  };
  const removeFund = (fundId: string) => {
    onUpdate({
      ...plan,
      funds: fundsList.filter((f) => f.fund_in_shell_id !== fundId),
    });
  };
  const changeCapital = (newVal: number) => {
    onUpdate({
      ...plan,
      capital: newVal,
    });
  };
  const changeAnnuity = (newVal: number) => {
    onUpdate({
      ...plan,
      annuity: newVal,
    });
  };

  switch (plan.management_type) {
    case "traditional":
      return (
        <div className="PensionPlanEdit__input">
          <p>Kapital:</p>
          <TextField
            type={TextFieldType.Number}
            value={(plan.capital || 0).toString()}
            onChange={(newVal) => changeCapital(+newVal)}
            suffix="kr"
          />
        </div>
      );

    case "fund":
      return (
        <FundSelect
          shellId={plan.insurance_shell}
          fundsList={fundsList}
          fundListHeader="Fonder"
          onAddFund={addFund}
          onFundValuationChange={changeFundValuation}
          onRemoveFund={removeFund}
        />
      );

    case "mixed":
    case "defined-benefit":
      return (
        <div className="PensionPlanEdit__input">
          <p>Belopp:</p>
          <TextField
            type={TextFieldType.Number}
            value={(plan.annuity || 0).toString()}
            onChange={(newVal) => changeAnnuity(+newVal)}
            suffix="kr/mån"
          />
        </div>
      );

    case "depot":
      return (
        <>
          <div className="PensionPlanEdit__input" style={{ marginBottom: "1rem" }}>
            <p>Kapital:</p>
            <TextField
              type={TextFieldType.Number}
              value={(plan.capital || 0).toString()}
              onChange={(newVal) => changeCapital(+newVal)}
              suffix="kr"
            />
          </div>
          <FundSelect
            shellId={plan.insurance_shell}
            fundsList={fundsList}
            fundListHeader="Fonder"
            onAddFund={addFund}
            onFundValuationChange={changeFundValuation}
            onRemoveFund={removeFund}
          />
        </>
      );

    default:
      return null;
  }
};

interface PensionPlanEditProps {
  plan: PensionPlanData;

  onUpdate(newData: PensionPlanData): any;
  onDelete(): any;
}

export const PensionPlanEdit = ({ plan, onUpdate, onDelete }: PensionPlanEditProps) => {
  return (
    <div className="PensionPlanEdit">
      <RippleEffect className="PensionPlanEdit__delete" onClick={onDelete} circled>
        <Cross color="red" size="1.5rem" />
      </RippleEffect>
      <h5 className="PensionPlanEdit__title">{plan.insurance_type}</h5>
      <div className="PensionPlanEdit__info">
        <div className="PensionPlanEdit__infoRow">
          <p>Försäkringsnummer:</p>
          <p>{plan.insurance_number}</p>
        </div>
        <div className="PensionPlanEdit__infoRow">
          <p>Förvaltningstyp:</p>
          <p>{managementTypeKeyLabelMap[plan.management_type]}</p>
        </div>
      </div>
      <PlanEditor plan={plan} onUpdate={onUpdate} />
    </div>
  );
};
