import * as skandia from "./files/pensionInstitutesLogo/logo-skandia.svg";
import * as pensionsmyndigheten from "./files/logo-ppm.svg";
import * as amf from "./files/pensionInstitutesLogo/logo-amf.svg";
import * as swedbank from "./files/pensionInstitutesLogo/logo-swedbank.png";
import * as spp from "./files/pensionInstitutesLogo/logo-spp.svg";
import * as lansforsakringar from "./files/pensionInstitutesLogo/logo-lansforsakringar.png";
import * as folksam from "./files/pensionInstitutesLogo/logo-folksam.png";
import * as danica from "./files/pensionInstitutesLogo/logo-danica_pension.svg";
import * as futur from "./files/pensionInstitutesLogo/logo-futur_pension.svg";
import * as handelsbanken_liv from "./files/pensionInstitutesLogo/logo-handelsbanken_liv.svg";
import * as folksam_lo_fondforsakring from "./files/pensionInstitutesLogo/logo-folksam_lo_fondforsakring.svg";
import * as seb from "./files/pensionInstitutesLogo/logo-seb.svg";
import * as nordea from "./files/pensionInstitutesLogo/logo-nordea.svg";
import * as movestic from "./files/pensionInstitutesLogo/logo-movestic.png";

export const pensionInstitutesLogo = {
  Skandia: skandia,
  Pensionsmyndigheten: pensionsmyndigheten,
  AMF: amf,
  Swedbank: swedbank,
  SPP: spp,
  Länsförsäkringar: lansforsakringar,
  Folksam: folksam,
  "Danica Pension": danica,
  "Futur Pension": futur,
  "Handelsbanken Liv": handelsbanken_liv,
  "Folksam LO Fondförsäkring": folksam_lo_fondforsakring,
  "SEB TryggLiv": seb,
  "SEB": seb,
  Nordea: nordea,
  Movestic: movestic
};
