import { RouteComponentProps } from "react-router-dom";
import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";

import { BlockerScreenType, BlockerScreen } from "@components/common";
import { AppState } from "@state/store";

interface ErrorWithMessageProps {
  title?: string;
  message?: string;
  hideBtn?: boolean;

  onGoBack(): any;
}

export const ErrorWithMessage = connect(
  (state: AppState) => ({
    ...state.error
  }),
  (dispatch: Dispatch, ownProps: RouteComponentProps<{}>) => ({
    onGoBack() {
      ownProps.history.goBack();
    }
  })
)(({ title, message, hideBtn, onGoBack }: ErrorWithMessageProps) => (
  <BlockerScreen
    type={BlockerScreenType.Error}
    title={title}
    content={message || "Något gick fel, försök igen"}
    btnText={hideBtn ? undefined : "Gå tillbaka"}
    onClick={hideBtn ? undefined : () => (window.location.href = "/")}
  />
));
