import * as React from "react";
import { PensionProfileForm, UserWithInfo } from "@common/models";
import { TextFieldType, FormikSelect, FormikTextField } from "@components/common";
import { FormikProps, Field } from "formik";
import * as Yup from "yup";
import {
  largeEstimationOptionsString,
  smallEstimationOptionsString,
  employmentSectorOptionsString,
  martialOptionsString,
  knowledgeOptionsString,
  noYesOptions,
  largeEstimationOptions,
  smallEstimationOptions,
  employmentSectorOptions,
  martialOptions,
  hasFundKnowledgeOptions,
  hasFundExperienceOptions,
  getCollectiveAgreementOptions,
  dependantChildrenOptions,
  retirementAgeOptions
} from "./options";

const REQUIRED_FIELD_TEXT = "Det här fältet behöver fyllas i";

const EconomySchemaShape = {
  ownsHouse: Yup.bool().required(REQUIRED_FIELD_TEXT),
  propertyValuetion: Yup.string().when("ownsHouse", {
    is: true,
    then: Yup.string()
      .oneOf(largeEstimationOptionsString.split(" "))
      .required(REQUIRED_FIELD_TEXT),
    otherwise: Yup.string().oneOf(largeEstimationOptionsString.split(" "))
  }),
  mortage: Yup.string().when("ownsHouse", {
    is: true,
    then: Yup.string()
      .oneOf(largeEstimationOptionsString.split(" "))
      .required(REQUIRED_FIELD_TEXT),
    otherwise: Yup.string().oneOf(largeEstimationOptionsString.split(" "))
  }),
  otherLoans: Yup.string()
    .required(REQUIRED_FIELD_TEXT)
    .oneOf(smallEstimationOptionsString.split(" ")),
  savings: Yup.string()
    .required(REQUIRED_FIELD_TEXT)
    .oneOf(smallEstimationOptionsString.split(" "))
};

export const EconomySchema = Yup.object().shape(EconomySchemaShape);

const WorkSchemaShape = {
  employmentSector: Yup.string()
    .required(REQUIRED_FIELD_TEXT)
    .oneOf(employmentSectorOptionsString.split(" ")),
  collectiveAgreement: Yup.string().required(REQUIRED_FIELD_TEXT),
  grossMonthlyIncome: Yup.number()
    .required(REQUIRED_FIELD_TEXT)
    .min(0, "Måste vara ett positivt värde")
    .max(80, "Högsta värde är 80 000 kr"),
  retirementAge: Yup.number()
    .required(REQUIRED_FIELD_TEXT)
    .min(55)
    .max(75)
};

export const WorkSchema = Yup.object().shape(WorkSchemaShape);

const FamilySchemaShape = {
  maritalStatus: Yup.string()
    .required(REQUIRED_FIELD_TEXT)
    .oneOf(martialOptionsString.split(" ")),
  dependantChildren: Yup.number()
    .required(REQUIRED_FIELD_TEXT)
    .min(0)
    .max(10)
};

export const FamilySchema = Yup.object().shape(FamilySchemaShape);

const RiskSchemaShape = {
  riskWillingness: Yup.bool().required(REQUIRED_FIELD_TEXT),
  hasFundExperience: Yup.string()
    .required(REQUIRED_FIELD_TEXT)
    .oneOf(knowledgeOptionsString.split(" ")),
  hasFundKnowledge: Yup.string()
    .required(REQUIRED_FIELD_TEXT)
    .oneOf(knowledgeOptionsString.split(" "))
};

export const RiskSchema = Yup.object().shape(RiskSchemaShape);

export const PensionProfileSchema = Yup.object().shape({
  ...EconomySchemaShape,
  ...WorkSchemaShape,
  ...FamilySchemaShape,
  ...RiskSchemaShape
});

interface SectionProps {
  readonly formikProps: FormikProps<PensionProfileForm>;
  readonly user: UserWithInfo;
  readonly hideTitle?: boolean;
}

export const EconomySection = (props: SectionProps) => {
  return (
    <>
      {!props.hideTitle && <h4 className="PensionProfile__title">Ekonomi</h4>}
      <Field
        component={FormikSelect}
        name="ownsHouse"
        label="Äger du en egen bostad?"
        placeholder="Ingen information"
        options={noYesOptions}
        isSearchable={false}
        stringToValue={(v: string) => v === "true"}
        valueToString={(v: boolean | undefined) => (typeof v === "boolean" ? String(v) : v)}
        id="pp-ownsHouse"
      />
      <div className="PensionProfile__line" />
      <Field
        component={FormikSelect}
        name="propertyValuetion"
        label="Värde fastighetsinnehav"
        placeholder="Ingen information"
        options={largeEstimationOptions}
        isSearchable={false}
        disabled={!props.formikProps.values.ownsHouse}
        id="pp-propertyValuetion"
      />
      <div className="PensionProfile__line" />
      <Field
        component={FormikSelect}
        name="mortage"
        label="Bostadslån"
        placeholder="Ingen information"
        isSearchable={false}
        options={largeEstimationOptions}
        disabled={!props.formikProps.values.ownsHouse}
        id="pp-mortage"
      />
      <div className="PensionProfile__line" />
      <Field
        component={FormikSelect}
        name="otherLoans"
        label="Övriga lån"
        placeholder="Ingen information"
        isSearchable={false}
        options={smallEstimationOptions}
        id="pp-otherLoans"
      />
      <div className="PensionProfile__line" />
      <Field
        component={FormikSelect}
        name="savings"
        label="Sparkapital"
        placeholder="Ingen information"
        isSearchable={false}
        options={smallEstimationOptions}
        id="pp-savings"
      />
    </>
  );
};

export const WorkSection = (props: SectionProps) => {
  const retirementAgeRequired = props.user.calculated_current_age.decimal_years >= 55;
  return (
    <>
      {!props.hideTitle && <h4 className="PensionProfile__title">Arbete</h4>}
      <Field
        component={FormikSelect}
        name="employmentSector"
        label="Arbetsgivare"
        placeholder="Ingen information"
        options={employmentSectorOptions}
        isSearchable={false}
        id="pp-employmentSector"
      />
      <div className="PensionProfile__line" />
      <Field
        component={FormikSelect}
        name="collectiveAgreement"
        label="Tjänsteavtal"
        placeholder="Ingen information"
        options={getCollectiveAgreementOptions(
          props.user.person_number,
          props.user.risk_profile.collar_color,
          props.formikProps.values.employmentSector
        )}
        id="pp-collectiveAgreement"
      />
      <div className="PensionProfile__line" />
      <Field
        component={FormikTextField}
        name="grossMonthlyIncome"
        label="Månadslön före skatt"
        type={TextFieldType.Number}
        placeholder="Ingen information"
        id="pp-grossMonthlyIncome"
        suffix="000"
      />
      {retirementAgeRequired && (
        <>
          <div className="PensionProfile__line" />
          <Field
            component={FormikSelect}
            name="retirementAge"
            label="Planerad pensionsålder"
            placeholder="Ingen information"
            isSearchable={false}
            options={retirementAgeOptions}
            stringToValue={(v: string) => Number(v)}
            valueToString={(v: number) => String(v)}
            id="pp-retirementAge"
          />
        </>
      )}
    </>
  );
};

export const FamilySection = (props: SectionProps) => {
  return (
    <>
      {!props.hideTitle && <h4 className="PensionProfile__title">Familj</h4>}
      <Field
        component={FormikSelect}
        name="maritalStatus"
        label="Civilstånd"
        placeholder="Ingen information"
        isSearchable={false}
        options={martialOptions}
        id="pp-maritalStatus"
      />
      <div className="PensionProfile__line" />
      <Field
        component={FormikSelect}
        name="dependantChildren"
        label="Hemmaboende barn"
        placeholder="Ingen information"
        options={dependantChildrenOptions}
        isSearchable={false}
        stringToValue={(v: string) => Number(v)}
        valueToString={(v: number) => String(v)}
        id="pp-dependantChildren"
      />
    </>
  );
};

export const RiskSection = (props: SectionProps) => {
  return (
    <>
      {!props.hideTitle && <h4 className="PensionProfile__title">Riskvillighet</h4>}
      <Field
        component={FormikSelect}
        name="riskWillingness"
        label="Jag är beredd att ta högre risker för att kunna tjäna mer."
        placeholder="Ingen information"
        isSearchable={false}
        options={noYesOptions}
        stringToValue={(v: string) => v === "true"}
        valueToString={(v: boolean | undefined) => (typeof v === "boolean" ? String(v) : v)}
        id="pp-riskWillingness"
        info={
          <>
            <b>Ja</b>, jag är beredd att ta högre risker för att kunna tjäna mer även fast det innebär större risk att
            pengarna minskar i värde.
            <br />
            <b>Nej</b>, jag undviker att ta risker för att minska risken att förlora pengar, fast det även innebär en
            mindre chans att pengarna ökar i värde.
          </>
        }
      />
      <div className="PensionProfile__line" />
      <Field
        component={FormikSelect}
        name="hasFundKnowledge"
        label="Kunskap om fonder och fondhandel"
        placeholder="Ingen information"
        isSearchable={false}
        options={hasFundKnowledgeOptions}
        id="pp-hasFundKnowledge"
        info={
          <>
            <b>Ingen</b> - Saknar helt kunskap om vad begreppet fond betyder.
            <br />
            <b>Mycket liten</b> - Har mycket liten insikt i hur fonder fungerar.
            <br />
            <b>Förstår ungefär</b> - Förstår ungefär hur en fond fungerar och känner till begrepp så som räntefond och
            aktiefond.
            <br />
            <b>Omfattande insikt</b> - Har eller har haft ett fondsparande under längre tid och aktivt fattat beslut om
            förändringar i innehavet.
          </>
        }
      />
      <div className="PensionProfile__line" />
      <Field
        component={FormikSelect}
        name="hasFundExperience"
        label="Erfarenhet av fonder och fondhandel"
        placeholder="Ingen information"
        isSearchable={false}
        options={hasFundExperienceOptions}
        id="pp-hasFundExperience"
        info={
          <>
            <b>Ingen</b> - Saknar helt erfarenhet av begreppet fond.
            <br />
            <b>Mycket liten</b> - Har aldrig självmant placerat i fonder, men har eller haft kapital i fonder via t.ex
            PPM eller tjänstepension.
            <br />
            <b>Viss erfarenhet</b> - Har eller har haft ett fondsparande där jag själv någon gång valt inriktning och
            risknivå.
            <br />
            <b>Omfattande erfarenhet</b> - Har eller har haft ett fondsparande under en längre tid och aktivt fattat
            beslut om förändring i innehavet.
          </>
        }
      />
    </>
  );
};
