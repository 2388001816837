import { takeEvery, select, put, call } from "redux-saga/effects";
import { push } from "connected-react-router";

import { AppState } from "@state/store";
import {
  refreshScrapingSummarySaga,
  setInsuranceSkippedAction,
  setInsuranceUpsellSkippedAction,
} from "@state/scraping";
import { responseErrorAction } from "@state/error";

import { TodoItemModel, MinpensionDataSteps } from "@common/models";
import {
  HomeRoute,
  TodoItemSuccessRoute,
  TodoItemBenefitsRoute,
  ChangeSavingsRoute,
  SupportRoute,
  UpdateProfileBenefitsRoute,
  MinpensionDataRoute,
  LifeInsuranceRoute,
  SafetyInsuranceRoute,
  LifeInsuranceUpsellRoute,
  SafetyInsuranceUpsellRoute,
} from "@common/routes";
import { TodoItemType } from "@common/types";
import { skipTodoItemRequest } from "@common/requests";

import { closeTodoItemAction, markItemAsCompleted, setTodoItemAction, skipItemAction } from "./actions";
import { selectedTodoItemSelector } from "./selectors";
import { trackHeapEvent, HeapEventNames } from "@state/analytics";

export function* finalizeTodoItemSaga() {
  const todoItem: TodoItemModel = yield select<AppState>(selectedTodoItemSelector);

  trackHeapEvent(HeapEventNames.TodoItemCompleted, { itemType: todoItem.type });

  // fetch updated trygakollen data
  yield call(refreshScrapingSummarySaga);

  // redirect to succes todo item route
  yield put(push(TodoItemSuccessRoute.format({})));
}

function* setTodoItemSaga({ item, skipRedirect }: typeof setTodoItemAction.typeInterface) {
  trackHeapEvent(HeapEventNames.TodoItemSelected, { itemType: item.type });
  switch (item.type) {
    case TodoItemType.ChangeSavings:
      if (!skipRedirect) {
        yield put(push(ChangeSavingsRoute.format({})));
      }
      return;
    case TodoItemType.ChangeFunds:
      yield put(push(TodoItemBenefitsRoute.format({})));
      return;
    case TodoItemType.UpdatePensionProfile:
      if (!skipRedirect) {
        yield put(push(UpdateProfileBenefitsRoute.format({})));
      }
      return;
    case TodoItemType.UpdatePensionAnalysis:
      yield put(
        push(MinpensionDataRoute.format({ step: MinpensionDataSteps.Login }, { backRedirect: HomeRoute.format({}) }))
      );
      return;
    case TodoItemType.LifeInsurance:
      yield put(push(LifeInsuranceRoute.format({})));
      return;
    case TodoItemType.SafetyInsurance:
      yield put(push(SafetyInsuranceRoute.format({})));
      return;
    case TodoItemType.LifeInsuranceUpsell:
      yield put(push(LifeInsuranceUpsellRoute.format({})));
      return;
    case TodoItemType.SafetyInsuranceUpsell:
      yield put(push(SafetyInsuranceUpsellRoute.format({})));
      return;
    case TodoItemType.FreeUserMock:
      yield put(push(SupportRoute.format({})));
      return;
    default:
      return;
  }
}

function* skipTodoItemSaga() {
  const item: TodoItemModel = yield select<AppState>(selectedTodoItemSelector);
  if (!item) {
    return;
  }
  const userId = yield select<AppState>((state) => state.user.userId);
  yield put(skipTodoItemRequest.actions.running({ userId, itemType: item.type }));
  const resp: typeof skipTodoItemRequest.types.response = yield call(
    skipTodoItemRequest,
    { userId, itemType: item.type },
    {}
  );
  if (resp.ok) {
    yield put(skipTodoItemRequest.actions.ok({ userId, itemType: item.type }, resp));
    trackHeapEvent(HeapEventNames.TodoItemSkipped, { itemType: item.type });
    if (item.type === TodoItemType.LifeInsurance || item.type === TodoItemType.SafetyInsurance) {
      yield put(setInsuranceSkippedAction({ itemType: item.type }));
    } else if (item.type === TodoItemType.LifeInsuranceUpsell || item.type === TodoItemType.SafetyInsuranceUpsell) {
      yield put(setInsuranceUpsellSkippedAction({ itemType: item.type }));
    }
    yield put(markItemAsCompleted({ withRedirect: true }));
  } else {
    yield put(skipTodoItemRequest.actions.error({ userId, itemType: item.type }, resp));
    yield put(responseErrorAction({ response: resp }));
  }
}

export function* todoItemSaga() {
  yield takeEvery(setTodoItemAction.type, setTodoItemSaga);
  yield takeEvery(skipItemAction.type, skipTodoItemSaga);
  yield takeEvery([closeTodoItemAction.type, markItemAsCompleted.type], function* (action: any) {
    // Do not redirect if markItemAsCompleted was dispatched without withRedirect set to true.
    if (action.type !== markItemAsCompleted.type || (action as typeof markItemAsCompleted.typeInterface).withRedirect) {
      yield put(push(HomeRoute.format({})));
    }
  });
}
