import * as React from "react";

import { icons } from "@assets";

import { LoginLayout } from "../loginLayout";
import { Button } from "../button";
import { DoubleBounce } from "../doubleBounce";

import "./BlockerScreen.scss";

export enum BlockerScreenType {
  Error = "error",
  Progress = "progress",
  Wait = "wait",
  NotInterested = "not-interested"
}

interface BlockerScreenProps {
  type: BlockerScreenType;
  title?: string;
  content?: React.ReactNode;
  btnText?: string;

  onClick?(): any;
}

const typeToIcon = {
  [BlockerScreenType.Error]: icons.error,
  [BlockerScreenType.Wait]: icons.hourglass,
  [BlockerScreenType.NotInterested]: icons.notInterested
};

export const BlockerScreen = ({ type, title, content, btnText, onClick }: BlockerScreenProps) => {
  return (
    <LoginLayout className="BlockerScreen">
      {type !== BlockerScreenType.Progress && <img className="BlockerScreen__icon" src={typeToIcon[type]} alt="" />}
      {(title || type === BlockerScreenType.Error) && <h3 className="BlockerScreen__title">{title || "Hoppsan!"}</h3>}
      {content && <div className="BlockerScreen__content">{content}</div>}
      {type === BlockerScreenType.Progress && (
        <div className="BlockerScreen__progress">
          <DoubleBounce size="6rem" isPrimary />
        </div>
      )}
      {btnText && onClick && <Button text={btnText} onClick={onClick} />}
    </LoginLayout>
  );
};
