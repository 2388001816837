import * as React from "react";

import { ShowMoreLink, RippleEffect } from "@components/common";
import { icons } from "@assets";

import "./PensionPrognosisSlide.scss";

interface PensionPrognosisSlideProps {
  prognos: number;

  onShowInfo(): any;
  onShowMore(): any;
}

export const PensionPrognosisSlide = ({ prognos, onShowMore, onShowInfo }: PensionPrognosisSlideProps) => {
  return (
    <div className="PensionPrognosisSlide">
      <div className="PensionPrognosisSlide__header">
        <p className="PensionPrognosisSlide__text">Enligt nuvarande prognos kommer du att få </p>
        <RippleEffect className="PensionPrognosisSlide__info" circled onClick={onShowInfo}>
          <img src={icons.help} alt="" />
        </RippleEffect>
      </div>

      <div className="PensionPrognosisSlide__bottom">
        <h2 className="PensionPrognosisSlide__value">{prognos.toLocaleString("sv")} kr</h2>
        <div className="PensionPrognosisSlide__footer">
          <p className="PensionPrognosisSlide__text">per månad </p>
          <ShowMoreLink text="Visa mer" onClick={onShowMore} />
        </div>
      </div>
    </div>
  );
};
