import { Job, TryggakollenData } from "@common/models";

export interface ScrapingState {
  status: ScrapingStatus;
  job?: Job;
  result?: TryggakollenData;
}

export const errorMap = {
  "login-aborted": `Du valde att avbryta. Försök gärna igen.`,
  "login-already-underway": `Välj "Avbryt" i din BankID-app och försök igen.`,
  "login-timeout": `Inloggningen tog för lång tid. Försök gärna igen.`,
  "invalid-personnummer": `Det angivna personnumret är ogiltigt!`,
  "scraping-failed": `Tyvärr gick något fel. Försök igen om en stund!`,
  "terms-and-conditions-must-be-agreed-today": `Tyvärr gick något fel. Försök igen om en stund!`,
  "service-unavailable": `Tjänsten är för tillfället inte tillgänglig. Försök igen senare.`
};

export type ScrapingStatus = "not-started" | "finished" | "in-progress" | "error";

export const defaultScrapingState: ScrapingState = {
  status: "not-started"
};
