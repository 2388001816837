import { MainSettingsForm, PensionProfileForm } from "@common/models";
import { RiskLevels } from "@common/types";

import { defineAction } from "../../lib/rd-action-creators";

export const updateUserMainSettingsAction = defineAction<{ form: MainSettingsForm }>("UPDATE USER MAIN SETTINGS");
export const updateUserPensionProfileAction = defineAction<{ form: PensionProfileForm }>("UPDATE USER PENSION PROFILE");
export const changeRequestedRiskLevelAction = defineAction<{ riskLevel: RiskLevels }>("CHANGE RISK LEVEL ACTION");
export const updatePensionProfileAndRiskLevelAction = defineAction<{ form: PensionProfileForm; riskLevel: RiskLevels }>(
  "UPDATE PERSION PROFILE AND RISK LEVEL"
);
export const finishPensionProfileUpdateAction = defineAction<{ riskLevel: RiskLevels }>(
  "FINISH PENSION PROFILE UPDATE"
);

export const pensionProfileUpdateFinished = defineAction("PENSION PROFILE UPDATE FINISHED");
