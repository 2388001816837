import { defineAction } from "../../lib/rd-action-creators";

export const getUserInfoAction = defineAction<{ id: string }>("GET USER INFO");
export const whoAmIAction = defineAction<{ redirectTo?: string }>("WHO AM I");
export const premiumRouteNotPermittedAction = defineAction("PREMIUM ROUTE NOT PERMITTED");
export const setUserIdAction = defineAction<{ id: string }>("SET USER ID ACTION");
export const markTermsAcceptedAction = defineAction<{ email?: string }>("TERMS ACCEPTED");
export const startInitialPensionProfileUpdate = defineAction("START INITIAL PENSION PROFILE UPDATE");
export const finishInitialPensionProfileUpdate = defineAction("FINISH INITIAL PENSION PROFILE UPDATE");
export const intoWatchedAction = defineAction("INTRO WATCHED");
