import * as React from "react";
import * as ReactDOM from "react-dom";
import { useState, useEffect } from "react";

import { PensaModal } from "@components/common";

let promptId = 1;

interface UiPropmptProps {
  onClose(): any;
  onAccept(): any;
}
const UiPrompt = ({ onClose, onAccept }: UiPropmptProps) => {
  const [isOpened, setIsOpened] = useState(false);
  useEffect(() => {
    if (!isOpened) {
      setIsOpened(true);
    }
  });
  return (
    <PensaModal
      isOpened={isOpened}
      onClose={onClose}
      submitText="Ladda om sidan"
      onSubmit={onAccept}
      header="Det finns en ny version"
      content=""
    />
  );
};

export async function createUIPrompt(onAccept: () => any, onClose: () => any) {
  const elem = document.createElement("div");
  elem.setAttribute("id", `ui_prompt_${promptId}`);
  promptId++;
  document.body.appendChild(elem);

  return new Promise((resolve, reject) => {
    ReactDOM.render(<UiPrompt onAccept={onAccept} onClose={onClose} />, elem, () => {
      resolve({
        dismiss() {
          ReactDOM.unmountComponentAtNode(elem);
          elem.remove();
        }
      });
    });
  });
}
