import * as React from "react";

interface ArrowProps {
  color?: string;
  direction?: "left" | "right" | "up" | "down";
  size: string;
}

const rotateMap = {
  left: 0,
  up: 90,
  right: 180,
  down: 270
};

export const Arrow = ({ color, size, direction }: ArrowProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      transform: `rotate(${rotateMap[direction || "left"]}deg)`
    }}
    width={size}
    height={size}
    viewBox="0 0 15 14"
  >
    <path
      fill={color || "#FFF"}
      fillRule="nonzero"
      d="M7.028 13.588l.846-.867-5.37-4.976h12.314V6.547H2.512l5.361-4.933L7.03.742.074 7.145z"
    />
  </svg>
);
