import * as React from "react";
import * as cn from "classnames";

import { Cross } from "../Cross";
import { Button } from "../button";
import { RippleEffect } from "../rippleEffect";

import "./Modal.scss";
import { createPortal } from "react-dom";

interface PensaModalProps {
  header?: React.ReactNode;
  content: React.ReactNode;
  contentClassName?: string;
  isOpened: boolean;

  submitText?: string;
  submitDisabled?: boolean;
  declineText?: string;
  parentElement?: HTMLElement;

  closeOnOutsideClick?: boolean;

  onClose(): any;
  onSubmit?(): any;
  onDecline?(): any;
}

interface PensaModalState {}

export class PensaModal extends React.Component<PensaModalProps, PensaModalState> {
  openedClass = "PensaModal--shown";

  addBodyClass = () => {
    const elem = this.props.parentElement || document.body;
    elem.classList.add(this.openedClass);
  };
  removeBodyClass = () => {
    const elem = this.props.parentElement || document.body;
    elem.classList.remove(this.openedClass);
  };

  componentDidMount() {
    if (this.props.isOpened) {
      this.addBodyClass();
    }
  }

  componentDidUpdate(prevProps: PensaModalProps) {
    if (prevProps.isOpened && !this.props.isOpened) {
      this.removeBodyClass();
    } else if (!prevProps.isOpened && this.props.isOpened) {
      this.addBodyClass();
    }
  }
  componentWillUnmount() {
    this.removeBodyClass();
  }

  render() {
    const {
      header,
      isOpened,
      submitText,
      submitDisabled,
      declineText,
      content,
      contentClassName,
      closeOnOutsideClick,
      onSubmit,
      onDecline,
      onClose,
    } = this.props;
    return createPortal(
      <div
        className="PensaModal"
        style={{
          top: isOpened ? this.props.parentElement?.scrollTop || 0 : 0,
          left: isOpened ? this.props.parentElement?.scrollLeft || 0 : 0,
          width: isOpened ? "100%" : 0,
          height: isOpened ? "100%" : 0,
        }}
      >
        <div
          className="PensaModal__overlay"
          style={{
            opacity: isOpened ? 1 : 0,
          }}
          onClick={closeOnOutsideClick ? onClose : undefined}
        />
        <div
          className="PensaModal__modal"
          style={{
            height: isOpened ? "auto" : 0,
            boxShadow: isOpened ? "0 -0.2rem 1.2rem 0 rgba(0, 0, 0, 0.2)" : "none",
            transform: isOpened ? "translateY(0)" : "translateY(100%)",
          }}
        >
          <div className="PensaModal__header">
            <p className="PensaModal__headerText">{header}</p>
            <RippleEffect className="PensaModal__headerIcon" circled onClick={onClose}>
              <Cross size="1.45rem" color="black" />
            </RippleEffect>
          </div>
          <div className={cn("PensaModal__content", contentClassName)}>{content}</div>
          {(onSubmit || onDecline) && (
            <div className="PensaModal__ations">
              {onSubmit && <Button text={submitText || "Ja"} primary disabled={submitDisabled} onClick={onSubmit} />}
              {onDecline && <Button text={declineText || "Nej"} onClick={onDecline} />}
            </div>
          )}
        </div>
      </div>,
      this.props.parentElement || document.body
    );
  }
}
