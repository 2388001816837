import { createSelector } from "reselect";
import sortBy from "lodash-es/sortBy";
import groupBy from "lodash-es/groupBy";
import uniqBy from "lodash-es/uniqBy";
import flatten from "lodash-es/flatten";
import sumBy from "lodash-es/sumBy";
import get from "lodash-es/get";

import {
  YourMoneyTableData,
  PensionPlanKind,
  ScrapingSummarySumsItem,
  ScrapingSummaryHistoryPoint
} from "@common/models";
import { scrapingResultSelector } from "@state/scraping/selectors";

export const pensionPlansSelector = createSelector([scrapingResultSelector], data =>
  data ? data.pension_plans || [] : []
);

const summaryCapitalSelector = createSelector([scrapingResultSelector], data =>
  data ? (get(data, "summary.sums.capital", undefined) as ScrapingSummarySumsItem) : undefined
);

export const totalCapitalSelector = createSelector([summaryCapitalSelector], capital => capital && capital.total);

export const yourMoneyTableDataSelector = createSelector(
  [pensionPlansSelector, summaryCapitalSelector],
  (pensionPlans, capitals) => {
    if (!(pensionPlans.length && capitals)) {
      return [];
    }
    const result: YourMoneyTableData[] = [];

    const pensionPlansByKind = groupBy(pensionPlans, pPlan => pPlan.kind);

    Object.keys(pensionPlansByKind).forEach((kind: PensionPlanKind) => {
      const byPensionIntitute = groupBy(pensionPlansByKind[kind], p => p.pension_institute);

      result.push({
        kind,
        total: capitals[kind],
        pensionInstitutes: sortBy(Object.keys(byPensionIntitute))
          .map(name => {
            return {
              name,
              insurances: sortBy(
                byPensionIntitute[name]
                  .map(bpi => {
                    return {
                      name: bpi.insurance_name,
                      total: bpi.total_valuation,
                      funds: sortBy(
                        [
                          ...(bpi.funds
                            ? bpi.funds!.map(fund => ({
                                name: fund.minpension_name,
                                value: fund.total_valuation
                              }))
                            : []),
                          ...(bpi.unidentified_funds
                            ? bpi.unidentified_funds!.map(fund => ({
                                name: fund.minpension_name,
                                value: fund.total_valuation
                              }))
                            : [])
                        ],
                        "name"
                      ).filter(fund => !!fund.value)
                    };
                  })
                  .filter(fund => !!fund.total),
                "name"
              )
            };
          })
          .filter(pi => !!pi.insurances.length)
      } as YourMoneyTableData);
    });

    return result;
  }
);

export const monthlyIncomeSelector = createSelector([scrapingResultSelector], data =>
  data ? data.gross_monthly_income : null
);
export const collectiveAgreementSelector = createSelector([scrapingResultSelector], data =>
  data ? data.collective_agreement : "N/A"
);
export const pensionPrognosisGradeSelector = createSelector([scrapingResultSelector], data =>
  data && data.prognosis.minpension ? data.prognosis.minpension.grade : undefined
);

export const allFundsSelector = createSelector([pensionPlansSelector], pensionPlans => {
  return {
    funds: flatten(pensionPlans.map(pPlan => pPlan.funds || [])),
    unidentifiedFunds: flatten(pensionPlans.map(pPlan => pPlan.unidentified_funds || []))
  };
});

export const uniqFundsSelector = createSelector([allFundsSelector], ({ funds, unidentifiedFunds }) => ({
  funds: uniqBy(funds, "minpension_name"),
  unidentifiedFunds: uniqBy(unidentifiedFunds, "minpension_name")
}));

export const fundUpdateDateSelector = createSelector([uniqFundsSelector], ({ funds }) => {
  const beginningOfTheMonth = new Date();
  beginningOfTheMonth.setDate(1);
  const orderedFunds = funds
    // fund has updated date and has data from morningstar
    .filter(f => !!f.updated_date && !!f.oneMonth_stats)
    .sort((f1, f2) => new Date(f2.updated_date).getTime() - new Date(f1.updated_date).getTime());
  return orderedFunds.length ? new Date(orderedFunds[0].updated_date) : beginningOfTheMonth;
});

export interface UnidentifiedFundData {
  name: string;
  totalValuation: number;
}
export interface IdentifiedFundData extends UnidentifiedFundData {
  meanReturn: number | null;
  ytdMeanReturn: number | null;
  monthlyChange: number | null;
  ytdChange: number | null;
}

export interface ShowMoreFundsData {
  funds: IdentifiedFundData[];
  unidentifiedFunds: UnidentifiedFundData[];
}

export const showMoreFundsSelector = createSelector([uniqFundsSelector], ({ funds, unidentifiedFunds }) => ({
  funds: sortBy(
    funds
      .filter(fund => !!fund.total_valuation)
      .map(f => ({
        name: f.minpension_name,
        meanReturn: f["oneMonth_stats"] ? f["oneMonth_stats"].mean_return : null,
        monthlyChange: f["oneMonth_stats"]
          ? Math.round((f["oneMonth_stats"].mean_return * f.total_valuation) / 100)
          : null,
        ytdMeanReturn: f["ytd_stats"] ? f["ytd_stats"].mean_return : null,
        ytdChange: f["ytd_stats"] ? Math.round((f["ytd_stats"].mean_return * f.total_valuation) / 100) : null,
        totalValuation: f.total_valuation
      })),
    "name"
  ),
  unidentifiedFunds: sortBy(
    unidentifiedFunds
      .map(f => ({
        name: f.minpension_name,
        totalValuation: f.total_valuation
      }))
      .filter(fund => !!fund.totalValuation),
    "name"
  )
}));

export interface FundPerformanceSummary {
  avgMeanReturn: number;
  ytdAvgMeanReturn: number;
  valuation: number;
  valuationChange: number;
  ytdValuationChange: number;
  updateDate: Date | null;
  monthName: string;
  year: string;
}

export const fundPerformanceSummarySelector = createSelector(
  [showMoreFundsSelector, fundUpdateDateSelector],
  ({ funds, unidentifiedFunds }, updateDate) => {
    const filtered = funds.filter(f => !!f.meanReturn);
    const filteredYtd = funds.filter(f => !!f.ytdMeanReturn);
    const filteredValuation = sumBy(filtered, "totalValuation");
    const filteredYtdValuation = sumBy(filteredYtd, "totalValuation");
    const avgMeanReturn =
      Math.round(
        filtered.reduce((prev, curr) => prev + (curr.totalValuation / filteredValuation) * curr.meanReturn!, 0) * 10
      ) / 10;

    const ytdAvgMeanReturn =
      Math.round(
        filteredYtd.reduce(
          (prev, curr) => prev + (curr.totalValuation / filteredYtdValuation) * curr.ytdMeanReturn!,
          0
        ) * 10
      ) / 10;

    const identifiedFundsValuation = sumBy(funds, "totalValuation");
    const unidentifiedFundsValuation = sumBy(unidentifiedFunds, "totalValuation");
    const valuationChange = Math.round(
      filtered.reduce<number>((prev, curr) => prev + curr.meanReturn! * curr.totalValuation, 0) / 100
    );
    const ytdValuationChange = Math.round(
      filteredYtd.reduce<number>((prev, curr) => prev + curr.ytdMeanReturn! * curr.totalValuation, 0) / 100
    );

    let monthName;
    let year;
    if (updateDate) {
      const prevMonth = new Date(updateDate);
      prevMonth.setMonth(updateDate.getMonth() - 1);
      monthName = prevMonth.toLocaleDateString("sv", { month: "short" });
      year = prevMonth.toLocaleString("sv", { year: "numeric" });
    } else {
      monthName = "Ingen info";
      year = "Ingen info";
    }

    return {
      avgMeanReturn,
      ytdAvgMeanReturn,
      valuationChange,
      ytdValuationChange,
      valuation: identifiedFundsValuation + unidentifiedFundsValuation,
      updateDate,
      monthName,
      year
    } as FundPerformanceSummary;
  }
);

export const pensionRevisionDateSelector = createSelector([scrapingResultSelector], data => {
  const date = data ? new Date(data.pension_data_date) : null;
  return (
    date &&
    date.toLocaleDateString("sv", {
      month: "numeric",
      year: "numeric",
      day: "numeric"
    })
  );
});

const historySelector = createSelector(
  [scrapingResultSelector],
  data => get(data, "summary.history.total_valuations", []) as ScrapingSummaryHistoryPoint[]
);

const maxPoints = 25;

export type YourMoneyChartDataPoint = { date: number; value: number };

function filterOutPoints(points: YourMoneyChartDataPoint[]): YourMoneyChartDataPoint[] {
  if (!points.length) {
    return [];
  }
  const min = points[0].date;
  const max = points[points.length - 1].date;
  const step = (max > min ? max - min : min - max) / maxPoints;
  let prev = 0;
  return points.filter(point => {
    // always include last point
    if (point.date > prev + step && (point.date < max - step || point.date === max)) {
      prev = point.date;
      return true;
    }
    return false;
  });
}

export const yourMoneyChartDataSelector = createSelector([historySelector], data =>
  filterOutPoints(
    data.map(point => ({
      ...point,
      date: new Date(point.date).getTime()
    }))
  )
);
