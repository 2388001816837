import { put, call, select, all } from "redux-saga/effects";
import { push } from "connected-react-router";

import { pegTryggaKollenRequest, getTryggakollenDataRequest, getUserInfoRequest } from "@common/requests";
import { AppState } from "@state/store";
import { getScrapingSummarySaga } from "@state/scraping";
import { getUserInfoSaga } from "@state/user";
import { SummaryRoute } from "@common/routes";
import { responseErrorAction } from "@state/error";
import { trackHeapEvent, HeapEventNames } from "@state/analytics";

export function* pegTryggaKollenSaga(userId: string) {
  yield put(pegTryggaKollenRequest.actions.running({ userId }));
  const response: typeof pegTryggaKollenRequest.types.response = yield call(pegTryggaKollenRequest, { userId }, {});
  if (response.ok) {
    yield put(pegTryggaKollenRequest.actions.ok({ userId }, response));
  } else {
    yield put(pegTryggaKollenRequest.actions.error({ userId }, response));
    yield put(responseErrorAction({ response, hideBtn: true }));
  }
  return response;
}

export function* prepareSummarySaga() {
  const user = yield select<AppState>(state => state.user.data);

  // if tryggakollen data is not pegged
  if (!user.is_tryggakollen_analysis_pegged) {
    // peg tryggakollen data
    const pegResp: typeof pegTryggaKollenRequest.types.response = yield call(pegTryggaKollenSaga, user._id);
    if (!pegResp.ok) {
      return;
    }
  }
  // fetch tryggakollen data
  const [summaryResp, userResp]: [
    typeof getTryggakollenDataRequest.types.response,
    typeof getUserInfoRequest.types.response
  ] = yield all([call(getScrapingSummarySaga, user._id), call(getUserInfoSaga, { id: user._id })]);
  // take user to summary/home page
  if (summaryResp.ok && userResp.ok) {
    trackHeapEvent(HeapEventNames.SummaryPrepared);
    yield put(push(SummaryRoute.format({})));
  }
}
