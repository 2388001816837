import { AppState } from "@state/store";
import { createSelector, Selector } from "reselect";

import groupBy from "lodash-es/groupBy";

import { PensionPlanData, PensionWithPensionPlans, getTotalPension } from "@common/models";

export const isLoadingDataSelector = (state: AppState) =>
  state.requests.getMinpensionData.loading ||
  state.requests.postMinpensionData.loading ||
  state.requests.postProcessMinpensionData.loading;

export const allmanPensionDataSelector = (state: AppState) => state.minpensionData.data?.allmanPension;

const getPlansSelector = (dataSelector: Selector<AppState, PensionWithPensionPlans | undefined>) => {
  return createSelector(dataSelector, (data) => data?.pensionPlans || []);
};
const getGroupedPlansSelector = (dataSelector: Selector<AppState, PensionPlanData[]>) => {
  return createSelector(dataSelector, (plans) => {
    const grouped = groupBy(plans, "pension_institute");
    const result: { pension_institute: string; plans: PensionPlanData[] }[] = [];
    for (const pi in grouped) {
      if (!grouped.hasOwnProperty(pi)) {
        continue;
      }
      result.push({ pension_institute: pi, plans: grouped[pi] });
    }
    return result.sort((a, b) => (a.pension_institute > b.pension_institute ? 1 : -1));
  });
};

export const tjanstePensionDataSelector = (state: AppState) => state.minpensionData.data?.tjanstePension;
export const tjanstePensionPlansSelector = getPlansSelector(tjanstePensionDataSelector);
export const tjanstePensionGroupedPlansSelector = getGroupedPlansSelector(tjanstePensionPlansSelector);

export const privatPensionDataSelector = (state: AppState) => state.minpensionData.data?.privatPension;
export const privatPensionPlansSelector = getPlansSelector(privatPensionDataSelector);
export const privatPensionGroupedPlansSelector = getGroupedPlansSelector(privatPensionPlansSelector);

export const pensionInstitutesSelector = (state: AppState) => state.minpensionData.pensionInstitutes;
export const pensionInstituteOptionsSelector = createSelector(pensionInstitutesSelector, (institutes) =>
  institutes.map((i) => ({ value: i, label: i }))
);

export const isTotalPensionValidSelector = (state: AppState) => {
  return (
    Math.abs(
      (state.minpensionData.data?.totalPension || 0) -
        (state.minpensionData.data ? getTotalPension(state.minpensionData.data) : 0)
    ) < 50000
  );
};

export const newPensionPlanSelector = (state: AppState) => {
  return state.minpensionData.newPensionPlan;
};
export const isNewPensionPlanEmptySelector = createSelector(newPensionPlanSelector, (data) => {
  return !(
    data.annuity ||
    data.capital ||
    data.funds?.length ||
    data.insurance_number ||
    data.insurance_shell ||
    data.insurance_type ||
    data.pension_institute ||
    data.management_type
  );
});
