import * as React from "react";
import * as cn from "classnames";

import { icons } from "@assets";

import "./InfoItem.scss";

interface InfoItemProps {
  text: React.ReactNode;
  bold?: boolean;
  exclamation?: boolean;
}

export const InfoItem = ({ text, bold, exclamation }: InfoItemProps) => {
  return (
    <div className={cn("InfoItem", { "InfoItem--bold": bold, "InfoItem--exclamation": exclamation })}>
      <img className="InfoItem__image" src={icons.info} alt="" />
      <div className="InfoItem__text">{text}</div>
    </div>
  );
};
