import * as React from "react";
import { connect } from "react-redux";

import { AppState } from "@state/store";
import { isRetiredUserSelector } from "@state/user";
import { BasicScoringModel } from "@common/models";
import { currentScoresSelector } from "@state/todoItem";
import { StarsWithScore, MeterItem, InfoItem } from "@components/common";
import { MeterType } from "@common/types";

import "./ShowMoreMeter.scss";

interface ShowMoreMeterProps {
  isRetired: boolean | undefined;
  scoring: BasicScoringModel;
}

export const ShowMoreMeterAtom = ({ scoring, isRetired }: ShowMoreMeterProps) => {
  return (
    <React.Fragment>
      <div className="ShowMoreMeter__overall">
        <h5 className="ShowMoreMeter__overallTitle">Ditt snittbetyg</h5>
        <StarsWithScore score={scoring.total} centered />
      </div>
      <InfoItem text="Hur många stjärnor du får räknas ut utifrån tre olika värden som är viktiga för din pension." />
      {!isRetired && <MeterItem type={MeterType.Savings} score={scoring.savings} />}
      <MeterItem type={MeterType.Funds} score={scoring.quality} />
      <MeterItem type={MeterType.Risk} score={scoring.risk} />
    </React.Fragment>
  );
};

export const ShowMoreMeter = connect((state: AppState) => ({
  isRetired: isRetiredUserSelector(state),
  scoring: currentScoresSelector(state)
}))(ShowMoreMeterAtom);
