import { Action } from "redux";

import { HomeState, defaultHomeState } from "./state";
import {
  homeSlideChangeAction,
  toggleFreeUserModalAction,
  moneyChartPointSelectAction,
  toggleSidebarMenuAction
} from "./actions";

export function homeReducer(state: HomeState = defaultHomeState, action: Action): HomeState {
  if (homeSlideChangeAction.is(action)) {
    return {
      ...state,
      currentSlide: action.newSlideNumber
    };
  }

  if (moneyChartPointSelectAction.is(action)) {
    return {
      ...state,
      selectedPoint: action.selectedPoint
    };
  }

  if (toggleSidebarMenuAction.is(action)) {
    return {
      ...state,
      isSidebarOpen: !state.isSidebarOpen
    };
  }

  if (toggleFreeUserModalAction.is(action)) {
    return {
      ...state,
      isModalOpen: !state.isModalOpen
    };
  }

  return state;
}
