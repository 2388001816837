import * as React from "react";
import sumBy from "lodash-es/sumBy";

import { YourMoneyTableData, planKindDisplayName } from "@common/models";

import "./YourMoneyItem.scss";

interface YourMoneyItemProps {
  data: YourMoneyTableData;
}

interface YourMoneyTotalItemProps {
  data: YourMoneyTableData[];
}

interface YourMoneyItemRowProps {
  name: string;
  valuation: number;
}

export const YourMoneyItem = ({ data: { kind, total, pensionInstitutes } }: YourMoneyItemProps) => {
  return (
    <div className="YourMoneyItem">
      <YourMoneyItemHeader name={planKindDisplayName[kind]} valuation={total} />
      <div className="YourMoneyItem__body">
        {pensionInstitutes.map(pi => (
          <div key={`YourMoneyItem__institute_${pi.name}`} className="YourMoneyItem__institute">
            <h4 className="YourMoneyItem__instituteName">{pi.name}</h4>
            {pi.insurances.map((insurance, index) => (
              <div key={`YourMoneyItem__insurance_${insurance.name}_${index}`} className="YourMoneyItem__insurance">
                <YourMoneyItemRow name={insurance.name} valuation={insurance.total} />
                {insurance.funds.length > 0 && (
                  <div className="YourMoneyItem__funds">
                    {insurance.funds.map(fund => (
                      <YourMoneyItemFundRow
                        key={`YourMoneyItem__fund_${fund.name}`}
                        name={fund.name}
                        valuation={fund.value}
                      />
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export const YourMoneyTotalItem = ({ data }: YourMoneyTotalItemProps) => {
  const totalValuation = sumBy(data, "total");
  return (
    <div className="YourMoneyItem YourMoneyTotalItem">
      <YourMoneyItemHeader name="Total Kapital" valuation={totalValuation} />
      <div className="YourMoneyItem__body">
        {data.map(item => (
          <YourMoneyItemRow
            key={`YourMoneyItemRow_${item.kind}`}
            name={planKindDisplayName[item.kind]}
            valuation={item.total}
          />
        ))}
      </div>
    </div>
  );
};

export const YourMoneyItemHeader = ({ name, valuation }: YourMoneyItemRowProps) => {
  return (
    <div className="YourMoneyItem__header">
      <p className="YourMoneyItem__headerName">{name}</p>
      <p className="YourMoneyItem__headerValuation">{`${valuation.toLocaleString("sv")} kr`}</p>
    </div>
  );
};

export const YourMoneyItemRow = ({ name, valuation }: YourMoneyItemRowProps) => {
  return (
    <div className="YourMoneyItem__row">
      <p className="YourMoneyItem__rowName">{name}</p>
      <p className="YourMoneyItem__rowValuation">{`${valuation.toLocaleString("sv")} kr`}</p>
    </div>
  );
};

export const YourMoneyItemFundRow = ({ name, valuation }: YourMoneyItemRowProps) => {
  return (
    <div className="YourMoneyItem__fundRow">
      <p className="YourMoneyItem__fundRowName">{name}</p>
      <p className="YourMoneyItem__fundRowValuation">{`${valuation.toLocaleString("sv")} kr`}</p>
    </div>
  );
};
