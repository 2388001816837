export enum PensionPlanKind {
  Allmänpension = "allmänpension",
  Tjänstepension = "tjänstepension",
  Privatpension = "privatpension"
}

export const planKindDisplayName = {
  [PensionPlanKind.Allmänpension]: "Allmänpension",
  [PensionPlanKind.Tjänstepension]: "Tjänstepension",
  [PensionPlanKind.Privatpension]: "Privatpension"
};

export interface YourMoneyTableData {
  kind: PensionPlanKind;
  total: number;
  pensionInstitutes: [
    {
      name: string;
      insurances: [
        {
          name: string;
          total: number;
          funds: [
            {
              name: string;
              value: number;
            }
          ];
        }
      ];
    }
  ];
}
