import * as React from "react";
import { connect } from "react-redux";
import { useRef, useEffect, useState } from "react";

import { Layout, LoadingOverlay, Button, ArrowBig } from "@components/common";
import { AppState } from "@state/store";
import { insuranceApplicationReceiptUrlSelector } from "@state/changeSavings";

import "./InsuranceApplicationReceipt.scss";

interface InsuranceApplicationReceiptProps {
  url?: string;
  applicationId?: string;
  isLoading: boolean;

  onConfirm(): any;
  onGoBack(): any;
}

export const InsuranceApplicationReceipt = connect((state: AppState) => ({
  url: insuranceApplicationReceiptUrlSelector(state),
  applicationId: state.changeSavings.insuranceApplicationResponse?.applicationId,
  isLoading: false
}))(({ url, isLoading, onConfirm, onGoBack }: InsuranceApplicationReceiptProps) => {
  const objectRef = useRef<HTMLObjectElement>(null);
  const [pdfViewed, setPdfViewed] = useState(false);

  useEffect(() => {
    const pdfDocument = objectRef.current;
    let timeoutID: any = undefined;
    let app: any = null;

    if (pdfDocument) {
      const waitForApp = () => {
        app = (pdfDocument.contentWindow as any).PDFViewerApplication;
        if (!app?.initialized) {
          timeoutID = setTimeout(() => waitForApp(), 100);
        } else {
          const onPageChange = function(e: any) {
            if (e.pageNumber >= 7) {
              app.eventBus.off("pagechange", onPageChange);
              setPdfViewed(true);
            }
          };
          app.eventBus.on("pagechange", onPageChange);
        }
      };

      waitForApp();
    }

    return () => timeoutID && clearTimeout(timeoutID);
  }, [url]);

  return (
    <Layout title="Signering" contentClassName="InsuranceApplicationReceipt" onGoBack={onGoBack}>
      {!pdfViewed && <ArrowBig size="5rem" color="#ff3366" direction="down" />}
      {url && <object ref={objectRef} className="PdfPreviewObject" data={url} width="100%" />}
      <Button text="Signera" onClick={onConfirm} primary disabled={!pdfViewed} />
      <LoadingOverlay isShown={isLoading || !url} />
    </Layout>
  );
});
