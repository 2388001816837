import * as React from "react";
import * as cn from "classnames";

import { RippleEffect } from "@components/common";
import { TodoItemType, MeterType } from "@common/types";
import { TodoItemModel } from "@common/models";
import { icons } from "@assets";

import "./TodoItem.scss";

interface TodoItemProps {
  item: TodoItemModel;

  className?: string;
  completed?: boolean;

  onClick(): any;
  onAnimationEnd(): any;
}

const getImage = (item: TodoItemModel) => {
  switch (item.type) {
    case TodoItemType.ChangeFunds:
      return item.payload.isRebalancing ? icons.balance : icons.change;
    case TodoItemType.ChangeSavings:
      return icons.save;
    case TodoItemType.UpdatePensionProfile:
      return icons.save;
    case TodoItemType.UpdatePensionAnalysis:
      return icons.change;
    case TodoItemType.LifeInsurance:
    case TodoItemType.SafetyInsurance:
      return icons.funds;
    case TodoItemType.LifeInsuranceUpsell:
    case TodoItemType.SafetyInsuranceUpsell:
      return icons.trendingUp;
    case TodoItemType.FreeUserMock:
      return item.detailsType === MeterType.Savings ? icons.save : icons.change;
    default:
      return;
  }
};

export const TodoItem = ({ item, completed, onClick, onAnimationEnd }: TodoItemProps) => {
  const { title, description } = item;
  return (
    <RippleEffect
      className={cn("TodoItem", { "TodoItem--completed": completed })}
      onClick={() => !completed && onClick()}
      onAnimationEnd={e => e.nativeEvent.animationName === "disappear" && onAnimationEnd()}
    >
      <img className="TodoItem__icon" src={getImage(item)} alt="" />
      <div className="TodoItem__info">
        <h5 className="TodoItem__title" title={title}>
          {title}
        </h5>
        <p className="TodoItem__description" title={description}>
          {description}
        </p>
      </div>
    </RippleEffect>
  );
};
