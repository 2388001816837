import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { push } from "connected-react-router";

import { TodoItemModel } from "@common/models";
import { getItemKey } from "@common/helpers";
import { AppState } from "@state/store";
import {
  homeScreenTodoItemListSelector,
  recentCompletedItemSelector,
  clearCompletedItemAction,
  setTodoItemAction
} from "@state/todoItem";
import { MeterType } from "@common/types";
import { FreeTodoItemRoute } from "@common/routes";
import { icons } from "@assets";

import { TodoItem } from "./todoItem";

import "./TodoItemList.scss";

interface TodoItemListComponentProps {
  items: TodoItemModel[];
  recentCompletedItem: TodoItemModel | undefined;

  onSetTodoItem(item: TodoItemModel): any;
  onGoToFreeTodoItem(type: MeterType): any;
  onAnimationEnd(): any;
}

export class TodoItemListComponent extends React.Component<TodoItemListComponentProps, {}> {
  render() {
    const { items, recentCompletedItem, onAnimationEnd, onSetTodoItem } = this.props;

    return (
      <section className="TodoItemList">
        <div className="TodoItemList__header">
          <h3 className="TodoItemList__title">Att-göra listan</h3>
          <p className="TodoItemList__itemsCount">
            {!!items.length && `${items.length} ${items.length === 1 ? "åtgärd" : "åtgärder"}`}
          </p>
        </div>
        {!!items.length ? (
          items.map((item, index) => (
            <TodoItem
              key={getItemKey(item)}
              item={item}
              completed={recentCompletedItem && item.id === recentCompletedItem.id}
              onClick={() => onSetTodoItem(item)}
              onAnimationEnd={() => {
                onAnimationEnd();
                this.setState({ recentCompleted: undefined });
              }}
            />
          ))
        ) : (
          <div className="TodoItemList__emptyList">
            <img src={icons.todoList} alt="" />
            <h5 className="TodoItemList__emptyListTitle">Inget på listan nu. </h5>
            <p className="TodoItemList__emptyListDescription">
              Du blir meddelad när det finns nya saker på Att-göra listan.
            </p>
          </div>
        )}
      </section>
    );
  }
}

export const TodoItemList = connect(
  (state: AppState) => ({
    items: homeScreenTodoItemListSelector(state),
    recentCompletedItem: recentCompletedItemSelector(state)
  }),
  (dispatch: Dispatch) => ({
    onSetTodoItem(item: TodoItemModel) {
      dispatch(setTodoItemAction({ item }));
    },
    onGoToFreeTodoItem(type: MeterType) {
      dispatch(push(FreeTodoItemRoute.format({ type })));
    },
    onAnimationEnd() {
      dispatch(clearCompletedItemAction({}));
    }
  })
)(TodoItemListComponent);
