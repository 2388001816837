import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { push } from "connected-react-router";
import Sidebar from "react-sidebar";

import { AppState } from "@state/store";
import { RippleEffect } from "@components/common";
import { icons } from "@assets";
import { newNotificationsCountSelector } from "@state/notifications";
import { NotificationsRoute } from "@common/routes";
import { toggleSidebarMenuAction } from "@state/home";

import { TodoItemList } from "./todoItemList";
import { Carousel } from "./carousel";
import { HomeSidebar } from "./sidebar";

import "./Home.scss";

interface HomeComponentProps {
  newNotificationsCount: number;
  isSidebarOpen: boolean;

  onNotificationsClick(): any;
  onToggleSidebar(): any;
}

const HomeComponent = ({
  isSidebarOpen,
  newNotificationsCount,
  onToggleSidebar,
  onNotificationsClick
}: HomeComponentProps) => {
  return (
    <Sidebar
      open={isSidebarOpen}
      onSetOpen={onToggleSidebar}
      sidebar={<HomeSidebar />}
      styles={{
        sidebar: {
          zIndex: "11"
        },
        overlay: {
          zIndex: "10"
        }
      }}
    >
      <div className="Home">
        <aside className="Home__menu">
          <RippleEffect className="Home__menuItem" circled onClick={onToggleSidebar}>
            <img src={icons.menu} alt="" />
          </RippleEffect>
          <img className="Home__logo" src={icons.logoPensionskraft} alt="" />
          <RippleEffect className="Home__menuItem" circled onClick={onNotificationsClick}>
            <img src={icons.bell} alt="" />
            {newNotificationsCount > 0 && <div className="Home__newNotifications" />}
          </RippleEffect>
        </aside>
        <main>
          <Carousel />
          <TodoItemList />
        </main>
      </div>
    </Sidebar>
  );
};

export const Home = connect(
  (state: AppState) => ({
    newNotificationsCount: newNotificationsCountSelector(state),
    isSidebarOpen: state.home.isSidebarOpen
  }),
  (dispatch: Dispatch) => ({
    onNotificationsClick() {
      dispatch(push(NotificationsRoute.format({})));
    },
    onToggleSidebar() {
      dispatch(toggleSidebarMenuAction({}));
    }
  })
)(HomeComponent);
