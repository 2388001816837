import { MinpensionData, PensionPlanData } from "@common/models";

export interface MinpensionDataState {
  data?: MinpensionData;
  wasChanged: boolean;
  pensionInstitutes: string[];
  newPensionPlan: Partial<PensionPlanData>;
}

export const defaultMinpensionDataState: MinpensionDataState = {
  pensionInstitutes: [],
  wasChanged: false,
  newPensionPlan: {},
};
