import * as React from "react";
import * as cn from "classnames";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import { push } from "connected-react-router";

import { icons } from "@assets";
import { isMobile, getUseMobileAnyway, setUseMobileAnyway, isSafari } from "@common/helpers";
import { WithInnerHeight, LoadingOverlay, RippleEffect, PensaModal } from "@components/common";
import { MinpensionDataSteps } from "@common/models";
import {
  getMinpensionDataAction,
  isLoadingDataSelector,
  getPensionInstitutesAction,
  dataCollectionCompletedAction,
  isTotalPensionValidSelector,
  isNewPensionPlanEmptySelector,
  updateNewPensionPlanAction,
} from "@state/minpensionData";
import { MinpensionDataRoute, MinpensionDataHelpRoute } from "@common/routes";

import { RecommendationToUseDesktop } from "./recommendationToUseDesktop";
import {
  LoginInstructions,
  CollectionStatus,
  AllmanPensionInfo,
  AllmanPension,
  TjanstePensionInfo,
  TjanstePension,
  PrivatPensionInfo,
  PrivatPension,
  TotalPension,
} from "./instructions";
import { MinpensionDataHelp } from "./help";

import "./MinpensionData.scss";

interface NotSavedModalData {
  isOpened: boolean;
  onDecline?(): void;
}

export const MinpensionData = () => {
  const [useMobile, setUseMobile] = useState(getUseMobileAnyway());
  const [isCheckModalOpened, setCheckModalOpened] = useState(false);
  const [notSavedModal, setNotSavedModal] = useState<NotSavedModalData>({ isOpened: false });
  const isLoadingData = useSelector(isLoadingDataSelector);

  const isTotalPensionValid = useSelector(isTotalPensionValidSelector);
  const isNewPensionPlanEmpty = useSelector(isNewPensionPlanEmptySelector);

  const { step } = useParams<{ step: MinpensionDataSteps }>();
  const location = useLocation();
  const routeMatch = MinpensionDataRoute.match(location);
  const backRedirect = routeMatch.isMatched && routeMatch.query?.backRedirect;
  const isHelpRoute = MinpensionDataHelpRoute.match(location).isMatched;

  const dispatch = useDispatch();

  const onStepChange = (step: MinpensionDataSteps, checkIfSaved?: boolean) => {
    if (checkIfSaved && !isNewPensionPlanEmpty) {
      setNotSavedModal({
        isOpened: true,
        onDecline: () => dispatch(push(MinpensionDataRoute.format({ step }, { backRedirect }))),
      });
    } else {
      dispatch(push(MinpensionDataRoute.format({ step }, { backRedirect })));
    }
  };

  const onCheckTotalPension = () => {
    if (!isTotalPensionValid) {
      setCheckModalOpened(true);
    } else {
      onCompleted();
    }
  };

  const onTakeMeToResults = () => {
    if (!isNewPensionPlanEmpty) {
      setNotSavedModal({
        isOpened: true,
        onDecline: onCheckTotalPension,
      });
    } else {
      onCheckTotalPension();
    }
  };
  const onCompleted = () => dispatch(dataCollectionCompletedAction({}));

  useEffect(() => {
    dispatch(getMinpensionDataAction({}));
    dispatch(getPensionInstitutesAction({}));
  }, []);

  const insturctionsEl = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (insturctionsEl) {
      insturctionsEl.current?.scrollTo && insturctionsEl.current?.scrollTo({ top: 0 });
    }
  }, [step, insturctionsEl]);

  const [iframeUrl] = useState(
    step === MinpensionDataSteps.Login ? "https://www.minpension.se/inloggning" : "https://www.minpension.se/mina-sidor"
  );

  if (isMobile() && !useMobile) {
    return (
      <RecommendationToUseDesktop
        onUseMobileAnyway={() => {
          setUseMobileAnyway();
          setUseMobile(true);
        }}
      />
    );
  }

  const isFullScreen = isMobile() || isSafari;

  return (
    <WithInnerHeight limitHeight>
      <main className={cn("MinpensionData", { "MinpensionData--fullscreen": isFullScreen })}>
        {!isFullScreen && (
          <section className="MinpensionData__iframe">
            <iframe src={iframeUrl} frameBorder="0" width="100%" height="100%" />
          </section>
        )}
        <aside className="MinpensionData__instructions" ref={insturctionsEl}>
          {isHelpRoute ? (
            <MinpensionDataHelp />
          ) : (
            <>
              <RippleEffect
                className="MinpensionData__help"
                circled
                onClick={() =>
                  dispatch(
                    push(MinpensionDataHelpRoute.format({ step: step as MinpensionDataSteps }, { backRedirect }))
                  )
                }
              >
                <img src={icons.helpDark} />
              </RippleEffect>
              {step === MinpensionDataSteps.Login && (
                <LoginInstructions
                  onNext={() => onStepChange(MinpensionDataSteps.CollectionStatus)}
                  onBack={backRedirect ? () => dispatch(push(backRedirect)) : undefined}
                />
              )}

              {step === MinpensionDataSteps.CollectionStatus && (
                <CollectionStatus
                  onNext={() => onStepChange(MinpensionDataSteps.TotalPension)}
                  onBack={() => onStepChange(MinpensionDataSteps.Login)}
                />
              )}

              {step === MinpensionDataSteps.TotalPension && (
                <TotalPension
                  onNext={() => onStepChange(MinpensionDataSteps.AllmanPensionInfo)}
                  onBack={() => onStepChange(MinpensionDataSteps.CollectionStatus)}
                />
              )}

              {step === MinpensionDataSteps.AllmanPensionInfo && (
                <AllmanPensionInfo
                  onNext={() => onStepChange(MinpensionDataSteps.AllmanPension)}
                  onBack={() => onStepChange(MinpensionDataSteps.TotalPension)}
                />
              )}

              {step === MinpensionDataSteps.AllmanPension && (
                <AllmanPension
                  onNext={() => onStepChange(MinpensionDataSteps.TjanstePensionInfo)}
                  onBack={() => onStepChange(MinpensionDataSteps.AllmanPensionInfo)}
                />
              )}

              {step === MinpensionDataSteps.TjanstePensionInfo && (
                <TjanstePensionInfo
                  onNext={() => onStepChange(MinpensionDataSteps.TjanstePension)}
                  onBack={() => onStepChange(MinpensionDataSteps.AllmanPension)}
                />
              )}

              {step === MinpensionDataSteps.TjanstePension && (
                <TjanstePension
                  onNext={() => onStepChange(MinpensionDataSteps.PrivatPensionInfo, true)}
                  onBack={() => onStepChange(MinpensionDataSteps.TjanstePensionInfo, true)}
                />
              )}

              {step === MinpensionDataSteps.PrivatPensionInfo && (
                <PrivatPensionInfo
                  onNext={() => onStepChange(MinpensionDataSteps.PrivatPension)}
                  onBack={() => onStepChange(MinpensionDataSteps.TjanstePension)}
                />
              )}

              {step === MinpensionDataSteps.PrivatPension && (
                <PrivatPension
                  onNext={onTakeMeToResults}
                  onBack={() => onStepChange(MinpensionDataSteps.PrivatPensionInfo, true)}
                />
              )}
            </>
          )}
        </aside>
      </main>

      <LoadingOverlay isShown={isLoadingData} />
      <PensaModal
        isOpened={isCheckModalOpened}
        parentElement={insturctionsEl.current!}
        content={
          <>
            Summan av dina olika pensioner stämmer inte. Vi rekommenderar att du går igenom den igen och ser att du
            angivit det korrekt.
          </>
        }
        submitText="Ok"
        onSubmit={() => setCheckModalOpened(false)}
        declineText="Gå vidare ändå"
        onDecline={onCompleted}
        onClose={() => setCheckModalOpened(false)}
      />
      <PensaModal
        isOpened={notSavedModal.isOpened}
        parentElement={insturctionsEl.current!}
        content={<>Du har ett pensionsinnehav som inte är sparat.</>}
        submitText="Gå tillbaka"
        onSubmit={() => setNotSavedModal({ isOpened: false })}
        declineText="Gå vidare utan att spara"
        onDecline={() => {
          notSavedModal.onDecline && notSavedModal.onDecline();
          setNotSavedModal({ isOpened: false });
          dispatch(updateNewPensionPlanAction({ data: {} }));
        }}
        onClose={() => setNotSavedModal({ isOpened: false })}
      />
    </WithInnerHeight>
  );
};
