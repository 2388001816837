import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { push } from "connected-react-router";

import { TodoItemModel, BasicScoringModel } from "@common/models";
import { selectedTodoItemSelector, currentScoresSelector } from "@state/todoItem";
import { AppState } from "@state/store";
import { TodoItemBenefitsDetailsRoute } from "@common/routes";

import { StarsWithScore } from "../starsWithScore";
import { ShowMoreLink } from "../showMoreLink";

import "./TodoItemIncrease.scss";

interface TodoItemIncreaseProps {
  item: TodoItemModel | undefined;
  currentScore: BasicScoringModel;
  withLink?: boolean;

  onShowDetails?(): any;
}

export const TodoItemIncreaseAtom = ({ withLink, item, currentScore, onShowDetails }: TodoItemIncreaseProps) => {
  const delta = item && item.increase ? item.increase.total : 0;
  const improvedScore = currentScore.total + delta;
  return (
    <div className="TodoItemIncrease">
      <h2 className="TodoItemIncrease__title">
        Höjer ditt betyg med {delta.toLocaleString("sv", { maximumFractionDigits: 2, minimumFractionDigits: 2 })} till:
      </h2>
      <StarsWithScore score={improvedScore} />
      {withLink && <ShowMoreLink text="Se fördelarna för dig" color="#12121c" onClick={onShowDetails!} />}
    </div>
  );
};

export const TodoItemIncrease = connect(
  (state: AppState, ownProps: { withLink?: boolean }) => ({
    item: selectedTodoItemSelector(state),
    currentScore: currentScoresSelector(state),
    withLink: ownProps.withLink
  }),
  (dispatch: Dispatch) => ({
    onShowDetails() {
      dispatch(push(TodoItemBenefitsDetailsRoute.format({})));
    }
  })
)(TodoItemIncreaseAtom);
