import { Store } from "redux";
import { Action } from "redux";

export const analyticsLoggerMiddleware = (store: Store<any>) => {
  return (next: any) => (action: Action) => {
    const returnValue = next(action);

    trackHeapEvent(action.type);

    return returnValue;
  };
};

export function trackHeapEvent(name: string, props: { [key: string]: string } = {}) {
  const heap = (window as any).heap;

  if (heap && heap.track) {
    heap.track(name, props);
  }
}

export function identify(userId: string) {
  const heap = (window as any).heap;

  if (heap && heap.identify) {
    heap.identify(userId);
  }
}

export enum HeapEventNames {
  TodoItemSelected = "User selected todo item",
  TodoItemCompleted = "User completed todo item",
  TodoItemSkipped = "User skipped todo item",

  LoginLinkClicked = "User clicked login link",
  SummaryPrepared = "Summary screen prepared",

  StandaloneInsurancePurchase = "Standalone insurance purchase",
  StandaloneInsuranceUpsell = "Standalone insurance upsell",
  StandaloneInsuranceOfferSkipped = "Standalone insurance offer skipped"
}
