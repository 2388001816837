import { UserWithInfo } from "@common/models";

export interface UserState {
  isFetching: boolean;
  isInitialPensionProfileUpdate: boolean;
  data?: UserWithInfo;
  error?: any;
  userId?: string;
}

export const defaultUserState: UserState = {
  isFetching: false,
  isInitialPensionProfileUpdate: false,
  data: undefined,
  error: undefined
};
