import { takeLatest, put, call, select, takeEvery, take, all } from "redux-saga/effects";
import { replace, push } from "connected-react-router";
import isEmpty from "lodash-es/isEmpty";

import { prepareSummarySaga } from "@state/pegTryggakollen";
import { responseErrorAction } from "@state/error";
import { AppState } from "@state/store";
import { logInSaga, getLoginStatusAction } from "@state/auth";
import { getScrapingSummarySaga, startScraping } from "@state/scraping";
import { registerTokenAction, askForPermissionsAction } from "@state/notifications";

import {
  UserWithInfo,
  UpdatePensionProfileTodoItem,
  SavingsTodoItem,
  TodoItemModel,
  userToPensionProfileForm,
  UpdatePensionAnalysisTodoItem,
  InsuranceApplicationResponse,
  InsuranceApplicationStatus,
  MinpensionDataSteps,
} from "@common/models";
import { getLoginCode, clearLoginCode, getIntroWatched } from "@common/helpers";
import {
  getUserInfoRequest,
  whoAmIRequest,
  putFreeMembershipRequest,
  markTermsAccepted,
  getUserActiveJobsRequest,
  getCurrentApplicationRequest,
} from "@common/requests";
import {
  MinpensionDataRoute,
  HomeRoute,
  isPremiumRoute,
  LoginRoute,
  ChangeSavingsRoute,
  UpdateProfileBenefitsRoute,
  LifeInsuranceRoute,
  PensionProfileUpdateRoute,
  IntroRoute,
  SafetyInsuranceRoute,
  isInsuranceOnlyRoute,
  LifeInsuranceUpsellRoute,
  SafetyInsuranceUpsellRoute,
  InsuranceApplicationRoute,
  MinpensionDataProcessingRoute,
  // PPMConfirmationRoute
} from "@common/routes";

import fadeOutSplash from "../../fadeOutSplash";

import {
  whoAmIAction,
  premiumRouteNotPermittedAction,
  setUserIdAction,
  markTermsAcceptedAction,
  startInitialPensionProfileUpdate,
  finishInitialPensionProfileUpdate,
  intoWatchedAction,
} from "./actions";
import {
  isFreeUserSelector,
  // isPPMConfirmedSelector
} from "./selectors";
import {
  savingsTodoItemSelector,
  updatePensionProfileTodoItemSelector,
  setTodoItemAction,
  insuranceTodoItemSelector,
  updatePensionAnalysisTodoItemSelector,
} from "@state/todoItem";
import { TodoItemType } from "@common/types";
import { PensionProfileSchema } from "@components/settingsPensionProfile/Sections";
import { pensionProfileUpdateFinished } from "@state/settings";
import { identify } from "@state/analytics";
import { handleInsuranceOnlyUserSaga } from "@state/insurance";
import {
  getCurrentInsuranceApplicationSaga,
  pollSavingsSendJobAction,
  setInsuranceAplicationsStep,
  InsuranceApplicationStep,
  processApplicationResponseSaga,
  pollSavingsSignJobAction,
} from "@state/changeSavings";
import { pollProcessMinpensionDataJobAction } from "@state/minpensionData";

export function* getUserInfoSaga(action: { id: string }) {
  const id = action.id;
  yield put(getUserInfoRequest.actions.running({ id }));
  const response: typeof getUserInfoRequest.types.response = yield call(getUserInfoRequest, { id });
  if (response.ok) {
    yield put(getUserInfoRequest.actions.ok({ id }, response));
  } else {
    yield put(getUserInfoRequest.actions.error({ id }, response));
    yield put(responseErrorAction({ response }));
  }
  return response;
}

export function* setFreeMembershipSaga(userId: string) {
  yield put(putFreeMembershipRequest.actions.running({ userId }));
  const response: typeof putFreeMembershipRequest.types.response = yield call(putFreeMembershipRequest, { userId }, {});
  if (response.ok) {
    yield put(putFreeMembershipRequest.actions.ok({ userId }, response));
  } else {
    yield put(putFreeMembershipRequest.actions.error({ userId }, response));
  }
  return response;
}

function* getActiveJobsSaga(userId: string) {
  yield put(getUserActiveJobsRequest.actions.running({ id: userId }));
  const resp: typeof getUserActiveJobsRequest.types.response = yield call(getUserActiveJobsRequest, { id: userId });
  if (resp.ok) {
    yield put(getUserActiveJobsRequest.actions.ok({ id: userId }, resp));
  } else {
    yield put(getUserActiveJobsRequest.actions.error({ id: userId }, resp));
    yield put(responseErrorAction({ response: resp }));
  }
  return resp;
}

function* configRouteSaga(route: string) {
  if (ChangeSavingsRoute.match(route).isMatched || InsuranceApplicationRoute.match(route).isMatched) {
    const savingsItem: SavingsTodoItem | undefined = yield select<AppState>(savingsTodoItemSelector);
    if (!savingsItem) {
      yield put(replace(HomeRoute.format({})));
      return;
    }
    yield put(setTodoItemAction({ item: savingsItem, skipRedirect: true }));
  } else if (UpdateProfileBenefitsRoute.match(route).isMatched) {
    const updateProfileItem: UpdatePensionProfileTodoItem | undefined = (yield select<AppState>(
      updatePensionProfileTodoItemSelector
    ))[0];
    if (!updateProfileItem || updateProfileItem.finished) {
      yield put(replace(HomeRoute.format({})));
      return;
    }
    yield put(setTodoItemAction({ item: updateProfileItem }));
  } else if (LifeInsuranceUpsellRoute.match(route).isMatched) {
    const insuranceItems: TodoItemModel[] = yield select<AppState>(insuranceTodoItemSelector);
    const lifeInsuraceUpsellItem = insuranceItems.find((item) => item.type === TodoItemType.LifeInsuranceUpsell);

    if (!lifeInsuraceUpsellItem || lifeInsuraceUpsellItem.finished) {
      yield put(replace(HomeRoute.format({})));
      return;
    }
    yield put(setTodoItemAction({ item: lifeInsuraceUpsellItem }));
  } else if (SafetyInsuranceUpsellRoute.match(route).isMatched) {
    const insuranceItems: TodoItemModel[] = yield select<AppState>(insuranceTodoItemSelector);
    const safetyInsuraceUpsellItem = insuranceItems.find((item) => item.type === TodoItemType.SafetyInsuranceUpsell);

    if (!safetyInsuraceUpsellItem || safetyInsuraceUpsellItem.finished) {
      yield put(replace(HomeRoute.format({})));
      return;
    }
    yield put(setTodoItemAction({ item: safetyInsuraceUpsellItem }));
  } else if (LifeInsuranceRoute.match(route).isMatched) {
    const insuranceItems: TodoItemModel[] = yield select<AppState>(insuranceTodoItemSelector);
    const lifeInsuraceItem = insuranceItems.find((item) => item.type === TodoItemType.LifeInsurance);

    if (!lifeInsuraceItem || lifeInsuraceItem.finished) {
      yield put(replace(HomeRoute.format({})));
      return;
    }
    yield put(setTodoItemAction({ item: lifeInsuraceItem }));
  } else if (SafetyInsuranceRoute.match(route).isMatched) {
    const insuranceItems: TodoItemModel[] = yield select<AppState>(insuranceTodoItemSelector);
    const safetyInsuraceItem = insuranceItems.find((item) => item.type === TodoItemType.SafetyInsurance);

    if (!safetyInsuraceItem || safetyInsuraceItem.finished) {
      yield put(replace(HomeRoute.format({})));
      return;
    }
    yield put(setTodoItemAction({ item: safetyInsuraceItem }));
  } else if (PensionProfileUpdateRoute.match(route).isMatched) {
    const updateProfileItem: UpdatePensionProfileTodoItem = (yield select<AppState>(
      updatePensionProfileTodoItemSelector
    ))[0];

    if (updateProfileItem && !updateProfileItem.finished) {
      yield put(setTodoItemAction({ item: updateProfileItem, skipRedirect: true }));
    }
  } else if (MinpensionDataRoute.match(route).isMatched) {
    const updatePensionAnalysisItem: UpdatePensionAnalysisTodoItem = (yield select<AppState>(
      updatePensionAnalysisTodoItemSelector
    ))[0];

    if (updatePensionAnalysisItem) {
      yield put(setTodoItemAction({ item: updatePensionAnalysisItem, skipRedirect: true }));
    }
  }

  // redirect user if necessery
  if (location.pathname !== route) {
    yield put(replace(route));
  }
}

function* initialPensionProfileUpdateSaga(section?: string) {
  yield put(startInitialPensionProfileUpdate({}));
  yield put(replace(PensionProfileUpdateRoute.format({ section })));
  yield take(pensionProfileUpdateFinished.type);
  yield put(finishInitialPensionProfileUpdate({}));
}

function* showIntroSaga() {
  yield put(replace(IntroRoute.format({ step: 1 })));
  fadeOutSplash();
  yield take(intoWatchedAction.type);
}

export function* handleUserSaga(id: string, redirectTo?: string) {
  yield put(setUserIdAction({ id }));
  identify(id);
  const userInfoResp: typeof getUserInfoRequest.types.response = yield call(getUserInfoSaga, { id });
  if (!userInfoResp.ok) {
    return;
  }

  let destinationRoute = typeof redirectTo === "string" ? redirectTo : null;
  const user: UserWithInfo = yield select<AppState>((state) => state.user.data);

  // check insurance only customer
  if (isInsuranceOnlyRoute(destinationRoute || location.pathname)) {
    yield call(handleInsuranceOnlyUserSaga, destinationRoute || location.pathname, user);
    return;
  }

  if (!getIntroWatched()) {
    yield call(showIntroSaga);
    if (destinationRoute === null) {
      destinationRoute = HomeRoute.format({});
    }
  }

  const pensionProfileForm = userToPensionProfileForm(user);
  const noRequestedRiskLevel = !user.risk_profile.requested_risk_level;
  if (!PensionProfileSchema.isValidSync(pensionProfileForm) || noRequestedRiskLevel) {
    fadeOutSplash();
    yield call(initialPensionProfileUpdateSaga, noRequestedRiskLevel ? "5" : "0");
    if (destinationRoute === null) {
      destinationRoute = HomeRoute.format({});
    }
  }

  if ("Notification" in window) {
    if ((Notification as any).permission === "granted") {
      yield put(registerTokenAction({}));
    } else if ((Notification as any).permission === "default") {
      yield put(askForPermissionsAction({}));
    }
  }

  const [activeJobsResp, currentInsuranceApplicationResp]: [
    typeof getUserActiveJobsRequest.types.response,
    typeof getCurrentApplicationRequest.types.response
  ] = yield all([call(getActiveJobsSaga, userInfoResp.result._id), call(getCurrentInsuranceApplicationSaga)]);
  if (!activeJobsResp.ok || !currentInsuranceApplicationResp.ok) {
    return;
  }

  const processMinpensionDataJob = activeJobsResp.result.find(
    (job) => job.queued.task_code === "process-manual-minpension-data"
  );
  if (processMinpensionDataJob) {
    yield put(pollProcessMinpensionDataJobAction({ jobId: processMinpensionDataJob._id }));
    yield put(push(MinpensionDataProcessingRoute.format({})));
    return;
  }

  const currentApplication: InsuranceApplicationResponse | null = isEmpty(currentInsuranceApplicationResp.result)
    ? null
    : (currentInsuranceApplicationResp.result as InsuranceApplicationResponse);
  const isFreeVersion = yield select<AppState>(isFreeUserSelector);

  // redirect user to minpension data page if it's not done
  if (userInfoResp.result.is_pension_data_update_required && !userInfoResp.result.is_tryggakollen_analysis_expired) {
    if (!MinpensionDataRoute.match(location).isMatched) {
      yield put(replace(MinpensionDataRoute.format({ step: MinpensionDataSteps.Login })));
    }
    return;
  }

  // freeze user data if it's required
  if (!userInfoResp.result.is_tryggakollen_analysis_pegged) {
    yield call(prepareSummarySaga);
    return;
  } else {
    // or just get scraping data
    yield call(getScrapingSummarySaga, id);
  }

  if (isPremiumRoute(location.pathname) && isFreeVersion) {
    yield put(premiumRouteNotPermittedAction({}));
    return;
  }

  // check if start savings job is in progress
  const savingsSendApplicationJob = activeJobsResp.result.find(
    (job) => job.queued.task_code === "savings-send-insurance-application"
  );
  const savingsSignApplicationJob = activeJobsResp.result.find(
    (job) => job.queued.task_code === "savings-sign-insurance-application"
  );
  if (savingsSendApplicationJob) {
    yield put(pollSavingsSendJobAction({ jobId: savingsSendApplicationJob._id }));
    yield put(setInsuranceAplicationsStep({ step: InsuranceApplicationStep.WaitingSend }));
    destinationRoute = InsuranceApplicationRoute.format({});
  } else if (savingsSignApplicationJob) {
    yield put(pollSavingsSignJobAction({ jobId: savingsSignApplicationJob._id }));
    // check what waiting screen to show based on job step
    yield put(
      setInsuranceAplicationsStep({
        step: savingsSignApplicationJob.completed_step_names.includes("waiting-for-sign-completed")
          ? InsuranceApplicationStep.WaitingProcessed
          : InsuranceApplicationStep.WaitingSign,
      })
    );
    destinationRoute = InsuranceApplicationRoute.format({});
  } else if (currentApplication) {
    yield call(processApplicationResponseSaga, currentApplication.applicationStatus);
    if (currentApplication.applicationStatus !== InsuranceApplicationStatus.PendingManualValidation) {
      destinationRoute = InsuranceApplicationRoute.format({});
    }
  } else if (InsuranceApplicationRoute.match(location.pathname).isMatched) {
    destinationRoute = HomeRoute.format({});
  }

  destinationRoute = destinationRoute === null ? location.pathname : destinationRoute;
  yield call(configRouteSaga, destinationRoute);
}

export function* whoAmISaga({ redirectTo }: typeof whoAmIAction.typeInterface) {
  yield put(whoAmIRequest.actions.running({}));
  try {
    const response: typeof whoAmIRequest.types.response = yield call(whoAmIRequest, {});
    if (response.ok) {
      yield put(whoAmIRequest.actions.ok({}, response));
      yield call(handleUserSaga, response.result._id, redirectTo);
    } else {
      yield put(whoAmIRequest.actions.error({}, response));
      // check if bankid login on the way
      if (response["statusCode"] === 409) {
        yield put(getLoginStatusAction({}));
        yield put(push(LoginRoute.format({})));
      } else {
        // assume user went not from login link
        if (!getLoginCode()) {
          // let user proceed if he wants to see intro
          if (!IntroRoute.match(location.pathname).isMatched) {
            yield put(push(LoginRoute.format({})));
          }
        } else {
          // assume user is not logged in
          if (response["statusCode"] === 404) {
            const loginResp = yield logInSaga();
            if (loginResp.ok) {
              yield call(handleUserSaga, loginResp.result._id, redirectTo);
            } else {
              // remove outdated login code
              clearLoginCode();
              // let user proceed if he wants to see intro
              if (!IntroRoute.match(location.pathname).isMatched) {
                yield put(push(LoginRoute.format({})));
              }
            }
          } else {
            yield put(responseErrorAction({ response, hideBtn: true }));
          }
        }
      }
    }
    fadeOutSplash();
  } catch (e) {
    alert("Error in who am I saga");
    alert(e.stack);
  }
}

function* termsAcceptedSaga(action: typeof markTermsAcceptedAction.typeInterface) {
  const userId = yield select((state: AppState) => state.user.userId);
  const userEmail = action.email || (yield select((state: AppState) => state.user.data && state.user.data.email));

  yield put(markTermsAccepted.actions.running({ userId, email: userEmail }));
  const response: typeof markTermsAccepted.types.response = yield call(
    markTermsAccepted,
    { userId, email: userEmail },
    {}
  );
  if (response.ok) {
    // reload user data to get latest updates
    yield put(markTermsAccepted.actions.ok({ userId, email: userEmail }, response));
    const userInfoResp: typeof getUserInfoRequest.types.response = yield call(getUserInfoSaga, { id: userId });
    if (userInfoResp.ok) {
      yield put(startScraping({}));
    }
  } else {
    yield put(markTermsAccepted.actions.error({ userId, email: userEmail }, response));
    yield put(responseErrorAction({ response }));
  }
}

export function* userSaga() {
  yield takeLatest(markTermsAcceptedAction.type, termsAcceptedSaga);

  yield takeLatest(whoAmIAction.type, whoAmISaga);
  yield takeEvery(premiumRouteNotPermittedAction.type, function* () {
    yield put(replace(HomeRoute.format({})));
  });
}
