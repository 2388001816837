import * as React from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Select, TextField, TextFieldType, Button } from "@components/common";
import { getShellsForTypeahedRequest } from "@common/requests";
import { PensionPlanData, managementTypeOptions } from "@common/models";
import { isMobile } from "@common/helpers";
import { newPensionPlanSelector, updateNewPensionPlanAction } from "@state/minpensionData";

import { PlanEditor } from "./PensionPlanEdit";

import "./InsuranceShellSelect.scss";

interface InsuranceShellSelectProps {
  pensionInstitutes: { label: string; value: string }[];

  onAddPensionPlan(plan: PensionPlanData): any;
}

export const InsuranceShellSelect = ({ pensionInstitutes, onAddPensionPlan }: InsuranceShellSelectProps) => {
  const [insuranceShellOptions, setInsuranceShellOptions] = useState<{ value: string; label: string }[]>([]);

  const pensionPlan = useSelector(newPensionPlanSelector);
  const dispatch = useDispatch();
  const onUpdateNewPensionPlan = (data: Partial<PensionPlanData>) => {
    dispatch(updateNewPensionPlanAction({ data }));
  };

  const managementType = pensionPlan.management_type;
  const pensionInstitute = pensionPlan.pension_institute;

  const shellSelected =
    pensionPlan.pension_institute &&
    pensionPlan.management_type &&
    pensionPlan.insurance_type &&
    pensionPlan.insurance_shell;
  const shellDataPresent = shellSelected && pensionPlan.insurance_number;

  const addPensionPlan = () => {
    if (!shellDataPresent) {
      return;
    }
    onAddPensionPlan(pensionPlan as PensionPlanData);
    dispatch(updateNewPensionPlanAction({ data: {} }));
  };

  useEffect(() => {
    setInsuranceShellOptions([]);
    if (pensionInstitute && managementType) {
      getShellsForTypeahedRequest({
        management_type: managementType,
        pension_institute: pensionInstitute,
      })
        .then((resp) => setInsuranceShellOptions(resp.ok ? resp.result : []))
        .catch(() => setInsuranceShellOptions([]));
    }
  }, [managementType, pensionInstitute]);

  return (
    <div className="InsuranceShellSelect">
      <div className="InsuranceShellSelect__form">
        <h5 className="InsuranceShellSelect__title">Nytt pensionsinnehav</h5>
        <div className="InsuranceShellSelect__formRow">
          <p>Pensionsbolag:</p>
          <Select
            options={pensionInstitutes}
            value={pensionInstitute}
            onChange={(val) =>
              onUpdateNewPensionPlan({
                pension_institute: val && val["value"],
                insurance_number: pensionPlan.insurance_number,
                management_type: pensionPlan.management_type,
              })
            }
            placeholder="Välj..."
            noOptionsMessage={() => "Inga val tillgängliga"}
            menuPlacement={isMobile() ? "bottom" : "top"}
          />
        </div>

        <div className="InsuranceShellSelect__formRow">
          <p>Försäkringsnummer:</p>
          <TextField
            type={TextFieldType.Text}
            value={pensionPlan.insurance_number}
            onChange={(v) =>
              onUpdateNewPensionPlan({
                ...pensionPlan,
                insurance_number: v,
              })
            }
          />
        </div>

        <div className="InsuranceShellSelect__formRow">
          <p>Förvaltningstyp:</p>
          <Select
            options={managementTypeOptions}
            value={managementType}
            isSearchable={false}
            onChange={(val) =>
              onUpdateNewPensionPlan({
                pension_institute: pensionPlan.pension_institute,
                insurance_number: pensionPlan.insurance_number,
                management_type: val && val["value"],
              })
            }
            placeholder="Välj..."
            noOptionsMessage={() => "Inga val tillgängliga"}
          />
        </div>

        <div className="InsuranceShellSelect__formRow">
          <p>Namn på försäkring:</p>
          <Select
            options={insuranceShellOptions}
            value={pensionPlan.insurance_shell}
            onChange={(val) =>
              onUpdateNewPensionPlan({
                pension_institute: pensionPlan.pension_institute,
                insurance_number: pensionPlan.insurance_number,
                management_type: pensionPlan.management_type,
                insurance_shell: val && val["value"],
                insurance_type: val && val["label"],
              })
            }
            placeholder="Välj..."
            noOptionsMessage={() => "Inga val tillgängliga"}
          />
        </div>

        {shellSelected && (
          <PlanEditor
            plan={pensionPlan as PensionPlanData}
            onUpdate={({ annuity, capital, funds }) =>
              onUpdateNewPensionPlan({ ...pensionPlan, annuity, capital, funds })
            }
          />
        )}

        <Button text="Lägg till pensionsinnehav" onClick={addPensionPlan} primary disabled={!shellDataPresent} />
      </div>
    </div>
  );
};
