import * as React from "react";

interface ArrowBigProps {
  color?: string;
  direction?: "left" | "right" | "up" | "down";
  size: string;
}

const rotateMap = {
  right: 0,
  down: 90,
  left: 180,
  up: 270
};

export const ArrowBig = ({ color, size, direction }: ArrowBigProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    style={{
      transform: `rotate(${rotateMap[direction || "left"]}deg)`
    }}
    height={size}
    viewBox="0 0 24 24"
  >
    <path d="M12 8V4l8 8-8 8v-4H4V8z" fill={color || "#FFF"} />
    <path d="M0 0h24v24H0z" fill="none" />
  </svg>
);
