import { MultiSelectValue } from "@components/common/controls/multiselect";
import { AutogiroForm } from "./bank-details";

export enum InsuranceApplicationStatus {
  Received = "Received",
  Pending = "Pending",
  PendingManualValidation = "PendingManualValidation",
  Rejected = "Rejected",
  WaitForSign = "WaitForSign",
  RejectedBySigner = "RejectedBySigner",
  Expired = "Expired",
  Approved = "Approved",
  Processed = "Processed",
  Error = "Error"
}

export interface KycFormInsuranceAmount {
  insurancePurpose: MultiSelectValue;
  initialPremiumAmount: MultiSelectValue;
  yearlyPremiumAmount: MultiSelectValue;
}
export interface KycFormCustomerInfo {
  sourceOfFunds: MultiSelectValue;
  isPep: boolean;
  taxableInOtherCountry: boolean;
  hasOtherCitizenship: boolean;
}

export enum PayoutType {
  Recurring = "Recurring",
  OneTime = "OneTime"
}

export interface PayoutInfo {
  type: PayoutType;
  payoutStartAge?: number;
  payoutPeriod?: number;
}

export interface BeneficiaryInfo {
  beneficiaryCode: string;
  disposition: boolean;
  privateProperty: BeneficiaryInfoPrivateProperty;
}

export enum BeneficiaryInfoPrivateProperty {
  TrueWith = "TrueWith",
  TrueWithout = "TrueWithout",
  False = "False"
}

export interface InsuranceApplicationResponse {
  applicationStatus: InsuranceApplicationStatus;
  applicationId: string;
  signCaseId: string | null;
  insuranceNumber: string | null;
  monthlyPayment: number;
  errorMsg: string[];
  recommendedFunds: InsuranceApplicationRecommendedFund[];
}

export interface InsuranceApplicationRecommendedFund {
  isin: string;
  name: string;
  share: number;
  sec_id: string;
}

export interface InsuranceApplicationBody {
  monthlyPaymentAmount: number;
  kycCustomerInfo: KycFormCustomerInfo;
  kycInsuranceAmount: KycFormInsuranceAmount;
  payoutInfo: PayoutInfo;
  beneficiaryInfo: BeneficiaryInfo;
  bankInfo: AutogiroForm;
}
