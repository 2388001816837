import * as firebase from "firebase/app";
import "firebase/messaging";

import { Store } from "redux";

import { registerTokenAction, pushReceivedAction } from "../../state/notifications/actions";

const config = {
  apiKey: "AIzaSyA53v-D82k8AwbN6OjML_bcz2-08lOrI0o",
  authDomain: "pensionskraft-trygga.firebaseapp.com",
  databaseURL: "https://pensionskraft-trygga.firebaseio.com",
  projectId: "pensionskraft-trygga",
  storageBucket: "pensionskraft-trygga.appspot.com",
  messagingSenderId: "356982221457"
};
firebase.initializeApp(config);

let messaging: firebase.messaging.Messaging | undefined = undefined;
export function getMessaging() {
  return messaging;
}
export const isSupported = firebase.messaging.isSupported();

if (isSupported) {
  messaging = firebase.messaging();
  messaging["usePublicVapidKey"](
    "BKYYMmh-prdB4R_eA9-fn4T4ZTljerVehWuxODC9BHpyY_yBXgc_gzrak1At7SoxpnHmnDBxMKi1z9eSqjW7imI"
  );
}

export const isWorkerReadyForMessages = (async () => {
  if ("serviceWorker" in navigator && isSupported && messaging) {
    messaging.useServiceWorker(await navigator.serviceWorker.ready);
    return true;
  } else {
    return false;
  }
})();

export function init(store: Store<any>) {
  if (!isSupported || !messaging) {
    return;
  }
  try {
    if (!("Notification" in window)) {
      console.log("This browser does not support desktop notification");
      return;
    }

    if ((Notification as any).permission === "granted") {
      messaging.onTokenRefresh(async () => {
        store.dispatch(registerTokenAction({}));
      });

      // When everything good
      messaging.onMessage(payload => {
        store.dispatch(pushReceivedAction({ push: payload }));
      });
    }
  } catch (err) {
    console.log("Error:", err);
  }
}
