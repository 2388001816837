import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Redirect, Switch, Route, RouteComponentProps } from "react-router-dom";
import { goBack, push } from "connected-react-router";

import { TodoItemModel, BasicScoringModel } from "@common/models";
import { TodoItemType, MeterType } from "@common/types";
import { AppState } from "@state/store";
import { selectedTodoItemSelector, currentScoresSelector } from "@state/todoItem";
import { Layout, InfoItem, Button, MeterItem, TodoItemIncrease, RippleEffect } from "@components/common";
import {
  HomeRoute,
  TodoItemBenefitsRoute,
  TodoItemBenefitsDetailsRoute,
  ChangeFundsRoute,
  ChangeSavingsRoute,
  SupportFAQRoute
} from "@common/routes";

import { TodoItemBenefitsSummary } from "./summary";

import "./TodoItemBenefits.scss";

interface TodoItemBenefitsProps {
  item: TodoItemModel | undefined;
  currentScore: BasicScoringModel;
  detailsOpened: boolean;

  onGoBack(): any;
  onGoToDetails(): any;
  onGoToChangeFunds(): any;
  onGoToChangeSavings(): any;
  onGoToFAQ(): any;
}

class TodoItemBenefitsAtom extends React.Component<TodoItemBenefitsProps, {}> {
  onNextClick = () => {
    const { item, onGoToChangeFunds, onGoToChangeSavings } = this.props;
    switch (item!.type) {
      case TodoItemType.ChangeFunds:
        onGoToChangeFunds();
        break;
      case TodoItemType.ChangeSavings:
        onGoToChangeSavings();
        break;
      default:
        return;
    }
  };

  get title(): string {
    const { item } = this.props;
    if (!item) {
      return "";
    }
    if (this.props.detailsOpened) {
      return "Fördelar för dig";
    }
    switch (item.type) {
      case TodoItemType.ChangeFunds:
        return "Byt Fonder";
      default:
        return "Spara";
    }
  }

  get subtitle(): string {
    const { item } = this.props;
    if (!item || this.props.detailsOpened) {
      return "";
    }
    switch (item.type) {
      case TodoItemType.ChangeFunds:
        return item.payload.pensionInstitute;
      default:
        return "";
    }
  }

  render() {
    const { item, currentScore, onGoBack, onGoToFAQ } = this.props;
    return !!item ? (
      <Layout contentClassName="TodoItemBenefits" title={this.title} subtitle={this.subtitle} onGoBack={onGoBack}>
        <Switch>
          <Route
            exact
            path={TodoItemBenefitsRoute.urlTemplate}
            render={() => (
              <React.Fragment>
                <div className="TodoItemBenefits__overall">
                  <TodoItemBenefitsSummary
                    isBetterFunds={!!(item.increase && item.increase.quality > 0)}
                    isBetterRisk={!!(item.increase && item.increase.risk > 0)}
                  />
                  <InfoItem
                    text={
                      <React.Fragment>
                        Det här fondbytet förbättrar ditt pensionsbetyg. Beräkningarna görs med den data som finns
                        tillgänglig. Du kan läsa mer om hur Pensionsbetyget beräknas under{" "}
                        <RippleEffect className="TodoItemBenefits__faqLink" rounded onClick={onGoToFAQ}>
                          Vanliga frågor
                        </RippleEffect>
                        .
                      </React.Fragment>
                    }
                  />
                </div>
                <Button text="Nästa" onClick={this.onNextClick} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={TodoItemBenefitsDetailsRoute.urlTemplate}
            render={() => (
              <React.Fragment>
                <InfoItem bold text="Här ser du hur din pension förbättras med den här ändringen." />
                {item.increase!.savings !== 0 && (
                  <MeterItem
                    type={MeterType.Savings}
                    score={currentScore.savings + item.increase!.savings}
                    increase={item.increase!.savings}
                  />
                )}
                {item.increase!.quality !== 0 && (
                  <MeterItem
                    type={MeterType.Funds}
                    score={currentScore.quality + item.increase!.quality}
                    increase={item.increase!.quality}
                  />
                )}
                {item.increase!.risk !== 0 && (
                  <MeterItem
                    type={MeterType.Risk}
                    score={currentScore.risk + item.increase!.risk}
                    increase={item.increase!.risk}
                  />
                )}
                <div className="TodoItemBenefits__detailsTotal">
                  <TodoItemIncrease />
                </div>
              </React.Fragment>
            )}
          />
        </Switch>
      </Layout>
    ) : (
      <Redirect to={HomeRoute.format({})} />
    );
  }
}

export const TodoItemBenefits = connect(
  (state: AppState, ownProps: RouteComponentProps<any>) => ({
    item: selectedTodoItemSelector(state),
    currentScore: currentScoresSelector(state),
    detailsOpened: !!ownProps.location.pathname.includes("details")
  }),
  (dispatch: Dispatch) => ({
    onGoBack() {
      dispatch(goBack());
    },
    onGoToChangeFunds() {
      dispatch(push(ChangeFundsRoute.format({})));
    },
    onGoToChangeSavings() {
      dispatch(push(ChangeSavingsRoute.format({})));
    },
    onGoToFAQ() {
      dispatch(push(SupportFAQRoute.format({})));
    }
  })
)(TodoItemBenefitsAtom as any);
