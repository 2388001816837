import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { push } from "connected-react-router";
import {
  Layout,
  ButtonLink,
  TextFieldType,
  LoadingOverlay,
  FormikTextField,
  UnsavedChangesModal
} from "@components/common";
import { HomeRoute, SettingsRiskLevelRoute, SettingsPensionProfileRoute } from "@common/routes";
import { AppState } from "@state/store";
import { UserWithInfo, MainSettingsForm, userToMainSettingsForm } from "@common/models";
import { icons } from "@assets";
import { updateUserMainSettingsAction } from "@state/settings";
import { riskLevelTranslations } from "@common/helpers";
import { RiskLevels } from "@common/types";
import * as Yup from "yup";
import { Formik, FormikProps, Field } from "formik";
import { useState } from "react";

import "./Settings.scss";

interface SettingsProps {
  user: UserWithInfo | undefined;
  isFetching: boolean;
  selectedRiskLevel: RiskLevels;

  onGoBack(): any;
  onGoToRiskLevel(): any;
  onGoToPensionProfile(): any;
  onSaveUser(form: MainSettingsForm): any;
}

export const MainSettingsSchema = Yup.object().shape({
  firstName: Yup.string(),
  lastName: Yup.string(),
  personNumber: Yup.string(),
  email: Yup.string()
    .required("Glöm inte fylla i din email!")
    .email("Kontrollera din email-adress!"),
  phone: Yup.string()
});

const SettingsAtom: React.SFC<SettingsProps> = ({
  user,
  isFetching,
  selectedRiskLevel,
  onGoBack,
  onGoToPensionProfile,
  onGoToRiskLevel,
  onSaveUser
}) => {
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);

  const onSaveModalAccept = (values: MainSettingsForm) => {
    setIsSaveModalOpen(false);
    onSaveUser(values);
  };

  const handleGoBack = (formikProps: FormikProps<MainSettingsForm>) => {
    if (formikProps.dirty) {
      setIsSaveModalOpen(true);
    } else {
      onGoBack();
    }
  };

  return (
    <Formik
      initialValues={userToMainSettingsForm(user)}
      onSubmit={values => onSaveModalAccept(values)}
      validationSchema={MainSettingsSchema}
      enableReinitialize
    >
      {(formikProps: FormikProps<MainSettingsForm>) => {
        const errorFields = Object.keys(formikProps.errors);
        const firstErrorFieldId = errorFields.length > 0 ? `settings-${errorFields[0]}` : undefined;

        return (
          <>
            <Layout title="Inställningar" contentClassName="Settings" onGoBack={handleGoBack.bind(null, formikProps)}>
              <div className="Settings__form">
                <div className="Settings__formHeader">
                  <h4 className="Settings__header">Personuppgifter</h4>
                  <img src={icons.profile} alt="" />
                </div>
                <Field
                  component={FormikTextField}
                  name="lastName"
                  label="Efternamn"
                  type={TextFieldType.Text}
                  id="settings-lastName"
                />
                <div className="Settings__line" />
                <Field
                  component={FormikTextField}
                  name="personNumber"
                  label="Personnummer"
                  type={TextFieldType.Personnumber}
                  id="settings-personNumber"
                  disabled
                />
                <div className="Settings__line" />
                <Field
                  component={FormikTextField}
                  name="email"
                  label="E-post"
                  type={TextFieldType.Text}
                  id="settings-email"
                />
                <div className="Settings__line" />
                <Field
                  component={FormikTextField}
                  name="phone"
                  label="Mobiltelefonnummer"
                  type={TextFieldType.Phone}
                  id="settings-phone"
                />
              </div>
              <div className="Settings__links">
                <h4 className="Settings__header">Din Ekonomi</h4>
                <p className="Settings__label">Din Risknivå</p>
                <ButtonLink
                  text={riskLevelTranslations[selectedRiskLevel] || selectedRiskLevel}
                  onClick={onGoToRiskLevel}
                />
                <ButtonLink text="Din Pensionsprofil" onClick={onGoToPensionProfile} />
              </div>
            </Layout>
            <UnsavedChangesModal
              errorElementId={firstErrorFieldId}
              isOpened={isSaveModalOpen}
              onAccept={onSaveModalAccept.bind(null, formikProps.values)}
              onClose={setIsSaveModalOpen.bind(null, false)}
              onDecline={onGoBack}
            />
            <LoadingOverlay isShown={isFetching} />
          </>
        );
      }}
    </Formik>
  );
};

export const Settings = connect(
  (state: AppState) => ({
    isFetching: state.settings.isFetching,
    user: state.user.data,
    selectedRiskLevel: state.user.data && state.user.data.risk_profile.requested_risk_level
  }),
  (dispatch: Dispatch) => ({
    onGoBack() {
      dispatch(push(HomeRoute.format({})));
    },
    onGoToRiskLevel() {
      dispatch(push(SettingsRiskLevelRoute.format({})));
    },
    onGoToPensionProfile() {
      dispatch(push(SettingsPensionProfileRoute.format({})));
    },
    onSaveUser(form: MainSettingsForm) {
      dispatch(updateUserMainSettingsAction({ form }));
    }
  })
)(SettingsAtom);
