export enum MeterType {
  Savings = "savings",
  Risk = "risk",
  Funds = "funds"
}

export enum ShowMoreType {
  Prognosis = "prognosis",
  Funds = "funds",
  Money = "money",
  Meter = "meter"
}

export enum TodoItemType {
  ChangeFunds = "ChangeFunds",
  ChangeSavings = "ChangeSavings",
  LifeInsurance = "LifeInsurance",
  SafetyInsurance = "SafetyInsurance",
  LifeInsuranceUpsell = "LifeInsuranceUpsell",
  SafetyInsuranceUpsell = "SafetyInsuranceUpsell",
  FreeUserMock = "FreeUserMock",
  UpdatePensionProfile = "UpdatePensionProfile",
  UpdatePensionAnalysis = "UpdatePensionAnalysis"
}

export enum SupportCategory {
  FAQ = "faq",
  Terms = "terms"
}

export enum RiskLevels {
  High = "high",
  Medium = "medium",
  Low = "low",
  Missing = "-"
}

export enum LinkActions {
  ShowMessage = "trygga-app-show-messages",
  ShowMain = "trygga-app-show-main",
  ShowStandaloneLifeInsuranceFlow = "trygga-app-show-standalone-life-insurance",
  ShowStandaloneSafetyInsuranceFlow = "trygga-app-show-standalone-safety-insurance",
  ShowStandaloneLifeInsuranceUpsellFlow = "trygga-app-show-standalone-life-insurance-upsell",
  ShowStandaloneSafetyInsuranceUpsellFlow = "trygga-app-show-standalone-safety-insurance-upsell"
}

export enum ChangeFundsType {
  Standard = "standard",
  Rebalancing = "rebalance"
}

export enum StandaloneInsuranceOffers {
  LifeInsurance = "life-insurance",
  SafetyInsurance = "safety-insurance",
  LifeInsuranceUpsell = "life-insurance-upsell",
  SafetyInsuranceUpsell = "safety-insurance-upsell"
}
