import {
  formatRangeValue,
  employmentSectorLabels,
  maritalStatusLabels,
  hasFundKnowledgeLabels,
  hasFundExperienceLabels
} from "@common/models";

export const smallEstimationOptionsString = "? 0-50 50-250 250-500 500+";
export const largeEstimationOptionsString =
  "? 0-0.5 0.5-1 1-1.5 1.5-2 2-2.5 2.5-3 3-3.5 3.5-4 4-4.5 4.5-5 5-5.5 5.5-6 6-6.5 6.5-7 7-7.5 7.5-8 8-8.5 8.5-9 9-9.5 9.5-10 10-10.5 10.5-11 11-11.5 11.5-12 12-12.5 12.5-13 13-13.5 13.5-14 14-14.5 10-11 11-12 12-13 13+";
export const employmentSectorOptionsString =
  "public-local public-regional public-national private cooperative unemployed retired";
export const martialOptionsString = "single married cohabiting";
export const knowledgeOptionsString = "none vague adequat extensive";
export const retirementAgeString = "55 56 57 58 59 60 61 62 63 64 65 66 67 68 69 70 71 72 73 74 75";
export const dependantChildrenString = "0 1 2 3 4 5 6 7 8 9 10";

const optionsFromEnumString = (str: string, formatter: (value: string) => string = v => v) => {
  return str.split(" ").map(value => ({ label: formatter(value), value }));
};

export const noYesOptions = [{ label: "Ja", value: "true" }, { label: "Nej", value: "false" }];
export const smallEstimationOptions = optionsFromEnumString(
  smallEstimationOptionsString,
  range => `${formatRangeValue(range, "tusen kronor")}`
);
export const largeEstimationOptions = optionsFromEnumString(
  largeEstimationOptionsString,
  range => `${formatRangeValue(range, "miljoner kronor")}`
);
export const employmentSectorOptions = optionsFromEnumString(
  employmentSectorOptionsString,
  sector => employmentSectorLabels[sector]
);
export const martialOptions = optionsFromEnumString(martialOptionsString, status => maritalStatusLabels[status]);
export const hasFundKnowledgeOptions = optionsFromEnumString(
  knowledgeOptionsString,
  status => hasFundKnowledgeLabels[status]
);
export const hasFundExperienceOptions = optionsFromEnumString(
  knowledgeOptionsString,
  status => hasFundExperienceLabels[status]
);
export const retirementAgeOptions = optionsFromEnumString(retirementAgeString);
export const dependantChildrenOptions = optionsFromEnumString(dependantChildrenString);

const collectiveAgreements = [
  { text: "Vet inte" },
  { text: "Privatanställd arbetare (SAF-LO)", collar_colors: "blue", sectors: "private retired unemployed" },
  {
    text: "Privatanställd tjänsteman (ITP1)",
    bornAfter: 1979,
    collar_colors: "white",
    sectors: "private retired unemployed"
  },
  {
    text: "Privatanställd tjänsteman (ITP2)",
    bornBefore: 1979,
    collar_colors: "white",
    sectors: "private retired unemployed"
  },
  {
    text: "Kommunal- & landstingsanställd (KAP-KL)",
    bornBefore: 1986,
    sectors: "public-local public-regional retired unemployed"
  },
  {
    text: "Kommunal- & landstingsanställd (AKAP-KL)",
    bornAfter: 1986,
    sectors: "public-local public-regional retired unemployed"
  },
  { text: "Statligt anställd (PA 16 Avd I)", bornAfter: 1988, sectors: "public-national retired unemployed" },
  { text: "Statligt anställd (PA 16 Avd II)", bornBefore: 1988, sectors: "public-national retired unemployed" },
  { text: "Anställd i affärsdrivande verk (ITP2)", bornBefore: 1979, sectors: "public-national retired unemployed" },
  { text: "Anställd i kommunalt bolag (KPA-Plan)", sectors: "public-local retired unemployed" },
  { text: "Anställd i kommunalt bolag (PA-KFS)", bornBefore: 1954, sectors: "public-local retired unemployed" },
  { text: "Anställd i kommunalt bolag (PA-KFS09)", bornAfter: 1954, sectors: "public-local retired unemployed" },
  {
    text: "Anställd i kooperativt bolag (GTP)",
    bornBefore: undefined,
    bornAfter: undefined,
    collar_colors: "",
    sectors: "cooperative retired unemployed"
  },
  { text: "Arkitekt (ITP2)", bornBefore: 1979, collar_colors: "white", sectors: "private retired unemployed" },
  { text: "Bankanställd (BTP1)", collar_colors: "white", sectors: "private retired unemployed" },
  { text: "Bankanställd (BTP2)", collar_colors: "white", sectors: "private retired unemployed" },
  {
    text: "Driver eget aktiebolag",
    bornBefore: undefined,
    bornAfter: undefined,
    collar_colors: "",
    sectors: "private retired unemployed"
  },
  { text: "Egen företagare", sectors: "private retired unemployed" },
  { text: "Flygledare (PA 16 Avd II)", collar_colors: "white", sectors: "public-national retired unemployed" },
  {
    text: "Försäkringsanställd (FTP1)",
    bornAfter: 1972,
    collar_colors: "white",
    sectors: "private retired unemployed"
  },
  {
    text: "Försäkringsanställd (FTP2)",
    bornBefore: 1972,
    collar_colors: "white",
    sectors: "private retired unemployed"
  },
  {
    text: "Anställd arbetare i kooperativt bolag (KAP)",
    collar_colors: "blue",
    sectors: "cooperative retired unemployed"
  },
  { text: "Kooperativt anställd arbetare (KFO-LO)", collar_colors: "blue", sectors: "cooperative retired unemployed" },
  {
    text: "Kooperativt anställd tjänsteman (KTP1)",
    bornAfter: 1981,
    collar_colors: "white",
    sectors: "cooperative retired unemployed"
  },
  {
    text: "Kooperativt anställd tjänsteman (KTP2)",
    bornBefore: 1981,
    collar_colors: "white",
    sectors: "cooperative retired unemployed"
  },
  { text: "Kyrkligt anställd (KAP-KL)", collar_colors: "", sectors: "public-local retired unemployed" },
  { text: "Pilot (K06 Bil G PPAK)", collar_colors: "white", sectors: "private retired unemployed" },
  { text: "Pilot (K06 Bil G)", collar_colors: "white", sectors: "private retired unemployed" },
  { text: "Pilot (PPA 07)", collar_colors: "white", sectors: "private retired unemployed" },
  { text: "Pilot (PPA 13)", collar_colors: "white", sectors: "private retired unemployed" },
  { text: "Posten-anställd (ITP-P)", sectors: "private retired unemployed" },
  { text: "Sjöbefäl (ITP2)", bornBefore: 1979, collar_colors: "white pink", sectors: "private retired unemployed" },
  {
    text: "Tidnings- och mediaanställd (ITP1)",
    bornAfter: 1979,
    collar_colors: "white",
    sectors: "private retired unemployed"
  },
  {
    text: "Tidnings- och mediaanställd (ITP2)",
    bornBefore: 1979,
    collar_colors: "white",
    sectors: "private retired unemployed"
  },
  {
    text: "Tjänstepension utan kollektivavtal",
    bornBefore: undefined,
    bornAfter: undefined,
    collar_color: "",
    sectors: "retired unemployed"
  },
  {
    text: "Yrkesofficer med 60 års pensionsålder (PA 16 Avd II)",
    collar_colors: "white",
    sectors: "public-national retired unemployed"
  },
  {
    text: "Yrkesofficer med 61 års pensionsålder (PA 16 Avd II)",
    collar_colors: "white",
    sectors: "public-national retired unemployed"
  },
  { text: "Annan anställning" },
  { text: "Saknar arbetsinkomst" }
];

export const getCollectiveAgreementOptions = (
  personNumber?: string,
  collarColor?: string,
  employmentSector?: string
) => {
  var yearUserIsBorn = parseInt((personNumber || "").slice(0, 4), 10);

  return collectiveAgreements
    .filter(agreement => {
      const bornBeforeMatches = agreement.bornBefore === undefined || agreement.bornBefore < yearUserIsBorn;
      const bornAfterMatches = agreement.bornAfter === undefined || agreement.bornAfter >= yearUserIsBorn;
      const collarColorMatches =
        agreement.collar_colors === undefined ||
        collarColor === undefined ||
        agreement.collar_colors.indexOf(collarColor) > -1;
      const sectorMatches =
        agreement.sectors === undefined ||
        employmentSector === undefined ||
        agreement.sectors.indexOf(employmentSector) > -1;

      return bornBeforeMatches && bornAfterMatches && collarColorMatches && sectorMatches;
    })
    .map(agreement => ({ label: agreement.text, value: agreement.text }));
};
