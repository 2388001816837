import { TodoItemModel } from "@common/models";

import { defineAction } from "../../lib/rd-action-creators/index";

export const setTodoItemAction = defineAction<{ item: TodoItemModel; skipRedirect?: boolean }>("SET TODO ITEM");
export const closeTodoItemAction = defineAction("CLOSE TODO ITEM");

export const clearCompletedItemAction = defineAction("CLEAR COMPLETED TODO ITEM");
export const markItemAsCompleted = defineAction<{ withRedirect: boolean }>("MARK TODO ITEM AS COMPLETED");
export const skipItemAction = defineAction("SKIP TODO ITEM");
