import * as React from "react";
import { useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { push } from "connected-react-router";

import { AppState } from "@state/store";
import { updateUserPensionProfileAction, finishPensionProfileUpdateAction } from "@state/settings";

import { PensionProfileForm, userToPensionProfileForm, UserWithInfo } from "@common/models";
import { RiskLevels } from "@common/types";

import { Button, LoadingOverlay, Layout, InfoPopup } from "@components/common";
import { Error404Page } from "@components/errors";
import { PensionProfileUpdateRoute } from "@common/routes";
import { RiskLevelBadge } from "@components/settingsRiskLevel";

import {
  EconomySection,
  WorkSection,
  FamilySection,
  RiskSection,
  EconomySchema,
  WorkSchema,
  FamilySchema,
  RiskSchema
} from "./Sections";

import "./PensionProfile.scss";

enum FlowSteps {
  ENTRY = 0,
  ECONOMY = 1,
  WORK = 2,
  FAMILY = 3,
  RISK = 4,
  RECOMMENDED_RISK_LEVEL = 5,
  LAST_STEP = RECOMMENDED_RISK_LEVEL
}

const PENSION_PROFILE_COMPONENTS = {
  [FlowSteps.ECONOMY]: EconomySection,
  [FlowSteps.WORK]: WorkSection,
  [FlowSteps.FAMILY]: FamilySection,
  [FlowSteps.RISK]: RiskSection
};

const PENSION_PROFILE_TITLE = {
  [FlowSteps.ECONOMY]: "Ekonomi",
  [FlowSteps.WORK]: "Arbete",
  [FlowSteps.FAMILY]: "Familj",
  [FlowSteps.RISK]: "Riskvillighet"
};

const SCHEMA_FOR_STEP = {
  [FlowSteps.ECONOMY]: EconomySchema,
  [FlowSteps.WORK]: WorkSchema,
  [FlowSteps.FAMILY]: FamilySchema,
  [FlowSteps.RISK]: RiskSchema
};

const PensionProfileScreen: React.SFC<{
  step: FlowSteps;
  user: UserWithInfo;
  onSubmit(values: PensionProfileForm): void;
  onGoBack(): void;
}> = ({ step, user, onSubmit, onGoBack }) => {
  if (!(step in PENSION_PROFILE_COMPONENTS)) {
    return null;
  }

  const SectionComponent = PENSION_PROFILE_COMPONENTS[step];
  const sectionTitle = PENSION_PROFILE_TITLE[step];
  const schema = SCHEMA_FOR_STEP[step];
  const pensionProfileForm = userToPensionProfileForm(user);

  return (
    <Layout title={sectionTitle} contentClassName="PensionProfile" onGoBack={onGoBack}>
      <Formik
        initialValues={pensionProfileForm}
        onSubmit={values => onSubmit(values)}
        validationSchema={schema}
        isInitialValid={schema === undefined || schema.isValidSync(pensionProfileForm)}
        enableReinitialize
      >
        {(formikProps: FormikProps<PensionProfileForm>) => (
          <>
            <div className="PensionProfile__card">
              <SectionComponent hideTitle user={user} formikProps={formikProps} />
            </div>
            <Button
              text="Nästa"
              className="PensionProfile__updateButton"
              onClick={() => {
                if (formikProps.isValid) {
                  formikProps.setStatus(null);
                  formikProps.handleSubmit();
                } else {
                  formikProps.setTouched(
                    Object.keys(formikProps.values).reduce((acc, key) => ({ ...acc, [key]: true }), {})
                  );
                  formikProps.setStatus("You have to fix all errors, before continuing");
                }
              }}
              disabled={typeof formikProps.status === "string" && !formikProps.isValid}
            />
          </>
        )}
      </Formik>
    </Layout>
  );
};

interface RiskLevelFormValues {
  riskLevel: RiskLevels;
}

const RiskLevelSchema = Yup.object().shape({
  riskLevel: Yup.string()
    .required("Det här fältet behöver fyllas i")
    .oneOf([RiskLevels.Low, RiskLevels.Medium, RiskLevels.High])
});

const RiskLevelStep: React.SFC<{
  initialRiskLevel: RiskLevels;
  recommendedRiskLevel: RiskLevels;
  onSubmit(riskLevel: RiskLevels): void;
  onGoBack(): void;
}> = ({ initialRiskLevel, recommendedRiskLevel, onSubmit, onGoBack }) => {
  const initialValues = { riskLevel: initialRiskLevel };

  return (
    <Layout title="Din Risknivå" contentClassName="PensionProfile" onGoBack={onGoBack}>
      <Formik
        initialValues={initialValues}
        onSubmit={values => onSubmit(values.riskLevel)}
        validationSchema={RiskLevelSchema}
        isInitialValid={RiskLevelSchema.isValidSync(initialValues)}
        enableReinitialize
      >
        {(formikProps: FormikProps<RiskLevelFormValues>) => (
          <>
            <div className="PensionProfile__card">
              <div className="PensionProfile__text">
                <p>Här ser du vilken risknivå i dina investeringar vi rekommenderar baserat på din Pensionsprofil.</p>
                <p>
                  Om du vill ändra till en annan risknivå, kan du göra det direkt här. Du kan också ändra det i
                  Inställningar senare.
                </p>
                <InfoPopup
                  popupContent={
                    <>
                      <b>Låg</b> - Risken att förlora hela kapitalet är låg och förväntningarna är en begränsad
                      värdeökning.
                      <br />
                      <b>Medium</b> - Investeringen ska kunna stiga i värde och du accepterar därmed även en ökad risk
                      för värdeminskning.
                      <br />
                      <b>Hög</b> - Investeringen ska kunna öka kraftigt i värde under stora kurssvängningar och du
                      accepterar därmed även att investeringen kan minska kraftigt under investeringsperioden.
                    </>
                  }
                >
                  <div className="PensionProfile__link">Mer om risknivåerna</div>
                </InfoPopup>
              </div>
              <RiskLevelBadge
                level={RiskLevels.High}
                isSelected={formikProps.values.riskLevel === RiskLevels.High}
                isRecommended={recommendedRiskLevel === RiskLevels.High}
                onClick={() => {
                  formikProps.setFieldValue("riskLevel", RiskLevels.High);
                }}
              />
              <RiskLevelBadge
                level={RiskLevels.Medium}
                isSelected={formikProps.values.riskLevel === RiskLevels.Medium}
                isRecommended={recommendedRiskLevel === RiskLevels.Medium}
                onClick={() => {
                  formikProps.setFieldValue("riskLevel", RiskLevels.Medium);
                }}
              />
              <RiskLevelBadge
                level={RiskLevels.Low}
                isSelected={formikProps.values.riskLevel === RiskLevels.Low}
                isRecommended={recommendedRiskLevel === RiskLevels.Low}
                onClick={() => {
                  formikProps.setFieldValue("riskLevel", RiskLevels.Low);
                }}
              />
            </div>
            <Button
              text="Ok"
              className="PensionProfile__updateButton"
              onClick={formikProps.handleSubmit}
              disabled={!formikProps.isValid}
              primary
            />
          </>
        )}
      </Formik>
    </Layout>
  );
};

interface PensionProfileUpdateProps extends RouteComponentProps<{ section?: string }> {
  user?: UserWithInfo;
  isFetching: boolean;

  onSaveUserAction(form: PensionProfileForm): void;
  onChangeRiskLevelAction(riskLevel: RiskLevels): any;
  goToNextSection(): void;
  goBack(): void;
}

export const PensionProfileUpdate = connect(
  (state: AppState) => ({
    user: state.user.data,
    isFetching: state.settings.isFetching || state.user.isFetching
  }),
  (dispatch: Dispatch, ownProps: PensionProfileUpdateProps) => ({
    onSaveUserAction(form: PensionProfileForm) {
      dispatch(updateUserPensionProfileAction({ form }));
    },
    onChangeRiskLevelAction(riskLevel: RiskLevels) {
      dispatch(finishPensionProfileUpdateAction({ riskLevel }));
    },
    goToNextSection() {
      const currentSection = Number(ownProps.match.params.section || 0);
      const match = PensionProfileUpdateRoute.match(ownProps.location);
      dispatch(
        push(
          PensionProfileUpdateRoute.format(
            { section: String(currentSection + 1) },
            {
              initialUrl: match.isMatched && match.query && match.query.initialUrl ? match.query.initialUrl : undefined
            }
          )
        )
      );
    },
    goBack() {
      const currentSection = Number(ownProps.match.params.section || 0);
      let backRoute = "";

      const match = PensionProfileUpdateRoute.match(ownProps.location);
      if (match.isMatched && match.query && match.query.initialUrl) {
        if (currentSection === FlowSteps.ECONOMY) {
          backRoute = match.query.initialUrl;
        } else {
          backRoute = PensionProfileUpdateRoute.format(
            { section: String(currentSection - 1) },
            { initialUrl: match.query.initialUrl }
          );
        }
      } else {
        backRoute = PensionProfileUpdateRoute.format({ section: String(currentSection - 1) });
      }

      dispatch(push(backRoute));
    }
  })
)(
  ({
    user,
    isFetching,
    onSaveUserAction,
    onChangeRiskLevelAction,
    goToNextSection,
    goBack,
    match
  }: PensionProfileUpdateProps) => {
    const [isUpdateFinished, setIsUpdateFinished] = useState(false);
    if (!user) {
      return null;
    }

    const currentStep = Number(match.params.section || 0) as FlowSteps;

    if (currentStep > FlowSteps.LAST_STEP) {
      return <Error404Page />;
    }

    const handlePensionProfileUpdate = (values: PensionProfileForm) => {
      onSaveUserAction(values);
      goToNextSection();
    };

    const handleRiskLevelUpdate = (riskLevel: RiskLevels) => {
      setIsUpdateFinished(true);
      onChangeRiskLevelAction(riskLevel);
    };

    let stepContent = null;

    switch (currentStep) {
      case FlowSteps.ENTRY:
        stepContent = (
          <Layout title="Din Pensionsprofil" contentClassName="PensionProfile">
            <div className="PensionProfile__card">
              <div className="PensionProfile__title">Välkommen!</div>
              <div className="PensionProfile__text">
                Du behöver nu svara på några enkla frågor om dig och din ekonomi. Det behövs för att kunna ge dig rätt
                råd!
              </div>
            </div>
            <Button text="Nästa" className="PensionProfile__updateButton" onClick={goToNextSection} />
          </Layout>
        );
        break;
      case FlowSteps.ECONOMY:
      case FlowSteps.WORK:
      case FlowSteps.FAMILY:
      case FlowSteps.RISK:
        stepContent = (
          <PensionProfileScreen
            step={currentStep}
            user={user}
            onSubmit={handlePensionProfileUpdate}
            onGoBack={goBack}
          />
        );
        break;
      case FlowSteps.RECOMMENDED_RISK_LEVEL:
        stepContent = (
          <RiskLevelStep
            recommendedRiskLevel={user.recommended_5step_risk_level}
            initialRiskLevel={user.risk_profile.requested_risk_level || user.recommended_5step_risk_level}
            onSubmit={handleRiskLevelUpdate}
            onGoBack={goBack}
          />
        );
        break;
      default:
        stepContent = null;
        break;
    }

    return (
      <>
        {stepContent}
        <LoadingOverlay isShown={isFetching || isUpdateFinished} />
      </>
    );
  }
);
