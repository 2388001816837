import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { push, goBack } from "connected-react-router";

import { Layout, Collapse } from "@components/common";
import { AppState } from "@state/store";
import { SupportRoute } from "@common/routes";
import { FAQModel } from "@common/models";

import "./SupportFAQ.scss";

interface SupportFAQProps {
  faqs: FAQModel[];
  hasPrevLocation: boolean;

  onGoBack(): any;
  onGoToSupport(): any;
}

export const SupportFAQ = connect(
  (state: AppState) => ({
    faqs: state.faq.data || [],
    hasPrevLocation: !!state.router.prevLocation
  }),
  (dispatch: Dispatch) => ({
    onGoToSupport() {
      dispatch(push(SupportRoute.format({})));
    },
    onGoBack() {
      dispatch(goBack());
    }
  })
)(({ faqs, hasPrevLocation, onGoBack, onGoToSupport }: SupportFAQProps) => {
  return (
    <Layout title="Vanliga frågor" onGoBack={hasPrevLocation ? onGoBack : onGoToSupport} contentClassName="SupportFAQ">
      {faqs.map(faq => (
        <Collapse key={faq.id} title={faq.title}>
          <p className="SupportFAQ__description">{faq.description}</p>
        </Collapse>
      ))}
    </Layout>
  );
});
