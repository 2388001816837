import { select, put, call, takeEvery } from "redux-saga/effects";

import { AppState } from "@state/store";
import { postPPMConfirmationRequest } from "@common/requests";
import { prepareSummarySaga } from "@state/pegTryggakollen";
import { responseErrorAction } from "@state/error";

import { PPMSummaryPrepared, PPMAcceptedAction, PPMDeclinedAction } from "./actions";

function* ppmAcceptedSaga() {
  const userId = yield select<AppState>(state => state.user.userId);

  yield put(postPPMConfirmationRequest.actions.running({ userId }));
  const resp: typeof postPPMConfirmationRequest.types.response = yield call(postPPMConfirmationRequest, { userId }, {});

  if (resp.ok) {
    yield put(postPPMConfirmationRequest.actions.ok({ userId }, resp));
    yield call(prepareSummarySaga);
    yield put(PPMSummaryPrepared({}));
  } else {
    yield put(postPPMConfirmationRequest.actions.error({ userId }, resp));
    yield put(responseErrorAction({ response: resp }));
  }
}

export function* ppmConfirmationSaga() {
  yield takeEvery(PPMAcceptedAction.type, ppmAcceptedSaga);
  yield takeEvery(PPMDeclinedAction.type, function*() {
    yield call(prepareSummarySaga);
    yield put(PPMSummaryPrepared({}));
  });
}
