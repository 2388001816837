import * as React from "react";

import { InstructionsLayout } from "./InstructionsLayout";
import { minpensionDataImages } from "@assets";

interface CollectionStatusProps {
  onNext(): any;
  onBack(): any;
}

export const CollectionStatus = ({ onNext, onBack }: CollectionStatusProps) => {
  return (
    <InstructionsLayout
      contentClassName="CollectionStatus"
      nextBtnText="Insamlingen är klar, ta mig till nästa steg"
      onNext={onNext}
      onBack={onBack}
    >
      <h5 className="MinpensionDataInstructions__title">Insamlingsstatus</h5>
      <p className="MinpensionDataInstructions__text">
        Är du inloggad på minpension.se?
        <br />
        <br />
        Har du precis registrerat dig kan det ta några minuter innan all information har hämtats. Vid "Insamlingsstatus"
        kan du se om det är klart eller inte. Du kommer få ett email när det är klart om det tar lång tid. Isåfall kan
        du komma tillbaka till den här sidan när det är klart.
        <img
          className="MinpensionDataInstructions__image MinpensionDataInstructions__image--wide"
          src={minpensionDataImages.collectionStatus}
          alt="Privat Pension Checkbox Image"
        />
      </p>
    </InstructionsLayout>
  );
};
