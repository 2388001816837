import * as React from "react";
import * as cn from "classnames";

import { ShowMoreLink } from "@components/common";

import "./MoneySlideForRetired.scss";

interface MoneySlideForRetiredProps {
  totalCapital: number | undefined;
  pensionRevisionDate: string | null;
  invertColors?: boolean;

  onShowMore?(): any;
}

export const MoneySlideForRetired = ({
  totalCapital,
  pensionRevisionDate,
  invertColors,
  onShowMore
}: MoneySlideForRetiredProps) => {
  const monthName = pensionRevisionDate
    ? new Date(pensionRevisionDate).toLocaleDateString("sv", { month: "short" })
    : "";
  const year = pensionRevisionDate ? new Date(pensionRevisionDate).toLocaleString("sv", { year: "numeric" }) : "";

  return (
    <div className={cn("MoneySlide", "MoneySlideForRetired", { "MoneySlide--inverse": invertColors })}>
      <div className="MoneySlideForRetired__top">
        <h3 className="MoneySlideForRetired__capital">
          {" "}
          {totalCapital ? `${totalCapital.toLocaleString("sv")} kr` : "Ingen info."}
        </h3>
      </div>
      <div className="MoneySlideForRetired__bot">
        <div className="MoneySlideForRetired__date">
          <p className="MoneySlideForRetired__month">{monthName}</p>
          <p className="MoneySlideForRetired__year">{year}</p>
        </div>
        {onShowMore && <ShowMoreLink text="Visa mer" onClick={onShowMore} />}
      </div>
    </div>
  );
};
