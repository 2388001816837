import { takeEvery, put, call, select } from "redux-saga/effects";
import { delay } from "redux-saga";
// import { push } from "react-router-redux";

import { prepareSummarySaga } from "@state/pegTryggakollen";
import { responseErrorAction } from "@state/error";
import { AppState } from "@state/store";

import {
  getUserInfoSaga,
  // isPPMConfirmedSelector
} from "@state/user";

// import { PPMConfirmationRoute } from "@common/routes";
import { startScrapingRequest, getJobRequest, getTryggakollenDataRequest, getUserInfoRequest } from "@common/requests";
import { isMobile, isBankIdStarted, setBankIdStarted, clearBankIdStarted, getBankIdUrl } from "@common/helpers";

import { startScraping, pollScrapingJob, updateScrapingJob } from "./actions";

function* startScrapingSaga() {
  clearBankIdStarted();
  const userId = yield select((state: AppState) => state.user.userId!);
  yield put(startScrapingRequest.actions.running({ id: userId }));
  const response: typeof startScrapingRequest.types.response = yield call(startScrapingRequest, { id: userId });
  if (response.ok) {
    yield put(startScrapingRequest.actions.ok({ id: userId }, response));
    yield put(pollScrapingJob({ jobId: response.result._id }));
  } else {
    yield put(startScrapingRequest.actions.error({ id: userId }, response));
    yield put(responseErrorAction({ response }));
  }
}

export function* getJobSaga(jobId: string) {
  yield put(getJobRequest.actions.running({ id: jobId }));
  const resp: typeof getJobRequest.types.response = yield call(getJobRequest, { id: jobId });
  if (resp.ok) {
    yield put(getJobRequest.actions.ok({ id: jobId }, resp));
  } else {
    yield put(getJobRequest.actions.error({ id: jobId }, resp));
    yield put(responseErrorAction({ response: resp, hideBtn: true }));
  }
  return resp;
}

export function* getScrapingSummarySaga(userId: string) {
  yield put(getTryggakollenDataRequest.actions.running({ id: userId }));
  const response: typeof getTryggakollenDataRequest.types.response = yield call(getTryggakollenDataRequest, {
    id: userId,
  });
  if (response.ok) {
    // in reducer we put summary in state
    yield put(getTryggakollenDataRequest.actions.ok({ id: userId }, response));
  } else {
    yield put(getTryggakollenDataRequest.actions.error({ id: userId }, response));
    yield put(responseErrorAction({ response, hideBtn: true }));
  }
  return response;
}

export function* scrapingFinishedSaga(userId: string) {
  // fetch updated user data
  const userResp: typeof getUserInfoRequest.types.response = yield call(getUserInfoSaga, { id: userId });
  if (!userResp.ok) {
    return;
  }

  // if pension revison was successfully created
  if (userResp.result.was_most_recent_pension_revision_successfully_created) {
    yield call(prepareSummarySaga);
  }
}

function* pollScrapingJobSaga(action: typeof pollScrapingJob.typeInterface) {
  const userId = yield select((state: AppState) => state.user.userId!);

  const response: typeof getJobRequest.types.response = yield getJobSaga(action.jobId);
  if (!response.ok) {
    return;
  }
  const job = response.result;
  // delay and then poll again if not finished or error
  if (job.failed) {
    // update user model if job fails
    // was added mainly because of terms-and-conditions-must-be-agreed-today
    const userResp: typeof getUserInfoRequest.types.response = yield call(getUserInfoSaga, { id: userId });
    if (!userResp.ok) {
      return;
    }
  } else {
    if (!job.finished_at) {
      if (job.current_step.name === "login-waiting:started" && !isBankIdStarted() && isMobile()) {
        setBankIdStarted();
        setTimeout(() => {
          location.href = job.results ? job.results[0] : getBankIdUrl();
          // tslint:disable-next-line:align
        }, 200);
      }
      // clear flag if login completed
      if (!!job.completed_step_names.find((stepName) => stepName === "login-waiting:completed") && isBankIdStarted()) {
        clearBankIdStarted();
      }
      yield delay(1000);
      yield put(pollScrapingJob({ jobId: action.jobId }));
    } else if (job.finished_at) {
      // scraping finished successfully
      yield call(scrapingFinishedSaga, userId);
    }
  }
  yield put(updateScrapingJob({ job }));
}

export function* refreshScrapingSummarySaga() {
  const userId = yield select((state: AppState) => state.user.userId!);
  yield call(getScrapingSummarySaga, userId);
}

export function* scrapingSaga() {
  yield takeEvery(startScraping.type, startScrapingSaga);
  yield takeEvery(pollScrapingJob.type, pollScrapingJobSaga);
}
