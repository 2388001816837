import * as React from "react";

import "./LifeInsuranceSummary.scss";
import { Layout, Button, LoadingOverlay } from "@components/common";

interface LifeInsuranceSummaryProps {
  coverage: number;
  price: number;
  isSaving: boolean;

  onPrev(): any;
  onNext(): any;
}

export const LifeInsuranceSummary = ({ isSaving, coverage, price, onPrev, onNext }: LifeInsuranceSummaryProps) => {
  return (
    <Layout title="Din Livförsäkring" contentClassName="LifeInsuranceSummary" onGoBack={onPrev}>
      <div className="LifeInsuranceSummary__card">
        <h3 className="LifeInsuranceSummary__title">Teckna försäkringen</h3>
        <p className="LifeInsuranceSummary__text">
          Du har valt att försäkra ett belopp om <strong>{coverage.toLocaleString("sv")} kr.</strong>
          <br />
          Din månadskostnad för försäkringen blir <strong>{price.toLocaleString("sv")} kr.</strong>
          <br />
          <br />
          Försäkringen innehåller vissa undantag som du kan läsa om i förköpsinformationen. Försäkringsvillkor och
          förköpsinformation finns på{" "}
          <a href="https://www.pensionskraft.se/livforsakring/" target="_blank">
            www.pensionskraft.se/livforsakring
          </a>
          .
        </p>

        <h3 className="LifeInsuranceSummary__title">Genom att signera bekräftar jag att jag:</h3>
        <p className="LifeInsuranceSummary__text">
          -tagit del av och förstått förköpsinformationen
          <br />
          -är folkbokförd i Sverige <br />
          -har fyllt 18 men inte 64 år (skyddet gäller tills fyllda 65 år) <br />
          -är fullt frisk och fullt arbetsför, inte har kännedom om allvarlig sjukdom, sjukdomstillstånd, skada eller
          kommande sjukskrivning <br />
          -under de senaste 12 månaderna inte haft någon kontakt med hälso- och sjukvården eller fått medicinering för
          en kronisk eller återkommande sjukdom <br />
          -inte har någon sjukhusvistelse inplanerad och/eller inte har ett läkar-/specialistbesök inbokat för
          odiagnostiserat tillstånd eller symptom samt <br />
          -aldrig fått behandling för: stroke/TIA någon form av cancer, leukemi eller tumör, någon form av
          transplantation, hjärt-/kärlsjukdom, immunbristsjukdom, nedsatt njurfunktion eller diabetes <br /> <br />
          Det är viktigt att du uppfyller ovan annars kan försäkringsersättning utebli.
        </p>

        <h3 className="LifeInsuranceSummary__title">Ångerrätt</h3>
        <p className="LifeInsuranceSummary__text">
          Du kan kontakta Pensionskraft inom 30 dagar efter att du fått hem försäkringsbeskedet, om du vill ångra
          tecknandet av försäkringen. Du kan därefter säga upp försäkringen när du vill. Ytterligare information om
          ångerrätt och uppsägning finner du i förköpsinformationen.
        </p>
      </div>

      <Button text="Teckna försäkringen" primary onClick={onNext} />

      <LoadingOverlay isShown={isSaving} />
    </Layout>
  );
};
