import { createSelector } from "reselect";
import get from "lodash-es/get";

import { FundsRecommendationsEntity } from "@common/models";
import { isPPMFund } from "@common/helpers";

import { scrapingResultSelector } from "@state/scraping/selectors";
import { pensionPlansSelector } from "@state/showMore/selectors";

export const fundRecommendationsSelector = createSelector(
  [scrapingResultSelector],
  data => get(data, "summary.recommendation.funds.by_pension_institute", []) as FundsRecommendationsEntity[]
);

const recommendedPPMFundSelectorCreator = (isRebalancing: boolean) =>
  createSelector([pensionPlansSelector, fundRecommendationsSelector], (pensionPlans, fundRecommendations) => {
    const recommendation = fundRecommendations.find(rec => isPPMFund(rec.pension_institute) && !rec.submitted_date);
    if (!recommendation) {
      return [];
    }
    const pensionPlan = pensionPlans.find(
      pp =>
        isPPMFund(pp.pension_institute) &&
        !!(recommendation.insurance_numbers || []).find(insNumber => insNumber === pp.insurance_name) &&
        !!pp.is_rebalancing === isRebalancing
    );
    return pensionPlan ? pensionPlan.recommended_funds || [] : [];
  });

export const recommendedRebalancePPMFundsSelector = recommendedPPMFundSelectorCreator(true);

export const recommendedStandardPPMFundsSelector = recommendedPPMFundSelectorCreator(false);
