import { Action } from "redux";

import { RequestsState, defaultRequestsState } from "./state";
import {
  postInsuranceApplicationRequest,
  postSignInsuranceApplicationRequest,
  putCancelInsuranceApplicationRequest,
  getActiveInsurancesRequest,
  postSkipInsuranceOfferRequest,
  getCurrentApplicationRequest,
  getMinpensionDataRequest,
  postMinpensionDataRequest,
  getPensionInstitutesRequest,
  postProcessMinpensionDataRequest,
} from "@common/requests";

const requests = {
  postInsuranceApplication: postInsuranceApplicationRequest,
  postSignInsuranceApplication: postSignInsuranceApplicationRequest,
  putCancelInsuranceApplication: putCancelInsuranceApplicationRequest,
  getActiveInsurances: getActiveInsurancesRequest,
  postSkipInsuranceOffer: postSkipInsuranceOfferRequest,
  getCurrentApplication: getCurrentApplicationRequest,
  getMinpensionData: getMinpensionDataRequest,
  postMinpensionData: postMinpensionDataRequest,
  getPensionInstitutes: getPensionInstitutesRequest,
  postProcessMinpensionData: postProcessMinpensionDataRequest,
};

export function requestsReducer(state: RequestsState = defaultRequestsState, action: Action): RequestsState {
  for (const requestName of Object.keys(requests)) {
    const request = requests[requestName];
    if (request.actions.isRunning(action)) {
      return { ...state, [requestName]: { loading: true } };
    }
    if (request.actions.isOk(action)) {
      return { ...state, [requestName]: { loading: false } };
    }
    if (request.actions.isError(action)) {
      return { ...state, [requestName]: { loading: false, error: action["error"] } };
    }
  }

  return state;
}
