import { takeEvery, put, select } from "redux-saga/effects";
import { LocationChangeAction, replace } from "connected-react-router";

import { isFreeUserSelector, premiumRouteNotPermittedAction } from "@state/user";
import { checkAccessCodeAction } from "@state/auth";
import {
  HomeRoute,
  isPremiumRoute,
  ChangeSavingsRoute,
  TodoItemBenefitsDetailsRoute,
  PPMConfirmationRoute
} from "@common/routes";
import { AppState } from "@state/store";
import { recentCompletedItemSelector, clearCompletedItemAction } from "@state/todoItem";
import { changeMonthlyDepositAction } from "@state/changeSavings";

import { isLocationChangeAction } from "./actions";

export function* customRouterSaga() {
  yield takeEvery(isLocationChangeAction, function*(action: LocationChangeAction) {
    window.scroll(0, 0);

    const isInititalRoute = yield select<AppState>(state => !state.router.prevLocation);

    if (action.payload.location.pathname.startsWith("/!")) {
      const match = action.payload.location.pathname.match(/\/\!(.+)?/);
      const loginCode = (match || [])[1];

      yield put(checkAccessCodeAction({ code: loginCode }));
    }

    const isFreeVersion = yield select<AppState>(isFreeUserSelector);
    if (isPremiumRoute(action.payload.location.pathname) && isFreeVersion) {
      yield put(premiumRouteNotPermittedAction(HomeRoute.format({})));
    }

    // clear todo item if user don't want to wait for animation
    const { wasHomeRoute, hasCompletedItem } = yield select<AppState>(state => ({
      wasHomeRoute: state.router.prevLocation && state.router.prevLocation.pathname === "/",
      hasCompletedItem: !!recentCompletedItemSelector(state)
    }));
    if (wasHomeRoute && hasCompletedItem) {
      yield put(clearCompletedItemAction({}));
    }

    // clear selected slider value when user is not in change savings flow
    const { wasChangeSavingsRoute, isNextStepOrBenefitsRoute } = yield select<AppState>(state => ({
      wasChangeSavingsRoute:
        state.router.prevLocation && ChangeSavingsRoute.match(state.router.prevLocation.pathname).isMatched,
      isNextStepOrBenefitsRoute:
        state.router.location &&
        (TodoItemBenefitsDetailsRoute.match(state.router.location.pathname).isMatched ||
          ChangeSavingsRoute.match(state.router.location.pathname).isMatched)
    }));
    if (wasChangeSavingsRoute && !isNextStepOrBenefitsRoute) {
      yield put(changeMonthlyDepositAction({ deposit: undefined }));
    }

    // redirect from ppm-confirmation or peg tryggakollen wating if it's the first route
    const isPPMConfirmationRoute = yield select<AppState>(
      state => !!state.router.location && PPMConfirmationRoute.match(state.router.location.pathname).isMatched
    );
    if (isInititalRoute && isPPMConfirmationRoute) {
      yield put(replace(HomeRoute.format({})));
    }
  });
}
