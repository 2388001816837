import { push } from "connected-react-router";
import { takeEvery, put } from "redux-saga/effects";

import { LoginRoute } from "@common/routes";

import { showErrorAction, responseErrorAction } from "./actions";

export function* errorSaga() {
  yield takeEvery(showErrorAction.type, function*(action: typeof showErrorAction.typeInterface) {
    yield put(push("/error"));
  });

  yield takeEvery(responseErrorAction.type, function*({ response, hideBtn }: typeof responseErrorAction.typeInterface) {
    if (response.errorType === "transport" && !navigator.onLine) {
      yield put(showErrorAction({ message: "Det finns ingen internetuppkoppling.", hideBtn }));
    } else if (response.errorType === "authorization") {
      yield put(push(LoginRoute.format({})));
    } else {
      yield put(
        showErrorAction({
          message: `Något gick fel. ${hideBtn ? "K" : "Försök igen. Är problemet kvar k"}ontakta supporten.`,
          hideBtn
        })
      );
    }
  });
}
