import * as React from "react";
import { useState } from "react";

import { getFundsForTypeahedRequest } from "@common/requests";
import { SelectAsync, OptionType, TextField, TextFieldType, Button, RippleEffect, Cross } from "@components/common";
import { FundInList } from "@common/models";

import "./FundSelect.scss";

interface FundSelectProps {
  shellId: string;
  fundsList: FundInList[];

  fundListHeader: string;

  onAddFund(newFunds: FundInList): any;
  onRemoveFund(fundId: string): any;
  onFundValuationChange(newVal: number, fundId: string): any;
}

export const FundSelect = ({
  shellId,
  fundsList,
  fundListHeader,
  onAddFund,
  onRemoveFund,
  onFundValuationChange,
}: FundSelectProps) => {
  const [selectedFund, setSelectedFund] = useState<OptionType | null>(null);
  const [fundValuation, setFundValuation] = useState<number>(0);

  const newFundDataPresent = selectedFund && fundValuation;

  const addFund = () => {
    if (!newFundDataPresent) {
      return;
    }
    onAddFund({ fund_in_shell_id: selectedFund!.value, name: selectedFund!.label, valuation: fundValuation });
    setSelectedFund(null);
    setFundValuation(0);
  };

  const loadOptions = (search: string) =>
    search.length >= 3
      ? getFundsForTypeahedRequest({ shellId, search }).then((resp) => (resp.ok ? resp.result : []))
      : new Promise((resolve) => resolve(undefined));

  return (
    <div className="FundSelect">
      <div className="FundSelect__fundsList">
        <h5 className="FundSelect__title">{fundListHeader}</h5>
        {fundsList.map((fund) => (
          <div className="FundSelect__fundItem" key={fund.fund_in_shell_id}>
            <p>{fund.name}</p>
            <TextField
              type={TextFieldType.Number}
              value={fund.valuation.toString()}
              onChange={(v) => onFundValuationChange(+v, fund.fund_in_shell_id)}
              suffix="kr"
            />
            <RippleEffect
              className="FundSelect__fundItem__delete"
              onClick={() => onRemoveFund(fund.fund_in_shell_id)}
              circled
            >
              <Cross color="red" size="1.2rem" />
            </RippleEffect>
          </div>
        ))}
      </div>
      <div className="FundSelect__addFund">
        <h5 className="FundSelect__title">Lägg till ny fond</h5>
        <div className="FundSelect__fundForm">
          <div className="FundSelect__fundFormRow">
            <p>Ange fond:</p>
            <SelectAsync
              value={selectedFund}
              loadOptions={loadOptions}
              onChange={(v) => setSelectedFund(v)}
              noOptionsMessage={({ inputValue }) =>
                inputValue.length >= 3
                  ? "Hittar inte fonden. Se till att du stavat rätt. Klicka på (HELP ICON) för att få mer hjälp eller kontakta kundtjänst."
                  : "Börja skriv fondnamnet"
              }
              loadingMessage={() => "Laddar"}
              placeholder="Välj..."
            />
          </div>
          <div className="FundSelect__fundFormRow">
            <p>Värde:</p>
            <TextField
              type={TextFieldType.Number}
              value={fundValuation?.toString()}
              onChange={(v) => setFundValuation(+v)}
              suffix="kr"
            />
          </div>

          <Button text="Lägg till fond" onClick={addFund} disabled={!newFundDataPresent} />
        </div>
      </div>
    </div>
  );
};
