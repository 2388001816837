import { connectRouter, LocationChangeAction } from "connected-react-router";
import { History } from "history";

import { isLocationChangeAction, isRouterAction } from "./actions";
import { CustomRouterState } from "./state";

export function customRouterReducer(history: History<any>) {
  const reducer = connectRouter(history);

  const defaultState = {
    ...reducer(...(([] as unknown) as [any, any])),
    prevLocation: null
  };

  return function(state: CustomRouterState = defaultState, action: LocationChangeAction): CustomRouterState {
    if (isLocationChangeAction(action) || isRouterAction(action)) {
      return {
        ...(state || {}),
        ...reducer(state, action),
        prevLocation: state ? state.location : null
      };
    }

    return state;
  };
}
