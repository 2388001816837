import { MeterType } from "./types";
import { TodoItemModel } from "./models";

export function isEmptyObject(obj: any) {
  return !obj || (obj.constructor === Object && Object.keys(obj).length === 0);
}

export function isMobile(): boolean {
  return /Android|iPhone|iPad|iPod/i.test(window.navigator.userAgent);
}
export const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export const isIOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);

export const getSign = (val: number) => (val > 0 ? "+" : "");

export const truncateText = (text: string, length: number) =>
  text.length > length ? `${text.substring(0, length)}…` : text;

export function getFormattedPercentsValue(input: number | string) {
  if (typeof input === "number") {
    return getSign(input) + input.toLocaleString("sv", { minimumFractionDigits: 1, maximumFractionDigits: 1 }) + "%";
  } else {
    const parsed = +input;
    return isNaN(parsed)
      ? "N/A"
      : getSign(parsed) + parsed.toLocaleString("sv", { minimumFractionDigits: 1, maximumFractionDigits: 1 }) + "%";
  }
}

export function getMeterTitle(type: MeterType): string {
  switch (type) {
    case MeterType.Savings:
      return "Sparat";
    case MeterType.Risk:
      return "Risk";
    case MeterType.Funds:
      return "Fond";
    default:
      return "";
  }
}

export function dataURItoBlob(dataURI: string) {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  var byteString = atob(dataURI.split(",")[1]);

  // separate out the mime component
  var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

  // write the bytes of the string to an ArrayBuffer
  var ab = new ArrayBuffer(byteString.length);

  // create a view into the buffer
  var ia = new Uint8Array(ab);

  // set the bytes of the buffer to the correct values
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  // write the ArrayBuffer to a blob, and you're done
  var blob = new Blob([ab], { type: mimeString });
  return blob;
}

export function isPPMFund(pensionInstitute: string): boolean {
  return pensionInstitute === "Pensionsmyndigheten";
}

// const pensionInstituteList = ["Nordea", "Danica Pension"];
const pensionInstituteList = ["Nordea"];

export function canSignDigitally(pensionInstitute: string): boolean {
  return !pensionInstituteList.find((inst) => inst === pensionInstitute);
}

export const roundNumberTo = (num: number, digits: number = 2): number => {
  const mult = Math.pow(10, digits);
  return Math.round(num * mult) / mult;
};

export const formatCapital = (num: number) => (Math.round(num / (100 * 1000)) * 1000).toLocaleString("sv");

// time interval in milleseconds
export const formatTimeInterval = (from: Date, to: Date): string => {
  const interval = to.getTime() - from.getTime();
  const minute = 60 * 1000;
  const hour = minute * 60;
  const day = hour * 24;
  const month = day * 30;
  if (interval < minute) {
    return "Precis nu";
  } else if (interval >= minute && interval < hour) {
    return `${Math.floor(interval / minute)}min`;
  } else if (interval >= hour && interval < day) {
    return `${Math.floor(interval / hour)}h`;
  } else if (interval >= day && interval < month) {
    return `${Math.floor(interval / day)}d`;
  } else {
    return `${from.getDate()}/${from.getMonth() + 1}`;
  }
};

let itemNo = 0;
export const getItemKey = (item: TodoItemModel): string => {
  itemNo += 1;
  return `todo_item_${item.type}_${itemNo}`;
};

export const getCurrentLevelName = (score: number): string => {
  if (score <= 2.49) {
    return "Nybörjare"; // Beginner'
  }
  if (score >= 2.5 && score <= 2.99) {
    return "På gång"; // Getting started'
  }
  if (score >= 3 && score <= 3.49) {
    return "Medel"; // Average
  }
  if (score >= 3.5 && score <= 3.99) {
    return "Bra"; // Good
  }
  if (score >= 4 && score <= 4.49) {
    return "Jättebra"; // Getting started'
  }
  if (score >= 4.5) {
    return "King"; // Getting started'
  }

  return "";
};

export function getArrowDirection(value: number) {
  if (value > 0) {
    return "asc";
  } else if (value < 0) {
    return "desc";
  } else {
    return "none";
  }
}

export const getFundName = (name: string) => {
  if (window.innerWidth < 420) {
    return truncateText(name, 35);
  } else if (window.innerWidth < 800) {
    return truncateText(name, 100);
  } else {
    return name;
  }
};

export const riskLevelTranslations = {
  high: "Hög",
  medium: "Mellan",
  low: "Låg",
};

export const riskLevelToKiidRisk = {
  low: 2.5,
  medium: 3.5,
  high: 4.5,
};

export const getBankIdUrl = (url?: string) => {
  // const hasHash = location.href.includes("#");
  // const redirectUrl = url || location.href;
  return `bankid:///?redirect=null`;
};
const bankIdStartedKey = "setBankIdAppStarted";
export const setBankIdStarted = () => localStorage.setItem(bankIdStartedKey, "true");
export const isBankIdStarted = () => !!localStorage.getItem(bankIdStartedKey);
export const clearBankIdStarted = () => localStorage.removeItem(bankIdStartedKey);

const loginCodeKey = "loginCode";
export const setLoginCode = (code: string) => localStorage.setItem(loginCodeKey, code);
export const getLoginCode = () => localStorage.getItem(loginCodeKey);
export const clearLoginCode = () => localStorage.removeItem(loginCodeKey);

const personNumberKey = "personNumber";
export const setPersonNumber = (personNumber: string) => localStorage.setItem(personNumberKey, personNumber);
export const getPersonNumber = () => localStorage.getItem(personNumberKey);
export const clearPersonNumber = () => localStorage.removeItem(personNumberKey);

const introWatchedKey = "introWatched";
export const setIntroWatched = () => localStorage.setItem(introWatchedKey, "true");
export const getIntroWatched = () => !!localStorage.getItem(introWatchedKey);
export const clearIntroWatched = () => localStorage.removeItem(introWatchedKey);

const useMobileAnywayKey = "useMobileAnyway";
export const setUseMobileAnyway = () => localStorage.setItem(useMobileAnywayKey, "true");
export const getUseMobileAnyway = () => !!localStorage.getItem(useMobileAnywayKey);
