import * as React from "react";
import { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { Redirect } from "react-router-dom";

import { HomeRoute } from "@common/routes";
import { isMobile } from "@common/helpers";
import { InsuranceApplicationRecommendedFund } from "@common/models";
import { BlockerScreen, BlockerScreenType, LoadingOverlay, Button, Layout, RippleEffect } from "@components/common";

import { AppState } from "@state/store";
import {
  InsuranceApplicationStep,
  signInsuranceApplicationAction,
  cancelInsuranceAplication,
} from "@state/changeSavings";

import { InsuranceApplicationReceipt } from "./receipt";

import "./InsuranceApplication.scss";

interface InsuranceApplicationComponentProps {
  step: InsuranceApplicationStep;
  insuranceNumber?: string;
  monthlyPayment: number;
  recommendedFunds: InsuranceApplicationRecommendedFund[];
  isCancellingApplication: boolean;
}

export const InsuranceApplicationComponent = connect((state: AppState) => ({
  step: state.changeSavings.insuranceApplicationStep,
  monthlyPayment: state.changeSavings.insuranceApplicationResponse?.monthlyPayment,
  recommendedFunds: state.changeSavings.insuranceApplicationResponse?.recommendedFunds,
  isCancellingApplication: state.requests.putCancelInsuranceApplication.loading,
}))(
  ({
    step,
    monthlyPayment,
    recommendedFunds,
    insuranceNumber,
    isCancellingApplication,
  }: InsuranceApplicationComponentProps) => {
    const dispatch = useDispatch();
    const [showReceipt, setShowReceipt] = useState(false);

    if (showReceipt) {
      return (
        <InsuranceApplicationReceipt
          onGoBack={() => setShowReceipt(false)}
          onConfirm={() => {
            setShowReceipt(false);
            dispatch(signInsuranceApplicationAction({}));
          }}
        />
      );
    }

    switch (step) {
      case InsuranceApplicationStep.WaitingSend:
        return (
          <BlockerScreen
            type={BlockerScreenType.Progress}
            title="Ditt nya sparande"
            content={
              <>
                Vänta medan Futur Pension behandlar dina uppgifter
                <br />
                <br />
                Det kan ta någon minut...
              </>
            }
          />
        );

      case InsuranceApplicationStep.WaitingSign:
        return (
          <BlockerScreen
            type={BlockerScreenType.Progress}
            title="BankID"
            content={`${isMobile() ? "Startar" : "Starta"} ditt BankID`}
          />
        );

      case InsuranceApplicationStep.WaitingProcessed:
        return (
          <BlockerScreen
            type={BlockerScreenType.Progress}
            title="Snart klart"
            content={
              <>
                Ditt nya sparande är snart klart. Väntar nu på att du ska få ditt försäkringsnummer från Futur Pension.
                <br />
                <br />
                Det kan ta någon minut...
              </>
            }
          />
        );

      case InsuranceApplicationStep.Processed:
        return <Redirect to={HomeRoute.format({})} />;

      case InsuranceApplicationStep.Summary:
        return (
          <>
            <Layout title="Summering" contentClassName="InsuranceApplicationSummary">
              <div className="InsuranceApplicationSummary__card">
                <h5 className="InsuranceApplicationSummary__title">Sammanfattning</h5>
                <p className="InsuranceApplicationSummary__text">
                  Spara <strong>{monthlyPayment.toLocaleString("sv")}</strong> kr/mån. <br /> Fast avgift 360 kr/år.
                </p>
                <div className="Settings__line" />
                <p className="InsuranceApplicationSummary__subtitle">Valda fonder till dig:</p>
                <div className="InsuranceApplicationSummary__links">
                  {recommendedFunds.map((fund) => (
                    <a
                      target="__blank"
                      key={fund.isin}
                      className="InsuranceApplicationSummary__link"
                      href={`https://www.morningstar.se/se/funds/snapshot/snapshot.aspx?id=${fund.sec_id}`}
                    >
                      {fund.name}
                    </a>
                  ))}
                </div>
                <p className="InsuranceApplicationSummary__text">Du kan ändra fonderna senare.</p>
              </div>

              <div className="InsuranceApplicationSummary__actions">
                <Button text="Nästa" onClick={() => setShowReceipt(true)} />
                <RippleEffect
                  className="InsuranceApplicationSummary__skip"
                  rounded
                  onClick={() => dispatch(cancelInsuranceAplication({}))}
                >
                  {"Avbryt"}
                </RippleEffect>
              </div>
            </Layout>
            <LoadingOverlay isShown={isCancellingApplication} />
          </>
        );

      case InsuranceApplicationStep.ManualValidation:
        return (
          <BlockerScreen
            type={BlockerScreenType.Wait}
            title="Tack för din förfrågan."
            content={
              <>
                Nu kommer dina uppgifter att gås igenom. Du får ett email när det är klart. Normalt tar det upp till två
                vardagar.
              </>
            }
            btnText="OK"
            onClick={() => dispatch(push(HomeRoute.format({})))}
          />
        );

      case InsuranceApplicationStep.Error:
      case InsuranceApplicationStep.Rejected:
        return (
          <>
            <BlockerScreen
              type={BlockerScreenType.Error}
              title="Oops, något gick fel"
              content={
                <>
                  Kontakta{" "}
                  <RippleEffect
                    rounded
                    inline
                    onClick={() => dispatch(cancelInsuranceAplication({ goToSupport: true }))}
                  >
                    kundtjänst
                  </RippleEffect>{" "}
                  för mer info.{" "}
                </>
              }
              btnText="Gå hem"
              onClick={() => dispatch(cancelInsuranceAplication({}))}
            />
            <LoadingOverlay isShown={isCancellingApplication} />
          </>
        );

      default:
        return <LoadingOverlay isShown />;
    }
  }
);
