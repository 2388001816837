import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Redirect } from "react-router-dom";

import { icons } from "@assets";
import { Button, StarsWithScore, WithInnerHeight } from "@components/common";
import { TodoItemModel, BasicScoringModel } from "@common/models";
import { TodoItemType } from "@common/types";
import {
  selectedTodoItemSelector,
  currentScoresSelector,
  markItemAsCompleted,
  recentCompletedItemSelector,
} from "@state/todoItem";
import { AppState } from "@state/store";
import { HomeRoute } from "@common/routes";

import "./TodoItemSuccess.scss";

interface TodoItemSuccessProps {
  item: TodoItemModel | undefined;
  completedItem: TodoItemModel | undefined;
  scores: BasicScoringModel;
  insuranceNumber?: string;

  onBtnClick(): any;
}

export const TodoItemSuccess = connect(
  (state: AppState) => ({
    item: selectedTodoItemSelector(state),
    completedItem: recentCompletedItemSelector(state),
    scores: currentScoresSelector(state),
    insuranceNumber: state.changeSavings.insuranceApplicationResponse?.insuranceNumber,
  }),
  (dispatch: Dispatch) => ({
    onBtnClick() {
      dispatch(markItemAsCompleted({ withRedirect: true }));
    },
  })
)(({ item: selected, completedItem, scores, insuranceNumber, onBtnClick }: TodoItemSuccessProps) => {
  const item = selected || completedItem;
  return !!item ? (
    <WithInnerHeight>
      <section className="TodoItemSuccess">
        <img className="TodoItemSuccess__checkmark" src={icons.checkmark} alt="" />
        <h1 className="TodoItemSuccess__title">{getTitle(item)}</h1>
        <p className="TodoItemSuccess__description">{getDescription(item, insuranceNumber)}</p>
        <Button inverse text="Klart!" onClick={onBtnClick} />
        {showScore(item) && (
          <StarsWithScore
            score={scores.total}
            centered
            starFillColor="white"
            starBackgroundColor="rgba(255, 255, 255, 0.4)"
          />
        )}
      </section>
    </WithInnerHeight>
  ) : (
    <Redirect to={HomeRoute.format({})} />
  );
});

const getTitle = (item: TodoItemModel): React.ReactNode => {
  if (!item) {
    return "";
  }
  switch (item.type) {
    case TodoItemType.ChangeFunds:
      return item.payload.isRebalancing ? (
        "Rebalansera fonder"
      ) : (
        <React.Fragment>
          Byt fonder hos <br />
          {item.payload.pensionInstitute}
        </React.Fragment>
      );
    case TodoItemType.ChangeSavings:
      return "Grattis till ditt nya sparande!";
    default:
      return "";
  }
};

const getDescription = (item: TodoItemModel, insuranceNumber?: string): React.ReactNode => {
  if (!item) {
    return "";
  }
  switch (item.type) {
    case TodoItemType.ChangeFunds:
      return "Då var allt klart! Ditt nya betyg är:";
    case TodoItemType.ChangeSavings:
      return (
        <React.Fragment>
          Ditt försäkringsnummer är <strong className="TodoItemSuccess__insuranceNumber">{insuranceNumber}</strong>.
          <br />
          Du kommer inom kort få ett försäkringsbesked skickat till dig från Futur Pension.
          <br />
          <br />
          Ditt nya betyg är:
        </React.Fragment>
      );
    case TodoItemType.LifeInsurance:
    case TodoItemType.SafetyInsurance:
      return (
        <React.Fragment>
          Grattis du har nu tecknat en{" "}
          {item.type === TodoItemType.LifeInsurance ? "livförsäkring" : "trygghetsförsäkring"}!
          <br />
          <br />
          Du kommer få försäkringsbeskedet skickat hem till dig via post.
        </React.Fragment>
      );
    case TodoItemType.LifeInsuranceUpsell:
    case TodoItemType.SafetyInsuranceUpsell:
      return (
        <React.Fragment>
          Grattis du har nu tecknat en{" "}
          {item.type === TodoItemType.LifeInsuranceUpsell ? "livförsäkring" : "trygghetsförsäkring"}!
          <br />
          <br />
          Du kommer få försäkringsbeskedet skickat hem till dig via post.
        </React.Fragment>
      );
    default:
      return "";
  }
};

const showScore = (item: TodoItemModel): React.ReactNode => {
  switch (item.type) {
    case TodoItemType.LifeInsurance:
    case TodoItemType.SafetyInsurance:
    case TodoItemType.LifeInsuranceUpsell:
    case TodoItemType.SafetyInsuranceUpsell:
      return false;
    default:
      return true;
  }
};
