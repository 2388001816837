import * as React from "react";

import { Layout, LoadingOverlay, Button, RippleEffect, BlockerScreen, BlockerScreenType } from "@components/common";
import { AxaInsuranceOptions } from "@common/models";

import "./LifeInsuranceBenefits.scss";

interface LifeInsuranceBenefitsProps {
  alternatives: AxaInsuranceOptions | undefined;
  isUpsell: boolean;
  isSkipping: boolean;

  onNext(selectedAmount: number): any;
  onSkip(): any;
}

export const StandaloneLifeInsuranceBenefits = ({
  isUpsell,
  isSkipping,
  alternatives,
  onNext,
  onSkip
}: LifeInsuranceBenefitsProps) => {
  if (alternatives && !alternatives.coverAmounts.length) {
    return (
      <BlockerScreen
        type={BlockerScreenType.Error}
        title="Redan klart!"
        content={<>Du har redan ändrat din premie och kan inte höja den mer. </>}
      />
    );
  }

  return (
    <Layout title="Fördelarna för dig" contentClassName="LifeInsuranceBenefits">
      <div className="LifeInsuranceBenefits__card">
        <p>
          En livförsäkring är extra värdefull om du har familj eller om ni har olika inkomster i hushållet. Din familj
          kan få ekonomisk ersättning för den närmaste tiden och har råd att behålla boendet. Inga snabba ekonomiska
          beslut behöver tas – dina anhöriga kan i lugn och ro fundera på hur de vill lägga upp sin ekonomi i framtiden.
          <br />
          <br />
          Genom att teckna en livförsäkring kan din familj få en ekonomisk trygghet. Här kan du läsa mer:
          <br />
          <a href="https://www.pensionskraft.se/livforsakring/" target="_blank">
            www.pensionskraft.se/livforsakring
          </a>
        </p>
      </div>
      <div className="LifeInsuranceBenefits__alternatives">
        {alternatives &&
          alternatives.coverAmounts.map(amount => (
            <Button
              key={`life_insurance_${amount}`}
              text={`${amount.toLocaleString("sv")} kr (${alternatives.grossMonthlyPremium[amount].toLocaleString(
                "sv"
              )} kr/mån)`}
              onClick={() => onNext(amount)}
            />
          ))}
      </div>

      <RippleEffect className="SafetyInsuranceBenefits__skip" rounded onClick={onSkip}>
        {isUpsell ? "Nej tack, jag vill inte ändra nivån" : "Nej tack, jag vill inte ha denna försäkring"}
      </RippleEffect>

      <LoadingOverlay isShown={!alternatives || isSkipping} />
    </Layout>
  );
};
