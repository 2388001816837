import * as React from "react";
// import { useState, useEffect } from "react";
import { Formik, FormikProps, Field } from "formik";
import * as Yup from "yup";

import { FormikTextField, TextFieldType, Button, FormikSelect, InfoItem } from "@components/common";
import { AutogiroForm } from "@common/models";

import { swedishBanks } from "./swedishBanks";

import "./Autogiro.scss";

const bankNameOptions = swedishBanks.map(bank => ({ label: bank, value: bank }));
export const AutogiroSchema = Yup.object().shape({
  bankName: Yup.string().oneOf(swedishBanks),
  sortCode: Yup.string().required(),
  accountNumber: Yup.string().required()
});

interface PensaAutogiroProps {
  initialValues?: AutogiroForm;

  subscriptionInfo: React.ReactNode;
  termsInfo: React.ReactNode;
  skipBankName?: boolean;
  title?: string;

  submitBtnText?: string;
  onSubmit(form: AutogiroForm): any;
}

// interface ChecklistProps {
//   list: React.ReactNode[];

//   onChange(allChecked: boolean): any;
// }

// const Checklist = ({ list }: ChecklistProps) => {
//   const [checked, setChecked] = useState({});

//   const check = (i: number) => {
//     const newChecked = {...checked, [i]: !checked[i]};
//     list.some((_, i) => )
//     setChecked();
//   };

//   useEffect(() => {
//     setChecked({})
//   }, [list]);

//   return <div className="PensaAutogiro__checklist">
//     {list}
//   </div>
// };

export const PensaAutogiro = function({
  initialValues,
  subscriptionInfo,
  termsInfo,
  submitBtnText,
  skipBankName,
  title,
  onSubmit
}: PensaAutogiroProps) {
  return (
    <>
      <InfoItem text={subscriptionInfo} />
      <Formik
        initialValues={initialValues}
        isInitialValid={(props: PensaAutogiroProps) => AutogiroSchema.isValidSync(props.initialValues)}
        validationSchema={AutogiroSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {(formikProps: FormikProps<AutogiroForm>) => {
          return (
            <form className="PensaAutogiro" onSubmit={formikProps.handleSubmit} noValidate autoComplete="off">
              <div className="PensaAutogiro__card">
                <h5 className="PensaAutogiro__title">{title || "Autogiromedgivande"}</h5>
                <p className="PensaAutogiro__text">{termsInfo}</p>
                {!skipBankName && (
                  <>
                    <Field
                      component={FormikSelect}
                      name="bankName"
                      label="Bank"
                      options={bankNameOptions}
                      placeholder="Välj..."
                      hideError={true}
                      id="life-insurance-bank-name"
                      isSearchable
                    />
                    <div className="Settings__line" />{" "}
                  </>
                )}
                <Field
                  component={FormikTextField}
                  name="sortCode"
                  label="Clearingnummer"
                  type={TextFieldType.NumberAndHyphen}
                  hideError={true}
                  id="life-insurance-sort-code"
                />
                <div className="Settings__line" />
                <Field
                  component={FormikTextField}
                  name="accountNumber"
                  label="Kontonummer"
                  type={TextFieldType.NumberAndHyphen}
                  hideError={true}
                  id="life-insurance-account-number"
                />
              </div>
              <Button
                text={submitBtnText || "Nästa"}
                onClick={formikProps.handleSubmit}
                disabled={!formikProps.isValid}
              />
            </form>
          );
        }}
      </Formik>
    </>
  );
};
