import * as React from "react";

interface CrossProps {
  color?: string;
  size: string;
  className?: string;
}

export const Cross = ({ color, size, className }: CrossProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" className={className} width={size} height={size} viewBox="0 0 14 14">
    <g fill="none" fillRule="evenodd">
      <path d="M-4-4h22v22H-4z" />
      <path
        fill={color || "#FFF"}
        d="M13.417 1.876L12.124.583 7 5.707 1.876.583.583 1.876 5.707 7 .583 12.124l1.293 1.293L7 8.293l5.124 5.124 1.293-1.293L8.293 7z"
      />
    </g>
  </svg>
);
