import * as React from "react";
import * as ReactDOM from "react-dom";

import { Provider } from "react-redux";
import { Route, Switch, Redirect } from "react-router-dom";
import { ConnectedRouter, replace } from "connected-react-router";
import { createBrowserHistory } from "history";

import { createStoreWithHistory } from "./state/store";
import { whoAmIAction } from "@state/user";
import {
  // PPMConfirmationRoute,
  SummaryRoute,
  ErrorWithMessageRoute,
  // HomeRoute,
  TodoItemBenefitsRoute,
  UpdateProfileBenefitsRoute,
  ChangeFundsRoute,
  ChangeSavingsRoute,
  LifeInsuranceRoute,
  TodoItemSuccessRoute,
  SupportRoute,
  SupportFAQRoute,
  SupportTermsRoute,
  ShowMoreRoute,
  SettingsRoute,
  SettingsRiskLevelRoute,
  SettingsPensionProfileRoute,
  NotificationsRoute,
  NotificationDetailsRoute,
  LoginRoute,
  HomeRoute,
  PensionProfileUpdateRoute,
  IntroRoute,
  SafetyInsuranceRoute,
  StandaloneLifeInsuranceRoute,
  StandaloneInsuranceSuccessRoute,
  StandaloneSafetyInsuranceRoute,
  LifeInsuranceUpsellRoute,
  SafetyInsuranceUpsellRoute,
  StandaloneLifeInsuranceUpsellRoute,
  StandaloneSafetyInsuranceUpsellRoute,
  StandaloneOfferSkippedRoute,
  StandaloneOfferCompletedRoute,
  InsuranceApplicationRoute,
  MinpensionDataRoute,
  MinpensionDataProcessingRoute,
} from "./common/routes";
import {
  // PPMConfirmation,
  Summary,
  Error404Page,
  ErrorWithMessage,
  Home,
  Login,
  TodoItemBenefits,
  UpdateProfileBenefits,
  ChangeFunds,
  ChangeSavings,
  LifeInsurance,
  TodoItemSuccess,
  Support,
  SupportFAQ,
  SupportTerms,
  ShowMore,
  Settings,
  SettingsRiskLevel,
  SettingsPensionProfile,
  NotificationList,
  NotificationDetails,
  PensionProfileUpdate,
  Intro,
  SafetyInsurance,
  StandaloneLifeInsurance,
  StandaloneInsuranceSuccess,
  StandaloneSafetyInsurance,
  StandaloneOfferSkipped,
  StandaloneOfferCompleted,
  InsuranceApplicationComponent,
  MinpensionData,
  MinpensionDataProcessing,
} from "./web/components";
import { init } from "./web/firebase";

import "./web/styles/index.scss";
import registerServiceWorker from "./registerServiceWorker";

const browserHistory = createBrowserHistory();
const store = createStoreWithHistory(browserHistory);

window.onerror = (message: string, filename?: string, lineno?: number, colno?: number, error?: Error) => {
  alert(JSON.stringify([message, filename, lineno, colno, error]));
};

registerServiceWorker();

// init firebase
init(store);

// not new login code
if (!location.pathname.startsWith("/!")) {
  store.dispatch(whoAmIAction({}));
}

// error page reload
if (location.pathname.startsWith("/error")) {
  store.dispatch(replace(HomeRoute.format({})));
}

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={browserHistory}>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path={LoginRoute.urlTemplate} component={Login} />
        <Redirect exact path={"/intro"} to={IntroRoute.format({ step: 1 })} />
        <Route exact path={IntroRoute.urlTemplate} component={Intro} />
        <Route path={MinpensionDataRoute.urlTemplate} component={MinpensionData} />
        <Route exact path={MinpensionDataProcessingRoute.urlTemplate} component={MinpensionDataProcessing} />
        {/* <Route exact path={PPMConfirmationRoute.urlTemplate} component={PPMConfirmation} /> */}
        <Route exact path={SummaryRoute.urlTemplate} component={Summary} />

        <Route exact path={ShowMoreRoute.urlTemplate} component={ShowMore} />

        <Route path={TodoItemBenefitsRoute.urlTemplate} component={TodoItemBenefits} />
        <Route path={UpdateProfileBenefitsRoute.urlTemplate} component={UpdateProfileBenefits} />
        <Route path={ChangeFundsRoute.urlTemplate} component={ChangeFunds} />
        <Route path={ChangeSavingsRoute.urlTemplate} component={ChangeSavings} />
        <Route path={InsuranceApplicationRoute.urlTemplate} component={InsuranceApplicationComponent} />

        <Route path={LifeInsuranceRoute.urlTemplate} component={LifeInsurance} />
        <Route path={SafetyInsuranceRoute.urlTemplate} component={SafetyInsurance} />
        <Route path={LifeInsuranceUpsellRoute.urlTemplate} component={LifeInsurance} />
        <Route path={SafetyInsuranceUpsellRoute.urlTemplate} component={SafetyInsurance} />

        <Route exact path={StandaloneLifeInsuranceRoute.urlTemplate} component={StandaloneLifeInsurance} />
        <Route exact path={StandaloneSafetyInsuranceRoute.urlTemplate} component={StandaloneSafetyInsurance} />
        <Route exact path={StandaloneLifeInsuranceUpsellRoute.urlTemplate} component={StandaloneLifeInsurance} />
        <Route exact path={StandaloneSafetyInsuranceUpsellRoute.urlTemplate} component={StandaloneSafetyInsurance} />

        <Route exact path={StandaloneInsuranceSuccessRoute.urlTemplate} component={StandaloneInsuranceSuccess} />
        <Route exact path={StandaloneOfferSkippedRoute.urlTemplate} component={StandaloneOfferSkipped} />
        <Route exact path={StandaloneOfferCompletedRoute.urlTemplate} component={StandaloneOfferCompleted} />

        {/* <Route exact path={FreeTodoItemRoute.urlTemplate} component={FreeTodoItem} /> */}
        <Route exact path={TodoItemSuccessRoute.urlTemplate} component={TodoItemSuccess} />

        <Route exact path={SupportRoute.urlTemplate} component={Support} />
        <Route exact path={SupportFAQRoute.urlTemplate} component={SupportFAQ} />
        <Route exact path={SupportTermsRoute.urlTemplate} component={SupportTerms} />

        <Route exact path={SettingsRoute.urlTemplate} component={Settings} />
        <Route exact path={SettingsRiskLevelRoute.urlTemplate} component={SettingsRiskLevel} />
        <Route exact path={SettingsPensionProfileRoute.urlTemplate} component={SettingsPensionProfile} />

        <Route exact path={NotificationsRoute.urlTemplate} component={NotificationList} />
        <Route exact path={NotificationDetailsRoute.urlTemplate} component={NotificationDetails} />

        <Route exact path={PensionProfileUpdateRoute.urlTemplate} component={PensionProfileUpdate} />

        {/* <Route exact path={LevelUpRoute.urlTemplate} component={LevelUp} /> */}

        <Route exact path={ErrorWithMessageRoute.urlTemplate} component={ErrorWithMessage} />
        <Route component={Error404Page} />
      </Switch>
    </ConnectedRouter>
  </Provider>,
  document.getElementById("root")
);
