import * as bell from "./files/icon-bell.svg";
import * as menu from "./files/icon-menu.svg";
import * as logoPensionskraft from "./files/logo-pensionskraft.svg";
import * as balance from "./files/icon-balance.svg";
import * as change from "./files/icon-change.svg";
import * as save from "./files/icon-save.svg";
import * as todoList from "./files/icon-todo-list.svg";
import * as info from "./files/icon-info.svg";
import * as risk from "./files/icon-risk.svg";
import * as funds from "./files/icon-funds.svg";
import * as profile from "./files/icon-profile.svg";
import * as noMessages from "./files/icon-no-messages.svg";
import * as logoBankid from "./files/logo-bankid.svg";
import * as logoPPM from "./files/logo-ppm.svg";
import * as checkmark from "./files/icon-checkmark.svg";
import * as notInterested from "./files/icon-not-interested.svg";
import * as arrowRight from "./files/icon-arrow-right.svg";
import * as email from "./files/icon-email.svg";
import * as phone from "./files/icon-phone.svg";
import * as globe from "./files/icon-globe.svg";
import * as helpdesk from "./files/icon-helpdesk.svg";
import * as settings from "./files/icon-settings.svg";
import * as help from "./files/icon-help.svg";
import * as helpDark from "./files/icon-help-dark.svg";
import * as logoMinpension from "./files/logo-minpension.png";
import * as error from "./files/icon-error.svg";
import * as handcoins from "./files/icon-handcoins.svg";
import * as hourglass from "./files/icon-hourglass.svg";
import * as lock from "./files/icon-lock.svg";
import * as bankgirot from "./files/logo-bankgirot.gif";
import * as trendingUp from "./files/icon-trending-up.svg";

export const icons = {
  bell,
  menu,
  logoPensionskraft,
  balance,
  save,
  change,
  todoList,
  info,
  risk,
  funds,
  profile,
  noMessages,
  logoBankid,
  logoPPM,
  checkmark,
  notInterested,
  arrowRight,
  email,
  phone,
  globe,
  helpdesk,
  settings,
  help,
  helpDark,
  logoMinpension,
  error,
  hourglass,
  handcoins,
  lock,
  bankgirot,
  trendingUp,
};

export * from "./pensionInstitutesLogo";
export * from "./intro";
export * from "./minpensionData";
