import * as React from "react";
import { PensaModal } from "./Modal";
import { useState } from "react";

interface UnsavedChangesModalProps {
  isOpened: boolean;
  errorElementId?: string;
  onAccept(): void;
  onDecline(): void;
  onClose(): void;
}

export const UnsavedChangesModal: React.SFC<UnsavedChangesModalProps> = props => {
  const [isErrorsModalOpen, setIsErrorsModalOpen] = useState(false);

  const onSaveModalSubmit = () => {
    if (props.errorElementId) {
      setIsErrorsModalOpen(true);
    } else {
      props.onAccept();
    }
  };

  const onErrorModalClose = () => {
    if (props.errorElementId) {
      const elementToScroll = document.getElementById(props.errorElementId);
      if (elementToScroll) {
        elementToScroll.scrollIntoView();
      }
    }

    setIsErrorsModalOpen(false);
    props.onClose();
  };

  return (
    <>
      <PensaModal
        header="Du har osparade ändringar"
        content="Vill du spara dina ändringar?"
        isOpened={props.isOpened && !isErrorsModalOpen}
        onSubmit={onSaveModalSubmit}
        onDecline={props.onDecline}
        onClose={props.onClose}
      />
      <PensaModal
        header="Finns fel"
        content="Rätta felen innan du sparar"
        isOpened={props.isOpened && isErrorsModalOpen}
        onClose={onErrorModalClose}
        onSubmit={onErrorModalClose}
      />
    </>
  );
};
