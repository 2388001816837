import { RiskLevels } from "../types";

export enum UserEventCode {
  PPMConfirmed = "ppm-consent-received",
  RiskProfileChanged = "risk-profile-changed",
  RiskLevelChanged = "risk-level-changed",
  LifeInsuranceSmsSent = "life-insurance-standalone-flow-sms-sent",
  SafetynsuranceSmsSent = "safety-insurance-standalone-flow-sms-sent"
}

export enum UserTypes {
  Customer = "customer"
}
export interface UserWithInfo {
  _id?: string;
  person_number: string;
  created_by: string;
  __v: number;
  password: string;
  total_payed: number;
  total_dept: number;
  minpension_pincode_status: string;
  fortnox_id: number;
  minpension_pincode: string;
  app_tasks?: null[] | null;
  email: string;
  created_date: string;
  todo_tasks?: null[] | null;
  recommended_5step_risk_level: RiskLevels;
  recommendations_made?: RecommendationsMadeEntity[] | null;
  most_recent: MostRecent;
  autogiro_consents?: null[] | null;
  debt_collection?: null[] | null;
  invoices?: InvoicesEntity[] | null;
  active_autogiro_contracts: number;
  active_invoice_contracts: number;
  contracts?: ContractsEntity[] | null;
  events?: EventsEntity[] | null;
  risk_profile: RiskProfile;
  membership?: Membership;
  minpension: Minpension;
  address: Address;
  phone: Phone;
  type: UserTypes;
  email_status: string;
  name: Name;
  birth_date: string;
  is_inhouse_sales_person: boolean;
  life_expectancy: number;
  gender: string;
  calculated_current_age: CalculatedCurrentAge;
  full_name: string;
  exists: boolean;
  id?: string;
  in_app_messages: InAppMessage[];

  isRegisteredAtMinpension: boolean;
  is_pension_data_update_required: boolean;
  was_most_recent_pension_revision_successfully_created: boolean;
  is_tryggakollen_analysis_pegged: boolean;
  is_risk_profile_update_required: boolean;
  is_tryggakollen_analysis_expired: boolean;
}

export interface InAppMessage {
  body: string;
  click_action: string;
  code: MessageCodes;
  date: string;
  expires: string;
  hidden: boolean;
  summary: string;
  title: string;
  viewed?: string;
  reminders: any[];
  completed?: string;
  _id: string;
}

export enum MessageCodes {
  FundPerformance = "view-fund-performance",
  NewDataReady = "new-data-is-ready",
  EndOfYearSummary = "view-end-of-year-summary"
}

export enum MessageClickActions {
  ShowFundPerformance = "show-fund-performance",
  ShowMainPage = "show-main-page",
  ShowEndOfYearSummary = "show-end-of-year-summary",
  ShowScrapingSummary = "show-scraping-summary"
}

export interface RecommendationsMadeEntity {
  recommendation: Recommendation;
  event: string;
  _id: string;
  returned?: Returned | null;
  sent: Sent;
  superkollen_data?: string | null;
}

export interface Recommendation {
  insurance_shell: InsuranceShell;
  risk_level: string;
  created_date: string;
}

export interface InsuranceShell {
  _id: string;
  pension_institute: string;
  insurance_type: string;
}

export interface Returned {
  form_url: string;
  admin: string;
  date: string;
}

export interface Sent {
  date: string;
  admin: string;
}

export interface MostRecent {
  annual_renewal?: string;
  membership_signup?: string;
  welcome_message_sent?: string;
  minpension_scraped?: string;
  pension_revision_created?: string;
  tryggakollen_analysis_pegged?: string;
  risk_profile_changed?: string;

  standalone_life_insurance_invitation_sent?: string;
  standalone_safety_insurance_invitation_sent?: string;
  standalone_life_insurance_upsell_sent?: string;
  standalone_safety_insurance_upsell_sent?: string;

  standalone_life_insurance_invitation_skipped?: string;
  standalone_safety_insurance_invitation_skipped?: string;
  standalone_life_insurance_upsell_skipped?: string;
  standalone_safety_insurance_upsell_skipped?: string;
}

export interface InvoicesEntity {
  number: number;
  date: string;
  due_date: string;
  ocr: string;
  balance: number;
  total: number;
  reminders: number;
  payed_date?: string | null;
  _id: string;
  credited: boolean;
  cancelled: boolean;
}

export interface ContractsEntity {
  number: number;
  active: boolean;
  continous: boolean;
  total: number;
  invoice_interval: number;
  terms_of_payment: string;
  last_invoice_date?: string | null;
  next_invoice_date: string;
  _id: string;
}

export interface EventsEntity {
  message?: string | null;
  _id: string;
  code: UserEventCode;
  files?: (FilesEntity | null)[] | null;
  date: string;
  admin?: string | null;
}

export interface FilesEntity {
  name: string;
  url: string;
  _id: string;
}

export interface RiskProfile {
  appears_retired?: boolean;
  requested_risk_level?: RiskLevels;
  dependant_children?: number;
  owns_own_home?: boolean;
  gross_monthly_income?: number;
  marital_status?: string;
  other_loans_kkr?: string;
  property_valuation_mkr?: string;
  collar_color: string;
  risk_willingness?: boolean;
  savings_kkr?: string;
  employment_sector?: string;
  has_fund_experience?: string;
  has_fund_knowledge?: string;
  mortgage_mkr?: string;
  collective_agreement?: string;
  collective_agreement_affirmed?: null;
  retirement_age?: number;
  wage_determination: string;
}

export const dependantChildrenLabels = (count: number) => {
  if (count === 0) {
    return "Inga";
  } else if (count >= 6) {
    return "6 eller fler";
  } else {
    return count.toString();
  }
};

export const employmentSectorLabels = {
  private: "Privat",
  ["public-local"]: "Kommunal",
  ["public-regional"]: "Landsting",
  ["public-national"]: "Statlig",
  cooperative: "Kooperativ",
  unemployed: "Arbetslös",
  retired: "Pensionär"
};

export const formatRangeValue = (range: string, suffix: string = "") => {
  if (range === "?") {
    return "Vet inte";
  }

  let result = range;

  if (range.includes("-")) {
    result = range
      .split("-")
      .map(val => (+val ? (+val).toLocaleString("sv") : val))
      .join("-");
  } else if (range[range.length - 1] === "+") {
    const val = range.substr(0, range.length - 1);
    result = `Mer än ${+val ? (+val).toLocaleString("sv") : val}`;
  }

  return `${result} ${suffix}`;
};

export const maritalStatusLabels = {
  single: "Ensamstående",
  married: "Gift",
  cohabiting: "Sambo"
};

export const hasFundKnowledgeLabels = {
  none: "Ingen",
  vague: "Mycket liten",
  adequat: "Förstår ungefär",
  extensive: "Omfattande insikt"
};

export const hasFundExperienceLabels = {
  none: "Ingen",
  vague: "Mycket liten",
  adequat: "Viss erfarenhet",
  extensive: "Omfattande erfarenhet"
};

export const hasFundKnowledgeTexts = {
  none: "Saknar helt kunskap om vad begreppet fond betyder.",
  vague: "Har mycket liten insikt i hur fonder fungerar.",
  adequat: "Förstår ungefär hur en fond fungerar och känner till begrepp så som räntefond och aktiefond.",
  extensive:
    "Har eller har haft ett fondsparande under längre tid och aktivt fattat beslut om förändringar i innehavet."
};

export const hasFundExperienceTexts = {
  none: "Saknar helt erfarenhet av begreppet fond.",
  vague:
    "Har aldrig självmant placerat i fonder, men har eller haft kapital i fonder via t.ex PPM eller tjänstepension.",
  adequat: "Har eller har haft ett fondsparande där jag själv någon gång valt inriktning och risknivå.",
  extensive:
    "Har eller har haft ett fondsparande under en längre tid och aktivt fattat beslut om förändring i innehavet."
};

export interface Membership {
  next_renewal_date: string;
  original_sales_org: string;
  sales_person: string;
  ended: boolean;
  autogiro: boolean;
  protection_service: boolean;
  terms_and_cond_ver: string;
  free_months: number;
  try_and_decide: boolean;
}

export interface Minpension {
  pincode: string;
  last_verified: string;
  pincode_login_not_allowed: boolean;
  pincode_status: string;
  collective_agreement_presets: CollectiveAgreementPresets;
  tou_agreed: string;
}

export interface CollectiveAgreementPresets {
  date: string;
  selected: string;
  filtered?: null[] | null;
}

export interface Address {
  street1: string;
  post_code: string;
  city: string;
  country: string;
}

export interface Phone {
  mobile: string;
  mobile_sms_failed: boolean;
}

interface Name {
  last_lowercase: string;
  first_lowercase: string;
  last: string;
  first: string;
}

export interface CalculatedCurrentAge {
  days: number;
  months: number;
  years: number;
  decimal_years: number;
}
