import { Action } from "redux";

import { postPPMConfirmationRequest } from "@common/requests";

import { PPMConfirmationState, defaultPPMConfirmationState } from "./state";
import { PPMDeclinedAction, PPMSummaryPrepared, PPMAcceptedAction } from "./actions";

export function ppmConfirmationReducer(
  state: PPMConfirmationState = defaultPPMConfirmationState,
  action: Action
): PPMConfirmationState {
  if (PPMDeclinedAction.is(action)) {
    return {
      ...state,
      ppmDeclined: true,
      isLoading: true
    };
  }

  if (PPMAcceptedAction.is(action)) {
    return {
      ...state,
      isLoading: true
    };
  }

  if (PPMSummaryPrepared.is(action)) {
    return {
      ...state,
      isLoading: false
    };
  }

  if (postPPMConfirmationRequest.actions.isError(action)) {
    return {
      ...state,
      isLoading: false
    };
  }
  return state;
}
