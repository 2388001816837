import { createSelector } from "reselect";

import { AppState } from "@state/store";
import { insurancesRecommendationSelector } from "@state/todoItem/selectors";
import { userSelector } from "@state/user/selectors";

import { InsuranceType, UserWithInfo } from "@common/models";
import { StandaloneInsuranceOffers } from "@common/types";

const axaProductsSelector = (state: AppState) => state.insurance.axaProducts.data || [];
const activeInsurancesSelector = (state: AppState) => state.insurance.activeInsurances || [];

export const lifeInsuranceOfferSelector = createSelector([axaProductsSelector], products =>
  products.find(product => product.type === InsuranceType.Life)
);

export const safetyInsuranceOfferSelector = createSelector([axaProductsSelector], products =>
  products.find(product => product.type === InsuranceType.Safety)
);

export const uncoveredCapitalSelector = createSelector([insurancesRecommendationSelector], recommendation => {
  return recommendation && recommendation.uncovered_capital;
});

export const activeSafetyInsuranceSelector = createSelector([activeInsurancesSelector], products =>
  products.find(product => product.type === InsuranceType.Safety)
);

export const activeLifeInsuranceSelector = createSelector([activeInsurancesSelector], products =>
  products.find(product => product.type === InsuranceType.Life)
);

const getOfferDate = (user: UserWithInfo, offer: StandaloneInsuranceOffers) => {
  let date;
  switch (offer) {
    case StandaloneInsuranceOffers.LifeInsurance:
      date = user.most_recent.standalone_life_insurance_invitation_sent;
      break;
    case StandaloneInsuranceOffers.LifeInsuranceUpsell:
      date = user.most_recent.standalone_life_insurance_upsell_sent;
      break;
    case StandaloneInsuranceOffers.SafetyInsurance:
      date = user.most_recent.standalone_safety_insurance_invitation_sent;
      break;
    case StandaloneInsuranceOffers.SafetyInsuranceUpsell:
      date = user.most_recent.standalone_safety_insurance_upsell_sent;
      break;
    default:
      break;
  }
  return date && new Date(date);
};

const getOfferSkipDate = (user: UserWithInfo, offer: StandaloneInsuranceOffers) => {
  let date;
  switch (offer) {
    case StandaloneInsuranceOffers.LifeInsurance:
      date = user.most_recent.standalone_life_insurance_invitation_skipped;
      break;
    case StandaloneInsuranceOffers.LifeInsuranceUpsell:
      date = user.most_recent.standalone_life_insurance_upsell_skipped;
      break;
    case StandaloneInsuranceOffers.SafetyInsurance:
      date = user.most_recent.standalone_safety_insurance_invitation_skipped;
      break;
    case StandaloneInsuranceOffers.SafetyInsuranceUpsell:
      date = user.most_recent.standalone_safety_insurance_upsell_skipped;
      break;
    default:
      break;
  }
  return date && new Date(date);
};

export const offerCompletedSelector = (state: AppState, offer: StandaloneInsuranceOffers) => {
  const activeInsuranceSelector =
    offer === StandaloneInsuranceOffers.LifeInsurance || offer === StandaloneInsuranceOffers.LifeInsuranceUpsell
      ? activeLifeInsuranceSelector
      : activeSafetyInsuranceSelector;

  const insurance = activeInsuranceSelector(state);
  const user = userSelector(state);

  if (!insurance || !user) {
    return false;
  }

  const offerDate = getOfferDate(user, offer);

  // if no SMS was sent and user has active insurance
  if (!offerDate) {
    // if it's initital insurance offer - mark as completed
    return offer === StandaloneInsuranceOffers.LifeInsurance || offer === StandaloneInsuranceOffers.SafetyInsurance;
  }

  const insuranceDate = new Date(insurance.start_date);

  return insuranceDate > offerDate;
};

export const offerSkippedSelector = (state: AppState, offer: StandaloneInsuranceOffers) => {
  const user = userSelector(state);
  if (!user) {
    return false;
  }

  const offerDate = getOfferDate(user, offer);
  const offerSkipDate = getOfferSkipDate(user, offer);
  if (!offerDate || !offerSkipDate) {
    return false;
  }

  return offerSkipDate > offerDate;
};

export const hasOfferOptionsSelector = (state: AppState, offer: StandaloneInsuranceOffers) => {
  const offerOptionsSelector =
    offer === StandaloneInsuranceOffers.LifeInsurance || offer === StandaloneInsuranceOffers.LifeInsuranceUpsell
      ? lifeInsuranceOfferSelector
      : safetyInsuranceOfferSelector;
  const offerOptions = offerOptionsSelector(state);

  if (!offerOptions) {
    return false;
  }

  return offerOptions.options.coverAmounts.length > 0;
};
