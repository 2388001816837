import { MessageCodes, MessageClickActions } from "@common/models";

import { defineAction } from "../../lib/rd-action-creators";

export const registerTokenAction = defineAction("NOTIFICATIONS REGISTER TOKEN");
export const askForPermissionsAction = defineAction("NOTIFICATIONS ASK FOR PERMISSIONS");
export const pushReceivedAction = defineAction<{ push: any }>("NOTIFICATIONS PUSH RECEIVED");
export const notificationMessageReadAction = defineAction<{ code: MessageCodes }>("NOTIFICATIONS MESSAGE READ");
export const notificationMessageCompleteAction = defineAction<{ code: MessageCodes }>("NOTIFICATIONS MESSAGE COMPLETE");
export const notificationMessageButtonClickAction = defineAction<{ action: MessageClickActions; code: MessageCodes }>(
  "NOTIFICATIONS MESSAGE BUTTON CLICK"
);
export const notificationsErrorAction = defineAction<{ error: any }>("NOTIFICATIONS ERROR");
