import * as React from "react";

import { minpensionDataImages } from "@assets";

import { InstructionsLayout } from "./InstructionsLayout";

interface PrivatPensionInfoProps {
  onNext(): any;
  onBack(): any;
}

export const PrivatPensionInfo = ({ onNext, onBack }: PrivatPensionInfoProps) => {
  return (
    <InstructionsLayout
      contentClassName="PrivatPensionInfo"
      nextBtnText="Gjort, ta mig till nästa steg"
      onNext={onNext}
      onBack={onBack}
    >
      <h5 className="MinpensionDataInstructions__title">Välj Privat pension</h5>
      <div className="MinpensionDataInstructions__text">
        1. Välj Privatpension i menyraden
        <img
          className="MinpensionDataInstructions__image MinpensionDataInstructions__image--wide"
          src={minpensionDataImages.privatPensionInfo1}
        />
        2. Efter du valt det så bockar du även i rutan "Se alla försäkringar i detalj" längre ned på sidan
        <img
          className="MinpensionDataInstructions__image MinpensionDataInstructions__image--wide"
          src={minpensionDataImages.showDetailsCheckbox}
        />
      </div>
    </InstructionsLayout>
  );
};
