import { AppState } from "@state/store";
import { UserEventCode } from "@common/models";
import { createSelector } from "reselect";

export const userSelector = (state: AppState) => state.user.data;

export const isFreeUserSelector = createSelector(
  [userSelector],
  (user) => user && user.membership && user.membership.try_and_decide
);

export const isRetiredUserSelector = createSelector(
  [userSelector],
  (user) => user && user && !!user.risk_profile.appears_retired
);

export const agreedToMinpensionTermsSelector = createSelector(
  [userSelector],
  (user) => !!user && !!user.minpension.tou_agreed
);

export const isPPMConfirmedSelector = createSelector(
  [userSelector],
  (user) => user && user.events && user.events.find((e) => e.code === UserEventCode.PPMConfirmed)
);

export const requestedRiskLevelSelector = createSelector(
  [userSelector],
  (user) => user && user.risk_profile.requested_risk_level
);

export const pensionProfileUpdateDateSelector = createSelector(
  [userSelector],
  (user) => user && (user.most_recent.risk_profile_changed || user.created_date)
);

export const formattedPensionProfileUpdateDateSelector = createSelector([pensionProfileUpdateDateSelector], (date) =>
  date
    ? new Date(date).toLocaleDateString("sv", {
        month: "numeric",
        year: "numeric",
        day: "numeric",
      })
    : "N/A"
);

export const yearsUntilRetirementSelector = createSelector([userSelector], (user) =>
  user ? (user.risk_profile.retirement_age || 65) - user.calculated_current_age.years : 0
);
export const analysisPegDateSelector = createSelector(
  [userSelector],
  (user) => user && user.most_recent.tryggakollen_analysis_pegged
);
