import { takeEvery, put, select, call } from "redux-saga/effects";
import { delay } from "redux-saga";
import { push } from "connected-react-router";
import isEmpty from "lodash-es/isEmpty";

import {
  postInsuranceApplicationRequest,
  postSignInsuranceApplicationRequest,
  getJobRequest,
  getCurrentApplicationRequest,
  putCancelInsuranceApplicationRequest,
} from "@common/requests";
import { InsuranceApplicationStatus, InsuranceApplicationResponse, InsuranceApplicationBody } from "@common/models";
import { getJobSaga } from "@state/scraping/saga";
import { InsuranceApplicationRoute, HomeRoute, SupportRoute } from "@common/routes";

import { AppState } from "../store";
import { responseErrorAction, showErrorAction } from "../error";

import {
  submitInsuranceApplicationAction,
  pollSavingsSendJobAction,
  setInsuranceAplicationsStep,
  pollSavingsSignJobAction,
  signInsuranceApplicationAction,
  cancelInsuranceAplication,
  setSavingsInsuranceNumber,
} from "./actions";
import { InsuranceApplicationStep } from "./state";
import { clearBankIdStarted, isBankIdStarted, isMobile, setBankIdStarted, isIOS } from "@common/helpers";
import { finalizeTodoItemSaga } from "@state/todoItem";

function* submitInsuranceApplicationSaga({
  kycCustomerInfo,
  kycInsuranceAmount,
  payoutInfo,
  beneficiaryInfo,
  bankInfo,
  monthlyPaymentAmount,
}: typeof submitInsuranceApplicationAction.typeInterface) {
  const userId: string = yield select<AppState>((state) => state.user.userId);
  const body: InsuranceApplicationBody = {
    monthlyPaymentAmount,
    kycCustomerInfo,
    kycInsuranceAmount,
    payoutInfo,
    beneficiaryInfo,
    bankInfo,
  };

  yield put(postInsuranceApplicationRequest.actions.running({ userId }));
  const response: typeof postInsuranceApplicationRequest.types.response = yield call(
    postInsuranceApplicationRequest,
    { userId },
    body
  );
  if (response.ok) {
    yield put(postInsuranceApplicationRequest.actions.ok({ userId }, response));
    yield put(pollSavingsSendJobAction({ jobId: response.result.jobId }));
    yield put(setInsuranceAplicationsStep({ step: InsuranceApplicationStep.WaitingSend }));
    yield put(push(InsuranceApplicationRoute.format({})));
  } else {
    yield put(postInsuranceApplicationRequest.actions.error({ userId }, response));
    yield put(responseErrorAction({ response }));
  }
}

function* cancelInsuranceApplicationSaga({ goToSupport }: typeof cancelInsuranceAplication.typeInterface) {
  const userId: string = yield select<AppState>((state) => state.user.userId);
  const applicationId: string = yield select<AppState>(
    (state) => state.changeSavings.insuranceApplicationResponse?.applicationId
  );

  // was already cancelled
  if (!applicationId) {
    yield put(push(goToSupport ? SupportRoute.format({}) : HomeRoute.format({})));
    return;
  }

  yield put(putCancelInsuranceApplicationRequest.actions.running({ userId, applicationId }));
  const response: typeof putCancelInsuranceApplicationRequest.types.response = yield call(
    putCancelInsuranceApplicationRequest,
    { userId, applicationId },
    {}
  );
  if (response.ok) {
    yield put(putCancelInsuranceApplicationRequest.actions.ok({ userId, applicationId }, response));
    yield put(push(goToSupport ? SupportRoute.format({}) : HomeRoute.format({})));
  } else {
    yield put(putCancelInsuranceApplicationRequest.actions.error({ userId, applicationId }, response));
    yield put(responseErrorAction({ response }));
  }
}

function* signInsuranceApplicationSaga({}: typeof signInsuranceApplicationAction.typeInterface) {
  clearBankIdStarted();

  yield put(setInsuranceAplicationsStep({ step: InsuranceApplicationStep.WaitingSign }));

  const userId: string = yield select<AppState>((state) => state.user.userId);
  const signCaseId: string = yield select<AppState>(
    (state) => state.changeSavings.insuranceApplicationResponse?.signCaseId
  );
  if (!signCaseId) {
    return;
  }
  const params = { userId, signCaseId };

  yield put(postSignInsuranceApplicationRequest.actions.running(params));
  const response: typeof postSignInsuranceApplicationRequest.types.response = yield call(
    postSignInsuranceApplicationRequest,
    params,
    {}
  );
  if (response.ok) {
    yield put(postSignInsuranceApplicationRequest.actions.ok(params, response));
    yield put(pollSavingsSignJobAction({ jobId: response.result.jobId }));
  } else {
    yield put(postSignInsuranceApplicationRequest.actions.error(params, response));
    yield put(responseErrorAction({ response }));
  }
}

export function* getCurrentInsuranceApplicationSaga() {
  const userId: string = yield select<AppState>((state) => state.user.userId);

  yield put(getCurrentApplicationRequest.actions.running({ userId }));
  const response: typeof getCurrentApplicationRequest.types.response = yield call(getCurrentApplicationRequest, {
    userId,
  });
  if (response.ok) {
    yield put(getCurrentApplicationRequest.actions.ok({ userId }, response));
  } else {
    yield put(getCurrentApplicationRequest.actions.error({ userId }, response));
    yield put(responseErrorAction({ response }));
  }

  return response;
}

export function* processApplicationResponseSaga(applicationStatus: InsuranceApplicationStatus) {
  switch (applicationStatus) {
    // first two statuses should be handled by server job
    // so if we encounter it here for some reason it's because something went wrong so better ask customer to contact support
    case InsuranceApplicationStatus.Received:
    case InsuranceApplicationStatus.Pending:
    case InsuranceApplicationStatus.Error:
      yield put(setInsuranceAplicationsStep({ step: InsuranceApplicationStep.Error }));
      break;

    case InsuranceApplicationStatus.RejectedBySigner:
      yield put(setInsuranceAplicationsStep({ step: InsuranceApplicationStep.Error }));
      break;
    case InsuranceApplicationStatus.Rejected:
      yield put(setInsuranceAplicationsStep({ step: InsuranceApplicationStep.Rejected }));
      break;

    case InsuranceApplicationStatus.WaitForSign:
      yield put(setInsuranceAplicationsStep({ step: InsuranceApplicationStep.Summary }));
      break;

    case InsuranceApplicationStatus.Approved:
      yield put(setInsuranceAplicationsStep({ step: InsuranceApplicationStep.WaitingProcessed }));
      break;

    case InsuranceApplicationStatus.PendingManualValidation:
      yield put(setInsuranceAplicationsStep({ step: InsuranceApplicationStep.ManualValidation }));
      break;

    case InsuranceApplicationStatus.Processed:
      yield put(setInsuranceAplicationsStep({ step: InsuranceApplicationStep.Processed }));
      break;

    default:
      break;
  }
}

function* pollSavingsSendJobSaga({ jobId }: typeof pollSavingsSendJobAction.typeInterface) {
  const resp: typeof getJobRequest.types.response = yield call(getJobSaga, jobId);
  if (!resp.ok) {
    return;
  }

  const job = resp.result;

  if (job.failed) {
    yield put(setInsuranceAplicationsStep({ step: InsuranceApplicationStep.Error }));
    return;
  }

  if (job.finished_at) {
    const currentApplicationResp: typeof getCurrentApplicationRequest.types.response = yield call(
      getCurrentInsuranceApplicationSaga
    );

    if (!currentApplicationResp.ok) {
      return;
    }

    const currentApplication: InsuranceApplicationResponse | null = isEmpty(currentApplicationResp.result)
      ? null
      : (currentApplicationResp.result as InsuranceApplicationResponse);

    if (!currentApplication) {
      yield put(showErrorAction({}));
    } else {
      yield call(processApplicationResponseSaga, currentApplication.applicationStatus);
    }

    return;
  }

  switch (job.current_step.name) {
    case "generate-recommendations-started":
    case "generate-recommendations-completed":
    case "post-insurance-application-started":
    case "post-insurance-application-completed":
    default:
  }

  yield delay(job.poll_interval * 1000);
  yield put(pollSavingsSendJobAction({ jobId }));
}

function* pollSavingsSignJobSaga({ jobId }: typeof pollSavingsSendJobAction.typeInterface) {
  const currentStep: InsuranceApplicationStep = yield select<AppState>(
    (state) => state.changeSavings.insuranceApplicationStep
  );

  const resp: typeof getJobRequest.types.response = yield call(getJobSaga, jobId);
  if (!resp.ok) {
    return;
  }

  const job = resp.result;

  if (job.failed) {
    yield put(setInsuranceAplicationsStep({ step: InsuranceApplicationStep.Error }));
    return;
  }

  if (job.finished_at) {
    // fetch new insurance application data with insurance number
    // also fetch new tryggakollen data with completed savings item
    const insuranceNumber = job.results?.[1] || null;
    yield put(setSavingsInsuranceNumber({ insuranceNumber }));
    yield call(finalizeTodoItemSaga);
    return;
  }

  if (job.completed_step_names.includes("waiting-for-sign-completed")) {
    // show waiting screen when application was signed with bankID
    if (currentStep !== InsuranceApplicationStep.WaitingProcessed) {
      yield put(setInsuranceAplicationsStep({ step: InsuranceApplicationStep.WaitingProcessed }));
    }
    // clear bankid started flag if not cleared already
    if (isBankIdStarted()) {
      clearBankIdStarted();
    }
  } else if (job.completed_step_names.includes("initiate-signing-completed") && !isBankIdStarted() && isMobile()) {
    // start bankID app if not started already
    setBankIdStarted();
    const autostartToken = job.results?.[0];
    location.href = `bankid:///?autostarttoken=${autostartToken}&redirect=${isIOS ? location.href : null}`;
  }

  yield delay(job.poll_interval * 1000);
  yield put(pollSavingsSignJobAction({ jobId }));
}

export function* changeSavingsSaga() {
  yield takeEvery(submitInsuranceApplicationAction.type, submitInsuranceApplicationSaga);
  yield takeEvery(signInsuranceApplicationAction.type, signInsuranceApplicationSaga);
  yield takeEvery(pollSavingsSendJobAction.type, pollSavingsSendJobSaga);
  yield takeEvery(pollSavingsSignJobAction.type, pollSavingsSignJobSaga);
  yield takeEvery(cancelInsuranceAplication.type, cancelInsuranceApplicationSaga);
}
