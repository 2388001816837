export interface AutogiroForm {
  bankName?: string;
  accountNumber: string;
  sortCode: string;
}

export const initialAutogiroFormValues: AutogiroForm = {
  bankName: "",
  accountNumber: "",
  sortCode: ""
};
