import { Action } from "redux";

import {
  getUserInfoRequest,
  postUserDataRequest,
  postRequestFCMTokenRequest,
  putMessageReadRequest,
  putMessageActionCompletedRequest,
  putFreeMembershipRequest,
  markTermsAccepted,
  postPPMConfirmationRequest,
  skipTodoItemRequest
} from "@common/requests";

import { UserState, defaultUserState } from "./state";
import { setUserIdAction, startInitialPensionProfileUpdate, finishInitialPensionProfileUpdate } from "./actions";

export function userReducer(state: UserState = defaultUserState, action: Action): UserState {
  if (getUserInfoRequest.actions.isRunning(action) || markTermsAccepted.actions.isRunning(action)) {
    return {
      ...state,
      isFetching: true
    };
  }

  if (markTermsAccepted.actions.isOk(action) || markTermsAccepted.actions.isError(action)) {
    return {
      ...state,
      isFetching: false
    };
  }

  if (setUserIdAction.is(action)) {
    return {
      ...state,
      userId: action.id
    };
  }

  if (getUserInfoRequest.actions.isOk(action)) {
    return {
      ...state,
      isFetching: false,
      data: { ...state.data, ...action.result },
      error: undefined
    };
  }

  // actions that change user model
  if (
    postUserDataRequest.actions.isOk(action) ||
    postRequestFCMTokenRequest.actions.isOk(action) ||
    putMessageReadRequest.actions.isOk(action) ||
    putMessageActionCompletedRequest.actions.isOk(action) ||
    putFreeMembershipRequest.actions.isOk(action) ||
    postPPMConfirmationRequest.actions.isOk(action) ||
    skipTodoItemRequest.actions.isOk(action)
  ) {
    return {
      ...state,
      data: { ...state.data, ...action.result }
    };
  }

  if (getUserInfoRequest.actions.isError(action)) {
    return {
      ...state,
      isFetching: false,
      data: undefined,
      error: action.errorType
    };
  }

  if (startInitialPensionProfileUpdate.is(action)) {
    return {
      ...state,
      isInitialPensionProfileUpdate: true
    };
  }

  if (finishInitialPensionProfileUpdate.is(action)) {
    return {
      ...state,
      isInitialPensionProfileUpdate: false
    };
  }

  return state;
}
