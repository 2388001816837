import * as React from "react";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { push } from "connected-react-router";
import { RouteComponentProps } from "react-router-dom";

import { AppState } from "@state/store";
import {
  lifeInsuranceOfferSelector,
  createInsuranceAction,
  skipStandaloneOfferAction,
  upsellInsuranceAction,
  offerCompletedSelector,
} from "@state/insurance";

import { StandaloneLifeInsuranceUpsellRoute, StandaloneOfferCompletedRoute } from "@common/routes";
import { AutogiroForm, initialAutogiroFormValues, AxaProduct } from "@common/models";
import { PensaAutogiro, Layout } from "@components/common";
import { StandaloneInsuranceOffers } from "@common/types";

import { StandaloneLifeInsuranceBenefits } from "./benefits";
import { LifeInsuranceHealthCheck } from "./healthCheck";
import { LifeInsuranceSummary } from "./summary";

import "./LifeInsurance.scss";

interface StandaloneLifeInsuranceProps {
  lifeInsuranceOffer: AxaProduct | undefined;
  offerCompleted: boolean;

  isSaving: boolean;
  isUpsell: boolean;
  isSkipping: boolean;

  onCreateLifeInsurance(productCode: string, coverAmount: number, price: number, form: AutogiroForm): any;
  onUpsellLifeInsurance(productCode: string, coverAmount: number, price: number): any;
  onSkipOffer(offer: StandaloneInsuranceOffers): any;
  onOfferCompleted(): any;
}

export const StandaloneLifeInsurance = connect(
  (state: AppState, ownProps: RouteComponentProps<{}>) => {
    const isUpsell = StandaloneLifeInsuranceUpsellRoute.match(ownProps.location.pathname).isMatched;
    const offer = isUpsell ? StandaloneInsuranceOffers.LifeInsuranceUpsell : StandaloneInsuranceOffers.LifeInsurance;

    return {
      isSaving: state.insurance.isSaving,
      isSkipping: state.requests.postSkipInsuranceOffer.loading,
      lifeInsuranceOffer: lifeInsuranceOfferSelector(state),
      offerCompleted: offerCompletedSelector(state, offer),
      isUpsell,
    };
  },
  (dispatch: Dispatch, ownProps: RouteComponentProps<{}>) => {
    const isUpsell = StandaloneLifeInsuranceUpsellRoute.match(ownProps.location.pathname).isMatched;
    const offer = isUpsell ? StandaloneInsuranceOffers.LifeInsuranceUpsell : StandaloneInsuranceOffers.LifeInsurance;

    return {
      onCreateLifeInsurance(productCode: string, coverAmount: number, price: number, form: AutogiroForm) {
        dispatch(createInsuranceAction({ productCode, coverAmount, price, form, isStandaloneFlow: true }));
      },
      onUpsellLifeInsurance(productCode: string, coverAmount: number, price: number) {
        dispatch(upsellInsuranceAction({ productCode, coverAmount, price, isStandaloneFlow: true }));
      },
      onSkipOffer(offer: StandaloneInsuranceOffers) {
        dispatch(skipStandaloneOfferAction({ offer }));
      },
      onOfferCompleted() {
        dispatch(push(StandaloneOfferCompletedRoute.format({ type: offer })));
      },
    };
  }
)(
  ({
    lifeInsuranceOffer,
    isUpsell,
    isSkipping,
    isSaving,
    offerCompleted,
    onCreateLifeInsurance,
    onUpsellLifeInsurance,
    onSkipOffer,
    onOfferCompleted,
  }: StandaloneLifeInsuranceProps) => {
    const [selectedAmount, setSelectedAmount] = useState<number | undefined>(undefined);
    const [healthCheck, setHealthCheck] = useState({ allChecked: false, completed: false });
    const [bankDetails, setBankDetails] = useState<{ form: AutogiroForm; completed: boolean }>({
      form: initialAutogiroFormValues,
      completed: false,
    });
    const [wasSubmitted, setWasSubmitted] = useState(false);

    const price = (selectedAmount && lifeInsuranceOffer?.options.grossMonthlyPremium[selectedAmount]) || 0;
    const productCode = lifeInsuranceOffer && lifeInsuranceOffer.id;

    useEffect(() => {
      window.scrollTo && window.scrollTo({ top: 0 });
    }, [selectedAmount, healthCheck, bankDetails]);

    useEffect(() => {
      if (offerCompleted && !wasSubmitted) {
        onOfferCompleted();
      }
    }, [offerCompleted, wasSubmitted]);

    if (!selectedAmount) {
      return (
        <StandaloneLifeInsuranceBenefits
          isSkipping={isSkipping}
          isUpsell={isUpsell}
          alternatives={lifeInsuranceOffer?.options}
          onNext={setSelectedAmount}
          onSkip={() =>
            onSkipOffer(
              isUpsell ? StandaloneInsuranceOffers.LifeInsuranceUpsell : StandaloneInsuranceOffers.LifeInsurance
            )
          }
        />
      );
    } else if (!healthCheck.completed) {
      return (
        <LifeInsuranceHealthCheck
          initAllChecked={healthCheck.allChecked}
          onPrev={() => setSelectedAmount(undefined)}
          onNext={() => setHealthCheck({ allChecked: true, completed: true })}
        />
      );
    } else if (!bankDetails.completed && !isUpsell) {
      return (
        <Layout
          title="Kontouppgifter"
          contentClassName="LifeInsuranceBankDetails"
          onGoBack={() =>
            isUpsell
              ? setHealthCheck({ allChecked: true, completed: false })
              : setBankDetails({ ...bankDetails, completed: false })
          }
        >
          <PensaAutogiro
            initialValues={bankDetails.form}
            subscriptionInfo={
              <>
                Försäkringen kostar <strong>{price.toLocaleString("sv")} kr</strong> per månad och betalas via autogiro.
              </>
            }
            termsInfo={
              <>
                Jag accepterar autogirovillkoren som finns på{" "}
                <a href="https://www.pensionskraft.se/livforsakring/" target="_blank">
                  www.pensionskraft.se/livforsakring
                </a>{" "}
                och godkänner att premien dras månadsvis via autogiro från nedanstående konto:
              </>
            }
            onSubmit={(form) => setBankDetails({ form, completed: true })}
          />
        </Layout>
      );
    } else if (selectedAmount && healthCheck.completed && (bankDetails.completed || isUpsell)) {
      return (
        <LifeInsuranceSummary
          isSaving={isSaving}
          price={price}
          coverage={selectedAmount || 0}
          onPrev={() => setBankDetails({ ...bankDetails, completed: false })}
          onNext={() => {
            setWasSubmitted(true);
            isUpsell
              ? onUpsellLifeInsurance(productCode!, selectedAmount, price)
              : onCreateLifeInsurance(productCode!, selectedAmount, price, bankDetails.form);
          }}
        />
      );
    }

    return null;
  }
);
