import { put, call, takeEvery, select } from "redux-saga/effects";
import { delay } from "redux-saga";
import { push } from "connected-react-router";

import { MessageCodes, LoginParams } from "@common/models";
import { LinkActions, ShowMoreType } from "@common/types";
import { getBankIdUrl, isMobile, getLoginCode, setLoginCode, clearLoginCode } from "@common/helpers";
import {
  logInRequest,
  logOutRequest,
  checkAccessCodeRequest,
  putFreeMembershipRequest,
  getlogInStatusRequest,
} from "@common/requests";
import {
  HomeRoute,
  SummaryRoute,
  ShowMoreRoute,
  LoginRoute,
  StandaloneLifeInsuranceRoute,
  StandaloneSafetyInsuranceRoute,
  StandaloneLifeInsuranceUpsellRoute,
  StandaloneSafetyInsuranceUpsellRoute,
} from "@common/routes";

import { showErrorAction, responseErrorAction } from "@state/error";
import { whoAmIAction, setUserIdAction, setFreeMembershipSaga, whoAmISaga } from "@state/user";
import { AppState } from "@state/store";

import fadeOutSplash from "../../fadeOutSplash";

import {
  checkAccessCodeAction,
  logInWithBankIDAction,
  bankIDLoginCompletedAction,
  getLoginStatusAction,
  logOutAction,
} from "./actions";
import { trackHeapEvent, HeapEventNames } from "@state/analytics";

function* logInRequestSaga(params: LoginParams) {
  yield put(logInRequest.actions.running({}));
  let logInResponse: typeof logInRequest.types.response = yield call(logInRequest, {}, params);
  if (logInResponse.ok) {
    yield put(logInRequest.actions.ok({}, logInResponse));
  } else {
    yield put(logInRequest.actions.error({}, logInResponse));
    const err = logInResponse["error"];
    // if user membership expired
    if (err && err.message === "Medlemskapet har upphört.") {
      const id = yield select<AppState>((state) => state.user.userId);
      const setMembershipResp: typeof putFreeMembershipRequest.types.response = yield call(setFreeMembershipSaga, id);
      if (!setMembershipResp.ok) {
        yield put(responseErrorAction({ response: setMembershipResp }));
        return logInResponse;
      }
      yield put(logInRequest.actions.running({}));
      logInResponse = yield call(logInRequest, {}, params);
      if (logInResponse.ok) {
        yield put(logInRequest.actions.ok({}, logInResponse));
      } else {
        yield put(logInRequest.actions.error({}, logInResponse));
      }
    }
  }
  return logInResponse;
}

export function* logInSaga() {
  const code = getLoginCode();
  if (!code) {
    return;
  }
  const logInResponse: typeof logInRequest.types.response = yield call(logInRequestSaga, {
    username: "undefined",
    password: code,
  });

  return logInResponse;
}

function* logInWithBankIdSaga(action: typeof logInWithBankIDAction.typeInterface) {
  const personNumber = action.personNumber;
  const timeout = setTimeout(() => {
    if (isMobile()) {
      location.href = getBankIdUrl(location.origin + HomeRoute.format({}));
    }
    // give server some time to process request in case of error
  }, 1000);
  const logInResponse: typeof logInRequest.types.response = yield call(logInRequestSaga, {
    username: personNumber,
    password: "bankid",
  });
  if (logInResponse.ok) {
    // wait for 1 sec to make sure server has time to save session
    yield delay(1000);
    yield call(whoAmISaga, whoAmIAction({ redirectTo: HomeRoute.format({}) }));
  } else {
    // don't open bankid app if error occures
    clearTimeout(timeout);
    if (logInResponse.errorType === "transport") {
      // consider that bankID app was launched and browser rejected request
      // consider empty response from heroku so start poll status
      yield put(getLoginStatusAction({}));
      return;
    }
    yield put(
      showErrorAction({
        message: (logInResponse["error"] && logInResponse["error"].message) || "Pröva länken du fick",
        title: "Kunde inte logga in dig",
      })
    );
  }
  yield put(bankIDLoginCompletedAction({}));
}

export function* logOutRequestSaga() {
  yield put(logOutRequest.actions.running({}));
  const response: typeof logOutRequest.types.response = yield call(logOutRequest, {});
  if (response.ok) {
    yield put(logOutRequest.actions.ok({}, response));
  } else {
    yield put(logOutRequest.actions.error({}, response));
  }
  return response;
}

function* logOutSaga() {
  const logOutResp: typeof logOutRequest.types.response = yield call(logOutRequestSaga);
  if (!logOutResp.ok) {
    yield put(responseErrorAction({ response: logOutResp }));
    return;
  }
  clearLoginCode();
  yield put(push(LoginRoute.format({})));
}

function* checkAccessCodeSaga(action: typeof checkAccessCodeAction.typeInterface) {
  const params = { code: action.code };
  yield put(checkAccessCodeRequest.actions.running(params));
  try {
    const response: typeof checkAccessCodeRequest.types.response = yield call(checkAccessCodeRequest, params);
    if (response.ok) {
      yield put(checkAccessCodeRequest.actions.ok(params, response));
      yield put(setUserIdAction({ id: response.result.user._id }));
      setLoginCode(action.code);
      yield call(logOutRequestSaga);
      let redirectUrl = HomeRoute.format({});

      trackHeapEvent(HeapEventNames.LoginLinkClicked, { linkAction: response.result.link_action });

      switch (response.result.link_action) {
        case LinkActions.ShowMessage:
          if (response.result.payload === MessageCodes.NewDataReady) {
            // if it's message with new data ready take user straight to summary screen
            redirectUrl = SummaryRoute.format({});
          } else if (response.result.payload === MessageCodes.FundPerformance) {
            // if it's message with fund performance take user straight to show more funds screen
            redirectUrl = ShowMoreRoute.format({ type: ShowMoreType.Funds });
          }
          break;
        case LinkActions.ShowStandaloneLifeInsuranceFlow:
          redirectUrl = StandaloneLifeInsuranceRoute.format({});
          break;
        case LinkActions.ShowStandaloneSafetyInsuranceFlow:
          redirectUrl = StandaloneSafetyInsuranceRoute.format({});
          break;
        case LinkActions.ShowStandaloneLifeInsuranceUpsellFlow:
          redirectUrl = StandaloneLifeInsuranceUpsellRoute.format({});
          break;
        case LinkActions.ShowStandaloneSafetyInsuranceUpsellFlow:
          redirectUrl = StandaloneSafetyInsuranceUpsellRoute.format({});
          break;

        default:
          break;
      }
      yield put(whoAmIAction({ redirectTo: redirectUrl }));
    } else {
      yield put(checkAccessCodeRequest.actions.error(params, response));
      yield put(responseErrorAction({ response, hideBtn: true }));
      fadeOutSplash();
    }
  } catch (e) {
    alert("Error in check access code.");
    alert(e.stack);
  }
}

export function* getLoginStatusSaga() {
  yield put(getlogInStatusRequest.actions.running({}));
  const resp: typeof getlogInStatusRequest.types.response = yield call(getlogInStatusRequest, {});
  if (resp.ok) {
    if (resp.result.pending) {
      yield delay(1000);
      yield put(getLoginStatusAction({}));
    } else {
      if (resp.result.success) {
        // wait a little bit
        yield delay(1000);
        yield call(whoAmISaga, whoAmIAction({ redirectTo: HomeRoute.format({}) }));
      } else {
        yield put(
          showErrorAction({
            message: resp.result.message,
          })
        );
      }
    }
    yield put(getlogInStatusRequest.actions.ok({}, resp));
  } else {
    yield put(getlogInStatusRequest.actions.error({}, resp));
    yield put(responseErrorAction({ response: resp, hideBtn: true }));
  }
}

export function* authSaga() {
  yield takeEvery(checkAccessCodeAction.type, checkAccessCodeSaga);
  yield takeEvery(logInWithBankIDAction.type, logInWithBankIdSaga);
  yield takeEvery(getLoginStatusAction.type, getLoginStatusSaga);
  yield takeEvery(logOutAction.type, logOutSaga);
}
