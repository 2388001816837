import * as React from "react";

import { InstructionsLayout } from "./InstructionsLayout";
import { minpensionDataImages } from "@assets";

interface TjanstePensionInfoProps {
  onNext(): any;
  onBack(): any;
}

export const TjanstePensionInfo = ({ onNext, onBack }: TjanstePensionInfoProps) => {
  return (
    <InstructionsLayout
      contentClassName="TjanstePensionInfo"
      nextBtnText="Gjort, ta mig till nästa steg"
      onNext={onNext}
      onBack={onBack}
    >
      <h5 className="MinpensionDataInstructions__title">Välj Tjänstepension</h5>
      <div className="MinpensionDataInstructions__text">
        1. Välj Tjänstepension i menyraden
        <img
          className="MinpensionDataInstructions__image MinpensionDataInstructions__image--wide"
          src={minpensionDataImages.tjanstePensionInfo1}
        />
        2. Efter du valt det så bockar du även i rutan "Se alla försäkringar i detalj" längre ned på sidan
        <img
          className="MinpensionDataInstructions__image MinpensionDataInstructions__image--wide"
          src={minpensionDataImages.showDetailsCheckbox}
        />
      </div>
    </InstructionsLayout>
  );
};
