import * as React from "react";

import { isMobile, isSafari } from "@common/helpers";
import { RippleEffect } from "@components/common";
import { icons } from "@assets";

import { InstructionsLayout } from "./InstructionsLayout";

import "./MinpensionDataInstructions.scss";

interface LoginInstructionsProps {
  onNext(): any;
  onBack?(): any;
}

export const LoginInstructions = ({ onNext, onBack }: LoginInstructionsProps) => {
  return (
    <InstructionsLayout
      contentClassName="LoginInstructions"
      nextBtnText="Jag är inloggad nu"
      onNext={onNext}
      onBack={onBack}
    >
      <h5 className="MinpensionDataInstructions__title">Logga in till minpension.se</h5>
      <div className="MinpensionDataInstructions__text">
        På minpension.se hämtar du information om din pension som används för att ge dig förslag på hur du kan förbättra
        den.
        <br />
        <br />
        Har du inte använt minpension.se förut så behöver du även registrera dig först. Annars loggar du in direkt.
        {isMobile() && (
          <>
            <RippleEffect
              rounded
              className="LoginInstructions__mobileLoginBtn"
              onClick={() => window.open("https://www.minpension.se/inloggning", "_blank")}
            >
              Logga in
            </RippleEffect>
            Eftersom du valt att använda mobiltelefon, kommer minpension.se att öppnas i ett nytt fönster.
          </>
        )}
        {isSafari && !isMobile() && (
          <>
            <RippleEffect
              rounded
              className="LoginInstructions__mobileLoginBtn"
              onClick={() => window.open("https://www.minpension.se/inloggning", "_blank")}
            >
              Logga in
            </RippleEffect>
            Minpension kommer öppnas i ett nytt fönster. Du behöver sedan växla mellan fönstren.
          </>
        )}
        <br />
        <br />
        Du kan alltid få hjälp om det är något du undrar över. Klicka bara på{" "}
        <img style={{ width: "1.5rem", margin: "0 0.3rem" }} src={icons.helpDark} /> längst upp till höger.
      </div>
    </InstructionsLayout>
  );
};
