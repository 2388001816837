import * as React from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { push, goBack } from "connected-react-router";
import { Formik, FormikProps } from "formik";

import { Layout, InfoItem, Button, LoadingOverlay, UnsavedChangesModal, PensaModal } from "@components/common";
import { AppState } from "@state/store";
import { SettingsRoute } from "@common/routes";
import { PensionProfileForm, UserWithInfo, userToPensionProfileForm } from "@common/models";
import { updateUserPensionProfileAction, updatePensionProfileAndRiskLevelAction } from "@state/settings";
import { riskLevelTranslations } from "@common/helpers";
import { RiskLevels } from "@common/types";
import { getNewRiskLevelRequest } from "@common/requests";
import { formattedPensionProfileUpdateDateSelector } from "@state/user";

import { EconomySection, WorkSection, FamilySection, RiskSection, PensionProfileSchema } from "./Sections";

import "./PensionProfile.scss";

interface SettingsPensionProfileProps {
  user?: UserWithInfo;
  isFetching: boolean;
  hasPrevLocation: boolean;
  profileUpdateDate: string | undefined;

  goBack(): void;
  goToSettings(): void;
  onSaveUserAction(form: PensionProfileForm): void;
  onSaveUserAndRiskLevelChangeAction(form: PensionProfileForm, riskLevel: RiskLevels): void;
}

export const SettingsPensionProfile = connect(
  (state: AppState) => ({
    user: state.user.data,
    isFetching: state.settings.isFetching,
    hasPrevLocation: !!state.router.prevLocation,
    profileUpdateDate: formattedPensionProfileUpdateDateSelector(state),
  }),
  (dispatch: Dispatch) => ({
    goToSettings() {
      dispatch(push(SettingsRoute.format({})));
    },
    goBack() {
      dispatch(goBack());
    },
    onSaveUserAction(form: PensionProfileForm) {
      dispatch(updateUserPensionProfileAction({ form }));
    },
    onSaveUserAndRiskLevelChangeAction(form: PensionProfileForm, riskLevel: RiskLevels) {
      dispatch(updatePensionProfileAndRiskLevelAction({ form, riskLevel }));
    },
  })
)(
  ({
    hasPrevLocation,
    isFetching,
    user,
    profileUpdateDate,
    goBack,
    goToSettings,
    onSaveUserAction,
    onSaveUserAndRiskLevelChangeAction,
  }: SettingsPensionProfileProps) => {
    const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
    const [isRiskModalOpen, setIsRiskModalOpen] = useState(false);
    const [isNewRiskLevelFetching, setIsNewRiskLevelFetching] = useState(false);
    const [newRiskLevel, setNewRiskLevel] = useState<RiskLevels>(RiskLevels.Missing);

    const handleSave = async (values: PensionProfileForm) => {
      setIsSaveModalOpen(false);
      setIsNewRiskLevelFetching(true);

      if (user && user.id !== undefined && values.riskWillingness !== undefined) {
        const riskLevelRequest = await getNewRiskLevelRequest(
          { userId: user.id },
          { risk_willingness: values.riskWillingness }
        );

        if (riskLevelRequest.ok) {
          const newRiskLevel = riskLevelRequest.result.recommended_5step_risk_level;
          if (newRiskLevel !== "-" && newRiskLevel !== user.recommended_5step_risk_level) {
            setNewRiskLevel(newRiskLevel);
            setIsNewRiskLevelFetching(false);
            setIsRiskModalOpen(true);
            return;
          }
        }
      }

      setIsNewRiskLevelFetching(false);
      onSaveUserAction(values);
    };

    const onGoBack = (formikProps: FormikProps<PensionProfileForm>) => {
      if (formikProps.dirty) {
        setIsSaveModalOpen(true);
      } else {
        hasPrevLocation ? goBack() : goToSettings();
      }
    };

    const onSaveUserAndRiskLevelChange = (form: PensionProfileForm, riskLevel: RiskLevels) => {
      setIsRiskModalOpen(false);
      onSaveUserAndRiskLevelChangeAction(form, riskLevel);
    };

    return user ? (
      <Formik
        initialValues={userToPensionProfileForm(user)}
        onSubmit={(values) => handleSave(values)}
        validationSchema={PensionProfileSchema}
        enableReinitialize
      >
        {(formikProps: FormikProps<PensionProfileForm>) => {
          const errorFields = Object.keys(formikProps.errors);
          const firstErrorFieldId = errorFields.length > 0 ? `pp-${errorFields[0]}` : undefined;

          return (
            <>
              <Layout
                title="Din Pensionsprofil"
                contentClassName="PensionProfile"
                onGoBack={onGoBack.bind(null, formikProps)}
              >
                <InfoItem
                  text={
                    <>
                      Dina svar på pensionsprofilen är det som bestämmer vilken rekommenderad risknivå du ska ha i dina
                      investeringar. <br /> Du uppdaterade din Pensionsprofil{" "}
                      <span style={{ whiteSpace: "nowrap" }}>{profileUpdateDate}</span> senast.
                    </>
                  }
                />
                <div className="PensionProfile__card">
                  <EconomySection user={user} formikProps={formikProps} />
                </div>
                <div className="PensionProfile__card">
                  <WorkSection user={user} formikProps={formikProps} />
                </div>
                <div className="PensionProfile__card">
                  <FamilySection user={user} formikProps={formikProps} />
                </div>
                <div className="PensionProfile__card">
                  <RiskSection user={user} formikProps={formikProps} />
                </div>
                <Button
                  text="Spara"
                  className="PensionProfile__updateButton"
                  onClick={formikProps.handleSubmit}
                  primary
                  disabled={!formikProps.dirty || !formikProps.isValid}
                />
                <p className="PensionProfile__disclaimer">
                  Det är viktigt att du är medveten om att pensionssparande i fonder och de placeringsalternativ vi
                  rekommenderar dig är förknippade med risk. Vi kan aldrig garantera värdeökningar och teoretiskt löper
                  man alltid risken att förlora hela det investerade beloppet. Det vi garanterar är att vi gör vårt
                  bästa för din pension. Pensionskraft är oberoende i valet av pensionsplaceringar och tar inte emot
                  ersättningar från tredje part för att placera befintligt pensionskapital, istället betalar du en fast
                  månads- eller årsavgift för tjänsten. På{" "}
                  <a href="https://www.pensionskraft.se/" target="_blank">
                    www.pensionskraft.se
                  </a>{" "}
                  kan du hitta mer information samt kundavtal och de allmänna villkoren för tjänsten.
                  <br />
                  <br />
                  För att få din rådgivningsdokumentation skickad till dig kontakta support.
                </p>
              </Layout>
              <UnsavedChangesModal
                errorElementId={firstErrorFieldId}
                isOpened={isSaveModalOpen}
                onAccept={formikProps.handleSubmit}
                onClose={setIsSaveModalOpen.bind(null, false)}
                onDecline={goBack}
              />
              <PensaModal
                header={`Du har nu fått "${riskLevelTranslations[newRiskLevel]}" som rekommederad risknivå för dig`}
                content="Vill du ändra till den risknivån och göra en ny Pensionskoll?"
                isOpened={isRiskModalOpen}
                onSubmit={onSaveUserAndRiskLevelChange.bind(null, formikProps.values, newRiskLevel)}
                onDecline={() => {
                  setIsRiskModalOpen(false);
                  onSaveUserAction(formikProps.values);
                }}
                onClose={() => {
                  setIsRiskModalOpen(false);
                  onSaveUserAction(formikProps.values);
                }}
              />
              <LoadingOverlay isShown={isFetching || isNewRiskLevelFetching} />
            </>
          );
        }}
      </Formik>
    ) : null;
  }
);
