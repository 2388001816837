import * as React from "react";
import { useDispatch } from "react-redux";
import { goBack } from "connected-react-router";
import { useParams } from "react-router-dom";

import { Collapse } from "@components/common";
import { MinpensionDataSteps, FAQModel } from "@common/models";
import { SupportContacts } from "@components/support";

import { InstructionsLayout } from "../instructions/InstructionsLayout";

import "./MinpensionDataHelp.scss";

interface MinpensionDataHelpProps {}

const getFaq = (step: MinpensionDataSteps): FAQModel[] => {
  switch (step) {
    case MinpensionDataSteps.Login:
      return [
        {
          id: "login_1",
          title: "Jag har inget bankid?",
          description: "Du kan skaffa ett bankid via din internetbank.",
        },
        {
          id: "login_2",
          title: "Varför behöver jag logga in själv?",
          description:
            "Just nu kan informationen om din pension inte hämtas automatiskt, utan du behöver lägga in den själv. Uppgifterna behövs för att kunna hjälpa dig med din pension.",
        },
        {
          id: "login_3",
          title: "Jag behöver registrera mig?",
          description:
            "Har du inte varit inloggat tidigare på minpension.se behöver du även registrera dig första gången du loggar in.",
        },
      ];

    case MinpensionDataSteps.CollectionStatus:
      return [
        {
          id: "collection_status_1",
          title: "Måste jag vänta tills all insamling är klar?",
          description:
            "En del mindre pensionsbolag tar längre tid på sig. Om du vet med dig att du inte har några pengar hos dessa, så behöver du inte vänta. Klickar du på Insamlingsstatus kan du se vilka pensionsbolag som svarat redan.",
        },
        {
          id: "collection_status_2",
          title: "Jag har inte fått något mail att insamlingen är klar?",
          description:
            "Logga in på minpension.se igen och klicka på Insamlingsstatus så ser du där om alla pensionsbolag har svarat redan.",
        },
      ];

    case MinpensionDataSteps.TotalPension:
      return [
        {
          id: "total_pension_1",
          title: "Jag hittar inte var jag ska klicka?",
          description:
            "Först behöver du vara inloggad till minpension.se. Kontrollera att du loggat in och eventuellt registrerat dig på minpension.se om du inte redan är det. Sedan ser du i övre menyraden vad du ska klicka på",
        },
        {
          id: "total_pension_2",
          title: "Insamlingsstatus är inte klar än?",
          description:
            "En del mindre pensionsbolag tar längre tid på sig. Om du vet med dig att du inte har några pengar hos dessa, så behöver du inte vänta. Klickar du på Insamlingsstatus kan du se vilka pensionsbolag som svarat redan.",
        },
        {
          id: "total_pension_3",
          title: "Jag saknar en del pension?",
          description:
            "Vänta tills insamlingen är klar och nu fått bekräftelsemail på det. Minpension visar de flesta pensioner, men inte allt.",
        },
      ];

    case MinpensionDataSteps.AllmanPensionInfo:
      return [
        {
          id: "allmanpension_info_1",
          title: "Jag hittar inte var jag ska klicka?",
          description:
            "Först behöver du vara inloggad till minpension.se. Kontrollera att du loggat in och eventuellt registrerat dig på minpension.se om du inte redan är det. Sedan ser du i övre menyraden vad du ska klicka på.",
        },
      ];

    case MinpensionDataSteps.AllmanPension:
      return [
        {
          id: "allmanpension_1",
          title: "Jag vet inte var jag ska leta?",
          description:
            "För att se infon om din Allmänna pension så klickar du först på Intjänad pension i övre menyraden. Sedan väljer du Allmän pension.",
        },
        {
          id: "allmanpension_2",
          title: "Jag ser inte mina fonder i Premiepensionen?",
          description:
            "För att se fonderna behöver du klicka på Premiepension. Då ser du lite längre ner på sidan vilka fonder du har.",
        },
        {
          id: "allmanpension_3",
          title: "Jag hittar inte fondnamnet?",
          description:
            "Du behöver skriva in de första bokstäverna i fondnamnet. Har du gjort det och det fortfarande inte hittar det, så kontakta oss så lägger vi till fonden.",
        },
      ];

    case MinpensionDataSteps.TjanstePensionInfo:
    case MinpensionDataSteps.PrivatPensionInfo:
      return [
        {
          id: "tjanstepension_info_1",
          title: "Jag hittar inte var jag ska klicka?",
          description:
            "Först behöver du vara inloggad till minpension.se. Kontrollera att du loggat in och eventuellt registrerat dig på minpension.se om du inte redan är det. Sedan ser du i övre menyraden vad du ska klicka på.",
        },
      ];

    case MinpensionDataSteps.TjanstePension:
      return [
        {
          id: "tjanstepension_1",
          title: "Jag vet inte var jag ska leta?",
          description:
            "För att se infon om din Tjänstepension så klickar du först på Intjänad pension i övre menyraden. Sedan väljer du Tjänstepension.",
        },
        {
          id: "tjanstepension_2",
          title: "Jag hittar inte informationen?",
          description:
            "Se till att du bockat för rutan ‘Se alla försäkringar i detalj’. Då kommer du se all info du behöver.",
        },
        {
          id: "tjanstepension_3",
          title: "Jag hittar inte rätt Namn på försäkring?",
          description:
            "Hittar du inte det namn du har på försäkringen i listan, så kontakta kundtjänst så ska vi lägga till det. Ange vilket pensionsbolag det gäller och namnet på försäkringen.",
        },
        {
          id: "tjanstepension_4",
          title: "Måste jag fylla i försäkringsnumret?",
          description: "Ja det är bra om du gör det eftersom det är det som sedan används för dina fondbyten.",
        },
        {
          id: "tjanstepension_5",
          title: "Jag har flera försäkringar, hur gör jag då?",
          description: "Klicka på Lägg till pensionsinnehav.",
        },
        {
          id: "tjanstepension_6",
          title: "Jag hittar inte fondnamnet?",
          description:
            "Du behöver skriva in de första bokstäverna i fondnamnet. Har du gjort det och det fortfarande inte hittar det, så kontakta oss så lägger vi till fonden.",
        },
      ];

    case MinpensionDataSteps.PrivatPension:
      return [
        {
          id: "privatpension_1",
          title: "Jag ser inget på Privat Pension?",
          description: "Det kan vara så att du inte har någon Privat pension sparad.",
        },
        {
          id: "privatpension_2",
          title: "Jag vet inte var jag ska leta?",
          description:
            "För att se infon om din Privata pension så klickar du först på Intjänad pension i övre menyraden. Sedan väljer du Privat pension.",
        },
        {
          id: "privatpension_3",
          title: "Jag hittar inte informationen?",
          description:
            "Se till att du bockat för rutan ‘Se alla försäkringar i detalj’. Då kommer du se all info du behöver.",
        },
        {
          id: "privatpension_4",
          title: "Jag hittar inte rätt Namn på försäkring?",
          description:
            "Hittar du inte det namn du har på försäkringen i listan, så kontakta kundtjänst så ska vi lägga till det. Ange vilket pensionsbolag det gäller och namnet på försäkringen.",
        },
        {
          id: "privatpension_5",
          title: "Måste jag fylla i försäkringsnumret?",
          description: "Ja det är bra om du gör det eftersom det är det som sedan används för dina fondbyten.",
        },
        {
          id: "privatpension_6",
          title: "Jag har flera försäkringar, hur gör jag då?",
          description: "Klicka på Lägg till pensionsinnehav.",
        },
        {
          id: "privatpension_7",
          title: "Jag hittar inte fondnamnet?",
          description:
            "Du behöver skriva in de första bokstäverna i fondnamnet. Har du gjort det och det fortfarande inte hittar det, så kontakta oss så lägger vi till fonden.",
        },
      ];

    default:
      return [];
  }
};

export const MinpensionDataHelp = ({}: MinpensionDataHelpProps) => {
  const { step } = useParams<{ step: MinpensionDataSteps }>();

  const faqs = getFaq(step as MinpensionDataSteps);

  const dispatch = useDispatch();
  return (
    <InstructionsLayout contentClassName="MinpensionDataHelp" onBack={() => dispatch(goBack())}>
      {faqs.map((faq) => (
        <Collapse key={faq.id} title={faq.title}>
          <p className="SupportFAQ__description">{faq.description}</p>
        </Collapse>
      ))}
      <SupportContacts text="Fick du inte svar på din fråga? Kontakta kundtjänst så hjälper vi dig." />
    </InstructionsLayout>
  );
};
