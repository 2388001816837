import * as React from "react";

import { InstructionsLayout } from "./InstructionsLayout";
import { minpensionDataImages } from "@assets";

interface AllmanPensionInfoProps {
  onNext(): any;
  onBack(): any;
}

export const AllmanPensionInfo = ({ onNext, onBack }: AllmanPensionInfoProps) => {
  return (
    <InstructionsLayout
      contentClassName="AllmanPensionInfo"
      nextBtnText="Gjort, ta mig till nästa steg"
      onNext={onNext}
      onBack={onBack}
    >
      <h5 className="MinpensionDataInstructions__title">Info om din pension</h5>
      <div className="MinpensionDataInstructions__text">
        Pensionen är uppdelad i tre delar: Allmän pension, Tjänstepension och Privat pension.
        <br />
        <br />
        I det här steget anger du vad du har i din Allmänna pension.
        <br />
        <br />
        1. Klicka på Allmän pension
        <img
          className="MinpensionDataInstructions__image MinpensionDataInstructions__image--wide"
          src={minpensionDataImages.allmanPensionInfo1}
          alt="Allmän pension pie chart"
        />
        2. När du valt Allmän pension behöver du också klicka på Premiepension för att se vilka fonder du har
        <img
          className="MinpensionDataInstructions__image MinpensionDataInstructions__image--wide"
          src={minpensionDataImages.allmanPensionInfo2}
          alt="Premiepension Checkbox Image"
        />
      </div>
    </InstructionsLayout>
  );
};
