import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { push } from "connected-react-router";

import { icons } from "@assets";
import { UserWithInfo } from "@common/models";
import { riskLevelTranslations } from "@common/helpers";
import { SettingsRoute, SupportRoute } from "@common/routes";
import { RippleEffect } from "@components/common";

import { AppState } from "@state/store";
import { logOutAction } from "@state/auth";
import { toggleSidebarMenuAction } from "@state/home";

import "./HomeSidebar.scss";

interface HomeSidebarProps {
  user: UserWithInfo | undefined;

  onToggleSidebar(): any;
  onGoToSettings(): any;
  onGoToSupport(): any;
  onLogOut(): any;
}

export const HomeSidebar = connect(
  (state: AppState) => ({
    user: state.user.data
  }),
  (dispatch: Dispatch) => ({
    onToggleSidebar() {
      dispatch(toggleSidebarMenuAction({}));
    },
    onGoToSettings() {
      dispatch(push(SettingsRoute.format({})));
      dispatch(toggleSidebarMenuAction({}));
    },
    onGoToSupport() {
      dispatch(push(SupportRoute.format({})));
      dispatch(toggleSidebarMenuAction({}));
    },
    onLogOut() {
      dispatch(logOutAction({}));
      dispatch(toggleSidebarMenuAction({}));
    }
  })
)(({ user, onGoToSettings, onGoToSupport, onToggleSidebar, onLogOut }: HomeSidebarProps) => {
  return (
    <div className="HomeSidebar">
      <div className="HomeSidebar__header">
        <div className="HomeSidebar__profile">
          <img className="HomeSidebar__profileImage" src={icons.profile} alt="" />
          <div className="HomeSidebar__profileInfo">
            <p className="HomeSidebar__profileName">{user && user.full_name}</p>
            <p className="HomeSidebar__profileEmail">{user && user.email}</p>
          </div>
        </div>
        <div className="HomeSidebar__risk">
          <div className="HomeSidebar__riskTitle">Risknivå</div>
          <div className="HomeSidebar__riskLevel">
            {user &&
              user.risk_profile.requested_risk_level &&
              riskLevelTranslations[user.risk_profile.requested_risk_level]}
          </div>
        </div>
      </div>
      <div className="HomeSidebar__content">
        <HomeSidebarLink text="Hem" icon={icons.globe} onClick={onToggleSidebar} />
        <HomeSidebarLink text="Support" icon={icons.helpdesk} onClick={onGoToSupport} />
        <HomeSidebarLink text="Inställningar" icon={icons.settings} onClick={onGoToSettings} />
      </div>
      <RippleEffect className="HomeSidebar__logout" onClick={onLogOut}>
        <p>Logga Ut</p>
      </RippleEffect>
    </div>
  );
});

interface HomeSidebarLinkProps {
  text: string;
  icon: string;

  onClick(): any;
}

const HomeSidebarLink = ({ text, icon, onClick }: HomeSidebarLinkProps) => {
  return (
    <RippleEffect className="HomeSidebarLink" onClick={onClick}>
      <img className="HomeSidebarLink__icon" src={icon} alt="" />
      <p className="HomeSidebarLink__text">{text}</p>
    </RippleEffect>
  );
};
