import { createSelector } from "reselect";

import { MessageCodes } from "@common/models";
import { userSelector } from "@state/user/selectors";

// messages sorted by created date
export const messageSelector = createSelector([userSelector], user => {
  return ((user && user.in_app_messages) || []).sort(
    (msg1, msg2) => new Date(msg2.date).getTime() - new Date(msg1.date).getTime()
  );
});

export const newDataReadyMessageSelector = createSelector([messageSelector], messages => {
  return messages.find(msg => msg.code === MessageCodes.NewDataReady);
});

export const fundPerformanceMessageSelector = createSelector([messageSelector], messages => {
  return messages.find(msg => msg.code === MessageCodes.FundPerformance);
});

export const visibleMessagesSelector = createSelector([messageSelector], messages => {
  return messages.filter(msg => !msg.hidden);
});

export const newNotificationsCountSelector = createSelector([visibleMessagesSelector], messages => {
  return messages.filter(msg => !(msg.viewed || msg.completed)).length;
});
