import { http } from "rd-redux-http";
import * as cookie from "cookie";

import {
  User,
  UserWithInfo,
  Job,
  LatestSuccesfullScraping,
  TryggakollenData,
  AccessCodeResponse,
  AxaInsuranceRequest,
  AxaProduct,
  InsuranceApplicationBody,
  AxaGroupInsurance,
  InsuranceApplicationResponse,
  MinpensionData,
  MinpensionDataResponse,
} from "./models";
import { dataURItoBlob, getLoginCode } from "./helpers";
import { LoginParams, LoginStatus } from "./models/auth";
import { TodoItemType, RiskLevels, StandaloneInsuranceOffers } from "./types";

const headers = (token?: string) => {
  // const cookies = cookie.parse(document.cookie);
  return new Headers({
    "Content-Type": "application/json",
  });
};

const setCorsMode = (req: Request): Request => {
  return new Request(req, {
    credentials: "same-origin",
    headers: headers(),
  });
};

function apiUrl(path: string): string {
  if (!path) {
    throw new Error("Url is not defined");
  }

  if (path.startsWith("/")) {
    path = path.substring(1);
  }

  return `/api/${path}`;
}

async function cloneFix(request: Request): Promise<Response> {
  // Execute original request
  const response = (await fetch(request.clone())).clone();
  return response;
}

async function reloginOnError(request: Request): Promise<Response> {
  // Execute original request
  const response = (await fetch(request.clone())).clone();
  const loginCode = getLoginCode();
  if (response.clone().status === 401) {
    // if we have login code in storage
    if (loginCode) {
      const refreshResult = await logInRequest({}, { username: "undefined", password: loginCode });
      if (refreshResult.ok) {
        // Repeat initial request
        return await fetch(request.clone());
      }
    }
  }
  return response;
}

export const logOutRequest = http
  .get(apiUrl("/logout"))
  .pre(setCorsMode)
  .withFetch(cloneFix)
  .convertResult((body: any) => new Promise((resolve, reject) => resolve(body)))
  .build();

export const pingRequest = http
  .get(apiUrl("/ping"))
  .pre(setCorsMode)
  .withFetch(cloneFix)
  .resultFromJson<{ reply: "pong" }>()
  .build();

export const logInRequest = http
  .post(apiUrl("/login"))
  .jsonBody<LoginParams>()
  .pre(setCorsMode)
  .withFetch(cloneFix)
  .resultFromJson<User, { message: string }>()
  .build();

export const getlogInStatusRequest = http
  .get<{}>(apiUrl("/login/status"))
  .pre(setCorsMode)
  .withFetch(cloneFix)
  .resultFromJson<LoginStatus>()
  .build();

export const checkAccessCodeRequest = http
  .get<{ code: string }>(apiUrl("/personal-links"), true)
  .pre(setCorsMode)
  .withFetch(cloneFix)
  .resultFromJson<AccessCodeResponse, any>()
  .build();

export const whoAmIRequest = http
  .get(apiUrl("/whoami"))
  .pre(setCorsMode)
  .withFetch(cloneFix)
  .resultFromJson<User>()
  .build();

// requests exptected to be authorized, so using reloginOnError
export const getUserInfoRequest = http
  .get<{ id: string }>(apiUrl("/users/:id"))
  .pre(setCorsMode)
  .withFetch(reloginOnError)
  .resultFromJson<UserWithInfo>()
  .build();

export const startScrapingRequest = http
  .get<{ id: string }>(apiUrl("/users/:id/minpension/scrape/mobilt-bankid"))
  .pre(setCorsMode)
  .withFetch(reloginOnError)
  .resultFromJson<{ title: string; _id: string }>()
  .build();

export const getLatestScrapingStatus = http
  .get<{ id: string }>(apiUrl("/users/:id/minpension/scrapings/latest/successful/status"))
  .pre(setCorsMode)
  .withFetch(reloginOnError)
  .resultFromJson<LatestSuccesfullScraping | {}>()
  .build();

export const getUserActiveJobsRequest = http
  .get(apiUrl("/users/:id/jobs/active"))
  .pre(setCorsMode)
  .withFetch(reloginOnError)
  .resultFromJson<Job[]>()
  .build();

export const getJobRequest = http
  .get<{ id: string }>(apiUrl("/jobs/:id"))
  .pre(setCorsMode)
  .withFetch(reloginOnError)
  .resultFromJson<Job>()
  .build();

export const getTryggakollenDataRequest = http
  .get<{ id: string }>(apiUrl("/users/:id/tryggakollen/data"))
  .pre(setCorsMode)
  .withFetch(reloginOnError)
  .resultFromJson<TryggakollenData>()
  .build();

const configureSignatureRequest = (req: Request, params: any, body: any) => {
  const formData = new FormData();
  formData.append("insurance_numbers", JSON.stringify(body.insurance_numbers));
  formData.append("is_review", body.isReview || false);
  body.validReviewFile && formData.append("valid_review_file", body.validReviewFile);
  if (body.signature) {
    formData.append("file", dataURItoBlob(body.signature), "signature.png");
  }
  const cookies = cookie.parse(document.cookie);
  return new Request(req, {
    method: "POST",
    credentials: "include",
    headers: new Headers({
      "X-XSRF-TOKEN": cookies["XSRF-TOKEN"],
    }),
    body: formData,
  });
};

export const postChangeFundsSignRequest = http
  .post<{ userId: string; insuranceCompany: string }>(
    apiUrl("/users/:userId/change-funds/:insuranceCompany/sign-forms")
  )
  .pre(configureSignatureRequest as any)
  .withFetch(reloginOnError)
  .customBody()
  .resultFromJson<{ fileKey?: string }>()
  .build();

export const postChangeFundsSendFormRequest = http
  .post<{ userId: string; insuranceCompany: string }>(
    apiUrl("/users/:userId/change-funds/:insuranceCompany/send-forms")
  )
  .jsonBody<{
    email: string;
    newScore: number;
    insuranceNumbers: string[];
  }>()
  .withFetch(reloginOnError)
  .convertResult((body: any) => new Promise((resolve, reject) => resolve(body)))
  .build();

export const putPPMChangeFundsRequest = http
  .put<{ userId: string }>(apiUrl("/users/:userId/change-funds/Pensionsmyndigheten/instructions-accepted"), true)
  .jsonBody<{ risk_level: string }>()
  .pre(setCorsMode)
  .withFetch(reloginOnError)
  .convertResult((body: any) => new Promise((resolve, reject) => resolve(body)))
  .build();

export const postUserDataRequest = http
  .post<{ userId: string }>(apiUrl("/users/:userId"))
  .jsonBody<Partial<UserWithInfo>>()
  .pre(setCorsMode)
  .withFetch(reloginOnError)
  .resultFromJson<UserWithInfo>()
  .build();

export const postRequestFCMTokenRequest = http
  .post<{ userId: string }>(apiUrl("/users/:userId/firebase/register/device"))
  .jsonBody<{ registration_id: string }>()
  .pre(setCorsMode)
  .withFetch(reloginOnError)
  .resultFromJson<UserWithInfo>()
  .build();

export const putMessageReadRequest = http
  .put<{ userId: string; messageCode: string }>(apiUrl("/users/:userId/in-app-messages/:messageCode/message-viewed"))
  .jsonBody<{}>()
  .pre(setCorsMode)
  .withFetch(reloginOnError)
  .resultFromJson<UserWithInfo>()
  .build();

export const putMessageActionCompletedRequest = http
  .put<{ userId: string; messageCode: string }>(apiUrl("/users/:userId/in-app-messages/:messageCode/action-completed"))
  .jsonBody<{}>()
  .pre(setCorsMode)
  .withFetch(reloginOnError)
  .resultFromJson<UserWithInfo>()
  .build();

export const postAquireLifeInsuranceRequest = http
  .post<{ userId: string }>(apiUrl("/users/:userId/request-support/acquire-life-insurance"))
  .jsonBody<{}>()
  .pre(setCorsMode)
  .withFetch(reloginOnError)
  .convertResult((body: any) => new Promise((resolve, reject) => resolve(body)))
  .build();

export const postAquirePersonalProtectionInsuranceRequest = http
  .post<{ userId: string }>(apiUrl("/users/:userId/request-support/acquire-personal-protection-insurance"))
  .jsonBody<{}>()
  .pre(setCorsMode)
  .withFetch(reloginOnError)
  .convertResult((body: any) => new Promise((resolve, reject) => resolve(body)))
  .build();

export const putFreeMembershipRequest = http
  .put<{ userId: string }>(apiUrl("/users/:userId/membership/upgrade/try-and-decide"))
  .jsonBody<{}>()
  .pre(setCorsMode)
  .withFetch(reloginOnError)
  .resultFromJson<UserWithInfo>()
  .build();

export const markTermsAccepted = http
  .put<{ userId: string; email: string }>(apiUrl("/users/:userId/minpension/terms-and-conditions/agree"), true)
  .jsonBody<{}>()
  .pre(setCorsMode)
  .withFetch(reloginOnError)
  .convertResult((body: any) => new Promise((resolve, reject) => resolve(body)))
  .build();

export const pegTryggaKollenRequest = http
  .put<{ userId: string }>(apiUrl("users/:userId/tryggakollen/peg"), true)
  .jsonBody<{}>()
  .pre(setCorsMode)
  .withFetch(reloginOnError)
  .resultFromJson<Job>()
  .build();

export const postPPMConfirmationRequest = http
  .put<{ userId: string }>(apiUrl(`users/:userId/ppm-consent-received`), true)
  .jsonBody<{}>()
  .pre(setCorsMode)
  .withFetch(reloginOnError)
  .resultFromJson<UserWithInfo>()
  .build();

export const skipTodoItemRequest = http
  .put<{ userId: string; itemType: TodoItemType }>(apiUrl(`users/:userId/todo-item/:itemType/skip`), true)
  .jsonBody<{}>()
  .pre(setCorsMode)
  .withFetch(reloginOnError)
  .resultFromJson<UserWithInfo>()
  .build();

export const getAxaGroupProductsRequest = http
  .get<{ "person-number": string }>(apiUrl("axagroup-insurances/products/current"), true)
  .pre(setCorsMode)
  .withFetch(reloginOnError)
  .resultFromJson<AxaProduct[]>()
  .build();

export const getActiveInsurancesRequest = http
  .get<{ id: string }>(apiUrl("users/:id/axagroup-insurances/active"), true)
  .pre(setCorsMode)
  .withFetch(reloginOnError)
  .resultFromJson<AxaGroupInsurance[]>()
  .build();

export const postCreateInsuraceRequest = http
  .post(apiUrl("axagroup-insurances"))
  .jsonBody<AxaInsuranceRequest>()
  .pre(setCorsMode)
  .withFetch(reloginOnError)
  .resultFromJson<any>()
  .build();

export const postUpsellInsuraceRequest = http
  .post(apiUrl("axagroup-insurances/upsell"))
  .jsonBody<AxaInsuranceRequest>()
  .pre(setCorsMode)
  .withFetch(reloginOnError)
  .resultFromJson<any>()
  .build();

export const postSkipInsuranceOfferRequest = http
  .post<{ id: string; offerCode: StandaloneInsuranceOffers }>(apiUrl("users/:id/standalone-insurance/:offerCode/skip"))
  .jsonBody<{}>()
  .pre(setCorsMode)
  .withFetch(reloginOnError)
  .resultFromJson<UserWithInfo>()
  .build();

export const getNewRiskLevelRequest = http
  .post<{ userId: string }>(apiUrl("/users/:userId/check-recommended-risk-level"))
  .jsonBody<{ risk_willingness: boolean }>()
  .pre(setCorsMode)
  .withFetch(reloginOnError)
  .resultFromJson<{ recommended_5step_risk_level: RiskLevels }, { message: string }>()
  .build();

export const postInsuranceApplicationRequest = http
  .post<{ userId: string }>(apiUrl("/users/:userId/danica/insurance-application"))
  .jsonBody<InsuranceApplicationBody>()
  .pre(setCorsMode)
  .withFetch(reloginOnError)
  .resultFromJson<{ jobId: string }>()
  .build();

export const getCurrentApplicationRequest = http
  .get<{ userId: string }>(apiUrl("/users/:userId/danica/current-insurance-application"))
  .pre(setCorsMode)
  .withFetch(reloginOnError)
  .resultFromJson<InsuranceApplicationResponse | {}>()
  .build();

export const postSignInsuranceApplicationRequest = http
  .post<{ userId: string; signCaseId: string }>(apiUrl("/users/:userId/danica/sign-insurance-application/:signCaseId"))
  .jsonBody<{}>()
  .pre(setCorsMode)
  .withFetch(reloginOnError)
  .resultFromJson<{ jobId: string }>()
  .build();

export const putCancelInsuranceApplicationRequest = http
  .put<{ userId: string; applicationId: string }>(
    apiUrl("/users/:userId/danica/insurance-application/:applicationId/cancel")
  )
  .jsonBody<{}>()
  .pre(setCorsMode)
  .withFetch(reloginOnError)
  .resultFromJson<{ jobId: string }>()
  .build();

export const getFundsForTypeahedRequest = http
  .get<{ shellId: string; search: string }>(apiUrl("manual-minpension-data/funds-typeahead"), true)
  .pre(setCorsMode)
  .withFetch(reloginOnError)
  .resultFromJson<
    {
      label: string;
      value: string;
    }[]
  >()
  .build();

export const getShellsForTypeahedRequest = http
  .get<{ management_type: string; pension_institute: string }>(
    apiUrl("manual-minpension-data/insuranceshells-typeahead"),
    true
  )
  .pre(setCorsMode)
  .withFetch(reloginOnError)
  .resultFromJson<
    {
      label: string;
      value: string;
    }[]
  >()
  .build();

export const getMinpensionDataRequest = http
  .get(apiUrl("manual-minpension-data"))
  .pre(setCorsMode)
  .withFetch(reloginOnError)
  .resultFromJson<MinpensionDataResponse | null>()
  .build();

export const postMinpensionDataRequest = http
  .post(apiUrl("manual-minpension-data"))
  .jsonBody<MinpensionData>()
  .pre(setCorsMode)
  .withFetch(reloginOnError)
  .resultFromJson<MinpensionDataResponse>()
  .build();

export const getPensionInstitutesRequest = http
  .get(apiUrl("manual-minpension-data/pension-institutes"))
  .pre(setCorsMode)
  .withFetch(reloginOnError)
  .resultFromJson<string[]>()
  .build();

export const postProcessMinpensionDataRequest = http
  .post(apiUrl("manual-minpension-data/process"))
  .jsonBody<{}>()
  .pre(setCorsMode)
  .withFetch(reloginOnError)
  .resultFromJson<{ jobId: string }>()
  .build();
