import * as React from "react";
import * as cn from "classnames";

import { RiskLevels } from "@common/types";
import { riskLevelTranslations } from "@common/helpers";

import "./RiskLevelBadge.scss";

interface RiskLevelBadgeProps {
  level: RiskLevels;

  isRecommended?: boolean;
  isSelected?: boolean;

  onClick(): any;
}

export const RiskLevelBadge = ({ level, isRecommended, isSelected, onClick }: RiskLevelBadgeProps) => {
  return (
    <div className={cn("RiskLevelBadge", { "RiskLevelBadge--selected": isSelected })} onClick={onClick}>
      <p className="RiskLevelBadge__name">{riskLevelTranslations[level] || level}</p>
      {isRecommended && <p className="RiskLevelBadge__recommendation">Rekommenderat!</p>}
    </div>
  );
};
