import { Action } from "redux";

import {
  postChangeFundsSignRequest,
  putPPMChangeFundsRequest,
  postChangeFundsSendFormRequest,
  getTryggakollenDataRequest
} from "@common/requests";
import { isLocationChangeAction } from "../customRouter";

import { ChangeFundsState, defaultChangeFundsState } from "./state";
import { showFormPreviewAction, toggleFormPreviewAction } from "./actions";

const requests = [
  postChangeFundsSignRequest,
  postChangeFundsSendFormRequest,
  putPPMChangeFundsRequest,
  getTryggakollenDataRequest
];

export function changeFundsReducer(
  state: ChangeFundsState = defaultChangeFundsState,
  action: Action
): ChangeFundsState {
  if (requests.some(req => req.actions.isRunning(action))) {
    return {
      ...state,
      isFetching: true
    };
  }

  if (requests.some(req => req.actions.isOk(action))) {
    return {
      ...state,
      isFetching: false
    };
  }

  if (requests.some(req => req.actions.isError(action))) {
    return {
      ...state,
      isFetching: false
    };
  }

  if (showFormPreviewAction.is(action)) {
    return {
      ...state,
      preview: {
        isOpen: true,
        itemId: action.itemId,
        signature: action.signature,
        pdfViewerDocUrl: action.pdfViewerDocUrl
      }
    };
  }

  if (toggleFormPreviewAction.is(action)) {
    return {
      ...state,
      preview: {
        ...state.preview,
        isOpen: !state.preview.isOpen
      }
    };
  }

  if (isLocationChangeAction(action)) {
    return {
      ...state,
      preview: {
        ...state.preview,
        isOpen: false
      }
    };
  }

  return state;
}
