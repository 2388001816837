import * as React from "react";
import * as cn from "classnames";

import { InfoPopup } from "@components/common/infoPopup";

import "./Checkbox.scss";

export interface CheckboxProps {
  value: boolean;
  label?: React.ReactNode;

  alignTop?: boolean;
  info?: React.ReactNode;

  onClick(): any;
}

export const Checkbox = ({ value, alignTop, info, label, onClick }: CheckboxProps) => {
  return (
    <div className={cn("Checkbox", { "Checkbox--checked": value, "Checkbox--top": alignTop })} onClick={onClick}>
      <div className="Checkbox__box">
        <div className="Checkbox__checkmark" />
      </div>
      <div className="Checkbox__label" onClick={(e) => info && e.stopPropagation()}>
        {label}
        {info && <InfoPopup popupContent={info} />}
      </div>
    </div>
  );
};
