import * as React from "react";
import { RouteComponentProps } from "react-router-dom";

import { icons } from "@assets";

import { WithInnerHeight } from "@components/common";
import { StandaloneInsuranceOffers } from "@common/types";

import "./StandaloneInsuranceSuccess.scss";

interface StandaloneInsuranceSuccessProps extends RouteComponentProps<{ type: StandaloneInsuranceOffers }> {}

const getText = (type: StandaloneInsuranceOffers) => {
  switch (type) {
    case StandaloneInsuranceOffers.LifeInsurance:
    case StandaloneInsuranceOffers.SafetyInsurance:
      return `Grattis du har nu tecknat en ${
        type === StandaloneInsuranceOffers.LifeInsurance ? "livförsäkring" : "trygghetsförsäkring"
      }!`;
    case StandaloneInsuranceOffers.SafetyInsuranceUpsell:
    case StandaloneInsuranceOffers.LifeInsuranceUpsell:
      return "Grattis du har nu höjt ditt försäkringsskydd!";
    default:
      return "";
  }
};

export const StandaloneInsuranceSuccess = ({
  match: {
    params: { type }
  }
}: StandaloneInsuranceSuccessProps) => {
  return (
    <WithInnerHeight>
      <section className="StandaloneInsuranceSuccess">
        <img className="StandaloneInsuranceSuccess__checkmark" src={icons.checkmark} alt="" />
        {/* <h1 className="StandaloneInsuranceSuccess__title">{getTitle(item)}</h1> */}
        <p className="StandaloneInsuranceSuccess__description">
          {getText(type)}
          <br />
          <br />
          Du kommer få försäkringsbeskedet skickat hem till dig via post.
        </p>
      </section>
    </WithInnerHeight>
  );
};
