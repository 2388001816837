import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";

import { icons } from "@assets";
import { Button, WithInnerHeight, LoadingOverlay } from "@components/common";
import { AppState } from "@state/store";
import { PPMAcceptedAction, PPMDeclinedAction } from "@state/ppmConfirmation";

import "./PPMConfirmation.scss";

interface PPMConfirmationProps {
  isLoading: boolean;

  onAccept(): any;
  onDecline(): any;
}

export const PPMConfirmation = connect(
  (state: AppState) => ({
    isLoading: state.ppmConfirmation.isLoading
  }),
  (dispatch: Dispatch) => ({
    onAccept() {
      dispatch(PPMAcceptedAction({}));
    },
    onDecline() {
      dispatch(PPMDeclinedAction({}));
    }
  })
)(({ isLoading, onAccept, onDecline }: PPMConfirmationProps) => {
  return (
    <WithInnerHeight limitHeight>
      <section className="PPMConfirmation">
        <img className="PPMConfirmation__logo" src={icons.logoPensionskraft} alt="" />
        <div className="PPMConfirmation__content">
          <h3 className="PPMConfirmation__title">Premiepension</h3>
          <p className="PPMConfirmation__text">
            Du kan även få råd och rekommendationer för din premiepension. Dest kostar inget extra.
          </p>
          <img className="PPMConfirmation__handcoins" src={icons.handcoins} alt="" />
        </div>
        <Button text="Ja, Tack." primary onClick={onAccept} />
        <Button text="Nej" onClick={onDecline} />
      </section>
      <LoadingOverlay isShown={isLoading} />
    </WithInnerHeight>
  );
});
