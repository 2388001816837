import * as React from "react";
import * as cn from "classnames";

import { WithInnerHeight } from "../withInnerHeight";
import { PensaLogo } from "../PensaLogo";

import "./LoginLayout.scss";

interface LoginLayoutProps {
  className?: string;
  children: React.ReactNode;
  hideImage?: boolean;
}

export const LoginLayout = ({ className, children, hideImage }: LoginLayoutProps) => {
  return (
    <WithInnerHeight limitHeight>
      <div className={cn("LoginLayout", className)}>
        {!hideImage && (
          <div className="LoginLayout__logo">
            <PensaLogo width="100%" height="100%" color="#ff3366" />
          </div>
        )}
        {children}
      </div>
    </WithInnerHeight>
  );
};
