import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { push } from "connected-react-router";

import { InfoItem, Button, RippleEffect } from "@components/common";
import { icons } from "@assets";
import { AppState } from "@state/store";
import { selectedTodoItemSelector } from "@state/todoItem";
import {
  recommendedRebalancePPMFundsSelector,
  recommendedStandardPPMFundsSelector,
  submitPPMChangeFundsAction
} from "@state/changeFunds";
import { ChangeFundTodoItem, RecommendedFundsEntity } from "@common/models";
import { SupportRoute } from "@common/routes";

import "./ChangeFundsPPM.scss";

interface ChangeFundsPPMProps {
  recommendedPPMFunds: RecommendedFundsEntity[];

  onSubmit(): any;
  onGoToSupport(): any;
}

export const ChangeFundsPPM = connect(
  (state: AppState) => {
    const item: ChangeFundTodoItem | undefined = selectedTodoItemSelector(state) as any;
    return {
      recommendedPPMFunds: item
        ? item.payload.isRebalancing
          ? recommendedRebalancePPMFundsSelector(state)
          : recommendedStandardPPMFundsSelector(state)
        : []
    };
  },
  (dispatch: Dispatch) => ({
    onSubmit() {
      dispatch(submitPPMChangeFundsAction({}));
    },
    onGoToSupport() {
      dispatch(push(SupportRoute.format({})));
    }
  })
)(({ recommendedPPMFunds, onSubmit, onGoToSupport }: ChangeFundsPPMProps) => {
  return (
    <React.Fragment>
      <InfoItem bold text="Dina PPM-fonder måste du byta själv. Så här gör du." />
      <div className="ChangeFundsPPM__instructions">
        <img className="ChangeFundsPPM__instructionsLogo" src={icons.logoPPM} alt="" />
        <div className="ChangeFundsPPM__instructionsLine" />
        <p className="ChangeFundsPPM__instructionsTitle">Logga in &amp; hitta dina fonder</p>
        <p className="ChangeFundsPPM__instructionsText">
          Logga in på PPM med länken som du hittar längst ned på sidan. När du har loggat in så klickar du på{" "}
          <strong>Mina Tjänster</strong> och under <strong>Min Premiepension</strong> hittar du{" "}
          <strong>Byt Fonder</strong>.
        </p>
        <div className="ChangeFundsPPM__instructionsLine" />
        <p className="ChangeFundsPPM__instructionsTitle">Ta bort och lägg till</p>
        <p className="ChangeFundsPPM__instructionsText">
          Ta bort de fonder du har listade genom att klicka på papperskorgen.
          <br />
          <br />
          Lägg till dessa fondnummer med följande procentfördelning:
        </p>
        <div className="ChangeFundsPPM__fundWrapper">
          {recommendedPPMFunds.map(fund => (
            <div key={fund.isin} className="ChangeFundsPPM__fund">
              <p className="ChangeFundsPPM__fundNumber">{fund.ppm_number}</p>
              <div className="ChangeFundsPPM__fundFooter">
                <p className="ChangeFundsPPM__fundInstitute">{fund.minpension_name}</p>
                <p className="ChangeFundsPPM__fundAllocation">{fund.allocation}%</p>
              </div>
            </div>
          ))}
        </div>
        <div className="ChangeFundsPPM__instructionsLine" />
        <p className="ChangeFundsPPM__instructionsTitle">Godkänn dina byten</p>
        <p className="ChangeFundsPPM__instructionsText">
          När du har gjort dina byten så trycker du på <strong>Godkänn</strong>. Glöm inte att komma tillbaka till
          Pensionskraft för att avsluta.
        </p>
      </div>
      <div className="ChangeFundsPPM__link">
        <p>Du hittar mer information om de föreslagna fonderna på </p>
        <a target="__blank" href="http://www.pensionskraft.se/fondinformation">
          www.pensionskraft.se/fondinformation
        </a>
      </div>
      <a href="https://www.pensionsmyndigheten.se/" target="_blank">
        <Button text="Öppna PPM" />
      </a>
      <Button text="Ok, gjort!" primary onClick={onSubmit} />
      <RippleEffect className="ChangeFundsPPM__helpLink" rounded onClick={onGoToSupport}>
        Kontakta oss om du behöver hjälp med bytet
      </RippleEffect>
      <p className="ChangeFundsPPM__disclaimer">
        Det är viktigt att du är medveten om att pensionssparande i fonder och de placeringsalternativ vi rekommenderar
        dig är förknippade med risk. Vi kan aldrig garantera värdeökningar och teoretiskt löper man alltid risken att
        förlora hela det investerade beloppet.
      </p>
    </React.Fragment>
  );
});
