import { takeEvery, select, put, call } from "redux-saga/effects";

import { TodoItemType } from "@common/types";
import { requestedRiskLevelSelector } from "@state/user";
import { postChangeFundsSignRequest, putPPMChangeFundsRequest, postChangeFundsSendFormRequest } from "@common/requests";
import { FundsRecommendationsEntity, TodoItemModel } from "@common/models";

import { AppState } from "../store";
import { responseErrorAction } from "../error";
import { finalizeTodoItemSaga, selectedTodoItemSelector, currentScoresSelector } from "../todoItem";

import {
  signDocumentAction,
  sendChangeFundFormAction,
  submitPPMChangeFundsAction,
  showFormPreviewAction
} from "./actions";
import { fundRecommendationsSelector } from "./selectors";

function* postChangeFundsSignSaga(insuranceCompany: string, signature: string, isReview: boolean = false) {
  const userId = yield select<AppState>(state => state.user.userId);
  const recommendation: FundsRecommendationsEntity = yield select<AppState>(state =>
    fundRecommendationsSelector(state).find(rec => rec.pension_institute === decodeURI(insuranceCompany))
  );
  yield put(postChangeFundsSignRequest.actions.running({ userId, insuranceCompany }));
  const response: typeof postChangeFundsSignRequest.types.response = yield call(
    postChangeFundsSignRequest,
    {
      userId,
      insuranceCompany
    },
    {
      signature,
      insurance_numbers: recommendation.insurance_numbers,
      isReview
    }
  );
  if (response.ok) {
    yield put(postChangeFundsSignRequest.actions.ok({ userId, insuranceCompany }, response));
  } else {
    yield put(postChangeFundsSignRequest.actions.error({ userId, insuranceCompany }, response));
    yield put(responseErrorAction({ response }));
  }
  return response;
}

function* signDocumentSaga(action: typeof signDocumentAction.typeInterface) {
  const item: TodoItemModel | undefined = yield select<AppState>(selectedTodoItemSelector);
  if (!item || item.type !== TodoItemType.ChangeFunds) {
    return;
  }
  const resp: typeof postChangeFundsSignRequest.types.response = yield call(
    postChangeFundsSignSaga,
    item.payload.pensionInstitute,
    action.signature,
    action.isReview
  );
  if (!resp.ok) {
    return;
  }
  if (action.isReview && resp.result.fileKey) {
    const userId = yield select<AppState>(state => state.user.userId);
    const pdfViewerDocUrl = `${location.origin}/web/viewer.html?file=${encodeURIComponent(
      `${location.origin}/api/users/${userId}/change-funds/review-forms?fileKey=${resp.result.fileKey}`
    )}`;

    yield put(showFormPreviewAction({ signature: action.signature, pdfViewerDocUrl, itemId: item.id }));
    return;
  }
  yield call(finalizeTodoItemSaga);
}

function* sendChangeFundFormSaga({ email }: typeof sendChangeFundFormAction.typeInterface) {
  const item: TodoItemModel | undefined = yield select<AppState>(selectedTodoItemSelector);
  const currentScore = yield select<AppState>(currentScoresSelector);
  if (!item || item.type !== TodoItemType.ChangeFunds) {
    return;
  }
  const userId = yield select<AppState>(state => state.user.userId);
  const recommendation: FundsRecommendationsEntity = yield select<AppState>(state =>
    fundRecommendationsSelector(state).find(rec => rec.pension_institute === decodeURI(item.payload.pensionInstitute))
  );
  const insuranceCompany = item.payload.pensionInstitute;

  yield put(postChangeFundsSendFormRequest.actions.running({ userId, insuranceCompany }));
  const response: typeof postChangeFundsSendFormRequest.types.response = yield call(
    postChangeFundsSendFormRequest,
    {
      userId,
      insuranceCompany
    },
    {
      email,
      insuranceNumbers: recommendation.insurance_numbers,
      newScore: currentScore.total + item.increase!.total
    }
  );
  if (!response.ok) {
    yield put(postChangeFundsSendFormRequest.actions.error({ userId, insuranceCompany }, response));
    yield put(responseErrorAction({ response }));
    return;
  }

  yield put(postChangeFundsSendFormRequest.actions.ok({ userId, insuranceCompany }, response));

  console.log(userId, recommendation);
  yield call(finalizeTodoItemSaga);
}

function* submitPPMChangeFundsSaga() {
  const userId = yield select<AppState>(state => state.user.userId);
  // eslint-disable-next-line
  const riskLevel = yield select<AppState>(requestedRiskLevelSelector);
  yield put(putPPMChangeFundsRequest.actions.running({ userId }));
  const response: typeof putPPMChangeFundsRequest.types.response = yield call(
    putPPMChangeFundsRequest,
    {
      userId
    },
    {
      risk_level: riskLevel
    }
  );
  if (response.ok) {
    yield put(putPPMChangeFundsRequest.actions.ok({ userId }, response));
    yield call(finalizeTodoItemSaga);
  } else {
    yield put(putPPMChangeFundsRequest.actions.error({ userId }, response));
    yield put(responseErrorAction({ response }));
  }
}

export function* changeFundsSaga() {
  yield takeEvery(signDocumentAction.type, signDocumentSaga);
  yield takeEvery(sendChangeFundFormAction.type, sendChangeFundFormSaga);
  yield takeEvery(submitPPMChangeFundsAction.type, submitPPMChangeFundsSaga);
}
