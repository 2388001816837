import * as React from "react";
import * as cn from "classnames";

import { getCurrentLevelName } from "@common/helpers";

import { StarsBar } from "../starsBar";

import "./StarsWithScore.scss";

interface StarsWithScoreProps {
  score: number;
  centered?: boolean;

  starFillColor?: string;
  starBackgroundColor?: string;
}

export const StarsWithScore = ({ score, centered, starFillColor, starBackgroundColor }: StarsWithScoreProps) => {
  return (
    <div className={cn("StarsWithScore", { "StarsWithScore--centered": centered })}>
      <StarsBar
        progress={score}
        fillColor={starFillColor || "#ffda00"}
        backgroundColor={starBackgroundColor || "rgba(0, 0, 0, 0.1)"}
      />
      <div className="StarsWithScore__score">
        <h3 className="StarsWithScore__scoreValue">
          {score.toLocaleString("sv", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
        </h3>
        <p className="StarsWithScore__scoreLevel">{getCurrentLevelName(score)}</p>
      </div>
    </div>
  );
};
