import * as React from "react";
import * as cn from "classnames";

import { StarsBar, ShowMoreLink } from "@components/common";
import { getCurrentLevelName } from "@common/helpers";

import "./MeterSlide.scss";

interface MeterSlideProps {
  score: number;
  invertColors?: boolean;
  isRenewalLetter?: boolean;

  onShowMore?(): any;
}

export const MeterSlide = ({ score, invertColors, isRenewalLetter, onShowMore }: MeterSlideProps) => {
  const adjustZeroScore = score === 0 && isRenewalLetter;
  return (
    <div className={cn("MeterSlide", { "MeterSlide--inverse": invertColors })}>
      <div className="MeterSlide__top">
        <p className="MeterSlide__title">Ditt betyg</p>
        <StarsBar
          progress={adjustZeroScore ? 5 : score}
          fillColor={invertColors ? "#ff3366" : "white"}
          backgroundColor={invertColors ? "rgba(255,51,102,0.3)" : "rgba(0, 0, 0, 0.2)"}
        />
      </div>
      <div className="MeterSlide__bot">
        <div className="MeterSlide__score">
          {!adjustZeroScore && (
            <p className="MeterSlide__scoreNumber">
              {score.toLocaleString("sv", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
            </p>
          )}
          <p className="MeterSlide__scoreText">
            {adjustZeroScore ? "Använd webappen så får du reda på det." : getCurrentLevelName(score)}
          </p>
        </div>
        {onShowMore && <ShowMoreLink text="Mer om ditt betyg" onClick={onShowMore} />}
      </div>
    </div>
  );
};
