import * as collectionStatus from "./files/minpensionData/collection-status.png";
import * as totalPension1 from "./files/minpensionData/total-pension-1.png";
import * as totalPension2 from "./files/minpensionData/total-pension-2.png";
import * as allmanPensionInfo1 from "./files/minpensionData/allman-pension-info-1.png";
import * as allmanPensionInfo2 from "./files/minpensionData/allman-pension-info-2.png";
import * as tjanstePensionInfo1 from "./files/minpensionData/tjanste-pension-info-1.png";
import * as showDetailsCheckbox from "./files/minpensionData/show-details-checkbox.png";
import * as privatPensionInfo1 from "./files/minpensionData/privat-pension-info-1.png";

export const minpensionDataImages = {
  collectionStatus,
  totalPension1,
  totalPension2,
  allmanPensionInfo1,
  allmanPensionInfo2,
  tjanstePensionInfo1,
  showDetailsCheckbox,
  privatPensionInfo1,
};
