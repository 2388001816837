import * as React from "react";
import * as cn from "classnames";

import { RippleEffect } from "@components/common/rippleEffect";
import { ChevronBold } from "@components/common/ChevronBold";

import "./ButtonLink.scss";

interface ButtonLinkProps {
  text: string;
  inverse?: boolean;

  onClick(): any;
}

export const ButtonLink = ({ text, inverse, onClick }: ButtonLinkProps) => {
  return (
    <RippleEffect className={cn("ButtonLink", { "ButtonLink--inverse": inverse })} onClick={onClick} inverse={!inverse}>
      <p className="ButtonLink__text">{text}</p>
      <ChevronBold
        direction="right"
        color={inverse ? "#12121c" : "white"}
        width="0.8rem"
        height="1.2rem"
        className="ButtonLink__chevron"
      />
    </RippleEffect>
  );
};
