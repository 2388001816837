import * as Yup from "yup";

import { MultiSelectValue } from "@components/common/controls/multiselect";
import { isEmpty } from "lodash-es";
import { PayoutType, KycFormCustomerInfo, PayoutInfo } from "@common/models";

const REQUIRED_FIELD_TEXT = "Det här fältet behöver fyllas i";

export const insurancePurposeOptions = [
  { label: "Pensionssparande", value: "Pension" },
  { label: "Målsparande", value: "Objective" },
  { label: "Placering", value: "Investments" },
];

export const paymentOptions = [
  { label: "0–100 000 kr", value: "Amount0To100K" },
  { label: "100 001–500 000 kr", value: "Amount100KTo500K" },
  { label: "500 001–1 000 000 kr", value: "Amount500KTo1M" },
  { label: "1 000 001–5 000 000 kr", value: "Amount1MTo5M" },
  // { label: "Över 5 000 000 kr", value: "AmountAbove5M" }
];

export const sourceOfFundsOptions = [
  { label: "Lön/Pension", value: "SalaryOrPension" },
  { label: "Sparande/placering", value: "Savings" },
  { label: "Bolagsförsäljning", value: "CorporationSale" },
  { label: "Arv/Gåva", value: "HeritageOrGift" },
  { label: "Fastighetsförsäljning", value: "PropertySale" },
];

export const defaultMultiSelectValue: MultiSelectValue = {
  selected: [],
};

const multiSelectSchema = Yup.object()
  .shape({
    other: Yup.string(),
    selected: Yup.array().of(Yup.string()),
  })
  .test("hasValue", REQUIRED_FIELD_TEXT, function (value: MultiSelectValue) {
    const valid = (!isEmpty(value.selected) || !!value.other) && value.other !== "";
    return (
      valid ||
      this.createError({
        path: this.path,
        message: REQUIRED_FIELD_TEXT,
      })
    );
  });

export const KycFormInsuranceAmountSchema = Yup.object().shape({
  insurancePurpose: multiSelectSchema,
  initialPremiumAmount: multiSelectSchema,
  yearlyPremiumAmount: multiSelectSchema,
});

export const KycFormCustomerInfoSchema = Yup.object()
  .shape({
    sourceOfFunds: multiSelectSchema,
    isPep: Yup.bool().required(REQUIRED_FIELD_TEXT),
    taxableInOtherCountry: Yup.bool().required(REQUIRED_FIELD_TEXT),
    hasOtherCitizenship: Yup.bool().required(REQUIRED_FIELD_TEXT),
  })
  .test("cannotProceed", "User is not valid for this insurance", function (value: KycFormCustomerInfo) {
    const cannotProceed = value.isPep || value.taxableInOtherCountry || value.hasOtherCitizenship;
    return !cannotProceed;
  });

export const getPayoutInfoSchema = (userAge: number) =>
  Yup.object()
    .shape({
      isOneTime: Yup.bool(),
      isRrecurring: Yup.bool(),
      payoutStartAge: Yup.number(),
      payoutPeriod: Yup.number(),
    })
    .test("notValid", "", function (value: PayoutInfo) {
      const minStartAge = userAge + 2;
      if (!value.payoutStartAge || value.payoutStartAge < minStartAge) {
        return this.createError({
          path: this.path,
          message: `Lägsta startålder är ${minStartAge}`,
        });
      }
      if (value.type === PayoutType.Recurring) {
        if (!value.payoutPeriod) {
          return this.createError({
            path: this.path,
            message: `Utbetalningstid ska vara minst 1 år`,
          });
        } else if (value.payoutPeriod > 95 - value.payoutStartAge) {
          return this.createError({
            path: this.path,
            message: `Sista utbetalning ska vara senast vid 95 års ålder`,
          });
        }
      }

      return true;
    });

export const noYesOptions = [
  { label: "Ja", value: "true" },
  { label: "Nej", value: "false" },
];
