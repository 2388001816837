export interface Job {
  _id: string;
  creator: string;
  steps: number;
  data: Data;
  results?: string[] | null;
  failed: boolean;
  updated_at: string;
  created_at: string;
  timeout_limit: number;
  poll_interval: number;
  current_step: CurrentStep;
  queued: Queued;
  title: string;
  __v: number;
  finished_at?: string;
  error_msg?: string;
  progress?: number;
  duration_hint?: DurationHint;
  completed_step_names: string[];
  timedOut: boolean;
}

export enum DurationHint {
  Slow = "slow",
}

export function isStepCompleted(job: Job, name: string) {
  return !!job.completed_step_names.find((stepName) => stepName === name);
}

export interface Data {
  current_funds_distribution?: null[] | null;
}
export interface CurrentStep {
  index: number;
  name: string;
}
export interface Queued {
  user: string;
  task_code: string;
  dyno: string;
}
