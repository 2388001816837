export interface TryggakollenData {
  appears_retired?: boolean;
  person_number: string;
  requested_risk_level: string;
  gross_monthly_income: number;
  collective_agreement: string;
  retirement_age: number;
  current_age: CurrentAge;
  gender: string;
  life_expectancy: number;
  years_until_retirement: number;
  expected_years_in_retirement: number;
  summary: TryggakollenSummaryModel;
  prognosis: Prognosis;
  pension_plans?: PensionPlansEntity[] | null;
  pension_data_date: string;
  analysis_pegged_date?: string;
}

export interface WeightedAverage {
  kiid_risk: KiidRisk;
  morningstar_rating: MorningstarRating;
}
export interface KiidRisk {
  currently: number;
  target: number;
}
export interface MorningstarRating {
  currently: number;
}
export interface KiidRisk1 {
  currently: number;
  target: number;
  improved: boolean;
}
export interface MorningstarRating1 {
  currently: number;
  improved: boolean;
}

export interface PensionPlansEntity {
  shell_id: string;
  pension_institute: string;
  insurance_name: string;
  kind: string;
  capital: number;
  annuity: number;
  payments_underway: boolean;
  fund_valuation: number;
  total_valuation: number;
  type: string;
  funds?: FundsEntity[] | null;
  funds_weighted_ytd_return?: number | null;
  recommended_funds?: RecommendedFundsEntity[] | null;
  insurance_number?: string | null;
  payed_from_age?: number | null;
  years_payed?: number | null;
  unidentified_funds?: UnidentifiedFundsEntity[] | null;
  is_rebalancing?: boolean;
}
export interface UnidentifiedFundsEntity {
  minpension_name: string;
  total_valuation: number;
}

export interface UpdatedDate {}
export interface FundsEntity {
  total_valuation: number;
  valuation_date?: string | null;
  minpension_name: string;
  isin: string;
  fund_company_name: string;
  ppm_number: string;
  kiid_risk: number;
  updated_date: string;
  morningstar_rating?: number | null;
  morningstar_category: string;
  fee: Fee;
  threeYr_stats: ThreeYrStats;
  ytd_stats: YtdStats;
  oneMonth_stats: OneMonthStats;
}

export interface OneMonthStats {
  mean_return: number;
}

export interface Fee {
  selling?: number | null;
  ongoing_charges: number;
  buying: number;
  annual_management: number;
  max_resultbased?: number | null;
}
export interface ThreeYrStats {
  standard_deviation: number;
  mean_return: number;
}
export interface YtdStats {
  mean_return: number;
}

export interface RecommendedFundsEntity {
  allocation: number;
  total_valuation: number;
  minpension_name: string;
  isin: string;
  fund_company_name: string;
  ppm_number: string;
  kiid_risk: number;
  updated_date: UpdatedDate;
  morningstar_rating?: number | null;
  morningstar_secid: string;
  morningstar_category: string;
  fee: Fee;
  threeYr_stats: ThreeYrStats;
  ytd_stats: YtdStats;
}

interface CurrentAge {
  decimal_years: number;
  years: number;
  months: number;
  days: number;
}
export interface TryggakollenSummaryModel {
  sums: ScrapingSummarySums;
  recommendation: RecommendationModel;
  overall: SummaryScrappingOverallModel;
  history: {
    total_valuations: ScrapingSummaryHistoryPoint[];
  };
}

export interface SummaryScrappingOverallModel {
  raw_metrics: any;
  scoring: SummaryScrappingOverallScoringModel;
}

export interface SummaryScrappingOverallScoringModel {
  risk?: OverallScoringItemModel;
  savings?: OverallScoringItemModel;
  quality?: OverallScoringItemModel;
  total: OverallScoringItemModel;
}
export interface OverallScoringItemModel {
  currently: number;
}

export interface RecommendationModel {
  funds?: FundsRecommendationModel;
  savings?: SavingsRecomendationModel;
  group_insurances: InsuranceRecommendationModel;
}

export interface InsuranceRecommendationModel {
  has_dependants?: boolean;
  uncovered_capital?: number;
  life_insurance?: {
    needs_cover: true;
    submitted_date?: string;
    upsell: InsuranceUpsellModel;
  };
  safety_insurance?: {
    submitted_date?: string;
    skipped_date?: string;
    upsell: InsuranceUpsellModel;
  };
}

export interface InsuranceUpsellModel {
  upsell_possible: boolean;
  submitted_date?: string;
  skipped_date?: string;
}

export interface SavingsRecomendationModel {
  deltas_for_alternatives?: { [key: number]: { savings_score: number; total_score: number } };
  assumed_inflation: number;
  assumed_interest: number;
  remaining_deposits: number;
  current_monthly_amount: number;
  suggested_monthly_amount: number;
  ideal_monthly_amount: number;
  requested_monthly_amount: number;
  submitted_date?: string;
  capital_gained_for_each_100kr: number;
  improvements: RecommendationImprovementsModel;
}

export interface FundsRecommendationModel {
  by_pension_institute: FundsRecommendationsEntity[] | null;
}

export interface FundsRecommendationsEntity {
  pension_institute: string;
  insurance_numbers?: string[];
  improvements: RecommendationImprovementsModel;
  submitted_date?: string;
  is_rebalancing: boolean;
}

export interface RecommendationImprovementsModel {
  risk?: number;
  savings?: number;
  quality?: number;
  total: number;
}

export interface WeightedAverage1 {
  kiid_risk: KiidRisk1;
  morningstar_rating: MorningstarRating;
}

export interface ScrapingSummaryHistoryPoint {
  date: string;
  value: number;
}
interface ScrapingSummarySums {
  annuity: ScrapingSummarySumsItem;
  capital: ScrapingSummarySumsItem;
  capital_of_which_funds: ScrapingSummarySumsItem;
  premiepension: number;
  inkomstpension: number;
}
export interface ScrapingSummarySumsItem {
  allmänpension: number;
  tjänstepension: number;
  privatpension: number;
  total: number;
}
interface Prognosis {
  approximation: Approximation;
  minpension: Minpension;
}
interface Approximation {
  sek_per_month: number;
}

export enum MinpensionGrades {
  Poor = "poor",
  Low = "low",
  Ok = "okay"
}
interface Minpension {
  final_pay: number;
  mean_return: number;
  note: string;
  pay_increase: number;
  excluded_insurances?: null[] | null;
  age_spans?: AgeSpansEntity[] | null;
  averaged: Averaged;
  grade: MinpensionGrades;
}
interface AgeSpansEntity {
  private_pension: number;
  public_pension: number;
  service_pension: number;
  total_pension: number;
  to_age?: ToAgeOrFromAge | null;
  from_age: ToAgeOrFromAge1;
}
interface ToAgeOrFromAge {
  month: number;
  years: number;
}
interface ToAgeOrFromAge1 {
  month: number;
  years: number;
}
interface Averaged {
  sek_per_month: number;
  percentage_of_final_pay: number;
}
