import { Action } from "redux";

import {
  getAxaGroupProductsRequest,
  postCreateInsuraceRequest,
  postUpsellInsuraceRequest,
  getActiveInsurancesRequest
} from "@common/requests";

import { InsuranceState, defaultInsuranceState } from "./state";

export function insuranceReducer(state: InsuranceState = defaultInsuranceState, action: Action): InsuranceState {
  if (getAxaGroupProductsRequest.actions.isRunning(action)) {
    return {
      ...state,
      axaProducts: {
        isLoading: true
      }
    };
  }

  if (getAxaGroupProductsRequest.actions.isOk(action)) {
    return {
      ...state,
      axaProducts: {
        isLoading: false,
        data: action.result
      }
    };
  }

  if (getAxaGroupProductsRequest.actions.isError(action)) {
    return {
      ...state,
      axaProducts: {
        isLoading: false
      }
    };
  }

  if (postCreateInsuraceRequest.actions.isRunning(action) || postUpsellInsuraceRequest.actions.isRunning(action)) {
    return {
      ...state,
      isSaving: true
    };
  }

  if (postCreateInsuraceRequest.actions.isOk(action) || postUpsellInsuraceRequest.actions.isOk(action)) {
    return {
      ...state,
      isSaving: false
    };
  }

  if (postCreateInsuraceRequest.actions.isError(action) || postUpsellInsuraceRequest.actions.isError(action)) {
    return {
      ...state,
      isSaving: false
    };
  }

  if (getActiveInsurancesRequest.actions.isOk(action)) {
    return {
      ...state,
      activeInsurances: action.result
    };
  }

  return state;
}
