import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { push, replace } from "connected-react-router";

import { HomeRoute, PensionProfileUpdateRoute, UpdateProfileBenefitsRoute } from "@common/routes";

import { Layout, Button, LoadingOverlay } from "@components/common";

import { AppState } from "@state/store";
import { skipItemAction } from "@state/todoItem";

import "./UpdateProfileBenefits.scss";
import { UpdatePensionProfileTodoItem, TodoItemModel } from "@common/models";

interface UpdateProfileBenefitsProps {
  isFetching: boolean;
  updateProfileTodoItem: UpdatePensionProfileTodoItem | undefined;

  onRedirectToHome(): any;
  onSetTodoItem(item: TodoItemModel): any;
  onSkipTodoItem(): any;
  onGoToPensionProfileUpdate(): any;
}

export const UpdateProfileBenefits = connect(
  (state: AppState) => ({
    isFetching: state.todoItem.isSkippingInProgress
  }),
  (dispatch: Dispatch) => ({
    onSkipTodoItem() {
      dispatch(skipItemAction({}));
    },
    onGoToPensionProfileUpdate() {
      dispatch(
        push(PensionProfileUpdateRoute.format({ section: "1" }, { initialUrl: UpdateProfileBenefitsRoute.format({}) }))
      );
    },
    onRedirectToHome() {
      dispatch(replace(HomeRoute.format({})));
    }
  })
)(({ isFetching, onSkipTodoItem, onGoToPensionProfileUpdate, onRedirectToHome }: UpdateProfileBenefitsProps) => {
  return (
    <>
      <Layout title="Uppdatera din Pensionsprofil" contentClassName="UpdateProfileBenefits" onGoBack={onRedirectToHome}>
        <main className="UpdateProfileBenefits__main">
          <div className="UpdateProfileBenefits__card">
            <p className="UpdateProfileBenefits__cardText">
              I din Pensionsprofil finns uppgifter som används för att bestämma vilken risknivå på ditt sparande du bör
              ha.
              <br />
              <br />
              Nu har du chansen att ta en minut för att kontrollera att dina uppgifter är stämmer.
            </p>
          </div>
        </main>
        <div className="UpdateProfileBenefits__actions">
          <Button text="Ja tack, jag vill se över min Pensionprofil" onClick={onGoToPensionProfileUpdate} primary />
          <Button text="Nej tack, jag hoppar över det" onClick={onSkipTodoItem} />
        </div>
      </Layout>
      <LoadingOverlay isShown={isFetching} />
    </>
  );
});
