import { InsuranceApplicationResponse } from "@common/models";

export interface ChangeSavingsState {
  selectedDeposit?: number;
  insuranceApplicationResponse?: InsuranceApplicationResponse;
  insuranceApplicationStep?: InsuranceApplicationStep;
}

export enum InsuranceApplicationStep {
  WaitingSend,
  WaitingSign,
  WaitingProcessed,
  Summary,
  ManualValidation,
  Error,
  Rejected,
  Processed,
}

export const defaultChangeSavingsState: ChangeSavingsState = {};
