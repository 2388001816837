import * as querystring from "querystring";
import * as URL from "url";

import { ParsedUrlQueryInput } from "querystring";

import { LocationDescriptor, UrlPath, NoMatch, Match } from "./api";

export function createPath<TParams, TQueryString = ParsedUrlQueryInput>(
  urlTemplate: string
): UrlPath<TParams, TQueryString> {
  if (!urlTemplate) {
    throw new Error("URL template is not defined.");
  }

  return new UrlPathImpl<TParams, TQueryString>(urlTemplate);
}

class UrlPathImpl<TParams, TQueryString = ParsedUrlQueryInput> implements UrlPath<TParams, TQueryString> {
  private paramNames: string[] = [];
  private regexpTemplate: string;

  get paramType(): TParams {
    throw new Error("Use this property in Typescript typeof operator only");
  }

  get queryType(): TQueryString {
    throw new Error("Use this property in Typescript typeof operator only");
  }

  urlTemplate: string = "";

  constructor(urlTemplate: string) {
    this.urlTemplate = urlTemplate;

    if (this.urlTemplate.endsWith("/")) {
      this.urlTemplate = this.urlTemplate.replace(/\/$/, "");
    }

    this.regexpTemplate =
      this.urlTemplate
        .split("/")
        .map(part => {
          if (part.startsWith(":")) {
            this.paramNames.push(part.substring(1));

            return "(.+)";
          }

          return part;
        })
        .join("/") + "/?";
  }

  match(url: string | Location, exact: boolean = false): NoMatch | Match<TParams, TQueryString> {
    const location = this.normalizeUrl(url);

    const result = new RegExp(exact ? this.regexpTemplate + "$" : this.regexpTemplate).exec(location.pathname);

    const noMatch: NoMatch = { isMatched: false };

    if (!result || result.length < this.paramNames.length + 1) {
      return noMatch;
    }

    const matches = result.slice(1);

    if (matches.length !== this.paramNames.length) {
      return noMatch;
    }

    const params = matches.reduce<TParams>((result: TParams, value, index) => {
      result[this.paramNames[index]] = value;

      return result;
    }, {} as TParams);

    let query: TQueryString | undefined = undefined;

    if (location.search) {
      const searchString = location.search.startsWith("?")
        ? location.search.substring(location.search.indexOf("?") + 1)
        : location.search;

      query = querystring.parse(searchString) as any;
    }

    return {
      isMatched: true,
      params,
      query: query as any
    };
  }

  format(params: TParams, query?: ParsedUrlQueryInput): string {
    params = params || ({} as any);

    const qs = query ? "?" + querystring.stringify(query) : "";

    return (
      this.urlTemplate
        .split("/")
        .map(part => {
          if (!part.startsWith(":")) {
            return part;
          }

          const paramName = part.endsWith("?") ? part.substring(1, part.length - 1) : part.substring(1);

          let value = params[paramName];

          if (value === null || value === undefined) {
            value = "";
          }

          return `${value}`;
        })
        .join("/") + qs
    );
  }

  private normalizeUrl(url: string | LocationDescriptor): LocationDescriptor {
    if (typeof url === "string") {
      const parsedUrl = URL.parse(url);
      return {
        pathname: parsedUrl.pathname || "/",
        search: parsedUrl.search
      };
    }

    if (typeof url === "object") {
      return {
        pathname: url.pathname,
        search: !url.search || url.search.indexOf("?") !== 0 ? url.search : url.search.substring(1)
      };
    }

    return {
      pathname: "",
      search: null
    };
  }
}
