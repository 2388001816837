import * as React from "react";
import * as cn from "classnames";
import XAxis from "recharts/es6/cartesian/XAxis";
import YAxis from "recharts/es6/cartesian/YAxis";
import Line from "recharts/es6/cartesian/Line";
import CartesianGrid from "recharts/es6/cartesian/CartesianGrid";
import LineChart from "recharts/es6/chart/LineChart";

import { roundNumberTo } from "@common/helpers";
import { ChartPointData } from "@state/home";
import { ShowMoreLink } from "@components/common";

import "./MoneySlide.scss";

interface MoneySlideProps {
  data: MoneySlideChartData[];
  selectedPoint?: ChartPointData;
  selectedPointSize?: string;
  invertColors?: boolean;

  onShowMore?(): any;
  onPointSelect(point: ChartPointData): any;
}

const isEqualPoints = (a?: ChartPointData, b?: ChartPointData): boolean => {
  return (
    !!a &&
    !!b &&
    a.index === b.index &&
    roundNumberTo(a.cx) === roundNumberTo(b.cx) &&
    roundNumberTo(a.cy) === roundNumberTo(b.cy)
  );
};

export interface MoneySlideChartData {
  date: number;
  value: number;
}

interface MoneySlideState {
  width: number;
  height: number;
}

const getDateFromNumber = (num: number): string => {
  const date = new Date(num);
  return `${date.toLocaleDateString("sv", { month: "short", year: "numeric" })}`;
};

export class MoneySlide extends React.Component<MoneySlideProps, MoneySlideState> {
  chartRef: any | null;
  lastClickCoords: { pageX: number; pageY: number } | null;

  constructor(props: MoneySlideProps) {
    super(props);
    this.state = { ...this.getSize() };
  }

  componentDidMount() {
    this.setState(prev => ({ ...prev, ...this.getSize() }));

    window.addEventListener("resize", this.resizeHandler.bind(this));
  }

  componentDidUpdate() {
    const { selectedPoint, onPointSelect } = this.props;
    const newSelectedPoint = this.getPoint(selectedPoint ? selectedPoint.index : undefined);
    newSelectedPoint && !isEqualPoints(selectedPoint, newSelectedPoint) && onPointSelect(newSelectedPoint);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeHandler.bind(this));
  }

  render() {
    const { data, selectedPoint, selectedPointSize, invertColors, onShowMore } = this.props;

    const xDomain = data.length > 0 ? getDomain(data, "date", 0.1) : undefined;
    const { width, height } = this.state;
    const gridHorizontalInterval = width / 6;
    const gridVerticalInterval = height / 6;

    return (
      <div className={cn("MoneySlide", { "MoneySlide--inverse": invertColors })}>
        <div className="MoneySlide__chart">
          {data.length > 0 && (
            <LineChart ref={(chart: any) => (this.chartRef = chart)} data={data} width={width} height={height!}>
              <XAxis dataKey="date" type="number" domain={xDomain} hide />
              <YAxis hide type="number" domain={getDomain(data, "value", 0.2, 0.2)} />
              <CartesianGrid
                strokeWidth={1}
                stroke={invertColors ? "rgba(255,51,102,0.3)" : "rgba(255,255,255,0.3)"}
                horizontalPoints={getGridLineCoords(gridVerticalInterval / 2, height!, gridVerticalInterval)}
                verticalPoints={getGridLineCoords(gridHorizontalInterval / 2, width!, gridHorizontalInterval)}
              />
              <Line
                dataKey="value"
                stroke={invertColors ? "#ff3366" : "#ffffff"}
                strokeWidth={2}
                isAnimationActive={false}
                dot={{
                  fill: invertColors ? "#ff3366" : "#ffffff",
                  r: 4.5,
                  stroke: "transparent",
                  strokeWidth: 15,
                  onClick: (e: any) => this.handlePointClick(e)
                }}
                activeDot={false}
              />
            </LineChart>
          )}
          {selectedPoint && (
            <div
              className="MoneySlide__selectedDot"
              style={{
                top: selectedPoint.cy,
                left: selectedPoint.cx,
                width: selectedPointSize,
                height: selectedPointSize,
                borderRadius: selectedPointSize
              }}
            />
          )}
        </div>
        <div className="MoneySlide__footer">
          <div className="MoneySlide__info">
            <p className="MoneySlide__infoKr">
              {selectedPoint && `${selectedPoint.payload.value.toLocaleString("sv")} kr`}
            </p>
            <p className="MoneySlide__infoDate">{selectedPoint && getDateFromNumber(selectedPoint.payload.date)}</p>
          </div>
          {onShowMore && <ShowMoreLink text="Visa mer" onClick={onShowMore} />}
        </div>
      </div>
    );
  }

  handlePointClick({ cx, cy, payload }: ChartPointData) {
    const index = this.props.data.findIndex(point => new Date(point.date).getTime() === payload.date);
    this.props.onPointSelect({ index, cx, cy, payload });
  }

  resizeHandler = (e: UIEvent) => {
    this.setState(prev => ({
      ...this.getSize()
    }));
  };

  getSize() {
    const container = document.querySelector<HTMLDivElement>(".MoneySlide__chart");
    if (!container) {
      return {
        height: 0,
        width: 0
      };
    }
    return {
      height: container.clientHeight,
      width: container.clientWidth
    };
  }

  getPointCoords(index: number) {
    const dots = document.querySelectorAll<any>(".recharts-dot.recharts-line-dot");
    const lastDot = dots[index];
    if (lastDot) {
      return { cx: lastDot.cx.baseVal.value, cy: lastDot.cy.baseVal.value };
    } else {
      return null;
    }
  }

  getPoint(index?: number): ChartPointData | null {
    if (!this.props.data.length) {
      return null;
    }
    const actualIndex = index === undefined ? this.props.data.length - 1 : index;
    const lastPointCoords = this.getPointCoords(actualIndex);
    if (lastPointCoords) {
      const lastPointData = this.props.data[actualIndex];
      return {
        ...lastPointCoords,
        index: actualIndex,
        payload: {
          date: new Date(lastPointData.date).getTime(),
          value: lastPointData.value
        }
      };
    }
    return null;
  }
}

function getDomain(data: any[], datakey: "value" | "date", spreadMin: number, spreadMax?: number): [number, number] {
  if (data.length > 1) {
    let min = data[0][datakey],
      max = data[0][datakey];

    data.forEach(point => {
      min = point[datakey] < min ? point[datakey] : min;
      max = point[datakey] > max ? point[datakey] : max;
    });

    const paddingMin = Math.round((max - min) * spreadMin);
    const paddingMax = Math.round((max - min) * (spreadMax || spreadMin));
    return [min - paddingMin, max + paddingMax];
  } else {
    const padding = datakey === "value" ? 1 : 3600 * 24 * 365 * 1000;
    return [data[0][datakey] - padding, data[0][datakey] + padding];
  }
}

function getGridLineCoords(start: number, end: number, interval: number): number[] {
  const res = [];
  for (let i = start; i < end; i += interval) {
    res.push(i);
  }
  return res;
}
