import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { push } from "connected-react-router";

import { AppState } from "@state/store";
import { icons } from "@assets";
import { currentScoresSelector, availableTodoItemListSelector } from "@state/todoItem";
import { BasicScoringModel, TodoItemModel, MessageCodes, InAppMessage } from "@common/models";
import { StarsWithScore, Button } from "@components/common";
import { HomeRoute } from "@common/routes";
import { notificationMessageCompleteAction, newDataReadyMessageSelector } from "@state/notifications";

import "./Summary.scss";

interface SummaryProps {
  isDataFetched: boolean;
  scoring: BasicScoringModel;
  items: TodoItemModel[];
  newDataReadyMsg: InAppMessage | undefined;

  onGoHome(): any;
  onNewDataReadyCompleted(): any;
}

class SummaryComponent extends React.Component<SummaryProps, {}> {
  componentDidMount() {
    const { newDataReadyMsg, onNewDataReadyCompleted } = this.props;
    if (newDataReadyMsg && !newDataReadyMsg.completed) {
      onNewDataReadyCompleted();
    }
  }

  componentDidUpdate(prev: SummaryProps) {
    const { newDataReadyMsg, onNewDataReadyCompleted } = this.props;
    if (newDataReadyMsg && !newDataReadyMsg.completed && (!prev.newDataReadyMsg || prev.newDataReadyMsg.completed)) {
      onNewDataReadyCompleted();
    }
  }

  render() {
    const { isDataFetched, items, scoring, onGoHome } = this.props;
    const totalIncrease = items.reduce(
      (acc, curr) =>
        curr.increase
          ? {
              savings: acc.savings || curr.increase.savings > 0,
              risk: acc.risk || curr.increase.risk > 0,
              quality: acc.quality || curr.increase.quality > 0
            }
          : acc,
      { savings: false, risk: false, quality: false }
    );
    const hasSomeIncrease = totalIncrease.quality || totalIncrease.risk || totalIncrease.savings;

    return (
      <section className="Summary">
        <img className="Summary__logo" src={icons.logoPensionskraft} alt="" />
        <div className="Summary__card">
          <div className="Summary__score">
            <h4 className="Summary__scoreTitle">Pensionskollen Resultat</h4>
            <h4 className="Summary__scoreText">Nu är din pensionskoll klar! Detta är ditt resultat.</h4>
            <StarsWithScore score={scoring.total} starBackgroundColor="rgba(0, 0, 0, 0.2)" starFillColor="#ffffff" />
          </div>
          <div className="Summary__improvements">
            {isDataFetched && hasSomeIncrease ? (
              <>
                <h4 className="Summary__improvementsTitle">Förbättringar</h4>
                <p className="Summary__improvementsText">Detta kan du göra för att förbättra din pension:</p>
                <ul className="Summary__improvementsList">
                  {totalIncrease.risk && <li>Justera din risk i dina pensionsinvesteringar</li>}
                  {totalIncrease.quality && <li>Byta till fonder med högre betyg</li>}
                  {totalIncrease.savings && <li>Starta ett pensionssparande</li>}
                </ul>
                <p className="Summary__improvementsText">
                  I din att-göra lista hittar du {items.length} förslag som hjälper dig med dom här sakerna.
                </p>
              </>
            ) : (
              <>
                <h4 className="Summary__improvementsTitle">Så får du hjälp med din pension</h4>
                <p className="Summary__improvementsText">
                  Varje månad får du ett meddelande som visar hur hur dina pensionspengar utvecklats. <br />
                  När vi hittar något som vi tycker du ska göra för att förbättra din pension får du ett meddelande.
                </p>
              </>
            )}
            <Button text="Sätt igång!" primary onClick={onGoHome} />
          </div>
        </div>
      </section>
    );
  }
}

export const Summary = connect(
  (state: AppState) => ({
    isDataFetched: !!state.scraping.result,
    scoring: currentScoresSelector(state),
    items: availableTodoItemListSelector(state),
    newDataReadyMsg: newDataReadyMessageSelector(state)
  }),
  (dispatch: Dispatch) => ({
    onGoHome() {
      dispatch(push(HomeRoute.format({})));
    },
    onNewDataReadyCompleted() {
      dispatch(notificationMessageCompleteAction({ code: MessageCodes.NewDataReady }));
    }
  })
)(SummaryComponent);
