import { Job } from "@common/models";
import { TodoItemType } from "@common/types";

import { defineAction } from "../../lib/rd-action-creators";

import { ScrapingStatus } from "./state";

export const startScraping = defineAction("START SCRAPING");
export const pollScrapingJob = defineAction<{ jobId: string }>("POLL SCRAPING JOB");
export const updateScrapingJob = defineAction<{ job: Job }>("UPDATE SCRAPING JOB");
export const setScrapingStatus = defineAction<{ status: ScrapingStatus }>("SET SCRAPING STATUS");
export const setInsuranceSkippedAction = defineAction<{
  itemType: TodoItemType.LifeInsurance | TodoItemType.SafetyInsurance;
}>("SET INSURANCE SKIPPED DATE");
export const setInsuranceUpsellSkippedAction = defineAction<{
  itemType: TodoItemType.LifeInsuranceUpsell | TodoItemType.SafetyInsuranceUpsell;
}>("SET INSURANCE UPSELL SKIPPED DATE");
