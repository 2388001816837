import * as React from "react";
import { useSelector, useDispatch } from "react-redux";

import { minpensionDataImages } from "@assets";
import { TextField, TextFieldType } from "@components/common";
import { updateTotalPensionAction } from "@state/minpensionData";
import { AppState } from "@state/store";

import { InstructionsLayout } from "./InstructionsLayout";

interface TotalPensionProps {
  onNext(): any;
  onBack(): any;
}

export const TotalPension = ({ onNext, onBack }: TotalPensionProps) => {
  const total = useSelector((state: AppState) => state.minpensionData.data?.totalPension || 0);
  const dispatch = useDispatch();

  return (
    <InstructionsLayout contentClassName="TotalPension" nextBtnText="Nästa" onNext={onNext} onBack={onBack}>
      <h5 className="MinpensionDataInstructions__title">Total pension</h5>
      <div className="MinpensionDataInstructions__text">
        Här anger du din totala intjänade pension.
        <br />
        <br />
        1. Klicka på "Intjänad pension" i menyraden
        <img
          className="MinpensionDataInstructions__image MinpensionDataInstructions__image--wide"
          src={minpensionDataImages.totalPension1}
        />
        2. Sedan skriver du in det belopp som står vid Total pension
        <img
          className="MinpensionDataInstructions__image MinpensionDataInstructions__image--wide"
          src={minpensionDataImages.totalPension2}
        />
        <div className="MinpensionDataInstructions__line" />
        <div className="TotalPension__row">
          <p>Total pension:</p>
          <TextField
            type={TextFieldType.Number}
            value={total.toString()}
            onChange={(v) => dispatch(updateTotalPensionAction({ total: +v }))}
            suffix="kr"
          />
        </div>
        <div className="MinpensionDataInstructions__line" />
      </div>
    </InstructionsLayout>
  );
};
