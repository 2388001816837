import * as React from "react";
import { createPortal } from "react-dom";

import { PensaModal, Layout } from "@components/common";
import { RecommendedFundsEntity } from "@common/models";

import "./index.scss";

interface ChangeFundsFundInfoProps {
  isOpen: boolean;
  recommendedFunds: RecommendedFundsEntity[];
  onClose(): any;
}

export const ChangeFundsFundInfo = ({ isOpen, recommendedFunds, onClose }: ChangeFundsFundInfoProps) => (
  <PensaModal
    header="Fondfakta"
    content={
      <div className="ChangeFundsFundInfo">
        {recommendedFunds.map(fund => (
          <a
            target="__blank"
            key={fund.isin}
            className="ChangeFundsFundInfo__link"
            href={`https://www.morningstar.se/se/funds/snapshot/snapshot.aspx?id=${fund.morningstar_secid}`}
          >
            {fund.minpension_name}
          </a>
        ))}
      </div>
    }
    isOpened={isOpen}
    onClose={onClose}
  />
);

interface ChangeFundsFormPreviewProps {
  pensionInstitute: string;
  pdfUrl: string;

  onClose(): any;
}

export const ChangeFundsFormPreview = ({ pensionInstitute, pdfUrl, onClose }: ChangeFundsFormPreviewProps) =>
  createPortal(
    <Layout
      className="ChangeFundsFormPreview"
      contentClassName="ChangeFundsFormPreview__content"
      title={pensionInstitute}
      onGoBack={onClose}
    >
      <object data={pdfUrl} width="100%" />
    </Layout>,
    document.querySelector("body")!
  );

export const ChangeFundsDisclaimer = () => (
  <p className="ChangeFundsDisclaimer">
    Det är viktigt att du är medveten om att pensionssparande i fonder och de placeringsalternativ vi rekommenderar dig
    är förknippade med risk. Vi kan aldrig garantera värdeökningar och teoretiskt löper man alltid risken att förlora
    hela det investerade beloppet.
  </p>
);
