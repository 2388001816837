import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { push, replace } from "connected-react-router";

import { AppState } from "@state/store";
import { Layout, Button } from "@components/common";
import { InAppMessage, MessageCodes, MessageClickActions } from "@common/models";
import {
  visibleMessagesSelector,
  notificationMessageReadAction,
  notificationMessageButtonClickAction
} from "@state/notifications";
import { NotificationsRoute } from "@common/routes";

import "./NotificationDetails.scss";

interface NotificationDetailsProps extends RouteComponentProps<{ code: MessageCodes }> {}

interface NotificationDetailsComponentProps {
  isUserFetched: boolean;
  message: InAppMessage | undefined;

  onGoBack(): any;
  onMessageNotExists(): any;
  onMessageRead(): any;
  onButtonClick(action: MessageClickActions): any;
}

class NotificationDetailsComponent extends React.Component<NotificationDetailsComponentProps, {}> {
  componentDidMount() {
    const { isUserFetched, message, onMessageNotExists, onMessageRead } = this.props;
    if (isUserFetched) {
      message ? !message.viewed && onMessageRead() : onMessageNotExists();
    }
  }

  componentDidUpdate(prev: NotificationDetailsComponentProps) {
    const { isUserFetched, message, onMessageRead, onMessageNotExists } = this.props;
    if (!prev.isUserFetched && isUserFetched) {
      message ? !message.viewed && onMessageRead() : onMessageNotExists();
    }
  }

  render() {
    const { message, onGoBack, onButtonClick } = this.props;
    const [btnText, action] = message && message.click_action ? message.click_action.split(":") : ["", ""];
    return message ? (
      <Layout onGoBack={onGoBack} title={message.title}>
        <div className="NotificationDetails">
          <div className="NotificationDetails__textCard">
            <p className="NotificationDetails__text">{message.body}</p>
          </div>

          {btnText && action && <Button text={btnText} onClick={() => onButtonClick(action as MessageClickActions)} />}
        </div>
      </Layout>
    ) : (
      ""
    );
  }
}

export const NotificationDetails = connect(
  (state: AppState, ownProps: NotificationDetailsProps) => ({
    isUserFetched: !!state.user.data,
    message: visibleMessagesSelector(state).find(msg => msg.code === ownProps.match.params.code)
  }),
  (dispatch: Dispatch, ownProps: NotificationDetailsProps) => ({
    onGoBack() {
      dispatch(push(NotificationsRoute.format({})));
    },
    onMessageNotExists() {
      dispatch(replace(NotificationsRoute.format({})));
    },
    onMessageRead() {
      dispatch(notificationMessageReadAction({ code: ownProps.match.params.code }));
    },
    onButtonClick(action: MessageClickActions) {
      dispatch(notificationMessageButtonClickAction({ action, code: ownProps.match.params.code }));
    }
  })
)(NotificationDetailsComponent as any);
