import * as React from "react";

interface ChevronProps {
  className?: string;
  color?: string;
  direction: "left" | "right" | "up" | "down";
  size?: string;
  onClick?(): any;
}

const rotateMap = {
  right: 0,
  down: 90,
  left: 180,
  up: 270
};

export const Chevron = ({ size, direction, className, color, onClick }: ChevronProps) => {
  return (
    <svg
      className={className}
      style={{
        transform: `rotate(${rotateMap[direction]}deg)`
      }}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width={size}
      height={size}
      viewBox="0 0 185.343 185.343"
      onClick={onClick}
    >
      <g>
        <path
          fill={color || "black"}
          d="M51.707,185.343c-2.741,0-5.493-1.044-7.593-3.149c-4.194-4.194-4.194-10.981,0-15.175
			l74.352-74.347L44.114,18.32c-4.194-4.194-4.194-10.987,0-15.175c4.194-4.194,10.987-4.194,15.18,0l81.934,81.934
			c4.194,4.194,4.194,10.987,0,15.175l-81.934,81.939C57.201,184.293,54.454,185.343,51.707,185.343z"
        />
      </g>
    </svg>
  );
};
