import * as React from "react";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { push } from "connected-react-router";
import { RouteComponentProps } from "react-router-dom";

import { AppState } from "@state/store";
import {
  getAxaProductsDescriptionAction,
  lifeInsuranceOfferSelector,
  uncoveredCapitalSelector,
  createInsuranceAction,
  upsellInsuranceAction,
} from "@state/insurance";

import { HomeRoute, LifeInsuranceUpsellRoute } from "@common/routes";
import { AutogiroForm, initialAutogiroFormValues, AxaProduct } from "@common/models";
import { PensaAutogiro, Layout } from "@components/common";
import { skipItemAction } from "@state/todoItem";

import { LifeInsuranceBenefits } from "./benefits";
import { LifeInsuranceHealthCheck } from "./healthCheck";
import { LifeInsuranceSummary } from "./summary";

import "./LifeInsurance.scss";

interface LifeInsuranceProps {
  lifeInsuranceOffer: AxaProduct | undefined;
  uncoveredCapital: number;
  isSaving: boolean;
  isUpsell: boolean;
  isUserLoaded: boolean;
  isSkipping: boolean;

  onGetProducts(): any;
  onGoHome(): any;
  onSkipTodoItem(): any;
  onCreateLifeInsurance(productCode: string, coverAmount: number, price: number, form: AutogiroForm): any;
  onUpsellLifeInsurance(productCode: string, coverAmount: number, price: number): any;
}

export const LifeInsurance = connect(
  (state: AppState, ownProps: RouteComponentProps<{}>) => ({
    isUserLoaded: !!state.user.data,
    isSaving: state.insurance.isSaving,
    uncoveredCapital: uncoveredCapitalSelector(state),
    lifeInsuranceOffer: lifeInsuranceOfferSelector(state),
    isSkipping: state.todoItem.isSkippingInProgress,
    isUpsell: LifeInsuranceUpsellRoute.match(ownProps.location.pathname).isMatched,
  }),
  (dispatch: Dispatch) => ({
    onGoHome() {
      dispatch(push(HomeRoute.format({})));
    },
    onGetProducts() {
      dispatch(getAxaProductsDescriptionAction({}));
    },
    onCreateLifeInsurance(productCode: string, coverAmount: number, price: number, form: AutogiroForm) {
      dispatch(createInsuranceAction({ productCode, coverAmount, price, form, isStandaloneFlow: false }));
    },
    onUpsellLifeInsurance(productCode: string, coverAmount: number, price: number) {
      dispatch(upsellInsuranceAction({ productCode, coverAmount, price, isStandaloneFlow: false }));
    },
    onSkipTodoItem() {
      dispatch(skipItemAction({}));
    },
  })
)(
  ({
    isSaving,
    isUpsell,
    isUserLoaded,
    isSkipping,
    uncoveredCapital,
    lifeInsuranceOffer,
    onGetProducts,
    onGoHome,
    onCreateLifeInsurance,
    onUpsellLifeInsurance,
    onSkipTodoItem,
  }: LifeInsuranceProps) => {
    const [selectedAmount, setSelectedAmount] = useState<number | undefined>(undefined);
    const [healthCheck, setHealthCheck] = useState({ allChecked: false, completed: false });
    const [bankDetails, setBankDetails] = useState<{ form: AutogiroForm; completed: boolean }>({
      form: initialAutogiroFormValues,
      completed: false,
    });
    const price = selectedAmount ? lifeInsuranceOffer!.options.grossMonthlyPremium[selectedAmount] : 0;
    const productCode = lifeInsuranceOffer && lifeInsuranceOffer.id;

    useEffect(() => {
      if (!lifeInsuranceOffer && isUserLoaded) {
        onGetProducts();
      }
    }, [lifeInsuranceOffer, isUserLoaded]);

    useEffect(() => {
      window.scrollTo && window.scrollTo({ top: 0 });
    }, [selectedAmount, healthCheck, bankDetails]);

    if (!selectedAmount) {
      return (
        <LifeInsuranceBenefits
          alternatives={lifeInsuranceOffer && lifeInsuranceOffer.options}
          uncoveredCapital={uncoveredCapital}
          isSkipping={isSkipping}
          isUpsell={isUpsell}
          onNext={setSelectedAmount}
          onPrev={onGoHome}
          onSkip={onSkipTodoItem}
        />
      );
    } else if (!healthCheck.completed) {
      return (
        <LifeInsuranceHealthCheck
          initAllChecked={healthCheck.allChecked}
          onPrev={() => setSelectedAmount(undefined)}
          onNext={() => setHealthCheck({ allChecked: true, completed: true })}
        />
      );
    } else if (!bankDetails.completed && !isUpsell) {
      return (
        <Layout
          title="Kontouppgifter"
          contentClassName="LifeInsuranceBankDetails"
          onGoBack={() => setHealthCheck({ allChecked: true, completed: false })}
        >
          <PensaAutogiro
            initialValues={bankDetails.form}
            subscriptionInfo={
              <>
                Försäkringen kostar <strong>{price.toLocaleString("sv")} kr</strong> per månad och betalas via autogiro.
              </>
            }
            termsInfo={
              <>
                Jag accepterar autogirovillkoren som finns på{" "}
                <a href="https://www.pensionskraft.se/livforsakring/" target="_blank">
                  www.pensionskraft.se/livforsakring
                </a>{" "}
                och godkänner att premien dras månadsvis via autogiro från nedanstående konto:
              </>
            }
            onSubmit={(form) => setBankDetails({ form, completed: true })}
          />
        </Layout>
      );
    } else if (selectedAmount && healthCheck.completed && (bankDetails.completed || isUpsell)) {
      return (
        <LifeInsuranceSummary
          isSaving={isSaving}
          price={price}
          coverage={selectedAmount || 0}
          onPrev={() =>
            isUpsell
              ? setHealthCheck({ allChecked: true, completed: false })
              : setBankDetails({ ...bankDetails, completed: false })
          }
          onNext={() =>
            isUpsell
              ? onUpsellLifeInsurance(productCode!, selectedAmount, price)
              : onCreateLifeInsurance(productCode!, selectedAmount, price, bankDetails.form)
          }
        />
      );
    }

    return null;
  }
);
