import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";

import { RippleEffect, Button, PensaEmailModal } from "@components/common";
import { ChangeFundTodoItem, RecommendedFundsEntity } from "@common/models";
import { pensionInstitutesLogo, icons } from "@assets";
import { AppState } from "@state/store";
import { selectedTodoItemSelector, currentItemRecommededFundsSelector } from "@state/todoItem";
import {
  ChangeFundsPreviewState,
  toggleFormPreviewAction,
  signDocumentAction,
  sendChangeFundFormAction
} from "@state/changeFunds";

import { ChangeFundsFundInfo, ChangeFundsFormPreview, ChangeFundsDisclaimer } from "../common";

import "./ChangeFundsPrint.scss";

interface ChangeFundsPrintProps {
  item: ChangeFundTodoItem;
  preview: ChangeFundsPreviewState;
  isFetching: boolean;
  recommendedFunds: RecommendedFundsEntity[];
  email: string;

  onTogglePreview(): any;
  onPreviewDocument(): any;
  onSendDocument(email: string): any;
}

interface ChangeFundsPrintState {
  isFundInfoModalOpened: boolean;
  isEmailModalOpen: boolean;
  email?: string;
}

class ChangeFundsPrintAtom extends React.Component<ChangeFundsPrintProps, ChangeFundsPrintState> {
  readonly state: ChangeFundsPrintState = {
    isFundInfoModalOpened: false,
    isEmailModalOpen: false
  };

  canvas: HTMLCanvasElement | null;
  signaturePad?: any;

  componentDidMount() {
    this.setCanvasWidth();
  }

  private setCanvasWidth = () => {
    const measure = document.querySelector(".ChangeFundsPrint__line");
    this.setState(prev => ({ ...prev, signaturePadWidth: measure!.clientWidth }));
  };

  onToggleFundInfoModal = () => {
    this.setState(prev => ({ ...prev, isFundInfoModalOpened: !prev.isFundInfoModalOpened }));
  };

  onToggleEmailModal = () => {
    this.setState(prev => ({ ...prev, isEmailModalOpen: !prev.isEmailModalOpen }));
  };

  onChangeEmail = (email: string) => {
    this.setState(prev => ({ ...prev, isEmailModalOpen: false, email }));
  };

  onPreview = () => {
    const { item, preview, isFetching, onTogglePreview, onPreviewDocument } = this.props;
    if (isFetching) {
      return;
    }
    item.id === preview.itemId ? onTogglePreview() : onPreviewDocument();
  };

  render() {
    const { item, email, preview, recommendedFunds, onTogglePreview, onSendDocument } = this.props;
    const { isFundInfoModalOpened, isEmailModalOpen, email: newEmail } = this.state;
    return (
      <React.Fragment>
        <div className="ChangeFundsPrint__card">
          <img className="ChangeFundsPrint__logo" src={pensionInstitutesLogo[item.payload.pensionInstitute]} alt="" />
          <div className="ChangeFundsPrint__line" />
          <p className="ChangeFundsPrint__text">
            Vi mailar dig en fondbytesblankett som du bara behöver skriva ut, signera och skicka till{" "}
            <strong>{item.payload.pensionInstitute}</strong>
          </p>
          <RippleEffect className="ChangeFundsPrint__preview" rounded onClick={this.onPreview}>
            <img src={icons.funds} alt="" />
            <p>Visa blanketten</p>
          </RippleEffect>
          <div className="ChangeFundsPrint__text">
            <span>Du ser vilka fonder som föreslås i dokumentet. Du hittar fakta om fonderna </span>
            <RippleEffect className="ChangeFundsPrint__inlineBtn" rounded onClick={this.onToggleFundInfoModal}>
              <span>här.</span>
            </RippleEffect>
          </div>
          <div className="ChangeFundsPrint__text">
            <span>
              Den mailas till <strong>{newEmail || email}</strong>{" "}
            </span>
            <RippleEffect className="ChangeFundsPrint__inlineBtn" rounded onClick={this.onToggleEmailModal}>
              <span>(ändra email)</span>
            </RippleEffect>
          </div>
        </div>
        <Button
          text="Maila så skriver jag ut!"
          primary
          disabled={false}
          onClick={() => onSendDocument(newEmail || email)}
        />
        <ChangeFundsDisclaimer />
        {preview.isOpen && (
          <ChangeFundsFormPreview
            pensionInstitute={item.payload.pensionInstitute}
            pdfUrl={preview.pdfViewerDocUrl || ""}
            onClose={onTogglePreview}
          />
        )}
        <ChangeFundsFundInfo
          isOpen={isFundInfoModalOpened}
          recommendedFunds={recommendedFunds}
          onClose={this.onToggleFundInfoModal}
        />
        <PensaEmailModal
          isOpened={isEmailModalOpen}
          initialEmail={newEmail || email}
          header="Ändra email:"
          onSubmit={this.onChangeEmail}
          onClose={this.onToggleEmailModal}
        />
      </React.Fragment>
    );
  }
}

export const ChangeFundsPrint = connect(
  (state: AppState) => ({
    item: selectedTodoItemSelector(state),
    preview: state.changeFunds.preview,
    isFetching: state.changeFunds.isFetching,
    recommendedFunds: currentItemRecommededFundsSelector(state),
    email: state.user.data && state.user.data.email
  }),
  (dispatch: Dispatch) => ({
    onTogglePreview() {
      dispatch(toggleFormPreviewAction({}));
    },
    onPreviewDocument() {
      dispatch(signDocumentAction({ signature: "", isReview: true }));
    },
    onSendDocument(email: string) {
      dispatch(sendChangeFundFormAction({ email }));
    }
  })
)(ChangeFundsPrintAtom);
