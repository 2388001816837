import { AxaProduct, AxaGroupInsurance } from "@common/models";

export interface InsuranceState {
  isSaving: boolean;
  axaProducts: {
    data?: AxaProduct[];
    isLoading: boolean;
  };
  activeInsurances?: AxaGroupInsurance[];
}

export const defaultInsuranceState: InsuranceState = {
  isSaving: false,
  axaProducts: {
    isLoading: false
  }
};
