import * as React from "react";

import { TodoItemIncrease } from "@components/common";

import "./TodoItemBenefitsSummary.scss";

interface TodoItemBenefitsSummaryProps {
  isBetterRisk?: boolean;
  isBetterFunds?: boolean;
}

export const TodoItemBenefitsSummary = ({ isBetterRisk, isBetterFunds }: TodoItemBenefitsSummaryProps) => {
  return (
    <div className="TodoItemBenefitsSummary">
      <div className="TodoItemBenefitsSummary__list">
        <p className="TodoItemBenefitsSummary__title">Fördelarna för dig:</p>
        <ul>
          {isBetterFunds && <li>• Du får fonder med högre fondbetyg</li>}
          {isBetterRisk && <li>• Du får fonder med lämpligare risk för dig</li>}
        </ul>
      </div>
      <div className="TodoItemBenefitsSummary__line" />
      <TodoItemIncrease withLink />
    </div>
  );
};
