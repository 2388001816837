import * as React from "react";

import { LoginLayout, RippleEffect, PensaLogo } from "@components/common";

import "./RecommendationToUseDesktop.scss";

interface RecommendationToUseDesktopProps {
  onUseMobileAnyway(): any;
}

export const RecommendationToUseDesktop = ({ onUseMobileAnyway }: RecommendationToUseDesktopProps) => {
  return (
    <LoginLayout className="RecommendationToUseDesktop">
      <div className="RecommendationToUseDesktop__logo">
        <PensaLogo width="100%" height="100%" color="#ff3366" />
      </div>
      <p className="RecommendationToUseDesktop__text">
        Det verkar som du surfar från en mobiltelefon.
        <br /> I nästa steg kommer vi hämta information om din pension. Vi rekommenderar att du stänger detta fönster
        och använder en dator istället.
        <br /> Det kommer vara lättare för dig.
      </p>

      <RippleEffect className="RecommendationToUseDesktop__link" onClick={onUseMobileAnyway} rounded>
        Jag förstår, men vill trots det använda mobilen
      </RippleEffect>
    </LoginLayout>
  );
};
