import * as React from "react";
import { push, goBack } from "connected-react-router";
import { connect } from "react-redux";
import { Dispatch } from "redux";

import { AppState } from "@state/store";
import { Layout } from "@components/common";
import { SupportRoute } from "@common/routes";

import "./SupportTerms.scss";

interface SupportTermsProps {
  hasPrevLocation: boolean;

  onGoBack(): any;
  onGoToSupport(): any;
}

export const SupportTerms = connect(
  (state: AppState) => ({
    hasPrevLocation: !!state.router.prevLocation
  }),
  (dispatch: Dispatch) => ({
    onGoToSupport() {
      dispatch(push(SupportRoute.format({})));
    },
    onGoBack() {
      dispatch(goBack());
    }
  })
)(({ hasPrevLocation, onGoToSupport, onGoBack }: SupportTermsProps) => {
  return (
    <Layout title="Avtalsvillkor" onGoBack={hasPrevLocation ? onGoBack : onGoToSupport} contentClassName="SupportTerms">
      <div className="SupportTerms__card">
        <p className="SupportTerms__text">
          Dessa kund och användarvillkor (”Villkoren”) reglerar användningen av Pensionskrafts tjänster. Pensionskraft
          är ett varumärke (reg.nr 2018/066099) som ägs av Pensa Sverige AB (‘Företaget’) org.nr 556968-2148, ett bolag
          med tillstånd från Finansinspektionen att bedriva förmedling av livförsäkringar(direkt),
          investeringsrådgivning och fondandelsförmedling, när du köper vår Premiumtjänst (”Tjänste- och
          Privatpensions-tjänsten”) och även om du väljer den kostnadsfria tilläggstjänsten Premiepensionstjänsten eller
          den kostnadsfria Gratistjänsten. Allt detta berättar vi mer om nedan.
        </p>
        <h3 className="SupportTerms__title">PREMIUMTJÄNSTEN UTGÖRS AV TVÅ TJÄNSTER</h3>
        <p className="SupportTerms__text">
          TJÄNSTE- OCH PRIVATPENSIONSTJÄNSTEN
          <br />
          I vår Tjänste- och Privatpensionstjänst ingår (1) en översikt och sammanställning över var dina svenska
          pensionspengar befinner sig idag, (2) en prognos för vad du får ut i pension om du inte gör något, samt (3)
          vilka förändringar vi tycker du borde göra baserat på den lämplighetsbedömning vi gjort. Vi gör inga
          automatiska förändringar utan ditt godkännande. Det ingår också regelbundna uppdateringar om hur ditt
          pensionskapital utvecklats.
          <br />
          PREMIEPENSIONSTJÄNSTEN
          <br /> Om du har Tjänste- och Privatpensionstjänsten och även önskar få information och rådgivning kring din
          premiepension kostar det inget extra. Avtal om vår Premiepensionstjänst ingås först efter att du fått tillgång
          till detta Avtal och skriftligt beställt tjänsten antingen genom e-post, webb, sms eller undertecknande av en
          särskild bekräftelsehandling. Om du inte ger oss en skriftlig bekräftelse kan vi tyvärr inte hjälpa till med
          din premiepension och avtalet om premiepensionstjänsten är inte giltigt. Vidare är erbjudandet av
          Premiepensionstjänsten beroende på huruvida ovan nämnda tillstånd från Finansinspektionen täcker rådgivning
          kring den.
        </p>
        <h3 className="SupportTerms__title">GRATISTJÄNSTEN</h3>
        <p className="SupportTerms__text">
          I gratistjänsten får du regelbunden information om din pension. Du har även möjlighet att uppgradera till vår
          Premiumtjänst som innefattar ovanstående beskrivna tjänster.
        </p>
        <h3 className="SupportTerms__title">VAD DU BEHÖVER GÖRA</h3>
        <p className="SupportTerms__text">
          För att du ska få tillgång till Pensionskrafts tjänster behöver du ge oss dina aktuella kontaktuppgifter och
          allteftersom uppdatera dem om något ändras. Du kan behöva underteckna en fullmakt du får från oss. Vill du
          genomföra de förändringar som vi föreslår kan du antingen (1) göra det på egen hand genom att kontakta
          respektive försäkringsbolag eller Pensions-myndigheten, eller (2) låta Pensionskraft göra det åt dig. Om du
          vill att vi gör det åt dig behöver du fylla i de blanketter som du fått tillhandahållna av oss. Alternativ (1)
          är snabbare.
        </p>
        <h3 className="SupportTerms__title">RISKINFORMATION</h3>
        <p className="SupportTerms__text">
          Det är viktigt att du är medveten om att sparande i fonder och andra finansiella investeringar som föreslås är
          förknippade med risk. Vi kan aldrig garantera värdeökningar och den tidigare värdeutvecklingen och
          avkastningen ger inte någon säker vägledning om den framtida utvecklingen och avkastningen. Det vi garanterar
          är att vi gör vårt bästa för din pension.
        </p>
        <h3 className="SupportTerms__title">ÅNGERRÄTT</h3>
        <p className="SupportTerms__text">
          Som kund hos Företaget har du ångerrätt enligt lagen (2005:59) om distansavtal och avtal utanför
          affärslokaler. Om du vill utnyttja ångerrätten kan du göra det via brev, email eller telefon (se
          kontaktuppgifter nedan).
          <br />
          <strong>Ångerrätt Premiumtjänsten</strong>
          <br />
          För att utnyttja din ångerrätt behöver du meddela oss inom 14 dagar från det att du fick del av detta Avtal.
          <br />
          <strong>Ångerrätt Premiepensionstjänsten</strong>
          <br />
          För att utnyttja din ångerrätt behöver du meddela oss inom 14 dagar från det att du fått avtalet.
          <br />
          <strong>Ångerrätt Gratistjänsten</strong>
          <br />
          Du kan när som helst välja att avsluta Gratistjänsten.
        </p>
        <h3 className="SupportTerms__title">PRISER</h3>
        <p className="SupportTerms__text">
          Priset för Tjänste- och Privatpensionstjänsten är endast 995 kr för ett helt år. Du kan välja att betala
          årsavgiften månadsvis 96 kr/mån via autogiro. Väljer du att betala för ett år görs det i förskott och då är
          betalningstiden 20 dagar.
        </p>
        <h3 className="SupportTerms__title">SKATTER OCH AVGIFTER</h3>
        <p className="SupportTerms__text">
          Du svarar för eventuella skatter och andra avgifter som ska betalas enligt svensk eller utländsk lag med
          anledning av uppdrag som du gett Pensionskraft enligt Avtalet. Detta gäller bland annat förvaltningsavgifter
          för de fonder du väljer att placera ditt pensionskapital i.
        </p>
        <h3 className="SupportTerms__title">AVTALSTID OCH UPPSÄGNING</h3>
        <p className="SupportTerms__text">
          För Premiumtjänsten gäller Villkoren i ett år från det att du blev kund och vid förlängning förlängs det med
          ett år i taget. Vill du inte längre vara kund, kan du när som helst meddela oss det. Det gör du enklast genom
          att ringa oss, men du kan också göra det via brev eller email (se kontaktuppgifter nedan). Om du vill säga upp
          Avtalet innan det förlängs behöver vi få din uppsägning senast dagen innan en ny avtalsperiod påbörjas. Vi
          meddelar dig skriftligen senast 30 dagar innan ny avtalsperiod börjar. Har vi inte skickat meddelande om
          förnyelse senast 30 dagar innan ny avtalsperiod påbörjas har du rätt att säga upp avtalet på en gång. Har en
          ny avtalsperiod påbörjats utan att du sagt upp Avtalet innan, så betalar vi inte tillbaka årsavgiften. Även vi
          kan säga upp avtalet på samma villkor som du och då gör vi det skriftligen via brev, email eller sms till den
          adress eller det telefonnummer du lämnat till oss. För Gratistjänsten gäller Villkoren fram tills det att du
          sagt upp tjänsten, vilket du kan göra genom att ringa, maila eller skicka brev till oss.
        </p>
        <h3 className="SupportTerms__title">KUND-NÖJDHETS-GARANTI</h3>
        <p className="SupportTerms__text">
          Pensionskraft ger en kund-nöjdhets-garanti för Premiumtjänsten till dig. Om du inte skulle vara nöjd med den,
          kan du inom tre (3) månader från det att du blev kund, säga upp tjänsten och få tillbaka inbetald årsavgift.
        </p>
        <h3 className="SupportTerms__title">KLAGOMÅL OCH TVISTLÖSNING</h3>
        <p className="SupportTerms__text">
          Om du eventuellt har klagomål på någon av våra tjänster får du gärna kontakta oss. Klagomål kan mailas till
          klagomal@pensionskraft.se eller skickas via brev till oss. Du kan också få din sak prövad i allmän domstol
          eller i vissa fall hos Allmänna Reklamationsnämnden (ARN). Svensk rätt ska tillämpas på Avtalet.
        </p>
        <h3 className="SupportTerms__title">PERSONUPPGIFTSHANTERING</h3>
        <p className="SupportTerms__text">
          I vår Personuppgiftspolicy finns det information om hur vi behandlar dina personuppgifter och hur vi skyddar
          din integritet när du använder någon av tjänsterna. Genom att godkänna dessa Användarvillkor samtycker du till
          den behandling av dina personuppgifter som framgår av vår Personuppgiftspolicy som finns på
          <a target="_blank" href="http://www.pensionskraft.se/kundavtal">
            http://www.pensionskraft.se/kundavtal
          </a>
          .
        </p>
        <h3 className="SupportTerms__title">ANSVARSBEGRÄNSNINGAR</h3>
        <p className="SupportTerms__text">
          Pensionskrafts ansvarsbegränsningar framgår i våra Allmänna Villkor, som finns på{" "}
          <a target="_blank" href="http://www.pensionskraft.se/kundavtal">
            http://www.pensionskraft.se/kundavtal
          </a>
          .
        </p>
        <h3 className="SupportTerms__title">ÄNDRING AV VILLKOREN</h3>
        <p className="SupportTerms__text">
          Pensionskraft har rätt att ändra villkoren i detta Avtal. De ändrade villkoren gäller då från den tid vi
          anger, dock tidigast från och med 15 dagar efter det datum vi meddelat dig via mail, brev eller sms. Om du
          inte godtar de ändrade villkoren har du rätt att säga upp Avtalet till omedelbart upphörande.
        </p>
        <h3 className="SupportTerms__title">KONTAKTUPPGIFTER</h3>
        <p className="SupportTerms__text">
          Pensa Sverige AB <br />
          Centralplan 15, 111 20 Stockholm <br />
          kundtjanst@pensionskraft.se <br />
          063 – 501 16 20 (kundtjänst) <br />
        </p>
      </div>
    </Layout>
  );
});
